/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select, { createFilter } from "react-select";
import HeaderJD from "../../components/Layout/HeaderJD";
import TryoutContext from "../../context/TryoutContext/TryoutContext";
import Modal from "../../components/Home/Modal";
import { getPendaftaranInfo, getPengerjaanInfo } from "../../script/dateUtils";
import GlobalContext from "../../context/GlobalContext/GlobalContext";
import dayjs from "dayjs";

const TryoutInfo = () => {
  const { tryoutId } = useParams();
  const navigate = useNavigate();
  const { tryoutList, subtesList, bundleList, getSubtes, kerjakanTryout } =
    useContext(TryoutContext);
  const { univList, jurusanList, getUnivList, getJurusanList } =
    useContext(GlobalContext);
  const [currentTryout, setCurrentTryout] = useState({});
  useEffect(() => {
    if (tryoutList && tryoutList.length) {
      const tryout = tryoutList.find(
        (item) => String(item.id) === String(tryoutId)
      );
      if (tryout) {
        let hasBundleAccess = false;
        const currentTimestamp = dayjs().valueOf();
        const { openPendaftaranDateTimestamp } = getPendaftaranInfo(tryout);
        const { endPengerjaanDateTimestamp } = getPengerjaanInfo(tryout);
        bundleList.forEach((item) => {
          if (item.status_daftar === "TERDAFTAR") {
            if (
              item.isi_bundle.find((item2) => item2.id_tryout === tryout.id)
            ) {
              hasBundleAccess = true;
            }
          }
        });
        if (!hasBundleAccess) {
          if (parseInt(tryout.id_partnership)) {
            // pakai promo
            if (!tryout.lunas) {
              navigate("/tryout");
              return;
            }
          }

          if (
            currentTimestamp < openPendaftaranDateTimestamp ||
            currentTimestamp > endPengerjaanDateTimestamp ||
            !tryout.waktu_verifikasi
          ) {
            navigate("/tryout");
          } else if (
            tryout.waktu_verifikasi &&
            currentTimestamp < tryout.waktu_verifikasi
          ) {
            navigate("/tryout");
          } else if (tryout.dimulai) {
            navigate(`/tryout/${tryout.id}/lobby`);
          } else {
            setGrupSubtes([]);
            getSubtes(tryout.id);
            setCurrentTryout(tryout);
            getUnivList();
          }
        } else {
          if (tryout.dimulai) {
            navigate(`/tryout/${tryout.id}/lobby`);
          } else if (currentTimestamp < openPendaftaranDateTimestamp) {
            navigate(`/tryout`);
          } else {
            setGrupSubtes([]);
            getSubtes(tryout.id);
            setCurrentTryout(tryout);
            getUnivList();
          }
        }
      } else {
        navigate("/tryout");
      }
    }
  }, [tryoutList, bundleList]);

  useEffect(() => {
    if (subtesList.length) {
      if (subtesList[0].id_tryout === currentTryout.id) {
        const uniqueSubtesList = subtesList
          .map((item) => item.tipe_string)
          .filter((value, index, self) => {
            return self.indexOf(value) === index;
          });
        const newGrupSubtes = uniqueSubtesList.map((item) => {
          const filtered = subtesList.filter(
            (item2) => item2.tipe_string === item
          );
          return {
            tipe_string: filtered[0].tipe_string,
            subtesList: filtered,
          };
        });
        setGrupSubtes(newGrupSubtes);
      } else {
        setGrupSubtes([]);
      }
    }
  }, [subtesList]);

  const [grupSubtes, setGrupSubtes] = useState([]);
  const [currentUniv, setCurrentUniv] = useState("");
  const [jurusanDisabled, setJurusanDisabled] = useState(true);

  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState(<></>);

  const [lobbyForm, setLobbyForm] = useState({
    klaster: 1,
    jurusan: 0,
  });

  const refreshJurusanList = async (univ, klaster) => {
    setLobbyForm({ klaster, jurusan: 0 });
    setJurusanDisabled(true);
    await getJurusanList(univ, klaster);
    setJurusanDisabled(false);
  };

  const DynamicSelect = ({ option, currentValue }) => {
    const options = option.map((item) => {
      return { value: item.id, label: item.jurusan };
    });
    const MenuList = function MenuList(props) {
      const children = props.children;

      if (!children.length) {
        return <>{children}</>;
      }

      return (
        <>
          {children.length &&
            children.map((key) => {
              delete key.props.innerProps.onMouseMove; //FIX LAG!!
              delete key.props.innerProps.onMouseOver; //FIX LAG!!

              return key;
            })}
        </>
      );
    };

    return (
      <Select
        placeholder="Pilih program studi"
        isDisabled={jurusanDisabled}
        styles={{
          control: () => ({
            alignItems: "center",
            border: "none",
            display: "flex",
            flexWrap: "wrap",
            WebkitBoxPack: "justify",
            justifyContent: "space-between",
            minHeight: "38px",
            position: "relative",
          }),
          menu: (provided, state) => ({
            ...provided,
            height: "13rem",
            overflow: "auto",
          }),
          placeholder: (style) => ({
            ...style,
            color: "black",
          }),
        }}
        className={`w-full border-none rounded-xl outline-none font-content-1 ${
          jurusanDisabled ? "bg-abu" : "bg-krem"
        }`}
        onChange={(val) => {
          setLobbyForm({ ...lobbyForm, jurusan: val.value });
        }}
        options={options}
        filterOption={createFilter({ ignoreAccents: false })}
        components={{
          MenuList,
        }}
        value={options.find((item) => item.value === currentValue)}
      />
    );
  };

  const [alertText, setAlertText] = useState({
    show: false,
    text: "",
    success: true,
  });

  const hideAlert = () => {
    setAlertText({
      show: false,
      text: alertText.message,
      success: alertText.success,
    });
  };

  const setSuccessAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: true,
    });
  };
  const setErrorAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: false,
    });
  };

  const onMulaiTryout = async () => {
    const res = await kerjakanTryout({
      id_tryout: currentTryout.id,
      id_jurusan: lobbyForm.jurusan,
    });

    if (res.success) navigate(`/tryout/${currentTryout.id}/lobby`);
    else {
      setOpen(false);
      setErrorAlert(res.display_message);
    }
  };

  const onSubmitLobby = () => {
    hideAlert();
    if (lobbyForm.jurusan && lobbyForm.klaster) {
      setModalContent(
        <>
          <div className="flex w-full justify-end p-7 pb-0">
            <i
              onClick={() => setOpen(false)}
              className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
            ></i>
          </div>
          <div className="flex flex-col gap-6 w-full px-10 pb-10">
            <h5 className="font-bold text-center">Konfirmasi</h5>
            <div className="flex flex-col gap-2 w-full">
              <p className="font-content-0 w-full text-center">
                Apakah kamu yakin ingin memulai Try Out ini?
              </p>
            </div>

            <div className="flex justify-center gap-4 w-100">
              <button
                onClick={onMulaiTryout}
                className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-white rounded-xl text-center"
              >
                Kerjakan Sekarang
              </button>
              <button
                onClick={() => setOpen(false)}
                type="button"
                className="border-2 border-oren font-content-0 flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-black rounded-xl text-center"
              >
                Batal
              </button>
            </div>
          </div>
        </>
      );
      setOpen(true);
    } else {
      setErrorAlert("Kolom universitas dan program studi wajib dipilih !");
    }
  };

  return (
    <>
      <div className="flex flex-col bg-white p-10 justify-start m-0 w-full">
        <HeaderJD title="Detail Try Out" />

        <div className="flex items-center justify-center flex-1 w-full">
          <div className="flex flex-col items-center justify-center text-center bg-white shadow-lg rounded-2xl p-7 md:px-14 gap-5 w-full md:w-4/5 lg:w-3/4 xl:w-2/3">
            <p className="font-subtitle font-bold">
              {currentTryout.nama_tryout}
            </p>
            <p className="font-content-0 font-bold">Rincian Subtes</p>

            {grupSubtes.length &&
              grupSubtes.map((item, index) => (
                <div className="w-full flex flex-col gap-5" key={index}>
                  <p className="font-content-0 font-bold mt-4">
                    {item.tipe_string}
                  </p>
                  <table className="w-full">
                    <thead>
                      <tr className="bg-krem">
                        <th className="font-content-1 font-bold rounded-l-xl py-2 px-3 w-1/2 md:w-3/5">
                          Subtes
                        </th>
                        <th className="font-content-1 font-bold py-2 px-3">
                          Jumlah Soal
                        </th>
                        <th className="font-content-1 font-bold rounded-r-xl py-2 px-3">
                          Waktu
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.subtesList.map((item2, index2) => (
                        <tr key={index2}>
                          <td className="font-content-1 text-left pl-2 md:pl-6 py-2">
                            {item2.nama}
                          </td>
                          <td className="font-content-1 text-center py-2">
                            {item2.jumlah_soal}
                          </td>
                          <td className="font-content-1 text-center py-2">
                            {parseFloat(item2.waktu)} menit
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ))}

            <p className="font-content-0 font-bold mt-4">Pilih Klastermu</p>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="flex justify-center gap-5 items-center">
                <div className="flex gap-1 items-center justify-center">
                  <input
                    type="radio"
                    name="klaster"
                    checked={lobbyForm.klaster === 1}
                    onChange={() => {
                      if (currentUniv !== "") {
                        refreshJurusanList(currentUniv, 1);
                      } else {
                        setLobbyForm({ ...lobbyForm, klaster: 1 });
                      }
                    }}
                  />
                  <p className="font-content-1">Saintek</p>
                </div>
                <div className="flex gap-1 items-center justify-center">
                  <input
                    type="radio"
                    name="klaster"
                    checked={lobbyForm.klaster === 2}
                    onChange={() => {
                      if (currentUniv !== "") {
                        refreshJurusanList(currentUniv, 2);
                      } else {
                        setLobbyForm({ ...lobbyForm, klaster: 2 });
                      }
                    }}
                  />
                  <p className="font-content-1">Soshum</p>
                </div>
              </div>
            </form>

            <p className="font-content-0 font-bold mt-4 text-center">
              Pilih Universitas Impianmu
            </p>
            <div className="w-full md:w-1/2 flex border-2 border-none rounded-xl items-center select-container">
              <Select
                placeholder="Pilih universitas"
                styles={{
                  control: () => ({
                    alignItems: "center",
                    border: "none",
                    display: "flex",
                    flexWrap: "wrap",
                    WebkitBoxPack: "justify",
                    justifyContent: "space-between",
                    minHeight: "38px",
                    position: "relative",
                  }),
                  placeholder: (style) => ({
                    ...style,
                    color: "black",
                  }),
                }}
                className="w-full border-none rounded-xl outline-none font-content-1 bg-krem"
                onChange={async (val) => {
                  if (val.value !== currentUniv) {
                    setCurrentUniv(val.value);

                    refreshJurusanList(val.value, lobbyForm.klaster);
                  }
                }}
                options={univList.map((item) => {
                  return { value: item, label: item };
                })}
                filterOption={createFilter({ ignoreAccents: false })}
              />
            </div>
            <p className="font-content-0 font-bold mt-4 text-center">
              Program Studi Impianmu
            </p>
            <div className="w-full md:w-1/2 flex border-2 border-none rounded-xl items-center">
              <DynamicSelect
                option={jurusanList}
                currentValue={lobbyForm.jurusan}
              />
            </div>

            {/* <div className="w-full flex flex-col gap-1">
              <p className="font-content-1 text-left">
                Sebelum mulai, berikut adalah beberapa peraturan Try Out Jalur
                Dalam:
              </p>
              <ul className="pl-5" style={{ listStyle: "unset" }}>
                <li>
                  <p className="font-content-1 text-left">
                    Rincian subtes Try Out ini sesuai dengan klaster program
                    studi yang dipilih dengan ketentuan seperti tabel di atas.
                  </p>
                </li>
                <li>
                  <p className="font-content-1 text-left">
                    Subtes yang dikerjakan akan berlanjut secara otomatis dengan
                    waktu istirahat 5 menit.
                  </p>
                </li>
                <li>
                  <p className="font-content-1 text-left">
                    Ketika tombol mulai diklik, kamu akan masuk ke laman
                    pengerjaan TPS dengan alokasi waktu{" "}
                    {grupSubtes.length &&
                      grupSubtes[0].subtesList
                        .map((item) => parseFloat(item.waktu))
                        .reduce((accumulator, a) => accumulator + a, 0)}{" "}
                    menit.
                  </p>
                </li>
              </ul>
            </div> */}

            <div
              className={`${
                alertText.show ? "flex" : "hidden"
              } p-4 mb-4 text-sm ${
                alertText.success
                  ? "text-green-700 bg-green-100"
                  : "text-red-700 bg-red-100"
              } rounded-lg`}
              role="alert"
            >
              <svg
                className="inline flex-shrink-0 mr-3 w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <div>{alertText.text}</div>
            </div>

            <button
              type="button"
              className="rounded-xl bg-oren text-white font-content-0 font-bold px-8 py-2"
              onClick={onSubmitLobby}
            >
              Mulai
            </button>
          </div>
        </div>
      </div>
      <Modal {...{ open, setOpen }}>
        <div className="relative text-left w-11/12 md:w-2/3 lg:w-1/2 xl:w-2/5 z-50 flex flex-col text-xl font-bold bg-white rounded-2xl">
          {modalContent}
        </div>
      </Modal>
    </>
  );
};

export default TryoutInfo;
