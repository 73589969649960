import { useEffect } from "react";
import { useState } from "react";

const EditPasswordUserComponent = ({
  currentUser,
  setOpen,
  editPasswordUser,
}) => {
  const [userForm, setUserForm] = useState({
    id_user: 0,
    password: "",
    confirmationPassword: "",
  });

  useEffect(() => {
    setUserForm({
      id_user: currentUser.id,
      password: "",
      confirmationPassword: "",
    });
  }, [currentUser]);

  const [alertText, setAlertText] = useState({
    show: false,
    text: "",
    success: true,
  });

  const setSuccessAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: true,
    });
  };
  const setErrorAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: false,
    });
  };
  const hideAlert = () => {
    setAlertText({
      show: false,
      text: alertText.message,
      success: alertText.success,
    });
  };

  const onSubmitEdit = async (e) => {
    e.preventDefault();
    hideAlert();
    if (userForm.password !== userForm.confirmationPassword) {
      setErrorAlert("Kata sandi tidak cocok.");
    } else {
      const button = e.target;
      button.disabled = true;
      const res = await editPasswordUser(userForm);
      button.disabled = false;
      if (res.success) {
        setSuccessAlert("Kata sandi berhasil diubah.");
      } else {
        setErrorAlert("Gagal mengubah kata sandi. " + res.display_message);
      }
    }
  };
  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <form
        onSubmit={onSubmitEdit}
        className="flex flex-col gap-6 w-full px-10 pb-10"
      >
        <h6 className="font-bold text-center">Ubah Kata Sandi</h6>
        <div className="flex flex-col gap-3 w-full mt-5">
          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Kata sandi baru</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="password"
                minLength="6"
                maxLength="20"
                value={userForm.password}
                onChange={(e) => {
                  setUserForm({
                    ...userForm,
                    password: e.target.value,
                  });
                }}
                required
              />
            </div>
          </div>

          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Konfirmasi kata sandi baru</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="password"
                minLength="6"
                maxLength="20"
                value={userForm.confirmationPassword}
                onChange={(e) => {
                  setUserForm({
                    ...userForm,
                    confirmationPassword: e.target.value,
                  });
                }}
                required
              />
            </div>
          </div>

          <div
            className={`${
              alertText.show ? "flex" : "hidden"
            } p-4 mb-4 text-sm ${
              alertText.success
                ? "text-green-700 bg-green-100"
                : "text-red-700 bg-red-100"
            } rounded-lg`}
            role="alert"
          >
            <svg
              className="inline flex-shrink-0 mr-3 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <div>{alertText.text}</div>
          </div>
        </div>
        <button
          type="submit"
          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-full text-white rounded-xl m-auto"
        >
          Simpan Perubahan
        </button>
      </form>
    </>
  );
};

export default EditPasswordUserComponent;
