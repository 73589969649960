/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HeaderJD from "../../components/Layout/HeaderJD";
import TryoutContext from "../../context/TryoutContext/TryoutContext";
import Modal from "../../components/Home/Modal";
import { getPendaftaranInfo, getPengerjaanInfo } from "../../script/dateUtils";
import dayjs from "dayjs";

const TryoutLobby = () => {
  const { tryoutId } = useParams();
  const navigate = useNavigate();
  const {
    tryoutList,
    subtesList,
    bundleList,
    getSubtes,
    kerjakanSubtes,
    finishTryout,
  } = useContext(TryoutContext);
  const [currentTryout, setCurrentTryout] = useState({});
  useEffect(() => {
    if (tryoutList && tryoutList.length) {
      const tryout = tryoutList.find(
        (item) => String(item.id) === String(tryoutId)
      );
      if (tryout) {
        let hasBundleAccess = false;
        bundleList.forEach((item) => {
          if (item.status_daftar === "TERDAFTAR") {
            if (
              item.isi_bundle.find((item2) => item2.id_tryout === tryout.id)
            ) {
              hasBundleAccess = true;
            }
          }
        });

        if (!hasBundleAccess) {
          const currentTimestamp = dayjs().valueOf();
          const { openPendaftaranDateTimestamp } = getPendaftaranInfo(tryout);
          const { endPengerjaanDateTimestamp } = getPengerjaanInfo(tryout);

          if (parseInt(tryout.id_partnership)) {
            // pakai promo
            if (!tryout.lunas) {
              navigate("/tryout");
              return;
            }
          }

          if (
            currentTimestamp < openPendaftaranDateTimestamp ||
            currentTimestamp > endPengerjaanDateTimestamp ||
            !tryout.waktu_verifikasi
          ) {
            navigate("/tryout");
          } else if (
            tryout.waktu_verifikasi &&
            currentTimestamp < tryout.waktu_verifikasi
          ) {
            navigate("/tryout");
          } else if (tryout.selesai_mengerjakan) {
            navigate("/tryout");
          } else if (!tryout.dimulai) {
            navigate(`/tryout/${tryout.id}/info`);
          } else {
            setGrupSubtes([]);
            getSubtes(tryout.id);
            setCurrentTryout(tryout);
          }
        } else {
          if (tryout.selesai_mengerjakan) {
            navigate("/tryout");
          } else if (!tryout.dimulai) {
            navigate(`/tryout/${tryout.id}/info`);
          } else {
            setGrupSubtes([]);
            getSubtes(tryout.id);
            setCurrentTryout(tryout);
          }
        }
      } else {
        navigate("/tryout");
      }
    }
  }, [tryoutList, bundleList]);

  useEffect(() => {
    if (subtesList.length) {
      if (subtesList[0].id_tryout === currentTryout.id) {
        const uniqueSubtesList = subtesList
          .map((item) => item.tipe_string)
          .filter((value, index, self) => {
            return self.indexOf(value) === index;
          });
        // filter saintek soshum
        const indexToDrop = uniqueSubtesList.findIndex((item) =>
          item
            .toLowerCase()
            .includes(currentTryout.klaster === 1 ? "soshum" : "saintek")
        );
        if (indexToDrop !== -1) {
          uniqueSubtesList.splice(indexToDrop, 1);
        }
        const newGrupSubtes = uniqueSubtesList.map((item) => {
          const filtered = subtesList.filter(
            (item2) => item2.tipe_string === item
          );
          return {
            tipe_string: filtered[0].tipe_string,
            subtesList: filtered,
          };
        });

        let tryoutSelesai = true;
        const now = dayjs().valueOf();
        newGrupSubtes.forEach((item) => {
          item.subtesList.forEach((item2) => {
            const timerSubtes = parseInt(item2.timer_subtes);
            if (timerSubtes === 0 || timerSubtes > now) {
              tryoutSelesai = false;
            }
          });
        });

        if (tryoutSelesai && !currentTryout.selesai_mengerjakan) {
          finishTryout({
            id_tryout: currentTryout.id,
          });
        }

        setGrupSubtes(newGrupSubtes);
      } else {
        setGrupSubtes([]);
      }
    }
  }, [subtesList]);

  // Modal
  const showConfirm = (subtes) => {
    setSelectedSubtes(subtes);
    hideAlert();
    setOpen(true);
  };

  // Alert
  const [alertText, setAlertText] = useState({
    show: false,
    text: "",
    success: true,
  });

  const hideAlert = () => {
    setAlertText({
      show: false,
      text: alertText.message,
      success: alertText.success,
    });
  };

  // const setSuccessAlert = (message) => {
  //   setAlertText({
  //     show: true,
  //     text: message,
  //     success: true,
  //   });
  // };
  const setErrorAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: false,
    });
  };

  const onClickKerjakan = async (e) => {
    hideAlert();
    const button = e.target;
    button.disabled = true;
    const res = await kerjakanSubtes({
      id_tryout: currentTryout.id,
      subtes: selectedSubtes.subtes,
    });
    if (res.success) {
      // setSuccessAlert("Anda akan dialihkan dalam 5 detik...");
    } else {
      setErrorAlert(
        res.display_message || "Gagal memulai subtes. Silakan coba kembali"
      );
    }
    button.disabled = false;
  };
  const [selectedSubtes, setSelectedSubtes] = useState({});

  const [grupSubtes, setGrupSubtes] = useState([]);

  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="flex flex-col bg-white p-10 justify-start m-0 w-full">
        <HeaderJD title={currentTryout.nama_tryout || ""} />

        {/* Tryout kamu */}
        <div className="flex flex-col w-full md:w-full-10 mt-10 gap-10">
          {grupSubtes.map((item, index) => {
            return (
              <div key={index} className="w-full flex flex-col">
                <p className="font-subtitle font-bold">{item.tipe_string}</p>
                <div className="flex gap-10 flex-wrap">
                  {item.subtesList.map((item2, index2) => (
                    <div
                      key={index2}
                      className="flex flex-col justify-between bg-white shadow-lg rounded-2xl p-7 gap-4 w-96 sm:w-2/5"
                    >
                      <div className="flex items-center gap-5">
                        <p className="font-content-0 font-bold">{item2.nama}</p>
                      </div>
                      <div className="flex flex-col gap-1">
                        <p className="font-content-1">
                          Waktu : {parseFloat(item2.waktu)} menit
                        </p>
                        <p className="font-content-1">
                          Jumlah Soal : {parseInt(item2.jumlah_soal)}
                        </p>
                      </div>

                      {item2.dikerjakan ? (
                        <div className="flex justify-center items-center px-5 py-3 bg-green-100 rounded-xl">
                          <p className="font-content-1 font-bold text-hijau">
                            Sudah dikerjakan
                          </p>
                        </div>
                      ) : (
                        <div className="flex justify-center items-center px-5 py-3 bg-krem rounded-xl">
                          <p className="font-content-1 font-bold text-merah">
                            Belum dikerjakan
                          </p>
                        </div>
                      )}

                      <button
                        disabled={item2.dikerjakan}
                        onClick={() => showConfirm(item2)}
                        className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/2 text-white rounded-xl"
                      >
                        Kerjakan
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Modal {...{ open, setOpen }}>
        <div className="relative text-left w-11/12 md:w-2/3 lg:w-1/2 xl:w-2/5 z-50 flex flex-col text-xl font-bold bg-white rounded-2xl">
          <div className="flex w-full justify-end p-7 pb-0">
            <i
              onClick={() => setOpen(false)}
              className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
            ></i>
          </div>
          <div className="flex flex-col gap-6 w-full px-10 pb-10">
            <h5 className="font-bold text-center">Konfirmasi</h5>
            <div className="flex flex-col gap-2 w-full">
              <p className="font-content-0 w-full text-center">
                Apakah kamu yakin ingin mengerjakan subtes {selectedSubtes.nama}{" "}
                sekarang ?
              </p>
            </div>

            <div
              className={`${
                alertText.show ? "flex" : "hidden"
              } p-4 mb-4 text-sm ${
                alertText.success
                  ? "text-green-700 bg-green-100"
                  : "text-red-700 bg-red-100"
              } rounded-lg`}
              role="alert"
            >
              <svg
                className="inline flex-shrink-0 mr-3 w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <div>{alertText.text}</div>
            </div>

            <div className="flex justify-center gap-4 w-100">
              <button
                onClick={onClickKerjakan}
                className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-white rounded-xl text-center"
              >
                Kerjakan Sekarang
              </button>
              <button
                onClick={() => setOpen(false)}
                type="button"
                className="border-2 border-oren font-content-0 flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-black rounded-xl text-center"
              >
                Batal
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TryoutLobby;
