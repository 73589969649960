const NotifJD = ({ notif }) => {
  return (
    notif &&
    notif.show &&
    notif.success !== undefined &&
    notif.message && (
      <div
        className={`w-full px-5 font-content-0 py-3 rounded-md text-white ${
          notif.show ? "flex" : "hidden"
        } ${notif.success ? "bg-hijau" : "bg-merah"}`}
      >
        {notif.message}
      </div>
    )
  );
};

const hideNotif = (setNotif) => {
  setNotif({
    show: false,
    success: true,
    message: "",
  });
};

const setNotifSuccess = (setNotif, message) => {
  hideNotif(setNotif);
  setNotif({
    show: true,
    success: true,
    message: message,
  });
};
const setNotifFailed = (setNotif, message) => {
  hideNotif(setNotif);
  setNotif({
    show: true,
    success: false,
    message: message,
  });
};

export default NotifJD;
export { hideNotif, setNotifSuccess, setNotifFailed };
