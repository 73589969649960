import dayjs from "dayjs";
import dateToString from "./dateToString";
import dateToTimestampWIB from "./dateToTimestampWIB";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const getPendaftaranInfo = (item) => {
  const openPendaftaranDate = dayjs(item.waktu_dibuka).startOf("day");
  const openPendaftaranDateString = dateToString(openPendaftaranDate);
  const openPendaftaranDateTimestamp = dateToTimestampWIB(item.waktu_dibuka, {
    hour: 0,
    minute: 0,
    second: 1,
  });

  const endPendaftaranDate = dayjs(item.waktu_dibuka)
    .add(parseInt(item.waktu_pendaftaran) - 1, "day")
    .endOf("day");
  const endPendaftaranDateString = dateToString(endPendaftaranDate);
  const endPendaftaranDateTimestamp = dateToTimestampWIB(
    endPendaftaranDate.format("YYYY-MM-DD"),
    {
      hour: 23,
      minute: 59,
      second: 59,
    }
  );
  return {
    openPendaftaranDateString,
    openPendaftaranDateTimestamp,
    endPendaftaranDateString,
    endPendaftaranDateTimestamp,
  };
};

const getPengerjaanInfo = (item, withHour = true) => {
  const endPengerjaanDate = dayjs(item.pengumuman)
    .subtract(1, "day")
    .endOf("day");
  const endPengerjaanDateString = dateToString(endPengerjaanDate, withHour);
  const endPengerjaanDateTimestamp = dateToTimestampWIB(
    endPengerjaanDate.format("YYYY-MM-DD"),
    {
      hour: 23,
      minute: 59,
      second: 59,
    }
  );
  return { endPengerjaanDateString, endPengerjaanDateTimestamp };
};

const getPengumumanInfo = (item) => {
  const openPengumumanDate = dayjs(item.pengumuman).startOf("day");
  const openPengumumanDateString = dateToString(openPengumumanDate);
  const openPengumumanDateTimestamp = dateToTimestampWIB(item.pengumuman, {
    hour: 0,
    minute: 0,
    second: 1,
  });

  return { openPengumumanDateString, openPengumumanDateTimestamp };
};

const getVerificationInfo = (item) => {
  const verifikasiDate = dayjs(item.waktu_verifikasi);
  const verifikasiTimestamp = verifikasiDate.valueOf();
  const verifikasiDateString = dateToString(
    dayjs
      .tz(item.waktu_verifikasi, "Asia/Jakarta")
      .format("YYYY-MM-DD HH:mm:ss"),
    true
  );
  return { verifikasiTimestamp, verifikasiDateString };
};

const getPromoInfo = (item) => {
  const openPromoDate = dayjs(item.waktu_dibuka).startOf("day");
  const openPromoDateString = dateToString(openPromoDate);
  const openPromoDateTimestamp = dateToTimestampWIB(item.waktu_dibuka, {
    hour: 0,
    minute: 0,
    second: 1,
  });

  const endPromoDate = dayjs(item.waktu_dibuka)
    .add(parseInt(item.lama_promo) - 1, "day")
    .endOf("day");
  const endPromoDateString = dateToString(endPromoDate);
  const endPromoDateTimestamp = dateToTimestampWIB(
    endPromoDate.format("YYYY-MM-DD"),
    {
      hour: 23,
      minute: 59,
      second: 59,
    }
  );

  const transferDate = dayjs(item.waktu_dibuka)
    .add(parseInt(item.lama_promo))
    .endOf("day");
  const transferDateString = dateToString(transferDate);
  const transferDateTimestamp = dateToTimestampWIB(
    transferDate.format("YYYY-MM-DD"),
    {
      hour: 23,
      minute: 59,
      second: 59,
    }
  );

  const endPengerjaanDate = dayjs(item.pengumuman)
    .subtract(1, "day")
    .endOf("day");
  const endPengerjaanDateString = dateToString(endPengerjaanDate);
  const endPengerjaanDateTimestamp = dateToTimestampWIB(
    endPengerjaanDate.format("YYYY-MM-DD"),
    {
      hour: 23,
      minute: 59,
      second: 59,
    }
  );
  return {
    openPromoDateString,
    openPromoDateTimestamp,
    endPromoDateString,
    endPromoDateTimestamp,
    transferDateString,
    transferDateTimestamp,
    endPengerjaanDateString,
    endPengerjaanDateTimestamp,
  };
};

export {
  getPendaftaranInfo,
  getPengerjaanInfo,
  getPengumumanInfo,
  getVerificationInfo,
  getPromoInfo,
};
