/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import { useState } from "react";
import HeaderJD from "../components/Layout/HeaderJD";
import Modal from "../components/Home/Modal";
import { Link } from "react-router-dom";
import PageComponent from "../components/Layout/PageComponent";
import EditPartnershipComponent from "../components/Partner/EditPartnershipComponent";
import PartnerContext from "../context/PartnerContext/PartnerContext";
import { useEffect } from "react";
import { useContext } from "react";
import FormatPrice from "../components/Layout/FormatPrice";
import { getPromoInfo } from "../script/dateUtils";
import cleanSpace from "../script/cleanSpace";
import EditPartnershipBundleComponent from "../components/Partner/EditPartnershipBundleComponent";

const AddPaymentMethodComponent = ({ setOpen, addPaymentMethod }) => {
  const [paymentForm, setPaymentForm] = useState({
    nama_metode: "",
    nomor_metode: "",
    nama_pemilik: "",
  });
  const [alertText, setAlertText] = useState({
    show: false,
    text: "",
    success: true,
  });

  const setSuccessAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: true,
    });
  };
  const setErrorAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: false,
    });
  };
  const hideAlert = () => {
    setAlertText({
      show: false,
      text: alertText.message,
      success: alertText.success,
    });
  };

  const onSubmitAdd = async (e) => {
    e.preventDefault();
    const button = e.target;
    button.disabled = true;
    hideAlert();
    paymentForm.nama_metode = cleanSpace(paymentForm.nama_metode);
    paymentForm.nama_pemilik = cleanSpace(paymentForm.nama_pemilik);
    paymentForm.nomor_metode = cleanSpace(paymentForm.nomor_metode);
    const res = await addPaymentMethod(paymentForm);
    button.disabled = false;
    if (res.success) {
      setOpen(false);
    } else {
      setErrorAlert(
        "Gagal menambah rekening. " +
          (res.display_message || "Coba beberapa saat lagi.")
      );
    }
  };
  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <form
        onSubmit={onSubmitAdd}
        className="flex flex-col gap-6 w-full px-10 pb-10"
      >
        <h6 className="font-bold text-center">Tambah Rekening</h6>
        <div className="flex flex-col gap-3 w-full mt-5">
          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Metode</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="text"
                placeholder="Contoh : Gopay"
                value={paymentForm.nama_metode}
                onChange={(e) => {
                  setPaymentForm({
                    ...paymentForm,
                    nama_metode: e.target.value,
                  });
                }}
                required
              />
            </div>
          </div>

          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Nomor</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="text"
                placeholder="Contoh : 081233213211"
                value={paymentForm.nomor_metode}
                onChange={(e) => {
                  setPaymentForm({
                    ...paymentForm,
                    nomor_metode: e.target.value,
                  });
                }}
                required
              />
            </div>
          </div>

          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Nama Pemilik</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="text"
                placeholder="Contoh : Jefri Pratama"
                value={paymentForm.nama_pemilik}
                onChange={(e) => {
                  setPaymentForm({
                    ...paymentForm,
                    nama_pemilik: e.target.value,
                  });
                }}
                required
              />
            </div>
          </div>

          <div
            className={`${
              alertText.show ? "flex" : "hidden"
            } p-4 mb-4 text-sm ${
              alertText.success
                ? "text-green-700 bg-green-100"
                : "text-red-700 bg-red-100"
            } rounded-lg`}
            role="alert"
          >
            <svg
              className="inline flex-shrink-0 mr-3 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <div>{alertText.text}</div>
          </div>
        </div>
        <button
          type="submit"
          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-full text-white rounded-xl m-auto"
        >
          Tambah Metode
        </button>
      </form>
    </>
  );
};

const EditPaymentMethodComponent = ({
  currentPayment,
  setOpen,
  editPaymentMethod,
}) => {
  const [paymentForm, setPaymentForm] = useState({
    id_metode: currentPayment.id,
    nama_metode: currentPayment.nama_metode,
    nomor_metode: currentPayment.nomor_metode,
    nama_pemilik: currentPayment.nama_pemilik,
  });

  const [alertText, setAlertText] = useState({
    show: false,
    text: "",
    success: true,
  });

  const setErrorAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: false,
    });
  };
  const hideAlert = () => {
    setAlertText({
      show: false,
      text: alertText.message,
      success: alertText.success,
    });
  };

  const onSubmitEdit = async (e) => {
    e.preventDefault();
    const button = e.target;
    button.disabled = true;
    hideAlert();
    paymentForm.nama_metode = cleanSpace(paymentForm.nama_metode);
    paymentForm.nama_pemilik = cleanSpace(paymentForm.nama_pemilik);
    paymentForm.nomor_metode = cleanSpace(paymentForm.nomor_metode);
    const res = await editPaymentMethod(paymentForm);
    button.disabled = false;
    if (res.success) {
      setOpen(false);
    } else {
      setErrorAlert(
        "Gagal menyimpan perubahan. " +
          (res.display_message || "Coba beberapa saat lagi.")
      );
    }
  };
  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <form
        onSubmit={onSubmitEdit}
        className="flex flex-col gap-6 w-full px-10 pb-10"
      >
        <h6 className="font-bold text-center">Tambah Rekening</h6>
        <div className="flex flex-col gap-3 w-full mt-5">
          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Metode</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="text"
                placeholder="Contoh : Gopay"
                value={paymentForm.nama_metode}
                onChange={(e) => {
                  setPaymentForm({
                    ...paymentForm,
                    nama_metode: e.target.value,
                  });
                }}
                required
              />
            </div>
          </div>

          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Nomor</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="text"
                placeholder="Contoh : 081233213211"
                value={paymentForm.nomor_metode}
                onChange={(e) => {
                  setPaymentForm({
                    ...paymentForm,
                    nomor_metode: e.target.value,
                  });
                }}
                required
              />
            </div>
          </div>

          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Nama Pemilik</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="text"
                placeholder="Contoh : Jefri Pratama"
                value={paymentForm.nama_pemilik}
                onChange={(e) => {
                  setPaymentForm({
                    ...paymentForm,
                    nama_pemilik: e.target.value,
                  });
                }}
                required
              />
            </div>
          </div>

          <div
            className={`${
              alertText.show ? "flex" : "hidden"
            } p-4 mb-4 text-sm ${
              alertText.success
                ? "text-green-700 bg-green-100"
                : "text-red-700 bg-red-100"
            } rounded-lg`}
            role="alert"
          >
            <svg
              className="inline flex-shrink-0 mr-3 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <div>{alertText.text}</div>
          </div>
        </div>
        <button
          type="submit"
          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-full text-white rounded-xl m-auto"
        >
          Simpan Metode
        </button>
      </form>
    </>
  );
};

const DeletePaymentMethodComponent = ({
  setOpen,
  currentPayment,
  deletePaymentMethod,
}) => {
  const [alertText, setAlertText] = useState({
    show: false,
    text: "",
    success: true,
  });

  const setErrorAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: false,
    });
  };
  const hideAlert = () => {
    setAlertText({
      show: false,
      text: alertText.message,
      success: alertText.success,
    });
  };
  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <div className="flex flex-col gap-6 w-full px-10 pb-10">
        <h5 className="font-bold text-center">Konfirmasi</h5>
        <div className="flex flex-col gap-2 w-full">
          <p className="font-content-0 w-full text-center">
            Apakah kamu yakin ingin menghapus rekening ini?
          </p>
        </div>

        <div
          className={`${alertText.show ? "flex" : "hidden"} p-4 mb-4 text-sm ${
            alertText.success
              ? "text-green-700 bg-green-100"
              : "text-red-700 bg-red-100"
          } rounded-lg`}
          role="alert"
        >
          <svg
            className="inline flex-shrink-0 mr-3 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clipRule="evenodd"
            ></path>
          </svg>
          <div>{alertText.text}</div>
        </div>

        <div className="flex justify-center gap-4 w-100">
          <button
            onClick={async (e) => {
              e.target.disabled = true;
              hideAlert();
              const res = await deletePaymentMethod({
                id_metode: currentPayment.id,
              });
              if (res.data) {
                setOpen(false);
              } else {
                setErrorAlert(
                  res.display_message || "Terjadi kesalahan, coba lagi nanti."
                );
              }
              e.target.enabled = true;
            }}
            className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-white rounded-xl text-center"
          >
            Ya
          </button>
          <button
            onClick={() => setOpen(false)}
            type="button"
            className="border-2 border-oren font-content-0 flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-black rounded-xl text-center"
          >
            Batal
          </button>
        </div>
      </div>
    </>
  );
};

const PartnerArea = () => {
  const {
    partnershipList,
    paginationPartnership,
    paymentMethodList,
    getAllPartnership,
    editPartnership,
    getAllPaymentMethod,
    addPaymentMethod,
    editPaymentMethod,
    deletePaymentMethod,
    partnershipBundleList,
    paginationPartnershipBundle,
    getAllPartnershipBundle,
    editPartnershipBundle,
    partnershipEventList,
    paginationPartnershipEvent,
    getAllPartnershipEvent,
  } = useContext(PartnerContext);

  useEffect(() => {
    getAllPartnership({
      page: 1,
    });
    getAllPartnershipBundle({
      page: 1,
    });
    getAllPaymentMethod();
    getAllPartnershipEvent({ page: 1 });
  }, []);
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState(<></>);
  return (
    <>
      <div className="flex flex-col bg-white p-10 justify-start m-0 w-full">
        <HeaderJD title="Partner Area" />

        {/* List Partnership */}
        <div className="flex flex-col w-full md:w-full-10 mt-10 gap-3">
          <p className="font-subtitle font-bold">Daftar Partnership Try Out</p>
          {partnershipList.length === 0 ? (
            <p className="font-content-0">
              Belum ada partnership try out dengan Jalur Dalam.
            </p>
          ) : (
            ""
          )}
          <div className="w-full overflow-auto">
            <table className="tabel-jd flex flex-row flex-nowrap w-full md:inline-table">
              <thead>
                {partnershipList.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={`${
                        index === 0 ? "md:table-row" : "md:hidden"
                      } flex flex-col mt-5 md:mt-0`}
                    >
                      <th className="h-14 md:h-auto p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                        Nama Try Out
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Kode Voucher
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Profit
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Promo Berlaku
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Terakhir Berlaku
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Detail
                      </th>
                      <th className="p-4 md:p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none md:rounded-r-xl overflow-hidden">
                        Aksi
                      </th>
                    </tr>
                  );
                })}
              </thead>
              <tbody className="flex-1 md:flex-none">
                {partnershipList.map((item, index) => {
                  const promoDate = getPromoInfo(item);
                  return (
                    <tr
                      className="flex flex-col md:table-row mt-5 md:mt-0"
                      key={index}
                    >
                      <td className="h-14 md:h-auto p-2 font-content-1 text-center">
                        {item.nama_tryout}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {item.kode_promo}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        <FormatPrice price={item.profit_partner} />
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {promoDate.openPromoDateString}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {promoDate.endPromoDateString}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        <Link
                          to={`/partner-area/${item.id_tryout}`}
                          className="font-content-1 text-center text-oren hover:underline font-bold"
                        >
                          Lihat Detail
                        </Link>
                      </td>
                      <td className="p-2 font-content-1 justify-center flex md:table-cell">
                        <button
                          onClick={() => {
                            setModalContent(
                              <EditPartnershipComponent
                                setOpen={setOpen}
                                currentPartnership={item}
                                editPartnership={editPartnership}
                              />
                            );

                            setOpen(true);
                          }}
                          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl m-auto"
                          disabled={parseInt(item.lunas)}
                        >
                          Edit Promo
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="flex w-full justify-center">
            <div className="flex gap-3">
              {paginationPartnership.totalPage > 1 ? (
                <PageComponent
                  currentPage={paginationPartnership.currentPage}
                  totalPage={paginationPartnership.totalPage}
                  getData={(page) => {
                    getAllPartnership({
                      page: page,
                    });
                  }}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full md:w-full-10 mt-10 gap-3">
          <p className="font-subtitle font-bold">Daftar Partnership Bundle</p>
          {partnershipBundleList.length === 0 ? (
            <p className="font-content-0">
              Belum ada partnership bundle dengan Jalur Dalam.
            </p>
          ) : (
            ""
          )}
          <div className="w-full overflow-auto">
            <table className="tabel-jd flex flex-row flex-nowrap w-full md:inline-table">
              <thead>
                {partnershipBundleList.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={`${
                        index === 0 ? "md:table-row" : "md:hidden"
                      } flex flex-col mt-5 md:mt-0`}
                    >
                      <th className="h-14 md:h-auto p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                        Nama Bundle
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Kode Voucher
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Profit
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Detail
                      </th>
                      {/* <th className="p-4 md:p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none md:rounded-r-xl overflow-hidden">
                        Aksi
                      </th> */}
                    </tr>
                  );
                })}
              </thead>
              <tbody className="flex-1 md:flex-none">
                {partnershipBundleList.map((item, index) => {
                  return (
                    <tr
                      className="flex flex-col md:table-row mt-5 md:mt-0"
                      key={index}
                    >
                      <td className="h-14 md:h-auto p-2 font-content-1 text-center">
                        {item.nama_bundle}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {item.kode_promo}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        <FormatPrice price={item.profit_partner} />
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        <Link
                          to={`/partner-area/bundle/${item.id_bundle}`}
                          className="font-content-1 text-center text-oren hover:underline font-bold"
                        >
                          Lihat Detail
                        </Link>
                      </td>
                      {/* <td className="p-2 font-content-1 justify-center flex md:table-cell">
                        <button
                          onClick={() => {
                            setModalContent(
                              <EditPartnershipBundleComponent
                                setOpen={setOpen}
                                currentPartnership={item}
                                editPartnershipBundle={editPartnershipBundle}
                              />
                            );

                            setOpen(true);
                          }}
                          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl m-auto"
                        >
                          Edit Kode Voucher
                        </button>
                      </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="flex w-full justify-center">
            <div className="flex gap-3">
              {paginationPartnershipBundle.totalPage > 1 ? (
                <PageComponent
                  currentPage={paginationPartnershipBundle.currentPage}
                  totalPage={paginationPartnershipBundle.totalPage}
                  getData={(page) => {
                    getAllPartnershipBundle({
                      page: page,
                    });
                  }}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        {/* List Rekening */}
        <div className="flex flex-col w-full md:w-full-10 mt-10 gap-3">
          <p className="font-subtitle font-bold">Daftar Rekening</p>

          {paymentMethodList.length === 0 ? (
            <p className="font-content-0">
              Belum ada rekening yang ditambahkan.
            </p>
          ) : (
            ""
          )}
          <button
            onClick={() => {
              setModalContent(
                <AddPaymentMethodComponent
                  setOpen={setOpen}
                  addPaymentMethod={addPaymentMethod}
                />
              );
              setOpen(true);
            }}
            className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl"
          >
            Tambah Rekening
          </button>
          <div className="w-full overflow-auto">
            <table className="tabel-jd flex flex-row flex-nowrap w-full md:inline-table">
              <thead>
                {paymentMethodList.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={`${
                        index === 0 ? "md:table-row" : "md:hidden"
                      } flex flex-col mt-5 md:mt-0`}
                    >
                      <th className="h-14 md:h-auto p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                        Metode
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Nomor
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Nama Pemilik
                      </th>
                      <th className="p-4 md:p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Edit
                      </th>
                      <th className="p-4 md:p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none md:rounded-r-xl overflow-hidden">
                        Hapus
                      </th>
                    </tr>
                  );
                })}
              </thead>
              <tbody className="flex-1 md:flex-none">
                {paymentMethodList.map((item, index) => {
                  return (
                    <tr
                      className="flex flex-col md:table-row mt-5 md:mt-0"
                      key={index}
                    >
                      <td className="h-14 md:h-auto p-2 font-content-1 text-center">
                        {item.nama_metode}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {item.nomor_metode}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {item.nama_pemilik}
                      </td>
                      <td className="p-2 font-content-1 justify-center flex md:table-cell">
                        <button
                          onClick={() => {
                            setModalContent(
                              <EditPaymentMethodComponent
                                setOpen={setOpen}
                                currentPayment={item}
                                editPaymentMethod={editPaymentMethod}
                              />
                            );

                            setOpen(true);
                          }}
                          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl m-auto"
                        >
                          Edit
                        </button>
                      </td>
                      <td className="p-2 font-content-1 justify-center flex md:table-cell">
                        <button
                          onClick={() => {
                            setModalContent(
                              <DeletePaymentMethodComponent
                                setOpen={setOpen}
                                currentPayment={item}
                                deletePaymentMethod={deletePaymentMethod}
                              />
                            );

                            setOpen(true);
                          }}
                          className="bg-merah font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl m-auto"
                        >
                          Hapus
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        {/* List Partnership Event */}
        <div className="flex flex-col w-full md:w-full-10 mt-10 gap-3">
          <div className="w-full overflow-auto mt-3 gap-2 flex flex-col">
            <p className="font-subtitle font-bold">Partnership Event</p>
            {partnershipEventList.length === 0 ? (
              <p className="font-content-0">
                Belum ada kerja sama event dengan Jalur Dalam.
              </p>
            ) : null}
            <table className="tabel-jd flex flex-row flex-nowrap w-full md:inline-table">
              <thead>
                {partnershipEventList.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={`${
                        index === 0 ? "md:table-row" : "md:hidden"
                      } flex flex-col mt-5 md:mt-0`}
                    >
                      <th className="h-14 md:h-auto p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                        Event
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        URL Logo
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Subdomain
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Status
                      </th>
                      <th className="p-4 md:p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none md:rounded-r-xl overflow-hidden">
                        Aksi
                      </th>
                    </tr>
                  );
                })}
              </thead>
              <tbody className="flex-1 md:flex-none">
                {partnershipEventList.map((item, index) => {
                  return (
                    <tr
                      className="flex flex-col md:table-row mt-5 md:mt-0"
                      key={index}
                    >
                      <td className="h-14 md:h-auto p-2 font-content-1 text-center">
                        {item.nama_event}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {item.logo_url}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {item.origin}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {parseInt(item.is_active) ? "Aktif" : "Tidak aktif"}
                      </td>
                      <td className="p-2 font-content-1 justify-center flex md:table-cell">
                        <Link
                          to={`/partner-area/event/${item.id}`}
                          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl m-auto"
                        >
                          Detail
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="flex w-full justify-center">
            <div className="flex gap-3">
              {paginationPartnershipEvent.totalPage ? (
                <PageComponent
                  currentPage={paginationPartnershipEvent.currentPage}
                  totalPage={paginationPartnershipEvent.totalPage}
                  getData={(page) => {
                    getAllPartnershipEvent({
                      page: page,
                    });
                  }}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal {...{ open, setOpen }}>
        <div className="relative text-left w-11/12 md:w-2/3 lg:w-1/2 xl:w-2/5 z-50 flex flex-col text-xl font-bold bg-white rounded-2xl">
          {modalContent}
        </div>
      </Modal>
    </>
  );
};

export default PartnerArea;
