/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import { useState } from "react";
import HeaderJD from "../../components/Layout/HeaderJD";
import FormatPrice from "../../components/Layout/FormatPrice";
import { useNavigate, useParams } from "react-router-dom";
import AdminContext from "../../context/AdminContext/AdminContext";
import { useContext } from "react";
import { useEffect } from "react";

const DetailPendaftarBundle = () => {
  const { bundleId } = useParams();
  const navigate = useNavigate();
  const {
    pendaftarPromoBundleList,
    pendaftarNonPromoBundleList,
    bundleListAdmin,
    getDetailPendaftarBundle,
  } = useContext(AdminContext);
  const [currentBundle, setCurrentBundle] = useState({});

  useEffect(() => {
    getDetailPendaftarBundle({
      id_bundle: bundleId,
    });
  }, []);

  useEffect(() => {
    if (bundleListAdmin && bundleListAdmin.length) {
      const bundle = bundleListAdmin.find(
        (item) => String(item.id) === String(bundleId)
      );
      if (bundle) {
        setCurrentBundle(bundle);
      } else {
        navigate("/admin-area");
      }
    }
  }, [bundleListAdmin]);

  const [pendapatanTotal, setPendapatanTotal] = useState({
    partnership: 0,
    nonPartnership: 0,
    total: 0,
  });

  useEffect(() => {
    if (currentBundle.id) {
      let pendapatan = {
        partnership: 0,
        nonPartnership: 0,
        total: 0,
      };
      pendaftarNonPromoBundleList.forEach((item) => {
        const userCount =
          parseInt(item.totalBelumVerifikasi) +
          parseInt(item.totalSudahVerifikasi);
        if (!item.id_partnership_bundle) {
          pendapatan.nonPartnership += currentBundle.harga * userCount;
        }
      });
      pendaftarPromoBundleList.forEach((item) => {
        const userCount =
          parseInt(item.totalBelumVerifikasi) +
          parseInt(item.totalSudahVerifikasi);
        pendapatan.partnership += currentBundle.profit_partnership * userCount;
      });

      pendapatan.total = pendapatan.nonPartnership + pendapatan.partnership;
      setPendapatanTotal(pendapatan);
    }
  }, [currentBundle, pendaftarPromoBundleList, pendaftarNonPromoBundleList]);

  return (
    <>
      <div className="flex flex-col bg-white p-10 justify-start m-0 w-full">
        <HeaderJD title="Detail Pendaftar" />

        <div className="flex flex-col w-full md:w-full-10 mt-10 gap-3">
          <p className="font-subtitle font-bold">Pendapatan</p>
          <table className="tabel-jd flex flex-row flex-nowrap w-full">
            <thead>
              <tr className={`flex flex-col mt-5 gap-2`}>
                <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                  Non Promo
                </th>
                <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                  Promo
                </th>
                <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                  Total
                </th>
              </tr>
            </thead>
            <tbody className="flex-1">
              <tr className="flex flex-col mt-5 gap-2">
                <td className="p-2 pl-4 font-content-1 text-left flex justify-start items-center">
                  <FormatPrice price={pendapatanTotal.nonPartnership} />
                </td>
                <td className="p-2 pl-4 font-content-1 text-left flex justify-start items-center">
                  <FormatPrice price={pendapatanTotal.partnership} />
                </td>
                <td className="p-2 pl-4 font-content-1 text-left flex justify-start items-center">
                  <FormatPrice price={pendapatanTotal.total} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* List Pendaftar Non Promo */}
        <div className="flex flex-col w-full md:w-full-10 mt-10 gap-3">
          <p className="font-subtitle font-bold">Pendaftar Non Promo</p>
          {pendaftarNonPromoBundleList.length === 0 ? (
            <p className="font-content-0">Tidak ada pendaftar</p>
          ) : (
            ""
          )}

          <div className="w-full overflow-auto">
            <table className="tabel-jd flex flex-row flex-nowrap w-full md:inline-table">
              <thead>
                {pendaftarNonPromoBundleList.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={`${
                        index === 0 ? "md:table-row" : "md:hidden"
                      } flex flex-col mt-5 md:mt-0`}
                    >
                      <th className="h-14 md:h-auto p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                        Metode
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Belum Verifikasi
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Sudah Verifikasi
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none md:rounded-r-xl overflow-hidden">
                        Total
                      </th>
                    </tr>
                  );
                })}
              </thead>
              <tbody className="flex-1 md:flex-none">
                {pendaftarNonPromoBundleList.map((item, index) => {
                  return (
                    <tr
                      className="flex flex-col md:table-row mt-5 md:mt-0"
                      key={index}
                    >
                      <td className="h-14 md:h-auto p-2 font-content-1 text-center">
                        {item.pembayaran}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {item.totalBelumVerifikasi}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {item.totalSudahVerifikasi}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {parseInt(item.totalBelumVerifikasi) +
                          parseInt(item.totalSudahVerifikasi)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        {/* List Pendaftar Promo */}
        <div className="flex flex-col w-full md:w-full-10 mt-10 gap-3">
          <p className="font-subtitle font-bold">Pendaftar Promo</p>
          {pendaftarPromoBundleList.length === 0 ? (
            <p className="font-content-0">Tidak ada pendaftar dengan promo</p>
          ) : (
            ""
          )}

          <div className="w-full overflow-auto">
            <table className="tabel-jd flex flex-row flex-nowrap w-full md:inline-table">
              <thead>
                {pendaftarPromoBundleList.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={`${
                        index === 0 ? "md:table-row" : "md:hidden"
                      } flex flex-col mt-5 md:mt-0`}
                    >
                      <th className="h-14 md:h-auto p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                        Partner
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Belum Verifikasi
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Sudah Verifikasi
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none md:rounded-r-xl overflow-hidden">
                        Total
                      </th>
                    </tr>
                  );
                })}
              </thead>
              <tbody className="flex-1 md:flex-none">
                {pendaftarPromoBundleList.map((item, index) => {
                  return (
                    <tr
                      className="flex flex-col md:table-row mt-5 md:mt-0"
                      key={index}
                    >
                      <td className="h-14 md:h-auto p-2 font-content-1 text-center">
                        {item.pembayaran}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {item.totalBelumVerifikasi}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {item.totalSudahVerifikasi}
                      </td>
                      <td className="p-2 font-content-1 justify-center flex md:table-cell">
                        {parseInt(item.totalBelumVerifikasi) +
                          parseInt(item.totalSudahVerifikasi)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailPendaftarBundle;
