/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import { useState } from "react";
import HeaderJD from "../../components/Layout/HeaderJD";
import FormatPrice from "../../components/Layout/FormatPrice";
import Modal from "../../components/Home/Modal";
import Select, { createFilter } from "react-select";
import { Link, useNavigate, useParams } from "react-router-dom";
import PageComponent from "../../components/Layout/PageComponent";
import AdminContext from "../../context/AdminContext/AdminContext";
import { useContext } from "react";
import { useEffect } from "react";
import { useRef } from "react";

const AddPartnershipComponent = ({
  currentPartner,
  setOpen,
  tryoutListAdminPremium,
  partnershipList,
  addPartnership,
}) => {
  const [partnershipForm, setPartnershipForm] = useState({
    id_tryout: 0,
    id_user: currentPartner.id,
    kode_promo: currentPartner.nama.split(" ")[0].toUpperCase(),
    profit: 4000,
  });

  const promoRef = useRef(<></>);

  const [optionList, setOptionList] = useState([]);
  useEffect(() => {
    const idTryoutList = partnershipList.map((item) =>
      parseInt(item.id_tryout)
    );
    if (tryoutListAdminPremium.length) {
      const newOptionList = tryoutListAdminPremium
        .filter((item) => !idTryoutList.includes(parseInt(item.id)))
        .map((item) => ({ ...item, value: item.id, label: item.nama_tryout }));
      setOptionList(newOptionList);
    }
  }, [tryoutListAdminPremium, partnershipList]);
  useEffect(() => {
    if (optionList.length)
      setPartnershipForm({
        id_tryout: optionList[0].value,
        id_user: currentPartner.id,
        kode_promo: currentPartner.nama.split(" ")[0].toUpperCase(),
        profit: 4000,
      });
  }, [optionList, currentPartner]);

  const [alertText, setAlertText] = useState({
    show: false,
    text: "",
    success: true,
  });

  const setErrorAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: false,
    });
  };
  const hideAlert = () => {
    setAlertText({
      show: false,
      text: alertText.message,
      success: alertText.success,
    });
  };

  const onSubmitAdd = async (e) => {
    e.preventDefault();
    if (partnershipForm.kode_promo.length < 6) {
      promoRef.current.setCustomValidity(
        "Panjang teks kurang dari 6 karakter."
      );
      promoRef.current.reportValidity();
    } else {
      promoRef.current.setCustomValidity("");
      const button = e.target;
      button.disabled = true;
      hideAlert();
      const res = await addPartnership(partnershipForm);
      button.disabled = false;
      if (res.success) {
        setOpen(false);
      } else {
        setErrorAlert("Gagal menambah partnership. " + res.display_message);
      }
    }
  };
  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <form
        onSubmit={onSubmitAdd}
        className="flex flex-col gap-6 w-full px-10 pb-10"
      >
        <h6 className="font-bold text-center">Tambah Partnership</h6>
        <div className="flex flex-col gap-3 w-full mt-5">
          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Try Out</p>
            </div>

            <div className="w-2/3 flex border-2 border-black rounded-xl items-center select-container">
              <Select
                placeholder="Pilih try out"
                styles={{
                  control: () => ({
                    alignItems: "center",
                    border: "none",
                    display: "flex",
                    flexWrap: "wrap",
                    WebkitBoxPack: "justify",
                    justifyContent: "space-between",
                    minHeight: "38px",
                    position: "relative",
                  }),
                }}
                className="w-full border-none outline-none font-content-1"
                onChange={(val) => {
                  if (val.value !== partnershipForm.id_tryout) {
                    setPartnershipForm({
                      ...partnershipForm,
                      id_tryout: val.value,
                    });
                  }
                }}
                options={optionList}
                value={optionList.find(
                  (item) => item.value === partnershipForm.id_tryout
                )}
                filterOption={createFilter({ ignoreAccents: false })}
              />
            </div>
          </div>

          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Kode Voucher</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="text"
                minLength="6"
                maxLength="20"
                value={partnershipForm.kode_promo}
                ref={promoRef}
                onChange={(e) => {
                  if (String(e.target.value).match(/^[a-zA-Z0-9]*$/)) {
                    e.target.setCustomValidity("");
                    setPartnershipForm({
                      ...partnershipForm,
                      kode_promo: e.target.value,
                    });
                  } else {
                    e.target.setCustomValidity(
                      "Kode voucher hanya boleh berisi angka dan huruf"
                    );
                    e.target.reportValidity();
                  }
                }}
                required
              />
            </div>
          </div>

          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Profit Partner</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="number"
                value={partnershipForm.profit}
                onChange={(e) => {
                  setPartnershipForm({
                    ...partnershipForm,
                    profit: e.target.value,
                  });
                }}
                required
              />
            </div>
          </div>

          <div
            className={`${
              alertText.show ? "flex" : "hidden"
            } p-4 mb-4 text-sm ${
              alertText.success
                ? "text-green-700 bg-green-100"
                : "text-red-700 bg-red-100"
            } rounded-lg`}
            role="alert"
          >
            <svg
              className="inline flex-shrink-0 mr-3 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <div>{alertText.text}</div>
          </div>
        </div>
        <button
          type="submit"
          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-full text-white rounded-xl m-auto"
          disabled={!optionList.length}
        >
          Tambah
        </button>
      </form>
    </>
  );
};

const AddPartnershipBundleComponent = ({
  currentPartner,
  setOpen,
  bundleListAdmin,
  partnershipBundleList,
  addPartnership,
}) => {
  const [partnershipForm, setPartnershipForm] = useState({
    id_bundle: 0,
    id_user: currentPartner.id,
    kode_promo: currentPartner.nama.split(" ")[0].toUpperCase(),
    profit: 4000,
  });

  const promoRef = useRef(<></>);

  const [optionList, setOptionList] = useState([]);
  useEffect(() => {
    const idbundleListAdmin = partnershipBundleList.map((item) =>
      parseInt(item.id_bundle)
    );
    if (bundleListAdmin.length) {
      const newOptionList = bundleListAdmin
        .filter((item) => !idbundleListAdmin.includes(parseInt(item.id)))
        .map((item) => ({ ...item, value: item.id, label: item.nama_bundle }));
      setOptionList(newOptionList);
    }
  }, [bundleListAdmin, partnershipBundleList]);
  useEffect(() => {
    if (optionList.length)
      setPartnershipForm({
        id_bundle: optionList[0].value,
        id_user: currentPartner.id,
        kode_promo: currentPartner.nama.split(" ")[0].toUpperCase(),
        profit: 4000,
      });
  }, [optionList, currentPartner]);

  const [alertText, setAlertText] = useState({
    show: false,
    text: "",
    success: true,
  });

  const setErrorAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: false,
    });
  };
  const hideAlert = () => {
    setAlertText({
      show: false,
      text: alertText.message,
      success: alertText.success,
    });
  };

  const onSubmitAdd = async (e) => {
    e.preventDefault();
    if (partnershipForm.kode_promo.length < 6) {
      promoRef.current.setCustomValidity(
        "Panjang teks kurang dari 6 karakter."
      );
      promoRef.current.reportValidity();
    } else {
      promoRef.current.setCustomValidity("");
      const button = e.target;
      button.disabled = true;
      hideAlert();
      const res = await addPartnership(partnershipForm);
      button.disabled = false;
      if (res.success) {
        setOpen(false);
      } else {
        setErrorAlert("Gagal menambah partnership. " + res.display_message);
      }
    }
  };
  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <form
        onSubmit={onSubmitAdd}
        className="flex flex-col gap-6 w-full px-10 pb-10"
      >
        <h6 className="font-bold text-center">Tambah Partnership</h6>
        <div className="flex flex-col gap-3 w-full mt-5">
          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Bundle</p>
            </div>

            <div className="w-2/3 flex border-2 border-black rounded-xl items-center select-container">
              <Select
                placeholder="Pilih bundle"
                styles={{
                  control: () => ({
                    alignItems: "center",
                    border: "none",
                    display: "flex",
                    flexWrap: "wrap",
                    WebkitBoxPack: "justify",
                    justifyContent: "space-between",
                    minHeight: "38px",
                    position: "relative",
                  }),
                }}
                className="w-full border-none outline-none font-content-1"
                onChange={(val) => {
                  if (val.value !== partnershipForm.id_bundle) {
                    setPartnershipForm({
                      ...partnershipForm,
                      id_bundle: val.value,
                    });
                  }
                }}
                options={optionList}
                value={optionList.find(
                  (item) => item.value === partnershipForm.id_bundle
                )}
                filterOption={createFilter({ ignoreAccents: false })}
              />
            </div>
          </div>

          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Kode Voucher</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="text"
                minLength="6"
                maxLength="20"
                value={partnershipForm.kode_promo}
                ref={promoRef}
                onChange={(e) => {
                  if (String(e.target.value).match(/^[a-zA-Z0-9]*$/)) {
                    e.target.setCustomValidity("");
                    setPartnershipForm({
                      ...partnershipForm,
                      kode_promo: e.target.value,
                    });
                  } else {
                    e.target.setCustomValidity(
                      "Kode voucher hanya boleh berisi angka dan huruf"
                    );
                    e.target.reportValidity();
                  }
                }}
                required
              />
            </div>
          </div>

          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Profit Partner</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="number"
                value={partnershipForm.profit}
                onChange={(e) => {
                  setPartnershipForm({
                    ...partnershipForm,
                    profit: e.target.value,
                  });
                }}
                required
              />
            </div>
          </div>

          <div
            className={`${
              alertText.show ? "flex" : "hidden"
            } p-4 mb-4 text-sm ${
              alertText.success
                ? "text-green-700 bg-green-100"
                : "text-red-700 bg-red-100"
            } rounded-lg`}
            role="alert"
          >
            <svg
              className="inline flex-shrink-0 mr-3 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <div>{alertText.text}</div>
          </div>
        </div>
        <button
          type="submit"
          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-full text-white rounded-xl m-auto"
          disabled={!optionList.length}
        >
          Tambah
        </button>
      </form>
    </>
  );
};

const AddPartnershipEventComponent = ({
  currentPartner,
  setOpen,
  addPartnership,
}) => {
  const [partnershipForm, setPartnershipForm] = useState({
    id_user: currentPartner.id,
    nama_event: "",
    logo_url: "",
    origin: "",
  });

  const [alertText, setAlertText] = useState({
    show: false,
    text: "",
    success: true,
  });

  const setErrorAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: false,
    });
  };
  const hideAlert = () => {
    setAlertText({
      show: false,
      text: alertText.message,
      success: alertText.success,
    });
  };

  const onSubmitAdd = async (e) => {
    e.preventDefault();
    const button = e.target;
    button.disabled = true;
    hideAlert();
    const res = await addPartnership(partnershipForm);
    button.disabled = false;
    if (res.success) {
      setOpen(false);
    } else {
      setErrorAlert("Gagal menambah partnership. " + res.display_message);
    }
  };
  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <form
        onSubmit={onSubmitAdd}
        className="flex flex-col gap-6 w-full px-10 pb-10"
      >
        <h6 className="font-bold text-center">Tambah Partnership Event</h6>
        <div className="flex flex-col gap-3 w-full mt-5">
          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Nama Event</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="text"
                minLength="6"
                value={partnershipForm.nama_event}
                onChange={(e) => {
                  setPartnershipForm({
                    ...partnershipForm,
                    nama_event: e.target.value,
                  });
                }}
                required
              />
            </div>
          </div>

          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">URL Logo</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="text"
                minLength="6"
                value={partnershipForm.logo_url}
                onChange={(e) => {
                  setPartnershipForm({
                    ...partnershipForm,
                    logo_url: e.target.value,
                  });
                }}
                required
              />
            </div>
          </div>

          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Subdomain Lengkap</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="text"
                minLength="6"
                value={partnershipForm.origin}
                onChange={(e) => {
                  setPartnershipForm({
                    ...partnershipForm,
                    origin: e.target.value,
                  });
                }}
                required
              />
            </div>
          </div>

          <div
            className={`${
              alertText.show ? "flex" : "hidden"
            } p-4 mb-4 text-sm ${
              alertText.success
                ? "text-green-700 bg-green-100"
                : "text-red-700 bg-red-100"
            } rounded-lg`}
            role="alert"
          >
            <svg
              className="inline flex-shrink-0 mr-3 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <div>{alertText.text}</div>
          </div>
        </div>
        <button
          type="submit"
          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-full text-white rounded-xl m-auto"
        >
          Tambah
        </button>
      </form>
    </>
  );
};

const DeletePartnerComponent = ({
  deletePartnership,
  setOpen,
  partnership,
}) => {
  const [alertText, setAlertText] = useState({
    show: false,
    text: "",
    success: true,
  });

  const setErrorAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: false,
    });
  };
  const hideAlert = () => {
    setAlertText({
      show: false,
      text: alertText.message,
      success: alertText.success,
    });
  };
  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <div className="flex flex-col gap-6 w-full px-10 pb-10">
        <h5 className="font-bold text-center">Konfirmasi</h5>
        <div className="flex flex-col gap-2 w-full">
          <p className="font-content-0 w-full text-center">
            Apakah kamu yakin ingin menghapus partnership ini?
          </p>
        </div>

        <div
          className={`${alertText.show ? "flex" : "hidden"} p-4 mb-4 text-sm ${
            alertText.success
              ? "text-green-700 bg-green-100"
              : "text-red-700 bg-red-100"
          } rounded-lg`}
          role="alert"
        >
          <svg
            className="inline flex-shrink-0 mr-3 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clipRule="evenodd"
            ></path>
          </svg>
          <div>{alertText.text}</div>
        </div>

        <div className="flex justify-center gap-4 w-100">
          <button
            onClick={async (e) => {
              e.target.disabled = true;
              hideAlert();
              const res = await deletePartnership({
                id_user: partnership.id_user,
                id_tryout: partnership.id_tryout,
              });
              if (res.data) {
                setOpen(false);
              } else {
                setErrorAlert(
                  res.display_message || "Terjadi kesalahan, coba lagi nanti."
                );
              }
              e.target.enabled = true;
            }}
            className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-white rounded-xl text-center"
          >
            Ya
          </button>
          <button
            onClick={() => setOpen(false)}
            type="button"
            className="border-2 border-oren font-content-0 flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-black rounded-xl text-center"
          >
            Batal
          </button>
        </div>
      </div>
    </>
  );
};

const DeletePartnerBundleComponent = ({
  deletePartnershipBundle,
  setOpen,
  partnership,
}) => {
  const [alertText, setAlertText] = useState({
    show: false,
    text: "",
    success: true,
  });

  const setErrorAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: false,
    });
  };
  const hideAlert = () => {
    setAlertText({
      show: false,
      text: alertText.message,
      success: alertText.success,
    });
  };
  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <div className="flex flex-col gap-6 w-full px-10 pb-10">
        <h5 className="font-bold text-center">Konfirmasi</h5>
        <div className="flex flex-col gap-2 w-full">
          <p className="font-content-0 w-full text-center">
            Apakah kamu yakin ingin menghapus partnership ini?
          </p>
        </div>

        <div
          className={`${alertText.show ? "flex" : "hidden"} p-4 mb-4 text-sm ${
            alertText.success
              ? "text-green-700 bg-green-100"
              : "text-red-700 bg-red-100"
          } rounded-lg`}
          role="alert"
        >
          <svg
            className="inline flex-shrink-0 mr-3 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clipRule="evenodd"
            ></path>
          </svg>
          <div>{alertText.text}</div>
        </div>

        <div className="flex justify-center gap-4 w-100">
          <button
            onClick={async (e) => {
              e.target.disabled = true;
              hideAlert();
              const res = await deletePartnershipBundle({
                id_user: partnership.id_user,
                id_bundle: partnership.id_bundle,
              });
              if (res.data) {
                setOpen(false);
              } else {
                setErrorAlert(
                  res.display_message || "Terjadi kesalahan, coba lagi nanti."
                );
              }
              e.target.enabled = true;
            }}
            className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-white rounded-xl text-center"
          >
            Ya
          </button>
          <button
            onClick={() => setOpen(false)}
            type="button"
            className="border-2 border-oren font-content-0 flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-black rounded-xl text-center"
          >
            Batal
          </button>
        </div>
      </div>
    </>
  );
};

const DeletePartnerEventComponent = ({
  deletePartnershipEvent,
  setOpen,
  partnership,
}) => {
  const [alertText, setAlertText] = useState({
    show: false,
    text: "",
    success: true,
  });

  const setErrorAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: false,
    });
  };
  const hideAlert = () => {
    setAlertText({
      show: false,
      text: alertText.message,
      success: alertText.success,
    });
  };
  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <div className="flex flex-col gap-6 w-full px-10 pb-10">
        <h5 className="font-bold text-center">Konfirmasi</h5>
        <div className="flex flex-col gap-2 w-full">
          <p className="font-content-0 w-full text-center">
            Apakah kamu yakin ingin menghapus partnership ini?
          </p>
        </div>

        <div
          className={`${alertText.show ? "flex" : "hidden"} p-4 mb-4 text-sm ${
            alertText.success
              ? "text-green-700 bg-green-100"
              : "text-red-700 bg-red-100"
          } rounded-lg`}
          role="alert"
        >
          <svg
            className="inline flex-shrink-0 mr-3 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clipRule="evenodd"
            ></path>
          </svg>
          <div>{alertText.text}</div>
        </div>

        <div className="flex justify-center gap-4 w-100">
          <button
            onClick={async (e) => {
              e.target.disabled = true;
              hideAlert();
              const res = await deletePartnershipEvent({
                id_user: partnership.id_partner,
                id_event: partnership.id,
              });
              if (res.data) {
                setOpen(false);
              } else {
                setErrorAlert(
                  res.display_message || "Terjadi kesalahan, coba lagi nanti."
                );
              }
              e.target.enabled = true;
            }}
            className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-white rounded-xl text-center"
          >
            Ya
          </button>
          <button
            onClick={() => setOpen(false)}
            type="button"
            className="border-2 border-oren font-content-0 flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-black rounded-xl text-center"
          >
            Batal
          </button>
        </div>
      </div>
    </>
  );
};

const DetailPartner = () => {
  const { idPartner } = useParams();
  const navigate = useNavigate();
  const {
    tryoutListAdmin,
    partnershipList,
    paginationPartnership,
    currentPartner,
    addPartnership,
    getAllPartnership,
    toggleLunasPartnership,
    deletePartnership,
    deletePartnershipBundle,
    getSinglePartner,
    bundleListAdmin,
    partnershipBundleList,
    getAllPartnershipBundle,
    paginationPartnershipBundle,
    partnershipEventList,
    paginationPartnershipEvent,
    addPartnershipEvent,
    getAllPartnershipEvent,
    deletePartnershipEvent,
  } = useContext(AdminContext);

  useEffect(() => {
    getAllPartnership({ page: 1, id_user: idPartner });
    getAllPartnershipBundle({ page: 1, id_user: idPartner });
    getAllPartnershipEvent({ page: 1, id_user: idPartner });
    (async () => {
      const res = await getSinglePartner({ id_user: idPartner });
      if (!(res.success && res.data?.id)) {
        navigate("/admin-area/partnership");
      }
    })();
  }, []);

  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState(<></>);
  return (
    <>
      <div className="flex flex-col bg-white p-10 justify-start m-0 w-full">
        <HeaderJD title="Detail Partner" />

        <div className="flex flex-col w-full md:w-full-10 mt-10 gap-3">
          <p className="font-subtitle font-bold">Info Partner</p>
          <table className="tabel-jd flex flex-row flex-nowrap w-full">
            <thead>
              <tr className={`flex flex-col mt-5 gap-2`}>
                <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                  Nama
                </th>
                <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                  Email
                </th>
                {/* <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                  Total Rev JD
                </th>
                <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                  Pending Rev JD
                </th> */}
              </tr>
            </thead>
            <tbody className="flex-1">
              <tr className="flex flex-col mt-5 gap-2">
                <td className="p-2 pl-4 font-content-1 text-left flex justify-start items-center">
                  {currentPartner.nama}
                </td>
                <td className="p-2 pl-4 font-content-1 text-left flex justify-start items-center">
                  {currentPartner.email}
                </td>
                {/* <td className="p-2 pl-4 font-content-1 text-left flex justify-start items-center">
                  <FormatPrice price={currentPartner.total_revenue_jd || 0} />
                </td>
                <td className="p-2 pl-4 font-content-1 text-left flex justify-start items-center">
                  <FormatPrice price={currentPartner.pending_revenue_jd || 0} />
                </td> */}
              </tr>
            </tbody>
          </table>
        </div>

        {/* List Partnership */}
        <div className="flex flex-col w-full md:w-full-10 mt-10 gap-3">
          <div className="w-full overflow-auto mt-3 gap-2 flex flex-col">
            <p className="font-subtitle font-bold">Partnership Try Out</p>
            <button
              onClick={() => {
                setModalContent(
                  <AddPartnershipComponent
                    currentPartner={currentPartner}
                    tryoutListAdminPremium={tryoutListAdmin.filter(
                      (item) => parseInt(item.tipe) === 1
                    )}
                    partnershipList={partnershipList}
                    setOpen={setOpen}
                    addPartnership={addPartnership}
                  />
                );
                setOpen(true);
              }}
              className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl"
            >
              Tambah Partnership Try Out
            </button>
            {partnershipList.length === 0 ? (
              <p className="font-content-0">
                Belum ada kerja sama try out dengan partner ini.
              </p>
            ) : null}
            <table className="tabel-jd flex flex-row flex-nowrap w-full md:inline-table">
              <thead>
                {partnershipList.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={`${
                        index === 0 ? "md:table-row" : "md:hidden"
                      } flex flex-col mt-5 md:mt-0`}
                    >
                      <th className="h-14 md:h-auto p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                        Try Out
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Kode Voucher
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Profit Partner
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Pendaftar
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Rev JD
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Status
                      </th>
                      <th className="p-4 md:p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Aksi
                      </th>
                      <th className="p-4 md:p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none md:rounded-r-xl overflow-hidden">
                        Hapus
                      </th>
                    </tr>
                  );
                })}
              </thead>
              <tbody className="flex-1 md:flex-none">
                {partnershipList.map((item, index) => {
                  return (
                    <tr
                      className="flex flex-col md:table-row mt-5 md:mt-0"
                      key={index}
                    >
                      <td className="h-14 md:h-auto p-2 font-content-1 text-center">
                        {item.nama_tryout}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {item.kode_promo}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        <FormatPrice price={item.profit_partner} />
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {item.user_count}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        <FormatPrice
                          price={
                            parseInt(item.profit_jd) * parseInt(item.user_count)
                          }
                        />
                      </td>
                      <td
                        className={`p-2 font-content-1 text-center font-bold ${
                          parseInt(item.lunas) ? "text-hijau" : "text-merah"
                        }`}
                      >
                        {parseInt(item.lunas) ? "Lunas" : "Belum Lunas"}
                      </td>
                      <td className="p-2 font-content-1 justify-center flex md:table-cell">
                        <button
                          onClick={async (e) => {
                            const button = e.target;
                            button.disabled = true;
                            await toggleLunasPartnership({
                              id_user: idPartner,
                              id_tryout: item.id_tryout,
                              page: paginationPartnership.currentPage,
                            });
                            button.disabled = false;
                          }}
                          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl m-auto"
                        >
                          Tandai Lunas
                        </button>
                      </td>
                      <td className="p-2 font-content-1 justify-center flex md:table-cell">
                        <button
                          onClick={() => {
                            setModalContent(
                              <DeletePartnerComponent
                                setOpen={setOpen}
                                deletePartnership={deletePartnership}
                                partnership={item}
                              />
                            );

                            setOpen(true);
                          }}
                          className="bg-merah font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl m-auto"
                          disabled={parseInt(item.user_count)}
                        >
                          Hapus
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="flex w-full justify-center">
            <div className="flex gap-3">
              {paginationPartnership.totalPage ? (
                <PageComponent
                  currentPage={paginationPartnership.currentPage}
                  totalPage={paginationPartnership.totalPage}
                  getData={(page) => {
                    getAllPartnership({
                      page: page,
                      id_user: idPartner,
                    });
                  }}
                />
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="w-full overflow-auto mt-3 gap-2 flex flex-col">
            <p className="font-subtitle font-bold">Partnership Bundle</p>
            <button
              onClick={() => {
                setModalContent(
                  <AddPartnershipBundleComponent
                    currentPartner={currentPartner}
                    bundleListAdmin={bundleListAdmin}
                    partnershipBundleList={partnershipBundleList}
                    setOpen={setOpen}
                    addPartnership={addPartnership}
                  />
                );
                setOpen(true);
              }}
              className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl"
            >
              Tambah Partnership Bundle
            </button>
            {partnershipBundleList.length === 0 ? (
              <p className="font-content-0">
                Belum ada kerja sama bundle dengan partner ini.
              </p>
            ) : null}
            <table className="tabel-jd flex flex-row flex-nowrap w-full md:inline-table">
              <thead>
                {partnershipBundleList.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={`${
                        index === 0 ? "md:table-row" : "md:hidden"
                      } flex flex-col mt-5 md:mt-0`}
                    >
                      <th className="h-14 md:h-auto p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                        Bundle
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Kode Voucher
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Profit Partner
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Pendaftar
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Profit Partner Total
                      </th>
                      <th className="p-4 md:p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none md:rounded-r-xl overflow-hidden">
                        Hapus
                      </th>
                    </tr>
                  );
                })}
              </thead>
              <tbody className="flex-1 md:flex-none">
                {partnershipBundleList.map((item, index) => {
                  return (
                    <tr
                      className="flex flex-col md:table-row mt-5 md:mt-0"
                      key={index}
                    >
                      <td className="h-14 md:h-auto p-2 font-content-1 text-center">
                        {item.nama_bundle}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {item.kode_promo}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        <FormatPrice price={item.profit_partner} />
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {item.user_count}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        <FormatPrice
                          price={
                            parseInt(item.profit_partner) *
                            parseInt(item.user_count)
                          }
                        />
                      </td>
                      <td className="p-2 font-content-1 justify-center flex md:table-cell">
                        <button
                          onClick={() => {
                            setModalContent(
                              <DeletePartnerBundleComponent
                                setOpen={setOpen}
                                deletePartnershipBundle={
                                  deletePartnershipBundle
                                }
                                partnership={item}
                              />
                            );

                            setOpen(true);
                          }}
                          className="bg-merah font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl m-auto"
                          disabled={parseInt(item.user_count)}
                        >
                          Hapus
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="flex w-full justify-center">
            <div className="flex gap-3">
              {paginationPartnershipBundle.totalPage ? (
                <PageComponent
                  currentPage={paginationPartnershipBundle.currentPage}
                  totalPage={paginationPartnershipBundle.totalPage}
                  getData={(page) => {
                    getAllPartnershipBundle({
                      page: page,
                      id_user: idPartner,
                    });
                  }}
                />
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="w-full overflow-auto mt-3 gap-2 flex flex-col">
            <p className="font-subtitle font-bold">Partnership Event</p>
            <button
              onClick={() => {
                setModalContent(
                  <AddPartnershipEventComponent
                    currentPartner={currentPartner}
                    setOpen={setOpen}
                    addPartnership={addPartnershipEvent}
                  />
                );
                setOpen(true);
              }}
              className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl"
            >
              Tambah Partnership Event
            </button>
            {partnershipEventList.length === 0 ? (
              <p className="font-content-0">
                Belum ada kerja sama event dengan partner ini.
              </p>
            ) : null}
            <table className="tabel-jd flex flex-row flex-nowrap w-full md:inline-table">
              <thead>
                {partnershipEventList.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={`${
                        index === 0 ? "md:table-row" : "md:hidden"
                      } flex flex-col mt-5 md:mt-0`}
                    >
                      <th className="h-14 md:h-auto p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                        Event
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        URL Logo
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Subdomain
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Status
                      </th>
                      <th className="p-4 md:p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Aksi
                      </th>
                      <th className="p-4 md:p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none md:rounded-r-xl overflow-hidden">
                        Hapus
                      </th>
                    </tr>
                  );
                })}
              </thead>
              <tbody className="flex-1 md:flex-none">
                {partnershipEventList.map((item, index) => {
                  return (
                    <tr
                      className="flex flex-col md:table-row mt-5 md:mt-0"
                      key={index}
                    >
                      <td className="h-14 md:h-auto p-2 font-content-1 text-center">
                        {item.nama_event}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {item.logo_url}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {item.origin}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {parseInt(item.is_active) ? "Aktif" : "Tidak aktif"}
                      </td>
                      <td className="p-2 font-content-1 justify-center flex md:table-cell">
                        <Link
                          to={`/admin-area/partnership/detail/${item.id}`}
                          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl m-auto"
                        >
                          Detail
                        </Link>
                      </td>
                      <td className="p-2 font-content-1 justify-center flex md:table-cell">
                        <button
                          onClick={() => {
                            setModalContent(
                              <DeletePartnerEventComponent
                                setOpen={setOpen}
                                deletePartnershipEvent={deletePartnershipEvent}
                                partnership={item}
                              />
                            );

                            setOpen(true);
                          }}
                          className="bg-merah font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl m-auto"
                        >
                          Hapus
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="flex w-full justify-center">
            <div className="flex gap-3">
              {paginationPartnershipEvent.totalPage ? (
                <PageComponent
                  currentPage={paginationPartnershipEvent.currentPage}
                  totalPage={paginationPartnershipEvent.totalPage}
                  getData={(page) => {
                    getAllPartnershipEvent({
                      page: page,
                      id_user: idPartner,
                    });
                  }}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal {...{ open, setOpen }}>
        <div className="relative text-left w-11/12 md:w-2/3 lg:w-1/2 xl:w-2/5 z-50 flex flex-col text-xl font-bold bg-white rounded-2xl">
          {modalContent}
        </div>
      </Modal>
    </>
  );
};

export default DetailPartner;
