import { Routes, Route } from "react-router-dom";

import GlobalState from "./context/GlobalContext/GlobalState";
import UserState from "./context/UserContext/UserState";
import TryoutState from "./context/TryoutContext/TryoutState";

import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
// import Materi from "./pages/Materi";
// import Navbar from "./components/Navbar";
// import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import PrivateRoute from "./components/Routing/PrivateRoute";
import Profil from "./pages/Profil";
import Tryout from "./pages/Tryout";
import RankingPembahasan from "./pages/RankingPembahasan";
import TryoutRegister from "./pages/tryout/TryoutRegister";
import TryoutLobby from "./pages/tryout/TryoutLobby";
import TryoutInfo from "./pages/tryout/TryoutInfo";
import TryoutKerjakan from "./pages/tryout/TryoutKerjakan";
import PembahasanLobby from "./pages/ranking-pembahasan/PembahasanLobby";
import PembahasanSubtes from "./pages/ranking-pembahasan/PembahasanSubtes";
import Ranking from "./pages/ranking-pembahasan/Ranking";
import BundleRegister from "./pages/tryout-bundle/BundleRegister";
import AdminState from "./context/AdminContext/AdminState";
import AdminRoute from "./components/Routing/AdminRoute";
import AdminArea from "./pages/AdminArea";
import PendaftarTryout from "./pages/admin-area/PendaftarTryout";
import EditIsiBundle from "./pages/admin-area/EditIsiBundle";
import PendaftarBundle from "./pages/admin-area/PendaftarBundle";
import PartnerArea from "./pages/PartnerArea";
import PartnerRoute from "./components/Routing/PartnerRoute";
import Partnership from "./pages/admin-area/Partnership";
import DetailPartner from "./pages/admin-area/DetailPartner";
import PartnerState from "./context/PartnerContext/PartnerState";
import DetailPartnership from "./pages/partner-area/DetailPartnership";
import DetailPendaftar from "./pages/admin-area/DetailPendaftar";
import DetailPartnershipBundle from "./pages/partner-area/DetailPartnershipBundle";
import DetailPendaftarBundle from "./pages/admin-area/DetailPendaftarBundle";
import DetailEvent from "./pages/admin-area/DetailEvent";
import Perjanjian from "./pages/Perjanjian";
import DetailPartnershipEvent from "./pages/partner-area/DetailPartnershipEvent";
import EditSoalLobbyPartner from "./pages/partner-area/EditSoalLobbyPartner";
import EditSoalLobbyAdmin from "./pages/admin-area/EditSoalLobbyAdmin";

function App() {
  return (
    <div id="body" className="w-full min-h-screen h-auto m-0 p-0">
      <ScrollToTop />
      <GlobalState>
        <UserState>
          <TryoutState>
            <AdminState>
              <PartnerState>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route
                    path="/perjanjian/:perjanjianId"
                    element={<Perjanjian />}
                  />
                  <Route
                    path="/dashboard"
                    element={
                      <PrivateRoute>
                        <Dashboard />
                      </PrivateRoute>
                    }
                  />
                  {/* <Route
                  path="/materi"
                  element={
                    <PrivateRoute>
                      <Materi />
                    </PrivateRoute>
                  }
                /> */}
                  <Route
                    path="/tryout"
                    element={
                      <PrivateRoute>
                        <Tryout />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/tryout/:tryoutId/daftar"
                    element={
                      <PrivateRoute>
                        <TryoutRegister />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/tryout/:tryoutId/info"
                    element={
                      <PrivateRoute>
                        <TryoutInfo />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/tryout/:tryoutId/lobby"
                    element={
                      <PrivateRoute>
                        <TryoutLobby />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/tryout/:tryoutId/kerjakan/:subtes"
                    element={
                      <PrivateRoute>
                        <TryoutKerjakan />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/tryout-bundle/:bundleId/daftar"
                    element={
                      <PrivateRoute>
                        <BundleRegister />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/ranking-pembahasan"
                    element={
                      <PrivateRoute>
                        <RankingPembahasan />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/ranking-pembahasan/:tryoutId/lobby"
                    element={
                      <PrivateRoute>
                        <PembahasanLobby />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/ranking-pembahasan/:tryoutId/pembahasan/:subtes"
                    element={
                      <PrivateRoute>
                        <PembahasanSubtes />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/ranking-pembahasan/:tryoutId/ranking"
                    element={
                      <PrivateRoute>
                        <Ranking />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/profil"
                    element={
                      <PrivateRoute>
                        <Profil />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/admin-area"
                    element={
                      <AdminRoute>
                        <AdminArea />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/admin-area/tryout/:tryoutId/edit-soal"
                    element={
                      <AdminRoute>
                        <EditSoalLobbyAdmin />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/admin-area/tryout/:tryoutId/pendaftar"
                    element={
                      <AdminRoute>
                        <PendaftarTryout />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/admin-area/tryout/:tryoutId/pendaftar/detail"
                    element={
                      <AdminRoute>
                        <DetailPendaftar />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/admin-area/bundle/:bundleId/edit-isi-bundle"
                    element={
                      <AdminRoute>
                        <EditIsiBundle />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/admin-area/bundle/:bundleId/pendaftar"
                    element={
                      <AdminRoute>
                        <PendaftarBundle />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/admin-area/bundle/:bundleId/pendaftar/detail"
                    element={
                      <AdminRoute>
                        <DetailPendaftarBundle />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/admin-area/partnership"
                    element={
                      <AdminRoute>
                        <Partnership />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/admin-area/partnership/:idPartner"
                    element={
                      <AdminRoute>
                        <DetailPartner />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/admin-area/partnership/detail/:idEvent"
                    element={
                      <AdminRoute>
                        <DetailEvent />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/partner-area"
                    element={
                      <PartnerRoute>
                        <PartnerArea />
                      </PartnerRoute>
                    }
                  />
                  <Route
                    path="/partner-area/bundle/:idBundle"
                    element={
                      <PartnerRoute>
                        <DetailPartnershipBundle />
                      </PartnerRoute>
                    }
                  />
                  <Route
                    path="/partner-area/event/:idEvent"
                    element={
                      <PartnerRoute>
                        <DetailPartnershipEvent />
                      </PartnerRoute>
                    }
                  />
                  <Route
                    path="/partner-area/event/edit-tryout/:idTryout"
                    element={
                      <PartnerRoute>
                        <EditSoalLobbyPartner />
                      </PartnerRoute>
                    }
                  />
                  <Route
                    path="/partner-area/:idTryout"
                    element={
                      <PartnerRoute>
                        <DetailPartnership />
                      </PartnerRoute>
                    }
                  />
                  {/* <Route path="/gallery" element={<Gallery />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/posts/:idPost" element={<Post />} />
          <Route path="/admin" element={<Login />} />
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/edit/:idPost" element={<EditPost />} /> */}
                </Routes>
              </PartnerState>
            </AdminState>
          </TryoutState>
        </UserState>
      </GlobalState>
    </div>
  );
}

export default App;
