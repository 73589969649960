import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const dateToTimestampWIB = (dateString, customTime = null) => {
  const dateWIB = dayjs.tz(dateString, "Asia/Jakarta");
  if (customTime) {
    const dateWIB2 = dateWIB
      .hour(customTime.hour)
      .minute(customTime.minute)
      .second(customTime.second);
    return dateWIB2.valueOf();
  } else {
    return dateWIB.valueOf();
  }
};

export default dateToTimestampWIB;
