/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HeaderJD from "../../components/Layout/HeaderJD";
import AdminContext from "../../context/AdminContext/AdminContext";
import EditSoalLobby from "../../components/Dashboard/EditSoalLobby";

const EditSoalLobbyAdmin = () => {
  const { tryoutId } = useParams();
  const navigate = useNavigate();
  const {
    tryoutListAdmin,
    subtesListAdmin,
    soalPembahasanListAdmin,
    getSubtesAdmin,
    addSubtes,
    deleteSubtes,
    getSoalPembahasanAdmin,
    deleteSoal,
    editSoal,
    addSoal,
  } = useContext(AdminContext);
  const [currentTryout, setCurrentTryout] = useState({});
  useEffect(() => {
    if (tryoutListAdmin && tryoutListAdmin.length) {
      const tryout = tryoutListAdmin.find(
        (item) => String(item.id) === String(tryoutId)
      );
      if (tryout) {
        getSubtesAdmin(tryout.id);
        setCurrentTryout(tryout);
      } else {
        navigate("/admin-area");
      }
    }
  }, [tryoutListAdmin]);

  return (
    <>
      <div className="flex flex-col bg-white p-10 justify-start m-0 w-full">
        <HeaderJD title={`Edit Soal ${currentTryout.nama_tryout}`} />

        {currentTryout ? (
          <EditSoalLobby
            {...{
              currentTryout: currentTryout,
              subtesListTryout: subtesListAdmin,
              soalPembahasanList: soalPembahasanListAdmin,
              getSubtesTryout: getSubtesAdmin,
              addSubtes: addSubtes,
              deleteSubtes: deleteSubtes,
              getSoalPembahasan: getSoalPembahasanAdmin,
              editSoal: editSoal,
              deleteSoal: deleteSoal,
              addSoal: addSoal,
            }}
          />
        ) : null}
      </div>
    </>
  );
};

export default EditSoalLobbyAdmin;
