import axios from "axios";
import { useNavigate } from "react-router-dom";
import UserContext from "./UserContext";
import UserReducer from "./UserReducer";
import { host } from "../config";
import { useReducer } from "react";

const UserState = ({ children }) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.token}`;
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(UserReducer, {
    userData: {},
  });

  const getUserData = async () => {
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.token}`,
      },
    };
    try {
      const res = await axios.get(host + "/user/get_user_data.php", config);
      if (res.data.success) {
        dispatch({
          type: "getUserData",
          payload: {
            userData: res.data.data,
          },
        });
        return res.data;
      } else {
        console.log(res.data.message);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const editUserData = async (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((prop) => {
      formData.append(prop, data[prop]);
    });

    try {
      const res = await axios({
        method: "POST",
        url: host + "/user/edit_user_data.php",
        data: formData,
        headers: { "content-type": "multipart/form-data" },
      });
      if (res.data.success) {
        dispatch({
          type: "editUserData",
          payload: {
            userData: res.data.data,
          },
        });
        return true;
      } else {
        console.log(res.data.message);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const logout = async () => {
    delete localStorage.clear();
    navigate("/");
  };

  const getAllMateri = async () => {
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.token}`,
      },
    };
    try {
      const res = await axios.get(host + "/materi/get_materi.php", config);
      if (res.data.success) {
        return res.data.data;
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
        return [];
      }
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const getMoreMateri = async (nama_pelajaran, page) => {
    const data = { nama_pelajaran, page: page + 1 };
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.token}`,
      },
    };
    try {
      const res = await axios.post(
        host + "/materi/get_materi_next.php",
        data,
        config
      );
      if (res.data.success) {
        return res.data.data;
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
        return [];
      }
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const searchMateri = async (nama_pelajaran, query) => {
    const data = { nama_pelajaran, query };
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.token}`,
      },
    };
    try {
      const res = await axios.post(
        host + "/materi/search_materi.php",
        data,
        config
      );
      if (res.data.success) {
        return res.data.data;
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
        return [];
      }
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const changeTahun = async (tahun) => {
    const data = { tahun };
    try {
      const res = await axios.post(host + "/auth/change_year.php", data);
      if (res.data.success) {
        localStorage.token = res.data.token;
        return { ...res.data, success: true };
      } else {
        console.log(res.data);
        return { ...res.data, success: false };
      }
    } catch (err) {
      console.log(err);
      return { success: false };
    }
  };

  return (
    <UserContext.Provider
      value={{
        userData: state.userData,
        getUserData,
        editUserData,
        getAllMateri,
        getMoreMateri,
        searchMateri,
        changeTahun,
        logout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserState;
