const ResetPassForm = ({ setOpen }) => {
  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <div className="flex flex-col gap-6 w-full px-10 pb-10">
        <h6 className="font-bold text-center">Lupa kata sandi?</h6>
        <p className="font-content-1 w-full text-center">
          Tenang, masukkan alamat email kamu dan jangan lupa untuk cek kotak
          masuk emailmu, ya!
        </p>
        <form className="flex flex-col gap-5 w-full">
          <div className="w-full flex border-2 border-black rounded-xl items-center">
            <input
              className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
              placeholder="Email"
              type="email"
            />
          </div>

          <button
            type="submit"
            className="rounded-xl bg-oren text-white font-content-0 font-bold px-8 py-2"
          >
            Kirim
          </button>
        </form>
      </div>
    </>
  );
};

export default ResetPassForm;
