const caraBayar = [
  // {
  //   id: "method-bca",
  //   value: "BCA",
  //   name: "BCA",
  //   is_rekening: 1,
  //   no: "4452032761",
  //   pemilik: "Harry Krisna D",
  //   step: [
  //     {
  //       header: "CARA TRANSFER VIA ATM",
  //       steps: [
  //         "Masukan kartu ATM",
  //         "Pilih Bahasa Indonesia",
  //         "Masukan 6 Digit PIN -> Benar",
  //         "Pilih Menu Lain",
  //         "Pilih Transfer",
  //         "Pilih tujuan transfer kemudian isi nomer rekening tujuan",
  //         "Masukan jumlah uang sesuai yang harus dibayar",
  //         "Konfirmasi. Tunggu beberapa saat hingga mesin ATM menampilkan konfirmasi pembayaran atas nama si penerima dan juga nominal uang yang akan ditransfer. Jika seluruh informasi telah benar, kamu bisa melanjutkan proses transaksi dengan memilih Ya pada layar",
  //         "Tunggu struk bukti transfer. Foto struk sebagai bukti pembayaran",
  //       ],
  //       hint: "Catatan : Jika menggunakan beda bank, tambahkan kode 014 sebelum nomer rekening saat mengisi nomer rek. tujuan",
  //     },
  //     {
  //       header: "CARA TRANSFER VIA BANK",
  //       steps: [
  //         "Siapkan uang serta kartu identitas asli",
  //         "Datang ke bank yang sesuai dengan rekening si penerima",
  //         "Ambil nomor antrian dan isi formulir yang disediakan",
  //         "Serahkan formulir ke teller untuk diproses",
  //         "Uang akan langsung masuk ke rekening penerima",
  //       ],
  //       hint: "",
  //     },
  //   ],
  // },
  // {
  //   id: "method-gopay",
  //   value: "Gopay",
  //   name: "Gopay",
  //   is_rekening: 0,
  //   no: "0895632448611",
  //   pemilik: "Harry Krisna D",
  //   step: [
  //     {
  //       header: "CARA TRANSFER VIA Gopay",
  //       steps: [
  //         "Buka aplikasi Go-jek tersebut, kemudian pilih menu Bayar",
  //         "Lalu klik gambar simbol telepon dengan keterangan “Nomor HP” yang artinya bayar atau transfer lewat nomor Hp",
  //         "Pilih nomor Hp yang dituju pada daftar kontak telepon kamu",
  //         "Masukan jumlah uang yang akan di transfer",
  //         "Klik Konfirmasi",
  //         "Tambahkan catatan jika perlu",
  //         "Klik Bayar dan masukan PIN yang terdiri dari 6 digit",
  //         "Jika transfermu berhasil, kamu akan segera mendapatkan notifikasi ‘pembayaran berhasil’",
  //         "Screenshot bukti pembayaran lalu upload pada formulir di bawah",
  //       ],
  //       hint: "",
  //     },
  //   ],
  // },
  // {
  //   id: "method-ovo",
  //   value: "OVO",
  //   name: "OVO",
  //   is_rekening: 0,
  //   no: "08995474645",
  //   pemilik: "Fitra Yogapratama",
  //   step: [
  //     {
  //       header: "CARA TRANSFER VIA OVO",
  //       steps: [
  //         "Pastikan sudah log in pada akun OVO dan OVO yang kamu gunakan sudah premium",
  //         "Pada layar utama, pilih fitur ‘transfer’",
  //         "Kemudian masukan nominal uang yang akan kamu transfer",
  //         "Pilih ‘Antar OVO’",
  //         "Masukan nomor telepon penerima, bisa secara langsung atau kamu juga bisa pilih nomor telepon melalui daftar kontak di Hpmu dengan klik simbol ‘kontak telepon’",
  //         "Pesan di kolom selanjutnya tersebut boleh di isi atau tidak",
  //         "Klik Transfer",
  //         "Jika semua nominal dan nomor telepon dinyatakan sudah benar, maka tinggal klik ‘konfirmasi’",
  //         "Kamu akan menerima informasi bahwa transfer sudah berhasil",
  //         "Screenshot bukti pembayaran lalu upload pada formulir di bawah",
  //       ],
  //       hint: "",
  //     },
  //   ],
  // },
  // {
  //   id: "method-dana",
  //   value: "Dana",
  //   name: "Dana",
  //   is_rekening: 0,
  //   no: "08995474645",
  //   pemilik: "Fitra Yogapratama",
  //   step: [
  //     {
  //       header: "CARA TRANSFER VIA DANA",
  //       steps: [
  //         "Pastikan aplikasi DANA yang kamu miliki sudah premium",
  //         "Login akun DANAmu",
  //         "Pilih ‘Send’ pada layar utama yang terdapat pada kolom atas",
  //         "Masukkan jumlah uang yang akan dikirim",
  //         "Klik ‘Send to’",
  //         "Pilih ‘Kontak,’ kemudian pilih nomor telepon penerima di dafta konta yang terdapat di teleponmu",
  //         "Klik ‘Send to’",
  //         "Setelah memastikan kebenaran data, pilih ‘Konfirmasi’",
  //         "Masukkan PIN",
  //         "Jika transfer berhasil, maka kamu akan menerima laporan ‘pembayaran sukses’",
  //         "Screenshot bukti pembayaran lalu upload pada formulir di bawah",
  //       ],
  //       hint: "",
  //     },
  //   ],
  // },
  // {
  //   id: "method-shopee",
  //   value: "Shopee",
  //   name: "Shopee Pay",
  //   is_rekening: 2,
  //   no: "0895632448611",
  //   pemilik: "harrykri",
  //   step: [
  //     {
  //       header: "CARA TRANSFER VIA SHOPEE PAY",
  //       steps: [
  //         "Pastikan akun Shopee yang kamu miliki sudah diverifikasi",
  //         "Buka aplikasi Shopee kamu",
  //         "Klik saldo Shopee Pay",
  //         "Pilih ‘Transfer’",
  //         "Pilih ‘Ke Pengguna Shopee’",
  //         "Masukkan username di atas dan pilih profilnya",
  //         "Masukkan saldo sesuai yang tertera di atas dan klik ‘Konfirmasi’",
  //         "Masukkan kode PIN Shopee Pay",
  //         "Screenshot bukti pembayaran lalu upload pada formulir di bawah",
  //       ],
  //       hint: "",
  //     },
  //   ],
  // },
  {
    id: "method-whatsapp",
    value: "CS",
    name: "CS WhatsApp",
    is_rekening: 2,
    no: (
      <a
        href={"https://bit.ly/TryoutJD"}
        target="_blank"
        rel="noreferrer"
        className="text-oren hover:underline"
      >
        Hubungi sekarang
      </a>
    ),
    pemilik: "Admin",
    step: [
      {
        header: "CARA BAYAR MELALUI ADMIN",
        steps: [
          "Hubungi admin dengan link ini atau link yang didapat dari media sosial",
          "Selesaikan pembayaran sesuai arahan admin",
          "Screenshot bukti chat setelah pembayaran berhasil",
          "Buka kembali laman ini dan pakai kode voucher dari chat admin",
          "Upload bukti pembayaran pada formulir di bawah",
        ],
        hint: "",
      },
    ],
  },
];

export default caraBayar;
