/* eslint-disable react-hooks/exhaustive-deps*/

import { useContext, useEffect, useRef, useState } from "react";

import Select, { createFilter } from "react-select";

import GlobalContext from "../../context/GlobalContext/GlobalContext";

const RegisterForm = ({ setOpen, data, setModalState }) => {
  const {
    provinsiList,
    sekolahList,
    getProvinsiList,
    getSekolahList,
    register,
    webEventInfo,
  } = useContext(GlobalContext);
  useEffect(() => {
    getProvinsiList();
  }, []);

  const [currentProv, setCurrentProv] = useState("");

  const DynamicSelect = ({ option, currentValue }) => {
    const options = option.map((item) => {
      return { value: item.npsn, label: item.nama };
    });
    const MenuList = function MenuList(props) {
      const children = props.children;

      if (!children.length) {
        return <>{children}</>;
      }

      return (
        <>
          {children.length &&
            children.map((key) => {
              delete key.props.innerProps.onMouseMove; //FIX LAG!!
              delete key.props.innerProps.onMouseOver; //FIX LAG!!

              return key;
            })}
        </>
      );
    };

    return (
      <Select
        placeholder="Asal sekolah"
        styles={{
          control: () => ({
            alignItems: "center",
            border: "none",
            display: "flex",
            flexWrap: "wrap",
            WebkitBoxPack: "justify",
            justifyContent: "space-between",
            minHeight: "38px",
            position: "relative",
          }),
          menu: (provided, state) => ({
            ...provided,
            height: "13rem",
            overflow: "auto",
          }),
        }}
        className="w-full border-none outline-none font-content-1"
        onChange={(val) => {
          setFormData({ ...formData, sekolah: val.value });
        }}
        options={options}
        filterOption={createFilter({ ignoreAccents: false })}
        components={{
          MenuList,
        }}
        value={options.find((item) => item.value === currentValue)}
      />
    );
  };
  const [passwordState, setPasswordState] = useState(true);

  // Handle Form Data
  const titleCase = (text) => {
    const words = text.toLowerCase().split(" ");
    const titleCaseWords = words.map((word) => {
      return word[0].toUpperCase() + word.slice(1);
    });
    return titleCaseWords.join(" ");
  };
  const [formData, setFormData] = useState({
    nama: (data && titleCase(data.nama)) || "",
    asal: "",
    sekolah: "",
    email: (data && data.email) || "",
    password: "",
  });

  useEffect(() => {
    if (data && data.email) {
      setTextAlert("Akun belum terdaftar, silahkan daftar terlebih dahulu");
      hideAllAlert();
      showErrorAlert();
    }
  }, [data]);

  const [textAlert, setTextAlert] = useState("");
  const errorAlertRef = useRef(<></>);
  const successAlertRef = useRef(<></>);

  const showErrorAlert = () => {
    errorAlertRef.current.classList.remove("hidden");
    errorAlertRef.current.classList.add("flex");
  };
  const showSuccessAlert = () => {
    successAlertRef.current.classList.remove("hidden");
    successAlertRef.current.classList.add("flex");
  };

  const hideAllAlert = () => {
    errorAlertRef.current.classList.remove("flex");
    errorAlertRef.current.classList.add("hidden");
    successAlertRef.current.classList.remove("flex");
    successAlertRef.current.classList.add("hidden");
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();
    hideAllAlert();
    let completed = true;
    let safe = true;
    Object.keys(formData).forEach((item) => {
      if (String(formData[item]).replace(" ", "").length <= 0) {
        completed = false;
      }
    });

    if (!completed) {
      setTextAlert("Semua kolom harus diisi!");
      showErrorAlert();
    } else {
      if (safe && formData["nama"].length < 6) {
        setTextAlert("Nama harus berisi minimal 6 karakter!");
        showErrorAlert();
        safe = false;
      }
      if (safe && formData["password"].length < 6) {
        setTextAlert("Password harus berisi minimal 6 karakter!");
        showErrorAlert();
        safe = false;
      }
    }

    if (completed && safe) {
      e.target[6].disabled = true;
      formData.nama = formData.nama.trim().replace(/ +(?= )/g, "");
      const result = await register(formData);
      e.target[6].disabled = false;
      if (result.success) {
        setTextAlert(
          "Pendaftaran berhasil, silahkan masuk dengan akun yang sudah kamu buat"
        );
        showSuccessAlert();
      } else {
        setTextAlert(
          result.display_message ||
            "Terjadi kesalahan saat mendaftar, coba beberapa saat lagi"
        );
        showErrorAlert();
      }
    }
  };

  return (
    <>
      {!webEventInfo ? (
        <div className="flex w-full justify-end p-7 pb-0">
          <i
            onClick={() => setOpen(false)}
            className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
          ></i>
        </div>
      ) : null}

      <div
        className={`flex flex-col gap-6 w-full px-10 pb-10 ${
          webEventInfo ? "py-10" : ""
        }`}
      >
        <h6 className="font-bold text-center">Daftar</h6>
        <p className="font-content-1 w-full text-center">
          Buat akun untuk bisa menikmati akses Try Out dimana saja dan kapan
          saja!
        </p>
        <form className="flex flex-col gap-5 w-full" onSubmit={onSubmitForm}>
          <div className="w-full flex border-2 border-black rounded-xl items-center">
            <input
              className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
              placeholder="Nama lengkap"
              type="text"
              value={formData.nama}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  nama: e.target.value.replace(/ +(?= )/g, ""),
                });
              }}
              required={true}
              autoFocus={true}
            />
          </div>
          <div className="w-full flex border-2 border-black rounded-xl items-center select-container">
            <Select
              placeholder="Asal daerah"
              styles={{
                control: () => ({
                  alignItems: "center",
                  border: "none",
                  display: "flex",
                  flexWrap: "wrap",
                  WebkitBoxPack: "justify",
                  justifyContent: "space-between",
                  minHeight: "38px",
                  position: "relative",
                }),
              }}
              className="w-full border-none outline-none font-content-1"
              onChange={(val) => {
                if (val.value !== currentProv) {
                  getSekolahList(val.value);
                  setCurrentProv(val.value);
                  setFormData({ ...formData, asal: val.value });
                }
              }}
              options={provinsiList.map((item) => {
                return { value: item, label: item };
              })}
              filterOption={createFilter({ ignoreAccents: false })}
            />
          </div>
          <div className="w-full flex border-2 border-black rounded-xl items-center">
            <DynamicSelect
              option={sekolahList}
              currentValue={formData.sekolah}
            />
          </div>
          <div className="w-full flex border-2 border-black rounded-xl items-center">
            <input
              className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
              placeholder="Email"
              type="email"
              value={formData.email}
              onChange={(e) => {
                setFormData({ ...formData, email: e.target.value.trim() });
              }}
              required={true}
            />
          </div>
          <div className="w-full flex border-2 pr-3 border-black rounded-xl items-center">
            <input
              className="w-full-5 sm:w-full-10 px-3 py-2 rounded-l-xl border-none outline-none bg-white font-content-1"
              placeholder="Masukkan kata sandimu"
              type={passwordState ? "password" : "text"}
              autoComplete="on"
              min="6"
              onChange={(e) => {
                setFormData({ ...formData, password: e.target.value });
              }}
              required={true}
            />
            <i
              onClick={() => {
                setPasswordState(!passwordState);
              }}
              className={`w-5 sm:w-10 text-abu fas ${
                passwordState ? "fa-eye-slash" : "fa-eye"
              } text-lg md:text-xl rounded-full cursor-pointer text-center`}
            ></i>
          </div>
          <div className="w-full flex border-2 pr-3 border-black rounded-xl items-center">
            <input
              className="w-full-5 sm:w-full-10 px-3 py-2 rounded-l-xl border-none outline-none bg-white font-content-1"
              placeholder="Ulangi kata sandimu"
              type={passwordState ? "password" : "text"}
              autoComplete="on"
              onChange={(e) => {
                if (e.target.value !== formData.password) {
                  e.target.setCustomValidity("Password tidak cocok!");
                  e.target.reportValidity();
                } else {
                  e.target.setCustomValidity("");
                }
              }}
              required={true}
            />
            <i
              onClick={() => {
                setPasswordState(!passwordState);
              }}
              className={`w-5 sm:w-10 text-abu fas ${
                passwordState ? "fa-eye-slash" : "fa-eye"
              } text-lg md:text-xl rounded-full cursor-pointer text-center`}
            ></i>
          </div>

          <div
            className="hidden p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg"
            role="alert"
            ref={errorAlertRef}
          >
            <svg
              className="inline flex-shrink-0 mr-3 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <div>{textAlert}</div>
          </div>
          <div
            ref={successAlertRef}
            className="hidden p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg"
            role="alert"
          >
            <svg
              className="inline flex-shrink-0 mr-3 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <div>{textAlert}</div>
          </div>

          <button
            type="submit"
            className="rounded-xl bg-oren text-white font-content-0 font-bold px-8 py-2"
          >
            Daftar
          </button>
        </form>
        <p className="font-content-1 text-center">
          Sudah punya akun?{" "}
          <span
            onClick={() =>
              setModalState({
                login: true,
                register: false,
                registerGoogle: false,
                resetPass: false,
              })
            }
            className="text-biru hover:underline cursor-pointer"
          >
            Login sekarang!
          </span>
        </p>
      </div>
    </>
  );
};

export default RegisterForm;
