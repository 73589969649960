const agreementData = {
  "april-2023": {
    title: "Perjanjian Kesepakatan Kerja Sama",
    start_date: "17 April 2023",
    party_2: "Mitra Kerja Sama",
    section_list: [
      {
        title: "Deskripsi Program Kerja Sama",
        content:
          "Pihak Pertama menyediakan program kerja sama untuk produk Try Out SNBT berjumlah 5 paket seharga Rp120.000 kepada peserta, dimana Pihak Kedua akan mempromosikan produk ini dengan cara membagikan kode voucher yang diberikan oleh Phak Pertama. Setiap peserta yang mendaftar menggunakan kode voucher Pihak Kedua akan mendapatkan diskon sebesar 58%, sehingga harga yang harus dibayar oleh peserta adalah Rp50.000. Pihak Kedua akan mendapatkan komisi sebesar Rp15.000 - Rp20.000 (dengan ketentuan) untuk setiap peserta yang mendaftar menggunakan kode voucher Pihak Kedua.",
      },
      {
        title: "Masa Berlaku Perjanjian",
        content: "Perjanjian ini akan berlaku mulai tanggal 17-30 April 2023.",
      },
      {
        title: "Hak dan Kewajiban Pihak Pertama",
        content: (
          <>
            Hak:
            <br />
            <ul>
              <li>
                Memperoleh bagian Rp30.000 - Rp35.000 per peserta melalui kode
                voucher mitra
              </li>
            </ul>
            Kewajiban:
            <ul>
              <li>Menyediakan produk dan kode voucher untuk Pihak Kedua.</li>
              <li>
                Menghitung jumlah peserta yang mendaftar menggunakan kode
                voucher Pihak Kedua.
              </li>
              <li>
                Mengumpulkan pembayaran dari peserta yang terverifikasi dan
                melakukan transfer komisi ke Pihak Kedua.
              </li>
              <li>
                Memberikan laporan mengenai jumlah peserta yang mendaftar
                menggunakan kode voucher Pihak Kedua dan komisi yang diterima
                oleh Pihak Kedua secara sistematis dan transparan.
              </li>
            </ul>
          </>
        ),
      },
      {
        title: "Hak dan Kewajiban Pihak Kedua",
        content: (
          <>
            Hak:
            <br />
            <ul>
              <li>
                Memperoleh bagian Rp15.000 - Rp20.000 per peserta melalui kode
                voucher unik
              </li>
            </ul>
            Kewajiban:
            <ul>
              <li>
                Mempromosikan produk Paket Try Out SNBT dan Seminar melalui
                berbagai media sosial dan saluran promosi lainnya dengan
                menggunakan kode voucher yang diberikan oleh Pihak Pertama.
              </li>
              <li>
                Mempromosikan produk Paket Try Out SNBT dan Seminar dengan cara
                yang tidak merugikan atau merusak nama baik produk tersebut
                seperti penggunaan kata kasar ataupun konten tidak pantas.
              </li>
              <li>
                Mematuhi semua syarat dan ketentuan yang tercantum dalam
                perjanjian ini.
              </li>
            </ul>
          </>
        ),
      },
      {
        title: "Pembayaran Komisi",
        content:
          "Pihak Pertama akan melakukan transfer komisi ke Pihak Kedua pada tanggal 1-5 Mei 2023. Komisi yang diterima oleh Pihak Kedua akan dihitung berdasarkan jumlah peserta yang mendaftar menggunakan kode voucher Pihak Kedua dikalikan komisi Pihak Kedua sebesar Rp15.000. Jika peserta yang menggunakan kode voucher Pihak Kedua mencapai 50 peserta, maka Pihak Kedua akan mendapatkan komisi sebesar Rp20.000 per peserta.",
      },
      {
        title: "Jangka Waktu Pembayaran Komisi",
        content:
          "Komisi akan dibayarkan Pihak Pertama ke Pihak Kedua selambat-lambatnya dalam waktu 5 hari kerja setelah periode kerja sama berakhir.",
      },
      {
        title: "Pengakhiran Perjanjian",
        content:
          "Setelah masa berlaku perjanjian berakhir, perjanjian ini dianggap telah selesai dan tidak ada kewajiban atau tanggung jawab yang harus dipenuhi oleh kedua belah pihak.",
      },
      {
        title: "Hak Atas Merek Dan Hak Cipta",
        content:
          "Pihak Kedua tidak diperbolehkan untuk melakukan tindakan yang melanggar hak cipta atau merek dagang tersebut karena hak cipta dan merek dagang Pihak Pertama sudah terdaftar resmi pada Hak Kekayaan Intelektual.",
      },
      {
        title: "Tindakan Apabila Terjadi Pelanggaran",
        content:
          "Pihak Pertama berhak untuk membatalkan penggunaan kode voucher Pihak Kedua apabila ditemukan tindakan yang merugikan seperti pada poin hak dan kewajiban di atas.",
      },
      {
        title: "Pemberitahuan",
        content:
          "Statistik peserta yang mendaftar menggunakan kode voucher Pihak Kedua akan ditampilkan secara langsung pada laman web bagian partner area. Informasi tersebut berisi kode voucher, jumlah pendaftar dengan kode tersebut, dan komisi yang diperoleh.",
      },
      {
        title: "Penyelesaian Sengketa",
        content:
          "Apabila terjadi sengketa antara Pihak Pertama dan Pihak Kedua, maka kedua belah pihak akan mencoba menyelesaikan secara musyawarah untuk mencapai kesepakatan yang terbaik.",
      },
      {
        title: "Pembaruan Perjanjian",
        content: (
          <>
            Dalam perjanjian kesepakatan kerja sama, bisa ditambahkan klausul
            bahwa perjanjian ini dapat diperbarui atau diubah dari waktu ke
            waktu dengan pemberitahuan pada{" "}
            <span className="italic">contact person</span> Pihak Kedua.
          </>
        ),
      },
      {
        title: "Persetujuan Terhadap Seluruh Ketentuan",
        content: (
          <>
            Dengan melakukan konfirmasi melalui{" "}
            <span className="italic">contact person</span> Pihak Pertama, maka
            Pihak Kedua setuju untuk mematuhi seluruh ketentuan yang tercantum
            dalam perjanjian kesepakatan kerja sama ini, serta syarat dan
            ketentuan lain yang ditetapkan oleh Pihak Pertama dari waktu ke
            waktu.
          </>
        ),
      },
    ],
    footer:
      "Demikianlah perjanjian kesepakatan kerja sama ini dibuat dan disahkan pada tanggal yang tertera di atas sebagai tanda persetujuan atas segala isi dalam perjanjian ini.",
  },

  "april-2023-2": {
    title: "Perjanjian Kesepakatan Kerja Sama",
    start_date: "27 April 2023",
    party_2: "Mitra Kerja Sama",
    section_list: [
      {
        title: "Deskripsi Program Kerja Sama",
        content:
          "Pihak Pertama menyediakan program kerja sama untuk produk Try Out SNBT berjumlah 5 paket seharga Rp120.000 kepada peserta, dimana Pihak Kedua akan mempromosikan produk ini dengan cara membagikan kode voucher yang diberikan oleh Phak Pertama. Setiap peserta yang mendaftar menggunakan kode voucher Pihak Kedua akan mendapatkan diskon sebesar 58%, sehingga harga yang harus dibayar oleh peserta adalah Rp30.000. Pihak Kedua akan mendapatkan komisi sebesar Rp10.000 - Rp15.000 (dengan ketentuan) untuk setiap peserta yang mendaftar menggunakan kode voucher Pihak Kedua.",
      },
      {
        title: "Masa Berlaku Perjanjian",
        content: "Perjanjian ini akan berlaku mulai tanggal 27-30 April 2023.",
      },
      {
        title: "Hak dan Kewajiban Pihak Pertama",
        content: (
          <>
            Hak:
            <br />
            <ul>
              <li>
                Memperoleh bagian Rp15.000 - Rp20.000 per peserta melalui kode
                voucher mitra
              </li>
            </ul>
            Kewajiban:
            <ul>
              <li>Menyediakan produk dan kode voucher untuk Pihak Kedua.</li>
              <li>
                Menghitung jumlah peserta yang mendaftar menggunakan kode
                voucher Pihak Kedua.
              </li>
              <li>
                Mengumpulkan pembayaran dari peserta yang terverifikasi dan
                melakukan transfer komisi ke Pihak Kedua.
              </li>
              <li>
                Memberikan laporan mengenai jumlah peserta yang mendaftar
                menggunakan kode voucher Pihak Kedua dan komisi yang diterima
                oleh Pihak Kedua secara sistematis dan transparan.
              </li>
            </ul>
          </>
        ),
      },
      {
        title: "Hak dan Kewajiban Pihak Kedua",
        content: (
          <>
            Hak:
            <br />
            <ul>
              <li>
                Memperoleh bagian Rp10.000 - Rp15.000 per peserta melalui kode
                voucher unik
              </li>
            </ul>
            Kewajiban:
            <ul>
              <li>
                Mempromosikan produk Paket Try Out SNBT dan Seminar melalui
                berbagai media sosial dan saluran promosi lainnya dengan
                menggunakan kode voucher yang diberikan oleh Pihak Pertama.
              </li>
              <li>
                Mempromosikan produk Paket Try Out SNBT dan Seminar dengan cara
                yang tidak merugikan atau merusak nama baik produk tersebut
                seperti penggunaan kata kasar ataupun konten tidak pantas.
              </li>
              <li>
                Mematuhi semua syarat dan ketentuan yang tercantum dalam
                perjanjian ini.
              </li>
            </ul>
          </>
        ),
      },
      {
        title: "Pembayaran Komisi",
        content:
          "Pihak Pertama akan melakukan transfer komisi ke Pihak Kedua pada tanggal 1-5 Mei 2023. Komisi yang diterima oleh Pihak Kedua akan dihitung berdasarkan jumlah peserta yang mendaftar menggunakan kode voucher Pihak Kedua dikalikan komisi Pihak Kedua sebesar Rp10.000. Jika peserta yang menggunakan kode voucher Pihak Kedua mencapai 50 peserta, maka Pihak Kedua akan mendapatkan komisi sebesar Rp15.000 per peserta.",
      },
      {
        title: "Jangka Waktu Pembayaran Komisi",
        content:
          "Komisi akan dibayarkan Pihak Pertama ke Pihak Kedua selambat-lambatnya dalam waktu 5 hari kerja setelah periode kerja sama berakhir.",
      },
      {
        title: "Pengakhiran Perjanjian",
        content:
          "Setelah masa berlaku perjanjian berakhir, perjanjian ini dianggap telah selesai dan tidak ada kewajiban atau tanggung jawab yang harus dipenuhi oleh kedua belah pihak.",
      },
      {
        title: "Hak Atas Merek Dan Hak Cipta",
        content:
          "Pihak Kedua tidak diperbolehkan untuk melakukan tindakan yang melanggar hak cipta atau merek dagang tersebut karena hak cipta dan merek dagang Pihak Pertama sudah terdaftar resmi pada Hak Kekayaan Intelektual.",
      },
      {
        title: "Tindakan Apabila Terjadi Pelanggaran",
        content:
          "Pihak Pertama berhak untuk membatalkan penggunaan kode voucher Pihak Kedua apabila ditemukan tindakan yang merugikan seperti pada poin hak dan kewajiban di atas.",
      },
      {
        title: "Pemberitahuan",
        content:
          "Statistik peserta yang mendaftar menggunakan kode voucher Pihak Kedua akan ditampilkan secara langsung pada laman web bagian partner area. Informasi tersebut berisi kode voucher, jumlah pendaftar dengan kode tersebut, dan komisi yang diperoleh.",
      },
      {
        title: "Penyelesaian Sengketa",
        content:
          "Apabila terjadi sengketa antara Pihak Pertama dan Pihak Kedua, maka kedua belah pihak akan mencoba menyelesaikan secara musyawarah untuk mencapai kesepakatan yang terbaik.",
      },
      {
        title: "Pembaruan Perjanjian",
        content: (
          <>
            Dalam perjanjian kesepakatan kerja sama, bisa ditambahkan klausul
            bahwa perjanjian ini dapat diperbarui atau diubah dari waktu ke
            waktu dengan pemberitahuan pada{" "}
            <span className="italic">contact person</span> Pihak Kedua.
          </>
        ),
      },
      {
        title: "Persetujuan Terhadap Seluruh Ketentuan",
        content: (
          <>
            Dengan melakukan konfirmasi melalui{" "}
            <span className="italic">contact person</span> Pihak Pertama, maka
            Pihak Kedua setuju untuk mematuhi seluruh ketentuan yang tercantum
            dalam perjanjian kesepakatan kerja sama ini, serta syarat dan
            ketentuan lain yang ditetapkan oleh Pihak Pertama dari waktu ke
            waktu.
          </>
        ),
      },
    ],
    footer:
      "Demikianlah perjanjian kesepakatan kerja sama ini dibuat dan disahkan pada tanggal yang tertera di atas sebagai tanda persetujuan atas segala isi dalam perjanjian ini.",
  },
};

const getAgreementData = (perjanjianId) => {
  return agreementData[perjanjianId];
};

export default agreementData;
export { getAgreementData };
