import { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import HeaderJD from "../components/Layout/HeaderJD";
import TryoutContext from "../context/TryoutContext/TryoutContext";
import dateToString from "../script/dateToString";
import { getPengumumanInfo } from "../script/dateUtils";
import dayjs from "dayjs";

const PembahasanNotReady = ({ data, addProp }) => {
  return (
    <div className="flex flex-col justify-center bg-white shadow-lg rounded-2xl p-7 gap-4 w-96">
      <div className="flex items-center gap-5">
        <p className="font-content-0 font-bold">
          Pembahasan {data.nama_tryout}
        </p>
      </div>
      <p className="font-content-1 mt-1">
        Pembahasan Try Out ini akan tersedia mulai{" "}
        {addProp.openPengumumanDateString}
      </p>
      <div className="flex w-full gap-5">
        <button
          disabled
          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/2 text-white rounded-xl"
        >
          Cek
        </button>
        <button
          disabled
          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl"
        >
          <i className="fa-solid fa-trophy"></i>
        </button>
      </div>
    </div>
  );
};

const PembahasanReady = ({ data }) => {
  return (
    <div className="flex flex-col justify-center bg-white shadow-lg rounded-2xl p-7 gap-4 w-96">
      <div className="flex items-center gap-5">
        <p className="font-content-0 font-bold">
          Pembahasan {data.nama_tryout}
        </p>
      </div>
      <p className="font-content-1 mt-1">
        Pembahasan Try Out-mu kini sudah hadir. Yuk pelajari sekarang!
      </p>
      <div className="flex w-full gap-5">
        <Link
          to={`/ranking-pembahasan/${data.id}/lobby`}
          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/2 text-white rounded-xl"
        >
          Cek
        </Link>
        <Link
          to={`/ranking-pembahasan/${data.id}/ranking`}
          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl"
        >
          <i className="fa-solid fa-trophy"></i>
        </Link>
      </div>
    </div>
  );
};

const PembahasanClosed = ({ data }) => {
  return (
    <div className="flex flex-col justify-center bg-white shadow-lg rounded-2xl p-7 gap-4 w-96">
      <div className="flex items-center gap-5">
        <p className="font-content-0 font-bold">
          Pembahasan {data.nama_tryout}
        </p>
      </div>
      <p className="font-content-1 mt-1">
        Maaf, kamu tidak memiliki akses untuk pembahasan Try Out ini.
      </p>
      <div className="flex w-full gap-5">
        <button
          disabled
          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/2 text-white rounded-xl"
        >
          Cek
        </button>
        <button
          disabled
          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl"
        >
          <i className="fa-solid fa-trophy"></i>
        </button>
      </div>
    </div>
  );
};

const PembahasanBundleClosed = ({ data }) => {
  return (
    <div className="flex flex-col justify-center bg-white shadow-lg rounded-2xl p-7 gap-4 w-96">
      <div className="flex items-center gap-5">
        <p className="font-content-0 font-bold">
          Pembahasan {data.nama_tryout}
        </p>
      </div>
      <p className="font-content-1 mt-1">
        Kamu belum mengerjakan Try Out ini. Yuk kerjain{" "}
        <Link to={`/tryout/${data.id}/info`} className="text-oren">
          {data.nama_tryout}
        </Link>{" "}
        dan akses pembahasannya!
      </p>
      <div className="flex w-full gap-5">
        <button
          disabled
          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/2 text-white rounded-xl"
        >
          Cek
        </button>
        <button
          disabled
          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl"
        >
          <i className="fa-solid fa-trophy"></i>
        </button>
      </div>
    </div>
  );
};

const PembahasanBundleTomorrow = ({ data }) => {
  const tomorrowDate = dayjs().add(1, "day");
  const tomorrowDateString = dateToString(tomorrowDate);
  return (
    <div className="flex flex-col justify-center bg-white shadow-lg rounded-2xl p-7 gap-4 w-96">
      <div className="flex items-center gap-5">
        <p className="font-content-0 font-bold">
          Pembahasan {data.nama_tryout}
        </p>
      </div>
      <p className="font-content-1 mt-1">
        Pembahasan Try Out ini akan tersedia mulai {tomorrowDateString}
      </p>
      <div className="flex w-full gap-5">
        <button
          disabled
          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/2 text-white rounded-xl"
        >
          Cek
        </button>
        <button
          disabled
          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl"
        >
          <i className="fa-solid fa-trophy"></i>
        </button>
      </div>
    </div>
  );
};

const RankingPembahasan = () => {
  const { tryoutList, bundleList } = useContext(TryoutContext);

  const [tryoutBundle, setTryoutBundle] = useState([]);

  useEffect(() => {
    if (bundleList.length && tryoutList.length) {
      let newTryoutBundle = [];
      bundleList.forEach((item) => {
        if (item.status_daftar === "TERDAFTAR") {
          item.isi_bundle.forEach((item2) => {
            newTryoutBundle.push({
              nama_bundle: item.nama_bundle,
              id_tryout: item2.id_tryout,
            });
          });
        }
      });
      newTryoutBundle = [...new Set(newTryoutBundle)];
      setTryoutBundle(newTryoutBundle);
    }
  }, [bundleList, tryoutList]);

  return (
    <>
      <div className="flex flex-col bg-white p-10 justify-start m-0 w-full">
        <HeaderJD title="Peringkat dan Pembahasan" />

        {/* Hasil Tryout */}
        <div className="flex flex-col w-full md:w-full-10 mt-10 mb-10">
          <p className="font-subtitle font-bold">
            Pelajari Pembahasan Try Out-mu!
          </p>
          {/* Daftar Hasil Tryout */}
          <div className="flex gap-10 flex-wrap">
            {tryoutList.map((item, index) => {
              const hasBundleAccess = tryoutBundle.find(
                (item2) => item2.id_tryout === item.id
              );

              if (hasBundleAccess) {
                if (item.selesai_mengerjakan) {
                  if (item.hasil_tersedia) {
                    return <PembahasanReady key={index} data={item} />;
                  } else {
                    return <PembahasanBundleTomorrow data={item} key={index} />;
                  }
                } else {
                  return <PembahasanBundleClosed data={item} key={index} />;
                }
              }

              // pengumuman
              const { openPengumumanDateString, openPengumumanDateTimestamp } =
                getPengumumanInfo(item);

              // current timestamp
              const currentTimestamp = dayjs().valueOf();

              if (currentTimestamp < openPengumumanDateTimestamp) {
                if (item.pembayaran.includes("bundle")) {
                  return <PembahasanReady key={index} data={item} />;
                } else {
                  return (
                    <PembahasanNotReady
                      key={index}
                      data={item}
                      addProp={{
                        openPengumumanDateString,
                      }}
                    />
                  );
                }
              } else {
                if (item.pembayaran && item.pembayaran !== "Gratis")
                  return <PembahasanReady key={index} data={item} />;
                else return <PembahasanClosed key={index} data={item} />;
              }
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default RankingPembahasan;
