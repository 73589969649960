/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from "react";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HeaderJD from "../../components/Layout/HeaderJD";
import PartnerContext from "../../context/PartnerContext/PartnerContext";
import EditSoalLobby from "../../components/Dashboard/EditSoalLobby";

const EditSoalLobbyPartner = () => {
  const { idTryout } = useParams();
  const navigate = useNavigate();
  const {
    currentTryoutPartnerEvent,
    getSingleTryoutPartnerEvent,
    subtesListTryoutPartner,
    soalPembahasanListPartner,
    getSubtesTryoutPartner,
    addSubtes,
    deleteSubtes,
    getSoalPembahasanPartner,
    editSoal,
    deleteSoal,
    addSoal,
  } = useContext(PartnerContext);
  useEffect(() => {
    (async () => {
      const res = await getSingleTryoutPartnerEvent({ id_tryout: idTryout });
      if (!res?.data) {
        navigate("/partner-area");
      }
    })();
  }, []);

  return (
    <>
      <div className="flex flex-col bg-white p-10 justify-start m-0 w-full">
        <HeaderJD
          title={`Edit Soal ${currentTryoutPartnerEvent.nama_tryout}`}
        />

        {currentTryoutPartnerEvent ? (
          <EditSoalLobby
            {...{
              currentTryout: currentTryoutPartnerEvent,
              subtesListTryout: subtesListTryoutPartner,
              soalPembahasanList: soalPembahasanListPartner,
              getSubtesTryout: getSubtesTryoutPartner,
              addSubtes: addSubtes,
              deleteSubtes: deleteSubtes,
              getSoalPembahasan: getSoalPembahasanPartner,
              editSoal: editSoal,
              deleteSoal: deleteSoal,
              addSoal: addSoal,
            }}
          />
        ) : null}
      </div>
    </>
  );
};

export default EditSoalLobbyPartner;
