/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import $ from "jquery";
import HeaderJD from "../../components/Layout/HeaderJD";
import TryoutContext from "../../context/TryoutContext/TryoutContext";
import { getPengumumanInfo } from "../../script/dateUtils";
import dayjs from "dayjs";

const PembahasanSubtes = () => {
  const { tryoutId, subtes } = useParams();
  const navigate = useNavigate();
  const {
    tryoutList,
    bundleList,
    subtesList,
    soalPembahasanList,
    jawabanList,
    getSubtes,
    getSoalPembahasan,
    getJawaban,
    clearVariable,
  } = useContext(TryoutContext);
  const [currentTryout, setCurrentTryout] = useState({});
  const [currentSubtes, setCurrentSubtes] = useState({});
  const [currentSoal, setCurrentSoal] = useState({
    soal: {},
    index: 0,
  });
  const [currentJawaban, setCurrentJawaban] = useState([]);
  useEffect(() => {
    return () => {
      clearVariable({
        soalPembahasanList: [],
        jawabanList: [],
      });
      setCurrentTryout({});
      setCurrentSubtes({});
      setCurrentJawaban([]);
      setCurrentSoal({
        soal: {},
        index: 0,
      });
    };
  }, []);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (tryoutList && tryoutList.length) {
      const tryout = tryoutList.find(
        (item) => String(item.id) === String(tryoutId)
      );
      if (tryout) {
        let hasBundleAccess = tryout.pembayaran?.includes("bundle");
        bundleList.forEach((item) => {
          if (item.status_daftar === "TERDAFTAR") {
            if (
              item.isi_bundle.find((item2) => item2.id_tryout === tryout.id)
            ) {
              hasBundleAccess = true;
            }
          }
        });
        const currentTimestamp = dayjs().valueOf();
        const { openPengumumanDateTimestamp } = getPengumumanInfo(tryout);

        if (!hasBundleAccess) {
          if (currentTimestamp < openPengumumanDateTimestamp) {
            navigate("/ranking-pembahasan");
          } else {
            if (!tryout.pembayaran || tryout.pembayaran === "Gratis")
              navigate("/ranking-pembahasan");
            else {
              getSubtes(tryout.id);
              setCurrentTryout(tryout);
            }
          }
        } else {
          if (!tryout.hasil_tersedia) {
            navigate("/ranking-pembahasan");
          } else {
            getSubtes(tryout.id);
            setCurrentTryout(tryout);
          }
        }
      } else {
        navigate("/ranking-pembahasan");
        return;
      }
    }
  }, [tryoutList]);

  useEffect(() => {
    if (subtesList.length) {
      if (subtesList[0].id_tryout === currentTryout.id) {
        // filter saintek soshum
        const indexToDrop = [];
        subtesList.forEach((item, index) => {
          if (
            item.tipe_string
              .toLowerCase()
              .includes(currentTryout.klaster === 1 ? "soshum" : "saintek")
          ) {
            indexToDrop.push(index);
          }
        });
        const newSubtesList = [...subtesList];
        for (let i = indexToDrop.length - 1; i >= 0; i--) {
          const index = indexToDrop[i];
          newSubtesList.splice(index, 1);
        }

        const subtesExist = newSubtesList.find(
          (item) => String(item.subtes) === String(subtes)
        );

        if (subtesExist) {
          setCurrentSubtes(subtesExist);
          getSoalPembahasan({
            subtes: subtesExist.subtes,
            id_tryout: currentTryout.id,
          });
        } else {
          navigate(`/ranking-pembahasan`);
          return;
        }
      }
    }
  }, [subtesList]);

  useEffect(() => {
    if (soalPembahasanList.length) {
      getJawaban({
        subtes: currentSubtes.subtes,
        id_tryout: currentTryout.id,
      });
      setCurrentSoal({
        soal: soalPembahasanList[0],
        index: 0,
      });
    }
  }, [soalPembahasanList]);

  useEffect(() => {
    if (jawabanList.length) {
      const newJawabanList = [...jawabanList];
      if (newJawabanList.length < soalPembahasanList.length) {
        const selisih = soalPembahasanList.length - newJawabanList.length;
        for (let i = 0; i < selisih; i++) {
          newJawabanList.push("F");
        }
      }
      setCurrentJawaban(newJawabanList);
      setLoading(false);
    }
  }, [jawabanList]);

  const opsiJawaban = ["A", "B", "C", "D", "E"];

  return (
    <>
      <div className="flex flex-col bg-white p-10 justify-start m-0 w-full">
        <HeaderJD title={currentSubtes.nama || ""} />

        {/* Tryout kamu */}
        <div className="flex flex-col lg:flex-row w-full mt-10 gap-5">
          <div className="flex flex-col justify-center items-center bg-white shadow-lg rounded-2xl p-7 gap-3 w-full lg:w-2/3 h-fit">
            <p className="font-subtitle font-bold text-center">
              Soal No {currentSoal.index + 1}
            </p>
            <div className="container-soal flex flex-col w-full  self-start">
              <div
                dangerouslySetInnerHTML={{
                  __html: $('<div className="relative"/>')
                    .html(currentSoal.soal.soal)
                    .text(),
                }}
              />
              <div className="border-dashed border-t-2 border-b-2 border-black w-full my-3"></div>
              <div
                dangerouslySetInnerHTML={{
                  __html: $('<div className="relative"/>')
                    .html(currentSoal.soal.pembahasan)
                    .text(),
                }}
              />
            </div>
            <div className="flex flex-row gap-4 w-full sm:w-3/4 justify-center items-center">
              {opsiJawaban.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`flex font-content-0 justify-center items-center rounded-xl aspect-video p-1 w-1/6 ${
                      currentJawaban[currentSoal.index] === item
                        ? (soalPembahasanList[currentSoal.index] &&
                            soalPembahasanList[currentSoal.index].kunci) ===
                          item
                          ? "text-white bg-hijau"
                          : "text-white bg-merah"
                        : (soalPembahasanList[currentSoal.index] &&
                            soalPembahasanList[currentSoal.index].kunci) ===
                          item
                        ? "text-white bg-hijau"
                        : "text-oren bg-white"
                    } font-bold border-2 border-oren cursor-default`}
                  >
                    {item}
                  </div>
                );
              })}
            </div>
          </div>

          <div className="flex flex-col justify-center items-center bg-white shadow-lg rounded-2xl p-7 gap-3 w-full lg:w-1/3 h-fit">
            <p className="font-subtitle font-bold text-center">Pilih Soal</p>
            <div className="grid grid-cols-4 gap-2 w-full">
              {!loading &&
                soalPembahasanList.map((item, index) => {
                  const sudahDijawab = opsiJawaban.includes(
                    currentJawaban[index]
                  );
                  const jawabanBetul = currentJawaban[index] === item.kunci;
                  return (
                    <div
                      key={index}
                      className={`${
                        currentSoal.index === index
                          ? "border-black border-4"
                          : sudahDijawab
                          ? "border-none"
                          : "border-oren border-2"
                      } ${
                        sudahDijawab
                          ? jawabanBetul
                            ? "bg-hijau text-white"
                            : "bg-merah text-white"
                          : "bg-white text-black"
                      } aspect-square flex justify-center items-center rounded-xl cursor-pointer`}
                      onClick={() => {
                        setCurrentSoal({ soal: item, index: index });
                      }}
                    >
                      {index + 1}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PembahasanSubtes;
