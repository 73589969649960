/* eslint-disable react-hooks/exhaustive-deps */

import { useContext } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import UserContext from "../context/UserContext/UserContext";
import TryoutContext from "../context/TryoutContext/TryoutContext";
import HeaderJD from "../components/Layout/HeaderJD";
import Modal from "../components/Home/Modal";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { getPendaftaranInfo } from "../script/dateUtils";
import { monthList } from "../script/dateToString";
import dayjs from "dayjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const LanggananSoalComponent = ({ setOpen }) => {
  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <div className="flex flex-col gap-6 w-full px-10 pb-10">
        <h5 className="font-bold text-center">
          Belajar Tiap Hari Lewat Whatsapp!
        </h5>
        <div className="flex flex-col gap-2 w-full">
          <p className="font-content-1 w-full text-center">
            Kamu mau dapet soal-soal latihan SNBT tiap hari lewat story
            Whatsapp? Cukup save kontak Kak Jede lalu update nomor HP kamu di
            halaman Profil ya!
          </p>
        </div>

        <div className="w-full flex justify-center">
          <img
            src={`${process.env.PUBLIC_URL}/images/promosi/langganan-soal.jpeg`}
            alt="langganan soal"
            className="w-full max-w-64"
          />
        </div>

        <div className="flex justify-center gap-4 w-full">
          <a
            href={"https://wa.me/6285713319386"}
            target="_blank"
            rel="noreferrer"
            className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-white rounded-xl text-center"
          >
            Save Kontak
          </a>
          <Link
            to={`/profil`}
            className="border-2 border-oren font-content-0 flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-black rounded-xl text-center"
          >
            Update Nomor HP
          </Link>
        </div>
      </div>
    </>
  );
};

const BeliBundleComponent = ({ setOpen }) => {
  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <div className="flex flex-col gap-6 w-full px-10 pb-10">
        <h5 className="font-bold text-center">
          Diskon 10 Try Out + Seminar SNBT !
        </h5>
        <div className="flex flex-col gap-2 w-full">
          <p className="font-content-1 w-full text-center">
            Kamu mau latihan soal-soal SNBT tapi takut harga mahal? Tenang, di
            Jalur Dalam lagi ada <strong>diskon</strong> Bundle Spesial Ramadhan
            + <strong>bonus seminar SNBT</strong>! Yuk daftar sekarang!
          </p>
        </div>

        <div className="w-full flex justify-center">
          <img
            src={`${process.env.PUBLIC_URL}/images/promosi/promo-bundle.jpeg`}
            alt="langganan soal"
            className="w-full max-w-xs"
          />
        </div>

        <div className="flex justify-center gap-4 w-full">
          <Link
            to="/tryout-bundle/5/daftar"
            className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-white rounded-xl text-center"
          >
            Daftar
          </Link>
          <button
            onClick={() => setOpen(false)}
            className="border-2 border-oren font-content-0 flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-black rounded-xl text-center"
          >
            Batal
          </button>
        </div>
      </div>
    </>
  );
};

const TryoutBerlangsung = ({ data, addProp }) => {
  return (
    <div className="flex flex-col justify-center bg-white shadow-lg rounded-2xl p-7 gap-3 w-96">
      <div className="flex items-center gap-5">
        <p className="font-content-0 font-bold">{data.nama_tryout}</p>
      </div>
      <p className="font-content-1 mt-2">Pelaksanaan try out :</p>
      <div className="flex justify-center items-center px-5 py-3 bg-green-100 rounded-xl">
        <p className="font-content-1 font-bold text-hijau">
          {addProp.openPendaftaranDateString} -{" "}
          {addProp.endPendaftaranDateString}
        </p>
      </div>
      <Link
        to="/tryout"
        className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/2 text-white rounded-xl"
      >
        Info selengkapnya
      </Link>
    </div>
  );
};

const Dashboard = () => {
  const { userData } = useContext(UserContext);
  const { tryoutList, hasilTryoutList, getHasilTryout } =
    useContext(TryoutContext);
  const [grupSubtes, setGrupSubtes] = useState([]);
  const [labels, setLabels] = useState([]);
  const [dataRerata, setDataRerata] = useState([]);
  const [dataGrupSubtes, setDataGrupSubtes] = useState([]);
  const [tryoutBerlangsungList, setTryoutBerlangsungList] = useState([]);
  useEffect(() => {
    const colorList = [
      "#F061CD",
      "#0BCCD8",
      "#7B05EF",
      "#12F94A",
      "#2A0019",
      "#0C4101",
      "#A36B92",
      "#969B99",
    ];
    if (grupSubtes.length) {
      const newDataGrupSubtes = grupSubtes.map((item, index) => {
        const options = {
          responsive: true,
          interaction: {
            mode: "index",
            intersect: false,
          },
          stacked: false,
          plugins: {
            title: {
              display: true,
              text: `Grafik Nilai ${item.tipe_string}`,
            },
          },
          // scales: scales,
        };

        const data = {
          labels: labels,
          datasets: item.subtesList.map((item2, index2) => {
            return {
              label:
                index === 0 ? String(item2.subtes).toUpperCase() : item2.nama,
              data: item2.data,
              borderColor: colorList[index2],
              backgroundColor: colorList[index2],
            };
          }),
        };
        return {
          options,
          data,
        };
      });
      setDataGrupSubtes(newDataGrupSubtes);
    }
  }, [grupSubtes]);

  useEffect(() => {
    const newTryoutBerlangsungList = [];
    const now = dayjs().valueOf();
    tryoutList.forEach((item) => {
      const { openPendaftaranDateTimestamp, endPendaftaranDateTimestamp } =
        getPendaftaranInfo(item);
      if (
        now > openPendaftaranDateTimestamp &&
        now < endPendaftaranDateTimestamp
      ) {
        newTryoutBerlangsungList.push(item);
      }
    });
    setTryoutBerlangsungList(newTryoutBerlangsungList);
    getHasilTryout();
  }, [tryoutList]);
  useEffect(() => {
    if (hasilTryoutList.length) {
      const newLabels = hasilTryoutList.map((item) => {
        const tryoutDate = dayjs(item.waktu_dibuka);
        const month = monthList[tryoutDate.month()];
        const year = tryoutDate.year();
        return `${month} ${year}`;
      });
      setLabels(newLabels);

      const newDataRerata = hasilTryoutList.map((item) =>
        parseFloat(item.rerata)
      );
      setDataRerata(newDataRerata);

      // Set data grup subtes
      let joinedSubtesList = [];

      hasilTryoutList.forEach((item) => {
        item.nilai_list.forEach((item2) => {
          joinedSubtesList.push(item2);
        });
      });
      const uniqueSubtesList = joinedSubtesList
        .map((item) => item.tipe_string)
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        });
      let newGrupSubtes = [];
      uniqueSubtesList.forEach((item) => {
        const singleGrup = joinedSubtesList.filter(
          (item2) => item2.tipe_string === item
        );
        const uniqueSingleGrup = [
          ...new Set(singleGrup.map((item2) => item2.subtes)),
        ].map((item2) => {
          const subtes = item2;
          const nama = singleGrup.find((item3) => item3.subtes === subtes).nama;
          const data = [];
          return { subtes, nama, data };
        });
        newGrupSubtes.push({
          tipe_string: item,
          subtesList: uniqueSingleGrup,
        });
      });

      hasilTryoutList.forEach((item) => {
        const nilaiList = item.nilai_list;
        newGrupSubtes.forEach((item2, index2) => {
          item2.subtesList.forEach((item3, index3) => {
            const subtes = item3.subtes;
            const singleNilai = nilaiList.find(
              (item4) => item4.subtes === subtes
            );
            if (singleNilai) {
              newGrupSubtes[index2].subtesList[index3].data.push(
                parseFloat(singleNilai.nilai)
              );
            } else {
              newGrupSubtes[index2].subtesList[index3].data.push(0);
            }
          });
        });
      });
      setGrupSubtes(newGrupSubtes);
    }
  }, [hasilTryoutList]);
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: "Grafik Rerata Nilai",
      },
    },
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: "Rerata",
        data: dataRerata,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        yAxisID: "y",
      },
    ],
  };

  // Promo
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState(<></>);
  useEffect(() => {
    // if (Object.keys(userData).length)
    //   if (!sessionStorage.hidePromo) {
    //     sessionStorage.hidePromo = true;
    //     setModalContent(<BeliBundleComponent setOpen={setOpen} />);
    //     setOpen(true);
    //   }
    // if (!sessionStorage.hidePromo && !userData.no_hp) {
    //   sessionStorage.hidePromo = true;
    //   setModalContent(<LanggananSoalComponent setOpen={setOpen} />);
    //   setOpen(true);
    // }
  }, [userData]);
  return (
    <>
      <div className="flex flex-col bg-white p-10 justify-start m-0 w-full">
        <HeaderJD
          title={`
            Hai${" "}
            ${
              (userData &&
                userData.nama &&
                userData.nama.split(" ")[0] +
                  (userData.nama.split(" ")[1] !== undefined
                    ? " " + userData.nama.split(" ")[1]
                    : "") +
                  "!") ||
              ""
            }`}
        />

        {/* Pengumuman */}
        <div className="flex-disabled flex-col w-full md:w-full-10 mt-5 hidden">
          <div className="flex justify-between items-center p-3 px-5 gap-8 rounded-2xl w-full bg-hijau text-white">
            <div className="flex items-center">
              <i className="fa-solid fa-bullhorn text-2xl"></i>
              {/* <div
                onClick={() => {
                  setModalContent(<LanggananSoalComponent setOpen={setOpen} />);
                  setOpen(true);
                }}
                rel="noreferrer"
                className="flex items-center flex-wrap font-content-1 ml-5 cursor-pointer"
              >
                Mau dapet soal-soal SNBT lewat Whatsapp&nbsp;<b>tiap hari</b>
                ?&nbsp;
                <b>Klik di sini.</b>
                <i className="fa-solid fa-arrow-right text-xl ml-2"></i>
              </div> */}
              {/* <div
                onClick={() => {
                  setModalContent(<BeliBundleComponent setOpen={setOpen} />);
                  setOpen(true);
                }}
                rel="noreferrer"
                className="flex items-center flex-wrap font-content-1 ml-5 cursor-pointer"
              >
                Mau dapet &nbsp;<strong>diskon</strong>&nbsp; latihan soal-soal
                SNBT + &nbsp;<strong>bonus seminar SNBT</strong>&nbsp;? &nbsp;
                <b>Klik di sini.</b>
                <i className="fa-solid fa-arrow-right text-xl ml-2"></i>
              </div> */}
            </div>
            {/* <i className="fa-solid fa-xmark text-xl cursor-pointer"></i> */}
          </div>
        </div>

        {/* Tryout Berlangsung */}
        {(tryoutBerlangsungList.length && (
          <div className="flex flex-col w-full md:w-full-10 mt-10 gap-5">
            <p className="font-subtitle font-bold">Try Out Berlangsung</p>
            {/* Daftar Hasil Tryout */}
            <div className="w-full flex gap-10 flex-wrap">
              {tryoutBerlangsungList.map((item, index) => {
                const { openPendaftaranDateString, endPendaftaranDateString } =
                  getPendaftaranInfo(item);
                return (
                  <TryoutBerlangsung
                    key={index}
                    data={item}
                    addProp={{
                      openPendaftaranDateString,
                      endPendaftaranDateString,
                    }}
                  />
                );
              })}
            </div>
          </div>
        )) ||
          ""}

        {/* Tryout kamu */}
        <div className="flex flex-col w-full md:w-full-10 mt-10 gap-5">
          <p className="font-subtitle font-bold">Progres Try Out-mu</p>
          <div className="flex gap-10 flex-col">
            {hasilTryoutList.length === 1 ? (
              <>
                <p className="font-content-0">
                  Kerjakan lebih banyak Try Out untuk mengamati perkembanganmu !
                </p>
                <Bar
                  height={window.window.innerWidth < 640 ? "300px" : "auto"}
                  options={options}
                  data={data}
                />
                {dataGrupSubtes.map((item, index) => (
                  <Line
                    height={window.window.innerWidth < 640 ? "300px" : "auto"}
                    key={index}
                    options={item.options}
                    data={item.data}
                  />
                ))}
              </>
            ) : hasilTryoutList.length > 1 ? (
              <>
                <Line
                  height={window.window.innerWidth < 640 ? "300px" : "auto"}
                  options={options}
                  data={data}
                />
                {dataGrupSubtes.map((item, index) => (
                  <Line
                    height={window.window.innerWidth < 640 ? "300px" : "auto"}
                    key={index}
                    options={item.options}
                    data={item.data}
                  />
                ))}
              </>
            ) : (
              <p className="font-content-0">
                Kamu belum mengikuti Try Out apapun.{" "}
                <Link className="text-oren hover:underline" to="/tryout">
                  Kerjakan Try Out
                </Link>{" "}
                dan lihat perkembanganmu di sini!
              </p>
            )}
          </div>
        </div>

        {/* Hasil Tryout */}
        <div className="flex flex-col w-full md:w-full-10 mt-10 mb-10 gap-5">
          <p className="font-subtitle font-bold">
            Unduh Sertifikat Try Out-mu!
          </p>
          {/* Daftar Hasil Tryout */}
          <div className="w-full overflow-auto">
            {hasilTryoutList.length ? (
              <table className="w-full tabel-jd">
                <thead>
                  <tr>
                    <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                      Nama Try Out
                    </th>
                    <th className="p-2 font-content-1 font-bold bg-krem rounded-r-xl overflow-hidden">
                      Sertifikat
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {hasilTryoutList.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="p-2 font-content-1 text-center">
                          {item.nama_tryout}
                        </td>
                        <td className="p-2 font-content-1 flex justify-center">
                          <a
                            href={`${window.location.origin}/sertifikat/sertif_${item.id_tryout}_${userData.id}.png`}
                            target="_blank"
                            rel="noreferrer"
                            className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl"
                          >
                            Unduh
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <p className="font-content-0">
                Kamu belum mengikuti Try Out apapun.{" "}
                <Link className="text-oren hover:underline" to="/tryout">
                  Kerjakan Try Out
                </Link>{" "}
                dan unduh sertifikatmu di sini!
              </p>
            )}
          </div>
        </div>
      </div>
      <Modal {...{ open, setOpen }}>
        <div className="relative text-left w-11/12 md:w-2/3 lg:w-1/2 xl:w-2/5 z-50 flex flex-col text-xl font-bold bg-white rounded-2xl">
          {modalContent}
        </div>
      </Modal>
    </>
  );
};

export default Dashboard;
