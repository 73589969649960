const Price2Digit = ({ price }) => {
  const priceValue = parseInt(price);
  const newPrice = priceValue / 1000;
  const isFloat = priceValue % 1000 !== 0;
  const finalPrice = isFloat ? newPrice.toFixed(1) : newPrice;

  return <>{finalPrice}K</>;
};

export default Price2Digit;
