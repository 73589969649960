import { useContext, useEffect } from "react";
import AdminContext from "../../context/AdminContext/AdminContext";
import GlobalContext from "../../context/GlobalContext/GlobalContext";
import UserContext from "../../context/UserContext/UserContext";
import Sidebar from "../Layout/Sidebar";

const AdminRoute = ({ children }) => {
  const { navigate } = useContext(GlobalContext);
  const { userData, getUserData } = useContext(UserContext);
  // const { getAllTryout, getAllBundle } = useContext(TryoutContext);
  const { getAllTryoutAdmin, getAllBundleAdmin, getAllKontakAdmin } =
    useContext(AdminContext);

  useEffect(() => {
    if (!localStorage.token) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (Object.keys(userData).length) {
      if (userData.role !== "ADMIN") {
        navigate("/dashboard");
      }
      (async () => {
        // await getAllBundle();
        // await getAllTryout();
        await getAllTryoutAdmin();
        await getAllBundleAdmin();
        await getAllKontakAdmin({
          page: 1,
        });
      })();
    }
  }, [userData]);

  return (
    <>
      <div className="flex bg-white items-center p-0 m-0 h-screen">
        {/* Sidebar */}
        <div className="absolute lg:static w-full lg:w-72 lg:h-screen top-0 left-0">
          <Sidebar />
        </div>
        <div className="flex-1 self-start h-screen overflow-auto">
          {children}
        </div>
      </div>
    </>
  );
};

export default AdminRoute;
