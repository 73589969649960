import { useContext, useEffect, useState } from "react";
import UserContext from "../context/UserContext/UserContext";
import HeaderJD from "../components/Layout/HeaderJD";
import Modal from "../components/Home/Modal";
import ChangeDataComponent from "../components/Profil/ChangeDataComponent";
import NotifJD, {
  setNotifSuccess,
  setNotifFailed,
} from "../components/Layout/NotifJD";
import { Menu } from "@headlessui/react";
import dayjs from "dayjs";

const tahunList = (() => {
  const firstYear = 2022;
  const currentDate = dayjs();
  const currentYear = currentDate.year();
  const currentMonth = currentDate.month();
  const result = [];
  if (currentMonth + 1 >= 8) {
    for (let i = firstYear; i <= currentYear; i++) {
      result.push(`${i}/${i + 1}`);
    }
  } else {
    for (let i = firstYear; i <= currentYear - 1; i++) {
      result.push(`${i}/${i + 1}`);
    }
  }

  return result;
})();

const Profil = () => {
  const { userData, logout, editUserData, changeTahun } =
    useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState(<></>);
  const [newData, setNewData] = useState({});
  const [notif, setNotif] = useState({
    success: true,
    show: false,
    message: "",
  });
  const [tahun, setTahun] = useState("");

  useEffect(() => {
    if (userData.sekolah) {
      setNewData({
        ...userData,
        sekolah: { id: "", nama: userData.sekolah },
        foto_profil: {
          dataURL: window.location.origin + userData.foto_profil,
          file: null,
        },
      });
      setTahun(userData.tahun);
    }
  }, [userData]);

  const onSubmitProfile = async (e) => {
    e.preventDefault();
    const button = e.target;
    button.disabled = true;
    const data = { ...newData };
    if (data.sekolah.id) {
      data.sekolah = data.sekolah.id;
    } else {
      delete data.sekolah;
    }
    let safe = true;

    if (data.foto_profil.file) {
      if (data.foto_profil.file.size > 2000000) {
        setNotifFailed(setNotif, "Ukuran foto harus kurang dari 2MB!");
        safe = false;
      } else data.foto_profil = data.foto_profil.file;
    } else {
      delete data.foto_profil;
    }

    if (safe && data.nama.length < 6) {
      setNotifFailed(setNotif, "Nama harus berisi minimal 6 karakter!");
      safe = false;
    }
    if (safe && data.password && data.password.length < 6) {
      setNotifFailed(setNotif, "Password harus berisi minimal 6 karakter!");
      safe = false;
    }

    if (safe) {
      const res = await editUserData(data);
      if (res) {
        setNotifSuccess(setNotif, "Profil berhasil diperbarui.");
      } else {
        setNotifFailed(
          setNotif,
          "Pembaruan profil gagal. Silakan coba lagi nanti."
        );
      }
    }
    button.disabled = false;
  };

  const LogoutComponent = () => {
    return (
      <>
        <div className="flex w-full justify-end p-7 pb-0">
          <i
            onClick={() => setOpen(false)}
            className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
          ></i>
        </div>
        <div className="flex flex-col gap-6 w-full px-10 pb-10">
          <h6 className="font-bold text-center">Konfirmasi</h6>
          <p className="font-subtitle w-full text-center">
            Apakah kamu yakin untuk keluar?
          </p>
          <div className="grid grid-rows-1 grid-cols-2 gap-3 place-self-center">
            <button
              onClick={logout}
              className="text-center bg-oren text-white rounded-xl flex-grow px-4 py-1 font-content-0 font-bold"
            >
              Ya
            </button>
            <button
              onClick={() => setOpen(false)}
              type="button"
              className="text-center bg-krem text-black rounded-xl flex-grow px-4 py-1 font-content-0 font-bold"
            >
              Batal
            </button>
          </div>
        </div>
      </>
    );
  };

  const changeData = (title) => {
    setModalContent(
      <ChangeDataComponent {...{ title, setOpen, newData, setNewData }} />
    );
    setOpen(true);
  };

  const onLogout = () => {
    setModalContent(<LogoutComponent />);
    setOpen(true);
  };

  return (
    <>
      <div className="flex flex-col bg-white p-10 justify-start m-0 w-full">
        <HeaderJD title="Profil" />

        <div className="flex items-center justify-center flex-1 w-full">
          <div className="flex flex-col items-center justify-center bg-white shadow-lg rounded-2xl p-7 md:px-14 gap-5 w-full md:w-4/5 lg:w-3/5 xl:w-2/5">
            <div
              onClick={() => changeData("foto_profil")}
              className="flex relative justify-center items-center w-1/4 aspect-square rounded-full overflow-hidden group cursor-pointer"
            >
              <img
                src={
                  (newData.foto_profil && newData.foto_profil.dataURL) ||
                  `${process.env.PUBLIC_URL}/images/profile-picture.png`
                }
                className="w-full h-full object-cover"
                alt="profile"
              />
              <div className="absolute w-full h-full hidden group-hover:block bg-abu bg-opacity-10"></div>
              <i className="absolute fa-solid fa-pen-to-square text-abu text-lg hidden group-hover:block"></i>
            </div>

            <Menu as="div" className="w-auto relative flex flex-col">
              <Menu.Button className="bg-krem font-content-1 flex gap-2 items-center justify-center px-4 py-2 rounded-xl">
                Tahun Ajaran : {tahun}
                <i className="fa-solid fa-caret-down text-lg"></i>
              </Menu.Button>

              <Menu.Items
                className={`absolute w-full z-10 flex flex-col top-12 bg-white rounded-xl overflow-hidden`}
              >
                {tahunList.map((item, index) => (
                  <Menu.Item
                    as="div"
                    key={index}
                    onClick={async () => {
                      if (item !== userData.tahun) {
                        setTahun(item);
                        const res = await changeTahun(item);
                        if (res.success) {
                          window.location.reload();
                        }
                      }
                    }}
                    className="text-black font-content-0 bg-white p-3 hover:bg-abu hover:bg-opacity-20 cursor-pointer"
                  >
                    {item}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Menu>

            <div className="w-full flex flex-col items-start justify-center">
              <div className="flex gap-2 font-content-1 font-bold items-center">
                Nama
                <i
                  onClick={() => changeData("nama")}
                  className="fa-solid fa-pen-to-square text-abu text-xs cursor-pointer"
                ></i>
              </div>
              <p className="font-content-0">{newData.nama}</p>
            </div>
            <div className="w-full flex flex-col items-start justify-center">
              <div className="flex gap-2 font-content-1 font-bold items-center">
                Asal
                <i
                  onClick={() => changeData("asal")}
                  className="fa-solid fa-pen-to-square text-abu text-xs cursor-pointer"
                ></i>
              </div>
              <p className="font-content-0">{newData.asal}</p>
            </div>
            <div className="w-full flex flex-col items-start justify-center">
              <div className="flex gap-2 font-content-1 font-bold items-center">
                No HP
                <i
                  onClick={() => changeData("no_hp")}
                  className="fa-solid fa-pen-to-square text-abu text-xs cursor-pointer"
                ></i>
              </div>
              <p className="font-content-0">{newData.no_hp}</p>
            </div>
            <div className="w-full flex flex-col items-start justify-center">
              <div className="flex gap-2 font-content-1 font-bold items-center">
                Sekolah
                <i
                  onClick={() => changeData("sekolah")}
                  className="fa-solid fa-pen-to-square text-abu text-xs cursor-pointer"
                ></i>
              </div>
              <p className="font-content-0">
                {newData.sekolah && newData.sekolah.nama}
              </p>
            </div>

            {userData.login_method === "REGULAR" && (
              <>
                <div className="w-full flex flex-col items-start justify-center">
                  <div className="flex gap-2 font-content-1 font-bold items-center">
                    Email
                    <i
                      onClick={() => changeData("email")}
                      className="fa-solid fa-pen-to-square text-abu text-xs cursor-pointer"
                    ></i>
                  </div>
                  <p className="font-content-0">{newData.email}</p>
                </div>
                <div className="w-full flex flex-col items-start justify-center">
                  <div className="flex gap-2 font-content-1 font-bold items-center">
                    Password
                    <i
                      onClick={() => changeData("password")}
                      className="fa-solid fa-pen-to-square text-abu text-xs cursor-pointer"
                    ></i>
                  </div>
                  <p className="font-content-0">**********</p>
                </div>
              </>
            )}

            <NotifJD notif={notif} />
            <div className="grid grid-rows-1 grid-cols-3 gap-3 place-self-center lg:place-self-start">
              <button
                onClick={onSubmitProfile}
                className="col-span-2 text-center bg-oren text-white rounded-xl flex-grow px-4 py-2 font-content-0 font-bold"
              >
                Simpan
              </button>
              <button
                onClick={onLogout}
                className="text-center bg-merah text-white rounded-xl flex-grow px-4 py-2 font-bold"
              >
                <i className="fa-solid fa-arrow-right-from-bracket text-lg"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal {...{ open, setOpen }}>
        <div className="relative text-left w-11/12 md:w-2/3 lg:w-1/2 xl:w-1/3 z-50 flex flex-col text-xl font-bold bg-white rounded-2xl">
          {modalContent}
        </div>
      </Modal>
    </>
  );
};

export default Profil;
