/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import { useState } from "react";
import Select from "react-select";
import HeaderJD from "../../components/Layout/HeaderJD";
import FormatPrice from "../../components/Layout/FormatPrice";
import Modal from "../../components/Home/Modal";
import { Link } from "react-router-dom";
import PageComponent from "../../components/Layout/PageComponent";
import AdminContext from "../../context/AdminContext/AdminContext";
import { useContext } from "react";
import { useEffect } from "react";

const AddPartnerComponent = ({
  setOpen,
  addPartner,
  query,
  sort,
  order,
  page,
}) => {
  const [partnerForm, setPartnerForm] = useState({
    email: "",
  });

  const [alertText, setAlertText] = useState({
    show: false,
    text: "",
    success: true,
  });

  const setSuccessAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: true,
    });
  };
  const setErrorAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: false,
    });
  };
  const hideAlert = () => {
    setAlertText({
      show: false,
      text: alertText.message,
      success: alertText.success,
    });
  };

  const onSubmitAdd = async (e) => {
    e.preventDefault();
    const button = e.target;
    button.disabled = true;
    hideAlert();
    const res = await addPartner({ ...partnerForm, query, sort, order, page });
    button.disabled = false;
    if (res.success) {
      setOpen(false);
    } else {
      setErrorAlert("Gagal menambah partner. " + res.display_message);
    }
  };
  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <form
        onSubmit={onSubmitAdd}
        className="flex flex-col gap-6 w-full px-10 pb-10"
      >
        <h6 className="font-bold text-center">Tambah Partner</h6>
        <div className="flex flex-col gap-3 w-full mt-5">
          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Email</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="email"
                value={partnerForm.email}
                onChange={(e) => {
                  setPartnerForm({
                    ...partnerForm,
                    email: e.target.value.trim(),
                  });
                }}
                required
              />
            </div>
          </div>

          <div
            className={`${
              alertText.show ? "flex" : "hidden"
            } p-4 mb-4 text-sm ${
              alertText.success
                ? "text-green-700 bg-green-100"
                : "text-red-700 bg-red-100"
            } rounded-lg`}
            role="alert"
          >
            <svg
              className="inline flex-shrink-0 mr-3 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <div>{alertText.text}</div>
          </div>
        </div>
        <button
          type="submit"
          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-full text-white rounded-xl m-auto"
        >
          Tambah Partner
        </button>
      </form>
    </>
  );
};

const DeletePartnerComponent = ({
  setOpen,
  partner,
  query,
  sort,
  order,
  page,
}) => {
  const { deletePartner } = useContext(AdminContext);
  const [alertText, setAlertText] = useState({
    show: false,
    text: "",
    success: true,
  });

  const setErrorAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: false,
    });
  };
  const hideAlert = () => {
    setAlertText({
      show: false,
      text: alertText.message,
      success: alertText.success,
    });
  };
  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <div className="flex flex-col gap-6 w-full px-10 pb-10">
        <h5 className="font-bold text-center">Konfirmasi</h5>
        <div className="flex flex-col gap-2 w-full">
          <p className="font-content-0 w-full text-center">
            Apakah kamu yakin ingin menghapus izin partner pengguna ini?
          </p>
        </div>

        <div
          className={`${alertText.show ? "flex" : "hidden"} p-4 mb-4 text-sm ${
            alertText.success
              ? "text-green-700 bg-green-100"
              : "text-red-700 bg-red-100"
          } rounded-lg`}
          role="alert"
        >
          <svg
            className="inline flex-shrink-0 mr-3 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clipRule="evenodd"
            ></path>
          </svg>
          <div>{alertText.text}</div>
        </div>

        <div className="flex justify-center gap-4 w-100">
          <button
            onClick={async (e) => {
              e.target.disabled = true;
              hideAlert();
              const res = await deletePartner({
                id_user: partner.id,
                query,
                sort,
                order,
                page,
              });
              if (res.data) {
                setOpen(false);
              } else {
                setErrorAlert(
                  res.display_message || "Terjadi kesalahan, coba lagi nanti."
                );
              }
              e.target.enabled = true;
            }}
            className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-white rounded-xl text-center"
          >
            Ya
          </button>
          <button
            onClick={() => setOpen(false)}
            type="button"
            className="border-2 border-oren font-content-0 flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-black rounded-xl text-center"
          >
            Batal
          </button>
        </div>
      </div>
    </>
  );
};

const Partnership = () => {
  const { partnerList, paginationPartner, addPartner, getAllPartner } =
    useContext(AdminContext);

  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState(<></>);

  const optionSortList = [
    { name: "Nama (A-Z)", sort: "nama", order: "asc", value: 1 },
    { name: "Nama (Z-A)", sort: "nama", order: "desc", value: 2 },
    // {
    //   name: "Total Revenue (9-0)",
    //   sort: "total_revenue_jd",
    //   order: "desc",
    //   value: 3,
    // },
    // {
    //   name: "Total Revenue (0-9)",
    //   sort: "total_revenue_jd",
    //   order: "asc",
    //   value: 4,
    // },
    // {
    //   name: "Pending Revenue (9-0)",
    //   sort: "pending_revenue_jd",
    //   order: "desc",
    //   value: 5,
    // },
    // {
    //   name: "Pending Revenue (0-9)",
    //   sort: "pending_revenue_jd",
    //   order: "asc",
    //   value: 6,
    // },
  ];
  const [currentSort, setCurrentSort] = useState(optionSortList[0]);
  const [currentQuery, setCurrentQuery] = useState("");

  useEffect(() => {
    getAllPartner({
      page: 1,
      query: currentQuery,
      sort: currentSort.sort,
      order: currentSort.order,
    });
  }, []);

  const onSubmitSearch = async (e) => {
    e.preventDefault();
    const button = e.target.querySelector("button");
    button.disabled = true;
    await getAllPartner({
      page: 1,
      query: currentQuery,
      sort: currentSort.sort,
      order: currentSort.order,
    });

    button.disabled = false;
  };

  return (
    <>
      <div className="flex flex-col bg-white p-10 justify-start m-0 w-full">
        <HeaderJD title="Dashboard Partnership" />

        {/* List Partner */}
        <div className="flex flex-col w-full md:w-full-10 mt-10 gap-3">
          <p className="font-subtitle font-bold">Daftar Partner</p>
          <button
            onClick={() => {
              setModalContent(
                <AddPartnerComponent
                  setOpen={setOpen}
                  addPartner={addPartner}
                  query={currentQuery}
                  sort={currentSort.sort}
                  order={currentSort.order}
                  page={paginationPartner.currentPage}
                />
              );
              setOpen(true);
            }}
            className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl"
          >
            Tambah Partner
          </button>

          <div className="w-full flex flex-col-reverse sm:flex-row items-center justify-end gap-3">
            <Select
              placeholder="Urutan"
              styles={{
                control: () => ({
                  alignItems: "center",
                  border: "none",
                  display: "flex",
                  flexWrap: "wrap",
                  WebkitBoxPack: "justify",
                  justifyContent: "space-between",
                  minHeight: "38px",
                  position: "relative",
                }),
                placeholder: (style) => ({
                  ...style,
                  color: "black",
                }),
              }}
              className="w-full sm:w-48 h-fit text-center border-none rounded-xl outline-none font-content-1 bg-krem"
              onChange={(val) => {
                if (val.value !== currentSort.value) {
                  const option = optionSortList.find(
                    (item) => item.value === val.value
                  );
                  setCurrentSort(option);

                  getAllPartner({
                    page: 1,
                    query: currentQuery,
                    sort: option.sort,
                    order: option.order,
                  });
                }
              }}
              options={optionSortList.map((item) => {
                return { value: item.value, label: item.name };
              })}
            />
            <div className="w-full sm:w-96 flex">
              <form onSubmit={onSubmitSearch} className="flex flex-1 gap-2">
                <div className="relative w-auto flex-1 flex border-2 border-abu focus-within:border-black transition-colors rounded-xl items-center">
                  <i
                    className={`${
                      currentQuery && "hidden"
                    } fa-solid fa-magnifying-glass text-base sm:text-lg absolute text-gray-400 ml-3`}
                  ></i>
                  <input
                    className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                    placeholder="        Cari nama atau email"
                    type="text"
                    onChange={(e) => setCurrentQuery(e.target.value)}
                  />
                </div>
                <button className="bg-oren font-content-0 text-white flex gap-2 items-center justify-center px-4 py-2 rounded-xl">
                  <i className="fa-solid fa-magnifying-glass text-xl"></i>
                </button>
              </form>
            </div>
          </div>
          <div className="w-full overflow-auto">
            <table className="tabel-jd flex flex-row flex-nowrap w-full md:inline-table">
              <thead>
                {partnerList.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={`${
                        index === 0 ? "md:table-row" : "md:hidden"
                      } flex flex-col mt-5 md:mt-0`}
                    >
                      <th className="h-14 md:h-auto p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                        Nama
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Email
                      </th>
                      {/* <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Total Revenue
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Pending Revenue
                      </th> */}
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Detail
                      </th>
                      <th className="p-4 md:p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none md:rounded-r-xl overflow-hidden">
                        Hapus Partner
                      </th>
                    </tr>
                  );
                })}
              </thead>
              <tbody className="flex-1 md:flex-none">
                {partnerList.map((item, index) => {
                  return (
                    <tr
                      className="flex flex-col md:table-row mt-5 md:mt-0"
                      key={index}
                    >
                      <td className="h-14 md:h-auto p-2 font-content-1 text-center">
                        {item.nama}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {item.email}
                      </td>
                      {/* <td className="p-2 font-content-1 text-center">
                        <FormatPrice price={item.total_revenue_jd || 0} />
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        <FormatPrice price={item.pending_revenue_jd || 0} />
                      </td> */}
                      <td className="p-2 font-content-1 text-center">
                        <Link
                          to={`/admin-area/partnership/${item.id}`}
                          className="font-content-1 text-center text-oren hover:underline font-bold"
                        >
                          Lihat Detail
                        </Link>
                      </td>
                      <td className="p-2 font-content-1 justify-center flex md:table-cell">
                        <button
                          onClick={() => {
                            setModalContent(
                              <DeletePartnerComponent
                                setOpen={setOpen}
                                partner={item}
                                query={currentQuery}
                                sort={currentSort.sort}
                                order={currentSort.order}
                                page={paginationPartner.currentPage}
                              />
                            );

                            setOpen(true);
                          }}
                          className="bg-merah font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl m-auto"
                        >
                          Hapus
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="flex w-full justify-center">
            <div className="flex gap-3">
              {paginationPartner.totalPage ? (
                <PageComponent
                  currentPage={paginationPartner.currentPage}
                  totalPage={paginationPartner.totalPage}
                  getData={(page) => {
                    getAllPartner({
                      page: page,
                      query: currentQuery,
                      sort: currentSort.sort,
                      order: currentSort.order,
                    });
                  }}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal {...{ open, setOpen }}>
        <div className="relative text-left w-11/12 md:w-2/3 lg:w-1/2 xl:w-2/5 z-50 flex flex-col text-xl font-bold bg-white rounded-2xl">
          {modalContent}
        </div>
      </Modal>
    </>
  );
};

export default Partnership;
