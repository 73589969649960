import { useContext } from "react";
import { useEffect } from "react";
import Select from "react-select";
import PartnerContext from "../../context/PartnerContext/PartnerContext";
import PageComponent from "../Layout/PageComponent";

const PendaftarBundleComponent = ({
  currentPartnership,
  optionSortList,
  currentSort,
  setCurrentSort,
  currentQuery,
  setCurrentQuery,
}) => {
  const {
    pesertaBundleList,
    paginationPesertaBundle,
    getPendaftarBundleSudahVerifikasi,
    downloadPesertaBundle,
  } = useContext(PartnerContext);

  useEffect(() => {
    if (currentPartnership.id_bundle)
      getPendaftarBundleSudahVerifikasi({
        page: 1,
        id_bundle: currentPartnership.id_bundle,
        query: currentQuery,
        sort: currentSort.sort,
        order: currentSort.order,
      });
  }, [currentPartnership]);

  const onSubmitSearch = async (e) => {
    e.preventDefault();
    const button = e.target.querySelector("button");
    button.disabled = true;
    await getPendaftarBundleSudahVerifikasi({
      page: 1,
      id_bundle: currentPartnership.id_bundle,
      query: currentQuery,
      sort: currentSort.sort,
      order: currentSort.order,
    });

    button.disabled = false;
  };

  return (
    <div className="flex flex-col w-full md:w-full-10 mt-10 gap-3">
      <p className="font-subtitle font-bold">Daftar Pendaftar</p>
      {pesertaBundleList.length === 0 ? (
        <p className="font-content-0">Belum ada pendaftar yang diterima.</p>
      ) : (
        <>
          <button
            type="button"
            onClick={async (e) => {
              const button = e.target;
              button.disabled = true;
              await downloadPesertaBundle({
                id_bundle: currentPartnership.id_bundle,
              });
              button.disabled = false;
            }}
            className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl"
          >
            Unduh CSV
          </button>
          <div className="w-full flex flex-col-reverse sm:flex-row items-center justify-end gap-3">
            <Select
              placeholder="Urutan"
              styles={{
                control: () => ({
                  alignItems: "center",
                  border: "none",
                  display: "flex",
                  flexWrap: "wrap",
                  WebkitBoxPack: "justify",
                  justifyContent: "space-between",
                  minHeight: "38px",
                  position: "relative",
                }),
                placeholder: (style) => ({
                  ...style,
                  color: "black",
                }),
              }}
              className="w-full sm:w-48 h-fit text-center border-none rounded-xl outline-none font-content-1 bg-krem"
              onChange={(val) => {
                if (val.value !== currentSort.value) {
                  const option = optionSortList.find(
                    (item) => item.value === val.value
                  );
                  setCurrentSort(option);

                  getPendaftarBundleSudahVerifikasi({
                    page: 1,
                    id_bundle: currentPartnership.id_bundle,
                    query: currentQuery,
                    sort: option.sort,
                    order: option.order,
                  });
                }
              }}
              options={optionSortList.map((item) => {
                return { value: item.value, label: item.name };
              })}
            />
            <div className="w-full sm:w-96 flex">
              <form onSubmit={onSubmitSearch} className="flex flex-1 gap-2">
                <div className="relative w-auto flex-1 flex border-2 border-abu focus-within:border-black transition-colors rounded-xl items-center">
                  <i
                    className={`${
                      currentQuery && "hidden"
                    } fa-solid fa-magnifying-glass text-base sm:text-lg absolute text-gray-400 ml-3`}
                  ></i>
                  <input
                    className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                    placeholder="        Cari nama atau email"
                    type="text"
                    onChange={(e) => setCurrentQuery(e.target.value)}
                  />
                </div>
                <button className="bg-oren font-content-0 text-white flex gap-2 items-center justify-center px-4 py-2 rounded-xl">
                  <i className="fa-solid fa-magnifying-glass text-xl"></i>
                </button>
              </form>
            </div>
          </div>
          <div className="w-full overflow-auto">
            <table className="tabel-jd flex flex-row flex-nowrap w-full md:inline-table">
              <thead>
                {pesertaBundleList.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={`${
                        index === 0 ? "md:table-row" : "md:hidden"
                      } flex flex-col mt-5 md:mt-0`}
                    >
                      <th className="h-14 md:h-auto p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                        Nama
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Email
                      </th>
                    </tr>
                  );
                })}
              </thead>
              <tbody className="flex-1 md:flex-none">
                {pesertaBundleList.map((item, index) => {
                  return (
                    <tr
                      className="flex flex-col md:table-row mt-5 md:mt-0"
                      key={index}
                    >
                      <td className="h-14 md:h-auto p-2 font-content-1 text-center">
                        {item.nama}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {item.email}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      )}

      <div className="flex w-full justify-center">
        <div className="flex gap-3">
          {paginationPesertaBundle.totalPage ? (
            <PageComponent
              currentPage={paginationPesertaBundle.currentPage}
              totalPage={paginationPesertaBundle.totalPage}
              getData={(page) => {
                getPendaftarBundleSudahVerifikasi({
                  page: page,
                  id_bundle: currentPartnership.id_bundle,
                  query: currentQuery,
                  sort: currentSort.sort,
                  order: currentSort.order,
                });
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default PendaftarBundleComponent;
