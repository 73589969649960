import { useContext, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import UserContext from "../../context/UserContext/UserContext";
import dayjs from "dayjs";
import GlobalContext from "../../context/GlobalContext/GlobalContext";

const NavItem = ({ closeSidebar, title, path, icon }) => {
  return (
    <NavLink
      to={path}
      className={({ isActive }) =>
        `flex gap-5 items-center ${isActive ? "text-oren font-bold" : ""}`
      }
      onMouseUp={closeSidebar}
    >
      {icon}
      <p className="font-content-1" style={{ fontWeight: "inherit" }}>
        {title}
      </p>
    </NavLink>
  );
};

const Sidebar = () => {
  const { webEventInfo } = useContext(GlobalContext);
  const { userData } = useContext(UserContext);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const closeSidebar = () => {
    setSidebarOpen(false);
  };
  const menuList = [
    {
      title: "Beranda",
      path: "/dashboard",
      icon: <i className="fa-solid fa-house text-2xl w-7"></i>,
      role: ["USER"],
    },
    // {title: 'Materi', path: '/materi', icon: <i className="fa-solid fa-circle-play text-2xl w-7"></i>, role: ["USER"]},
    {
      title: "Try Out",
      path: "/tryout",
      icon: <i className="fa-solid fa-laptop text-xl w-7"></i>,
      role: ["USER"],
    },
    {
      title: "Peringkat & Pembahasan",
      path: "/ranking-pembahasan",
      icon: <i className="fa-solid fa-square-poll-horizontal text-2xl w-7"></i>,
      role: ["USER"],
    },
    {
      title: "Admin Area",
      path: "/admin-area",
      icon: <i className="fa-solid fa-user-tie text-2xl w-7"></i>,
      role: ["ADMIN"],
    },
    {
      title: "Partner Area",
      path: "/partner-area",
      icon: <i className="fa-solid fa-user-group text-xl w-7"></i>,
      role: ["PARTNER"],
    },
    {
      title: "Profil",
      path: "/profil",
      icon: <i className="fa-solid fa-circle-user text-2xl w-7"></i>,
      role: ["USER", "ADMIN", "PARTNER"],
    },
  ];
  return (
    <>
      <div className="flex justify-center lg:hidden w-full p-5 relative z-10">
        <div className="flex w-full rounded-2xl bg-krem justify-between p-3">
          <i
            onClick={() => setSidebarOpen(!sidebarOpen)}
            className="fa-solid fa-bars text-2xl cursor-pointer"
          ></i>
          <div className="flex h-8">
            <img
              src={`${process.env.PUBLIC_URL}/images/logo-jd-dark.png`}
              className="h-full"
              alt="jd"
            />
          </div>
          <Link
            to="/profil"
            className="flex justify-center items-center w-7 rounded-full aspect-square overflow-hidden"
          >
            <img
              src={
                (userData && window.location.origin + userData.foto_profil) ||
                `${process.env.PUBLIC_URL}/images/profile-picture.png`
              }
              className="w-full rounded-full aspect-square"
              alt="profile"
            />
          </Link>
        </div>
      </div>
      <div
        onClick={() => setSidebarOpen(false)}
        className={`absolute ${
          sidebarOpen
            ? "translate-x-0 bg-gray-600"
            : "-translate-x-full bg-transparent trans"
        } top-0 left-0 w-screen h-screen bg-opacity-40 transition-colors z-50`}
      ></div>
      <div
        className={`absolute top-0 transition-transform ${
          sidebarOpen ? "translate-x-0" : "-translate-x-72"
        } lg:translate-x-0 overflow-hidden lg:static flex flex-col h-screen w-72 lg:w-full pl-10 pr-6 py-10 gap-20 bg-krem z-50`}
      >
        <div className="w-2/3 flex">
          <img
            src={`${process.env.PUBLIC_URL}/images/logo-jd-dark.png`}
            alt="jd"
            className="w-full"
          />
        </div>
        <div className="flex flex-col gap-5">
          {menuList.map((item, index) => {
            if (item.role.includes(userData.role)) {
              return (
                <NavItem
                  key={index}
                  closeSidebar={closeSidebar}
                  title={item.title}
                  path={item.path}
                  icon={item.icon}
                />
              );
            } else {
              return null;
            }
          })}
        </div>

        <div className="flex flex-col gap-5 mt-auto">
          <div className="flex gap-4 justify-center">
            <a
              href="https://instagram.com/jalurdalam"
              rel="noreferrer"
              target="_blank"
            >
              <i className="fa-brands fa-instagram text-3xl"></i>
            </a>
            <a
              href="https://wa.me/6285713319386"
              rel="noreferrer"
              target="_blank"
            >
              <i className="fa-brands fa-whatsapp text-3xl"></i>
            </a>
          </div>
          <p className="font-content-1 text-center text-abu">
            © {webEventInfo?.nama_platform || "Jalur Dalam"} {dayjs().year()}
          </p>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
