/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import { useContext, useState } from "react";
import dayjs from "dayjs";
import AdminContext from "../context/AdminContext/AdminContext";
import HeaderJD from "../components/Layout/HeaderJD";
import Modal from "../components/Home/Modal";
import { Link } from "react-router-dom";
import { useRef } from "react";
import PageComponent from "../components/Layout/PageComponent";
import { useEffect } from "react";

const BatasPendaftaran = ({ waktuDibuka, waktuPendaftaran }) => {
  const batasPendaftaran = dayjs(waktuDibuka).add(
    parseInt(waktuPendaftaran) - 1,
    "day"
  );
  const batasPendaftaranString = batasPendaftaran.format("YYYY-MM-DD");

  return <>{batasPendaftaranString}</>;
};

const EditTryoutComponent = ({ tryoutData, setOpen, editTryout }) => {
  const [tryoutForm, setTryoutForm] = useState({
    id: parseInt(tryoutData.id),
    nama_tryout: tryoutData.nama_tryout,
    waktu_dibuka: tryoutData.waktu_dibuka,
    waktu_pendaftaran: tryoutData.waktu_pendaftaran,
    pengumuman: tryoutData.pengumuman,
    tampilkan: parseInt(tryoutData.tampilkan),
    link_post: tryoutData.link_post,
    syarat_share: tryoutData.syarat_share,
    tipe: parseInt(tryoutData.tipe),
    harga: tryoutData.harga,
    profit_partnership: tryoutData.profit_partnership,
    collab_ig: tryoutData.collab_ig,
  });

  const { tipeTryoutList } = useContext(AdminContext);

  const textareaRef = useRef(<></>);

  const [alertText, setAlertText] = useState({
    show: false,
    text: "",
    success: true,
  });

  const setSuccessAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: true,
    });
  };
  const setErrorAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: false,
    });
  };
  const hideAlert = () => {
    setAlertText({
      show: false,
      text: alertText.message,
      success: alertText.success,
    });
  };

  const onSubmitEdit = async (e) => {
    e.preventDefault();
    const button = e.target;
    button.disabled = true;
    hideAlert();
    const res = await editTryout(tryoutForm);
    button.disabled = false;
    if (res.success) {
      setOpen(false);
    } else {
      setErrorAlert("Gagal mengedit info. " + res.display_message);
    }
  };
  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <form
        onSubmit={onSubmitEdit}
        className="flex flex-col gap-6 w-full px-10 pb-10"
      >
        <h6 className="font-bold text-center">Edit {tryoutData.nama_tryout}</h6>
        <div className="flex flex-col gap-3 w-full mt-5">
          {/* <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Id</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="number"
                value={tryoutForm.id}
                onChange={(e) => {
                  setTryoutForm({ ...tryoutForm, id: e.target.value });
                }}
                required
              />
            </div>
          </div> */}
          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Nama Try Out</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="text"
                value={tryoutForm.nama_tryout}
                onChange={(e) => {
                  setTryoutForm({ ...tryoutForm, nama_tryout: e.target.value });
                }}
                required
              />
            </div>
          </div>

          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Waktu Dibuka</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="date"
                value={tryoutForm.waktu_dibuka}
                onChange={(e) => {
                  setTryoutForm({
                    ...tryoutForm,
                    waktu_dibuka: e.target.value,
                  });
                }}
                required
              />
            </div>
          </div>

          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Lama Pendaftaran</p>
            </div>

            <div className="flex w-2/3 gap-2 md:gap-3">
              <div className="flex w-1/2 border-2 border-black rounded-xl items-center select-container">
                <input
                  className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                  type="number"
                  value={tryoutForm.waktu_pendaftaran}
                  onChange={(e) => {
                    setTryoutForm({
                      ...tryoutForm,
                      waktu_pendaftaran: e.target.value,
                    });
                  }}
                  required
                />
              </div>
              <div className="flex items-center justify-start">
                <p className="font-content-1">hari</p>
              </div>
            </div>
          </div>

          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Last Pendaftaran</p>
            </div>

            <div className="flex w-2/3 border-none rounded-xl items-center select-container">
              <div className="w-full px-3 py-2 rounded-xl border-none font-content-1">
                <BatasPendaftaran
                  waktuDibuka={tryoutForm.waktu_dibuka}
                  waktuPendaftaran={
                    tryoutForm.waktu_pendaftaran === ""
                      ? 0
                      : parseInt(tryoutForm.waktu_pendaftaran)
                  }
                />
              </div>
            </div>
          </div>

          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Pengumuman</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="date"
                value={tryoutForm.pengumuman}
                onChange={(e) => {
                  setTryoutForm({
                    ...tryoutForm,
                    pengumuman: e.target.value,
                  });
                }}
                required
              />
            </div>
          </div>

          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Tampilkan</p>
            </div>

            <div className="flex w-2/3 border-none rounded-xl items-center select-container gap-5 py-2">
              <div className="flex gap-1 items-center justify-center">
                <input
                  type="radio"
                  name="tryoutTampilkan"
                  checked={tryoutForm.tampilkan === 1}
                  onChange={() => {
                    setTryoutForm({ ...tryoutForm, tampilkan: 1 });
                  }}
                />
                <p className="font-content-1">Ya</p>
              </div>
              <div className="flex gap-1 items-center justify-center">
                <input
                  type="radio"
                  name="tryoutTampilkan"
                  checked={tryoutForm.tampilkan === 0}
                  onChange={() => {
                    setTryoutForm({ ...tryoutForm, tampilkan: 0 });
                  }}
                />
                <p className="font-content-1">Tidak</p>
              </div>
            </div>
          </div>

          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Tipe</p>
            </div>

            <div className="grid grid-cols-2 w-2/3 border-none rounded-xl items-center select-container gap-5 py-2">
              {tipeTryoutList.map((item, index) => (
                <div
                  key={index}
                  className="flex gap-1 items-center justify-start w-full"
                >
                  <input
                    type="radio"
                    name="tryoutTipe"
                    checked={tryoutForm.tipe === parseInt(item.id_tipe)}
                    onChange={() => {
                      setTryoutForm({
                        ...tryoutForm,
                        tipe: parseInt(item.id_tipe),
                      });
                    }}
                  />
                  <p className="font-content-1">{item.nama_tipe}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Harga</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="number"
                value={tryoutForm.harga}
                onChange={(e) => {
                  setTryoutForm({ ...tryoutForm, harga: e.target.value });
                }}
                required
              />
            </div>
          </div>

          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Profit Partnership</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="number"
                value={tryoutForm.profit_partnership}
                onChange={(e) => {
                  setTryoutForm({
                    ...tryoutForm,
                    profit_partnership: e.target.value,
                  });
                }}
                required
              />
            </div>
          </div>

          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Link Post</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="text"
                value={tryoutForm.link_post}
                onChange={(e) => {
                  setTryoutForm({ ...tryoutForm, link_post: e.target.value });
                }}
              />
            </div>
          </div>

          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Syarat Share</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center overflow-hidden">
              <textarea
                rows="6"
                className="border-none outline-none p-1 w-full font-content-1"
                style={{ whiteSpace: "pre-line" }}
                ref={textareaRef}
                defaultValue={tryoutForm.syarat_share.replace(
                  /(\\r)|(\\n)/g,
                  "\n"
                )}
                onChange={(e) => {
                  setTryoutForm({
                    ...tryoutForm,
                    syarat_share: e.target.value,
                  });
                }}
              ></textarea>
            </div>
          </div>

          {tryoutForm.tipe === 2 ? (
            <div className="flex w-full items-center justify-between gap-5">
              <div className="flex items-center w-1/3 gap-2 md:gap-3">
                <p className="font-content-1">Collab IG</p>
              </div>

              <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
                <input
                  className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                  type="text"
                  value={tryoutForm.collab_ig}
                  onChange={(e) => {
                    setTryoutForm({ ...tryoutForm, collab_ig: e.target.value });
                  }}
                />
              </div>
            </div>
          ) : null}

          <div
            className={`${
              alertText.show ? "flex" : "hidden"
            } p-4 mb-4 text-sm ${
              alertText.success
                ? "text-green-700 bg-green-100"
                : "text-red-700 bg-red-100"
            } rounded-lg`}
            role="alert"
          >
            <svg
              className="inline flex-shrink-0 mr-3 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <div>{alertText.text}</div>
          </div>
        </div>
        <button
          type="submit"
          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-full text-white rounded-xl m-auto"
        >
          Update
        </button>
      </form>
    </>
  );
};

const AddTryoutComponent = ({ setOpen, addTryout }) => {
  const { tipeTryoutList } = useContext(AdminContext);
  const now = dayjs();
  const formattedDate = now.format("YYYY-MM-DD");

  const [tryoutForm, setTryoutForm] = useState({
    id: 1,
    nama_tryout: "",
    waktu_dibuka: formattedDate,
    waktu_pendaftaran: 6,
    pengumuman: formattedDate,
    tampilkan: 0,
    link_post: "",
    syarat_share: "",
    tipe: 0,
    harga: 20000,
    profit_partnership: 6000,
    collab_ig: "",
  });

  const textareaRef = useRef(<></>);

  const [alertText, setAlertText] = useState({
    show: false,
    text: "",
    success: true,
  });

  const setSuccessAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: true,
    });
  };
  const setErrorAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: false,
    });
  };
  const hideAlert = () => {
    setAlertText({
      show: false,
      text: alertText.message,
      success: alertText.success,
    });
  };

  const onSubmitAdd = async (e) => {
    e.preventDefault();
    const button = e.target;
    button.disabled = true;
    hideAlert();
    const res = await addTryout(tryoutForm);
    button.disabled = false;
    if (res.success) {
      setOpen(false);
    } else {
      setErrorAlert("Gagal menambah Try Out. " + res.display_message);
    }
  };
  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <form
        onSubmit={onSubmitAdd}
        className="flex flex-col gap-6 w-full px-10 pb-10"
      >
        <h6 className="font-bold text-center">Tambah Try Out</h6>
        <div className="flex flex-col gap-3 w-full mt-5">
          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Id</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="number"
                value={tryoutForm.id}
                onChange={(e) => {
                  setTryoutForm({ ...tryoutForm, id: e.target.value });
                }}
                required
              />
            </div>
          </div>
          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Nama Try Out</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="text"
                value={tryoutForm.nama_tryout}
                onChange={(e) => {
                  setTryoutForm({ ...tryoutForm, nama_tryout: e.target.value });
                }}
                required
              />
            </div>
          </div>

          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Waktu Dibuka</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="date"
                value={tryoutForm.waktu_dibuka}
                onChange={(e) => {
                  setTryoutForm({
                    ...tryoutForm,
                    waktu_dibuka: e.target.value,
                  });
                }}
                required
              />
            </div>
          </div>

          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Lama Pendaftaran</p>
            </div>

            <div className="flex w-2/3 gap-2 md:gap-3">
              <div className="flex w-1/2 border-2 border-black rounded-xl items-center select-container">
                <input
                  className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                  type="number"
                  value={tryoutForm.waktu_pendaftaran}
                  onChange={(e) => {
                    setTryoutForm({
                      ...tryoutForm,
                      waktu_pendaftaran: e.target.value,
                    });
                  }}
                  required
                />
              </div>
              <div className="flex items-center justify-start">
                <p className="font-content-1">hari</p>
              </div>
            </div>
          </div>

          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Last Pendaftaran</p>
            </div>

            <div className="flex w-2/3 border-none rounded-xl items-center select-container">
              <div className="w-full px-3 py-2 rounded-xl border-none font-content-1">
                <BatasPendaftaran
                  waktuDibuka={tryoutForm.waktu_dibuka}
                  waktuPendaftaran={
                    tryoutForm.waktu_pendaftaran === ""
                      ? 0
                      : parseInt(tryoutForm.waktu_pendaftaran)
                  }
                />
              </div>
            </div>
          </div>

          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Pengumuman</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="date"
                value={tryoutForm.pengumuman}
                onChange={(e) => {
                  setTryoutForm({
                    ...tryoutForm,
                    pengumuman: e.target.value,
                  });
                }}
                required
              />
            </div>
          </div>

          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Tampilkan</p>
            </div>

            <div className="flex w-2/3 border-none rounded-xl items-center select-container gap-5 py-2">
              <div className="flex gap-1 items-center justify-center">
                <input
                  type="radio"
                  name="tryoutTampilkan"
                  checked={tryoutForm.tampilkan === 1}
                  onChange={() => {
                    setTryoutForm({ ...tryoutForm, tampilkan: 1 });
                  }}
                />
                <p className="font-content-1">Ya</p>
              </div>
              <div className="flex gap-1 items-center justify-center">
                <input
                  type="radio"
                  name="tryoutTampilkan"
                  checked={tryoutForm.tampilkan === 0}
                  onChange={() => {
                    setTryoutForm({ ...tryoutForm, tampilkan: 0 });
                  }}
                />
                <p className="font-content-1">Tidak</p>
              </div>
            </div>
          </div>

          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Tipe</p>
            </div>

            <div className="grid grid-cols-2 w-2/3 border-none rounded-xl items-center select-container gap-5 py-2">
              {tipeTryoutList.map((item, index) => (
                <div
                  key={index}
                  className="flex gap-1 items-center justify-start w-full"
                >
                  <input
                    type="radio"
                    name="tryoutTipe"
                    checked={tryoutForm.tipe === parseInt(item.id_tipe)}
                    onChange={() => {
                      setTryoutForm({
                        ...tryoutForm,
                        tipe: parseInt(item.id_tipe),
                      });
                    }}
                  />
                  <p className="font-content-1">{item.nama_tipe}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Harga</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="number"
                value={tryoutForm.harga}
                onChange={(e) => {
                  setTryoutForm({ ...tryoutForm, harga: e.target.value });
                }}
                required
              />
            </div>
          </div>

          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Profit Partnership</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="number"
                value={tryoutForm.profit_partnership}
                onChange={(e) => {
                  setTryoutForm({
                    ...tryoutForm,
                    profit_partnership: e.target.value,
                  });
                }}
                required
              />
            </div>
          </div>

          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Link Post</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="text"
                value={tryoutForm.link_post}
                onChange={(e) => {
                  setTryoutForm({ ...tryoutForm, link_post: e.target.value });
                }}
              />
            </div>
          </div>

          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Syarat Share</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center overflow-hidden">
              <textarea
                rows="6"
                className="border-none outline-none p-1 w-full font-content-1"
                style={{ whiteSpace: "pre-line" }}
                ref={textareaRef}
                defaultValue={tryoutForm.syarat_share.replace(
                  /(\\r)|(\\n)/g,
                  "\n"
                )}
                onChange={(e) => {
                  setTryoutForm({
                    ...tryoutForm,
                    syarat_share: e.target.value,
                  });
                }}
              ></textarea>
            </div>
          </div>

          {tryoutForm.tipe === 2 ? (
            <div className="flex w-full items-center justify-between gap-5">
              <div className="flex items-center w-1/3 gap-2 md:gap-3">
                <p className="font-content-1">Collab IG</p>
              </div>

              <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
                <input
                  className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                  type="text"
                  value={tryoutForm.collab_ig}
                  onChange={(e) => {
                    setTryoutForm({ ...tryoutForm, collab_ig: e.target.value });
                  }}
                />
              </div>
            </div>
          ) : null}

          <div
            className={`${
              alertText.show ? "flex" : "hidden"
            } p-4 mb-4 text-sm ${
              alertText.success
                ? "text-green-700 bg-green-100"
                : "text-red-700 bg-red-100"
            } rounded-lg`}
            role="alert"
          >
            <svg
              className="inline flex-shrink-0 mr-3 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <div>{alertText.text}</div>
          </div>
        </div>
        <button
          type="submit"
          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-full text-white rounded-xl m-auto"
        >
          Tambah Try Out
        </button>
      </form>
    </>
  );
};

const AddBundleComponent = ({ setOpen, addBundle }) => {
  const [bundleForm, setBundleForm] = useState({
    id: 1,
    nama_bundle: "",
    harga: 50000,
    tampilkan: 0,
  });

  const [alertText, setAlertText] = useState({
    show: false,
    text: "",
    success: true,
  });

  const setSuccessAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: true,
    });
  };
  const setErrorAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: false,
    });
  };
  const hideAlert = () => {
    setAlertText({
      show: false,
      text: alertText.message,
      success: alertText.success,
    });
  };

  const onSubmitAdd = async (e) => {
    e.preventDefault();
    const button = e.target;
    button.disabled = true;
    hideAlert();
    const res = await addBundle(bundleForm);
    button.disabled = false;
    if (res.success) {
      setOpen(false);
    } else {
      setErrorAlert("Gagal menambah bundle. " + res.display_message);
    }
  };
  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <form
        onSubmit={onSubmitAdd}
        className="flex flex-col gap-6 w-full px-10 pb-10"
      >
        <h6 className="font-bold text-center">Tambah Bundle</h6>
        <div className="flex flex-col gap-3 w-full mt-5">
          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Id</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="number"
                value={bundleForm.id}
                onChange={(e) => {
                  setBundleForm({ ...bundleForm, id: e.target.value });
                }}
                required
              />
            </div>
          </div>
          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Nama Bundle</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="text"
                value={bundleForm.nama_bundle}
                onChange={(e) => {
                  setBundleForm({ ...bundleForm, nama_bundle: e.target.value });
                }}
                required
              />
            </div>
          </div>
          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Harga</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="number"
                value={bundleForm.harga}
                onChange={(e) => {
                  setBundleForm({ ...bundleForm, harga: e.target.value });
                }}
                required
              />
            </div>
          </div>
          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Tampilkan</p>
            </div>

            <div className="flex w-2/3 border-none rounded-xl items-center select-container gap-5 py-2">
              <div className="flex gap-1 items-center justify-center">
                <input
                  type="radio"
                  name="tryoutTampilkan"
                  checked={bundleForm.tampilkan === 1}
                  onChange={() => {
                    setBundleForm({ ...bundleForm, tampilkan: 1 });
                  }}
                />
                <p className="font-content-1">Ya</p>
              </div>
              <div className="flex gap-1 items-center justify-center">
                <input
                  type="radio"
                  name="tryoutTampilkan"
                  checked={bundleForm.tampilkan === 0}
                  onChange={() => {
                    setBundleForm({ ...bundleForm, tampilkan: 0 });
                  }}
                />
                <p className="font-content-1">Tidak</p>
              </div>
            </div>
          </div>

          <div
            className={`${
              alertText.show ? "flex" : "hidden"
            } p-4 mb-4 text-sm ${
              alertText.success
                ? "text-green-700 bg-green-100"
                : "text-red-700 bg-red-100"
            } rounded-lg`}
            role="alert"
          >
            <svg
              className="inline flex-shrink-0 mr-3 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <div>{alertText.text}</div>
          </div>
        </div>
        <button
          type="submit"
          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-full text-white rounded-xl m-auto"
        >
          Tambah Bundle
        </button>
      </form>
    </>
  );
};

const DeleteContactComponent = ({ setOpen, kontak }) => {
  const { deleteKontak } = useContext(AdminContext);
  const [alertText, setAlertText] = useState({
    show: false,
    text: "",
    success: true,
  });

  const setErrorAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: false,
    });
  };
  const hideAlert = () => {
    setAlertText({
      show: false,
      text: alertText.message,
      success: alertText.success,
    });
  };
  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <div className="flex flex-col gap-6 w-full px-10 pb-10">
        <h5 className="font-bold text-center">Konfirmasi</h5>
        <div className="flex flex-col gap-2 w-full">
          <p className="font-content-0 w-full text-center">
            Apakah kamu yakin ingin menghapus data kontak ini?
          </p>
        </div>

        <div
          className={`${alertText.show ? "flex" : "hidden"} p-4 mb-4 text-sm ${
            alertText.success
              ? "text-green-700 bg-green-100"
              : "text-red-700 bg-red-100"
          } rounded-lg`}
          role="alert"
        >
          <svg
            className="inline flex-shrink-0 mr-3 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clipRule="evenodd"
            ></path>
          </svg>
          <div>{alertText.text}</div>
        </div>

        <div className="flex justify-center gap-4 w-100">
          <button
            onClick={async (e) => {
              e.target.disabled = true;
              hideAlert();
              const res = await deleteKontak({ id_kontak: kontak.id });
              if (res.data) {
                setOpen(false);
              } else {
                setErrorAlert(
                  res.display_message || "Terjadi kesalahan, coba lagi nanti."
                );
              }
              e.target.enabled = true;
            }}
            className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-white rounded-xl text-center"
          >
            Ya
          </button>
          <button
            onClick={() => setOpen(false)}
            type="button"
            className="border-2 border-oren font-content-0 flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-black rounded-xl text-center"
          >
            Batal
          </button>
        </div>
      </div>
    </>
  );
};

const EditBundleComponent = ({ bundleData, setOpen, editBundle }) => {
  const [bundleForm, setBundleForm] = useState({
    id: parseInt(bundleData.id),
    nama_bundle: bundleData.nama_bundle,
    harga: bundleData.harga,
    profit_partnership: bundleData.profit_partnership,
    tampilkan: parseInt(bundleData.tampilkan),
  });

  const [alertText, setAlertText] = useState({
    show: false,
    text: "",
    success: true,
  });

  const setSuccessAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: true,
    });
  };
  const setErrorAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: false,
    });
  };
  const hideAlert = () => {
    setAlertText({
      show: false,
      text: alertText.message,
      success: alertText.success,
    });
  };

  const onSubmitEdit = async (e) => {
    e.preventDefault();
    const button = e.target;
    button.disabled = true;
    hideAlert();
    const res = await editBundle(bundleForm);
    button.disabled = false;
    if (res.success) {
      setOpen(false);
    } else {
      setErrorAlert("Gagal mengedit info. " + res.display_message);
    }
  };
  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <form
        onSubmit={onSubmitEdit}
        className="flex flex-col gap-6 w-full px-10 pb-10"
      >
        <h6 className="font-bold text-center">Edit {bundleData.nama_bundle}</h6>
        <div className="flex flex-col gap-3 w-full mt-5">
          {/* <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Id</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="number"
                value={bundleForm.id}
                onChange={(e) => {
                  setBundleForm({ ...bundleForm, id: e.target.value });
                }}
                required
              />
            </div>
          </div> */}
          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Nama Bundle</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="text"
                value={bundleForm.nama_bundle}
                onChange={(e) => {
                  setBundleForm({ ...bundleForm, nama_bundle: e.target.value });
                }}
                required
              />
            </div>
          </div>
          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Harga</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="number"
                value={bundleForm.harga}
                onChange={(e) => {
                  setBundleForm({ ...bundleForm, harga: e.target.value });
                }}
                required
              />
            </div>
          </div>
          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Profit Partnership</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="number"
                value={bundleForm.profit_partnership}
                onChange={(e) => {
                  setBundleForm({
                    ...bundleForm,
                    profit_partnership: e.target.value,
                  });
                }}
                required
              />
            </div>
          </div>
          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Tampilkan</p>
            </div>

            <div className="flex w-2/3 border-none rounded-xl items-center select-container gap-5 py-2">
              <div className="flex gap-1 items-center justify-center">
                <input
                  type="radio"
                  name="tryoutTampilkan"
                  checked={bundleForm.tampilkan === 1}
                  onChange={() => {
                    setBundleForm({ ...bundleForm, tampilkan: 1 });
                  }}
                />
                <p className="font-content-1">Ya</p>
              </div>
              <div className="flex gap-1 items-center justify-center">
                <input
                  type="radio"
                  name="tryoutTampilkan"
                  checked={bundleForm.tampilkan === 0}
                  onChange={() => {
                    setBundleForm({ ...bundleForm, tampilkan: 0 });
                  }}
                />
                <p className="font-content-1">Tidak</p>
              </div>
            </div>
          </div>

          <div
            className={`${
              alertText.show ? "flex" : "hidden"
            } p-4 mb-4 text-sm ${
              alertText.success
                ? "text-green-700 bg-green-100"
                : "text-red-700 bg-red-100"
            } rounded-lg`}
            role="alert"
          >
            <svg
              className="inline flex-shrink-0 mr-3 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <div>{alertText.text}</div>
          </div>
        </div>
        <button
          type="submit"
          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-full text-white rounded-xl m-auto"
        >
          Update
        </button>
      </form>
    </>
  );
};

const AdminArea = () => {
  const {
    tryoutListAdmin,
    bundleListAdmin,
    kontakListAdmin,
    paginationKontak,
    editTryout,
    addTryout,
    addBundle,
    editBundle,
    generateRanking,
    generateKontak,
    getAllKontakAdmin,
    getTipeTryoutList,
    tipeTryoutList,
  } = useContext(AdminContext);
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState(<></>);

  useEffect(() => {
    getTipeTryoutList();
  }, []);

  // Alert for generate ranking
  const [alertText2, setAlertText2] = useState({
    show: false,
    text: "",
    success: true,
  });

  const setSuccessAlert2 = (message) => {
    setAlertText2({
      show: true,
      text: message,
      success: true,
    });
  };
  const setErrorAlert2 = (message) => {
    setAlertText2({
      show: true,
      text: message,
      success: false,
    });
  };
  const hideAlert2 = () => {
    setAlertText2({
      show: false,
      text: alertText2.message,
      success: alertText2.success,
    });
  };

  // Alert for generate kontak
  const [alertText3, setAlertText3] = useState({
    show: false,
    text: "",
    success: true,
  });

  const setSuccessAlert3 = (message) => {
    setAlertText3({
      show: true,
      text: message,
      success: true,
    });
  };
  const setErrorAlert3 = (message) => {
    setAlertText3({
      show: true,
      text: message,
      success: false,
    });
  };
  const hideAlert3 = () => {
    setAlertText3({
      show: false,
      text: alertText3.message,
      success: alertText3.success,
    });
  };
  return (
    <>
      <div className="flex flex-col bg-white p-10 justify-start m-0 w-full">
        <HeaderJD title="Admin Area" />

        {/* List Try Out */}
        <div className="flex flex-col w-full md:w-full-10 mt-10 gap-3">
          <p className="font-subtitle font-bold">Daftar Try Out</p>

          <button
            onClick={() => {
              setModalContent(
                <AddTryoutComponent setOpen={setOpen} addTryout={addTryout} />
              );
              setOpen(true);
            }}
            className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl"
          >
            Tambah Try Out
          </button>
          <div className="w-full overflow-auto">
            <table className="tabel-jd flex flex-row flex-nowrap w-full md:inline-table">
              <thead>
                {tryoutListAdmin.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={`${
                        index === 0 ? "md:table-row" : "md:hidden"
                      } flex flex-col mt-5 md:mt-0`}
                    >
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                        Id
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Nama
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Dibuka
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Last Daftar
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Pengumuman
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Ditampilkan
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Jenis
                      </th>
                      <th className="p-2 py-4 md:py-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden row-span-3">
                        Edit Info
                      </th>
                      <th className="p-2 py-4 md:py-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden row-span-3">
                        Edit Soal
                      </th>
                      <th className="p-2 py-4 md:py-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none md:rounded-r-xl overflow-hidden row-span-3">
                        Pendaftar
                      </th>
                    </tr>
                  );
                })}
              </thead>
              <tbody className="flex-1 md:flex-none">
                {tryoutListAdmin.map((item, index) => {
                  return (
                    <tr
                      className="flex flex-col md:table-row mt-5 md:mt-0"
                      key={index}
                    >
                      <td className="p-2 font-content-1 text-center">
                        {item.id}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {item.nama_tryout}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {item.waktu_dibuka}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        <BatasPendaftaran
                          waktuDibuka={item.waktu_dibuka}
                          waktuPendaftaran={item.waktu_pendaftaran}
                        />
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {item.pengumuman}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {parseInt(item.tampilkan) ? (
                          <span className="text-hijau">Ya</span>
                        ) : (
                          <span className="text-merah">Tidak</span>
                        )}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {
                          tipeTryoutList.find(
                            (item2) => item2.id_tipe === item.tipe
                          )?.nama_tipe
                        }
                      </td>
                      <td className="p-2 font-content-1 justify-center flex md:table-cell">
                        <button
                          onClick={() => {
                            setModalContent(
                              <EditTryoutComponent
                                tryoutData={item}
                                setOpen={setOpen}
                                editTryout={editTryout}
                              />
                            );
                            setOpen(true);
                          }}
                          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl m-auto"
                        >
                          Edit Info
                        </button>
                      </td>
                      <td className="p-2 font-content-1 justify-center flex md:table-cell">
                        <Link
                          to={`/admin-area/tryout/${item.id}/edit-soal`}
                          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl m-auto"
                        >
                          Edit Soal
                        </Link>
                      </td>
                      <td className="p-2 font-content-1 justify-center flex md:table-cell">
                        <Link
                          to={`/admin-area/tryout/${item.id}/pendaftar`}
                          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl m-auto"
                        >
                          Lihat Pendaftar
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        {/* List Bundle */}
        <div className="flex flex-col w-full md:w-full-10 mt-10 gap-3">
          <p className="font-subtitle font-bold">Daftar Bundle</p>

          <button
            onClick={() => {
              setModalContent(
                <AddBundleComponent setOpen={setOpen} addBundle={addBundle} />
              );
              setOpen(true);
            }}
            className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl"
          >
            Tambah Bundle
          </button>
          <div className="w-full overflow-auto">
            <table className="tabel-jd flex flex-row flex-nowrap w-full md:inline-table">
              <thead>
                {bundleListAdmin.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={`${
                        index === 0 ? "md:table-row" : "md:hidden"
                      } flex flex-col mt-5 md:mt-0`}
                    >
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                        Id
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Nama
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Jumlah Try Out
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Harga
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Ditampilkan
                      </th>
                      <th className="p-2 py-4 md:py-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden row-span-3">
                        Edit Info
                      </th>
                      <th className="p-2 py-4 md:py-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden row-span-3">
                        Edit Isi
                      </th>
                      <th className="p-2 py-4 md:py-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none md:rounded-r-xl overflow-hidden row-span-3">
                        Pendaftar
                      </th>
                    </tr>
                  );
                })}
              </thead>
              <tbody className="flex-1 md:flex-none">
                {bundleListAdmin.map((item, index) => {
                  return (
                    <tr
                      className="flex flex-col md:table-row mt-5 md:mt-0"
                      key={index}
                    >
                      <td className="p-2 font-content-1 text-center">
                        {item.id}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {item.nama_bundle}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {item.isi_bundle}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {item.harga}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {parseInt(item.tampilkan) ? (
                          <span className="text-hijau">Ya</span>
                        ) : (
                          <span className="text-merah">Tidak</span>
                        )}
                      </td>
                      <td className="p-2 font-content-1 justify-center flex md:table-cell">
                        <button
                          onClick={() => {
                            setModalContent(
                              <EditBundleComponent
                                bundleData={item}
                                setOpen={setOpen}
                                editBundle={editBundle}
                              />
                            );
                            setOpen(true);
                          }}
                          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl m-auto"
                        >
                          Edit Info
                        </button>
                      </td>
                      <td className="p-2 font-content-1 justify-center flex md:table-cell">
                        <Link
                          to={`/admin-area/bundle/${item.id}/edit-isi-bundle`}
                          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl m-auto"
                        >
                          Edit Isi
                        </Link>
                      </td>
                      <td className="p-2 font-content-1 justify-center flex md:table-cell">
                        <Link
                          to={`/admin-area/bundle/${item.id}/pendaftar`}
                          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl m-auto"
                        >
                          Lihat Pendaftar
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        {/* List Bundle */}
        <div className="flex flex-col w-full md:w-full-10 mt-10 gap-3">
          <p className="font-subtitle font-bold">Generate Kontak</p>

          <button
            onClick={async (e) => {
              e.target.disabled = true;
              hideAlert3();
              const res = await generateKontak();
              if (res.success) {
                setSuccessAlert3("Kontak baru berhasil digenerate");
              } else {
                setErrorAlert3(
                  "Gagal generate kontak. Periksa console untuk info lebih lanjut."
                );
              }
              e.target.disabled = false;
            }}
            className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl"
          >
            Generate Kontak
          </button>
          <div
            className={`${
              alertText3.show ? "flex" : "hidden"
            } p-4 mb-4 text-sm ${
              alertText3.success
                ? "text-green-700 bg-green-100"
                : "text-red-700 bg-red-100"
            } rounded-lg`}
            role="alert"
          >
            <svg
              className="inline flex-shrink-0 mr-3 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <div>{alertText3.text}</div>
          </div>
          <div className="w-full overflow-auto">
            <table className="tabel-jd flex flex-row flex-nowrap w-full md:inline-table">
              <thead>
                {kontakListAdmin.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={`${
                        index === 0 ? "md:table-row" : "md:hidden"
                      } flex flex-col mt-5 md:mt-0`}
                    >
                      <th className="h-14 md:h-auto p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                        Tanggal
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Jumlah Kontak Baru
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Jumlah Kontak Kumulatif
                      </th>
                      <th className="p-4 md:p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Unduh
                      </th>
                      <th className="p-4 md:p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none md:rounded-r-xl overflow-hidden">
                        Hapus
                      </th>
                    </tr>
                  );
                })}
              </thead>
              <tbody className="flex-1 md:flex-none">
                {kontakListAdmin.map((item, index) => {
                  return (
                    <tr
                      className="flex flex-col md:table-row mt-5 md:mt-0"
                      key={index}
                    >
                      <td className="h-14 md:h-auto p-2 font-content-1 text-center">
                        {item.nama_folder}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {item.total_kontak}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {item.total_kontak_kumulatif}
                      </td>
                      <td className="p-2 font-content-1 justify-center flex md:table-cell">
                        <a
                          href={`${window.location.origin}/kontak/${item.nama_folder}/new_contact.vcf`}
                          target="_blank"
                          rel="noreferrer"
                          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl m-auto"
                        >
                          Unduh
                        </a>
                      </td>
                      <td className="p-2 font-content-1 justify-center flex md:table-cell">
                        <button
                          onClick={() => {
                            setModalContent(
                              <DeleteContactComponent
                                setOpen={setOpen}
                                kontak={item}
                              />
                            );
                            setOpen(true);
                          }}
                          className="bg-merah font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl m-auto"
                        >
                          Hapus
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="flex w-full justify-center">
            <div className="flex gap-3">
              {paginationKontak.totalPage ? (
                <PageComponent
                  currentPage={paginationKontak.currentPage}
                  totalPage={paginationKontak.totalPage}
                  getData={(page) => {
                    getAllKontakAdmin({
                      page: page,
                    });
                  }}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        {/* Tools Tambahan */}
        <div className="flex flex-col w-full md:w-full-10 mt-10 gap-3">
          <p className="font-subtitle font-bold">Partnership</p>
          <p className="font-content-0">
            Info lebih lanjut terdapat pada&nbsp;
            <Link
              to="/admin-area/partnership"
              className="text-oren hover:underline"
            >
              dashboard partnership
            </Link>
            .
          </p>
        </div>

        {/* Tools Tambahan */}
        <div className="flex flex-col w-full md:w-full-10 mt-10 gap-3">
          <p className="font-subtitle font-bold">Generate Ranking</p>

          <button
            onClick={async (e) => {
              e.target.disabled = true;
              hideAlert2();
              const res = await generateRanking();
              if (res.success) {
                setSuccessAlert2(
                  "Ranking dan sertifikat berhasil digenerate. Buka console untuk info lebih lanjut."
                );
              } else {
                setErrorAlert2(
                  "Ranking dan sertifikat gagal digenerate. Buka console untuk info lebih lanjut."
                );
              }
              e.target.disabled = false;
            }}
            className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl"
          >
            Generate Ranking dan Sertifikat
          </button>
          <div
            className={`${
              alertText2.show ? "flex" : "hidden"
            } p-4 mb-4 text-sm ${
              alertText2.success
                ? "text-green-700 bg-green-100"
                : "text-red-700 bg-red-100"
            } rounded-lg`}
            role="alert"
          >
            <svg
              className="inline flex-shrink-0 mr-3 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <div>{alertText2.text}</div>
          </div>
        </div>
      </div>
      <Modal {...{ open, setOpen }}>
        <div className="relative text-left w-11/12 md:w-2/3 lg:w-1/2 xl:w-2/5 z-50 flex flex-col text-xl font-bold bg-white rounded-2xl">
          {modalContent}
        </div>
      </Modal>
    </>
  );
};

export default AdminArea;
