import { useRef } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import Select from "react-select";
import PartnerContext from "../../context/PartnerContext/PartnerContext";
import PageComponent from "../Layout/PageComponent";

const DeletePartnerComponent = ({
  setOpen,
  currentPartnership,
  user,
  query,
  sort,
  order,
  page,
}) => {
  const { deleteUser } = useContext(PartnerContext);
  const [alertText, setAlertText] = useState({
    show: false,
    text: "",
    success: true,
  });

  const setErrorAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: false,
    });
  };
  const hideAlert = () => {
    setAlertText({
      show: false,
      text: alertText.message,
      success: alertText.success,
    });
  };

  const [emailInput, setEmailInput] = useState("");
  const buttonRef = useRef(<></>);
  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          const button = buttonRef.current;
          button.disabled = true;
          hideAlert();
          if (emailInput === user.email) {
            const res = await deleteUser({
              id_user: user.id_user,
              id_tryout: currentPartnership.id_tryout,
              query,
              sort,
              order,
              page,
            });
            if (res.data) {
              setOpen(false);
            } else {
              setErrorAlert(
                res.display_message || "Terjadi kesalahan, coba lagi nanti."
              );
            }
          } else {
            setErrorAlert("Email tidak sesuai");
          }
          button.disabled = false;
        }}
        className="flex flex-col gap-6 w-full px-10 pb-10"
      >
        <h5 className="font-bold text-center">Konfirmasi</h5>
        <div className="flex flex-col gap-2 w-full">
          <p className="font-content-0 w-full text-center">
            Apakah kamu yakin ingin menghapus peserta ini?
          </p>
        </div>

        <div className="flex flex-col gap-2 w-full items-center">
          <p className="font-content-1 w-full text-center">
            Ketik ulang email peserta untuk melanjutkan :{" "}
            <span className="bg-krem font-bold">{user.email}</span>
          </p>
          <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
            <input
              className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
              type="email"
              value={emailInput}
              onChange={(e) => {
                setEmailInput(e.target.value);
              }}
              required
            />
          </div>
        </div>

        <div
          className={`${alertText.show ? "flex" : "hidden"} p-4 mb-4 text-sm ${
            alertText.success
              ? "text-green-700 bg-green-100"
              : "text-red-700 bg-red-100"
          } rounded-lg`}
          role="alert"
        >
          <svg
            className="inline flex-shrink-0 mr-3 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clipRule="evenodd"
            ></path>
          </svg>
          <div>{alertText.text}</div>
        </div>

        <div className="flex justify-center gap-4 w-100">
          <button
            type="submit"
            ref={buttonRef}
            className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-white rounded-xl text-center"
          >
            Ya
          </button>
          <button
            onClick={() => setOpen(false)}
            type="button"
            className="border-2 border-oren font-content-0 flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-black rounded-xl text-center"
          >
            Batal
          </button>
        </div>
      </form>
    </>
  );
};

const PendaftarComponent = ({
  setModalContent,
  setOpen,
  currentPartnership,
  optionSortList,
  currentSort,
  setCurrentSort,
  currentQuery,
  setCurrentQuery,
}) => {
  const {
    pesertaTryoutList,
    paginationPesertaTryout,
    getPendaftarSudahVerifikasi,
    downloadPeserta,
  } = useContext(PartnerContext);

  useEffect(() => {
    if (currentPartnership.id_tryout)
      getPendaftarSudahVerifikasi({
        page: 1,
        id_tryout: currentPartnership.id_tryout,
        query: currentQuery,
        sort: currentSort.sort,
        order: currentSort.order,
      });
  }, [currentPartnership]);

  const onSubmitSearch = async (e) => {
    e.preventDefault();
    const button = e.target.querySelector("button");
    button.disabled = true;
    await getPendaftarSudahVerifikasi({
      page: 1,
      id_tryout: currentPartnership.id_tryout,
      query: currentQuery,
      sort: currentSort.sort,
      order: currentSort.order,
    });

    button.disabled = false;
  };

  return (
    <div className="flex flex-col w-full md:w-full-10 mt-10 gap-3">
      <p className="font-subtitle font-bold">Daftar Pendaftar</p>
      {pesertaTryoutList.length === 0 ? (
        <p className="font-content-0">Belum ada pendaftar yang diterima.</p>
      ) : (
        <>
          <button
            type="button"
            onClick={async (e) => {
              const button = e.target;
              button.disabled = true;
              await downloadPeserta({
                id_tryout: currentPartnership.id_tryout,
              });
              button.disabled = false;
            }}
            className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl"
          >
            Unduh CSV
          </button>
          <div className="w-full flex flex-col-reverse sm:flex-row items-center justify-end gap-3">
            <Select
              placeholder="Urutan"
              styles={{
                control: () => ({
                  alignItems: "center",
                  border: "none",
                  display: "flex",
                  flexWrap: "wrap",
                  WebkitBoxPack: "justify",
                  justifyContent: "space-between",
                  minHeight: "38px",
                  position: "relative",
                }),
                placeholder: (style) => ({
                  ...style,
                  color: "black",
                }),
              }}
              className="w-full sm:w-48 h-fit text-center border-none rounded-xl outline-none font-content-1 bg-krem"
              onChange={(val) => {
                if (val.value !== currentSort.value) {
                  const option = optionSortList.find(
                    (item) => item.value === val.value
                  );
                  setCurrentSort(option);

                  getPendaftarSudahVerifikasi({
                    page: 1,
                    id_tryout: currentPartnership.id_tryout,
                    query: currentQuery,
                    sort: option.sort,
                    order: option.order,
                  });
                }
              }}
              options={optionSortList.map((item) => {
                return { value: item.value, label: item.name };
              })}
            />
            <div className="w-full sm:w-96 flex">
              <form onSubmit={onSubmitSearch} className="flex flex-1 gap-2">
                <div className="relative w-auto flex-1 flex border-2 border-abu focus-within:border-black transition-colors rounded-xl items-center">
                  <i
                    className={`${
                      currentQuery && "hidden"
                    } fa-solid fa-magnifying-glass text-base sm:text-lg absolute text-gray-400 ml-3`}
                  ></i>
                  <input
                    className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                    placeholder="        Cari nama atau email"
                    type="text"
                    onChange={(e) => setCurrentQuery(e.target.value)}
                  />
                </div>
                <button className="bg-oren font-content-0 text-white flex gap-2 items-center justify-center px-4 py-2 rounded-xl">
                  <i className="fa-solid fa-magnifying-glass text-xl"></i>
                </button>
              </form>
            </div>
          </div>
          <div className="w-full overflow-auto">
            <table className="tabel-jd flex flex-row flex-nowrap w-full md:inline-table">
              <thead>
                {pesertaTryoutList.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={`${
                        index === 0 ? "md:table-row" : "md:hidden"
                      } flex flex-col mt-5 md:mt-0`}
                    >
                      <th className="h-14 md:h-auto p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                        Nama
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Email
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Pembayaran
                      </th>
                      <th className="h-14 md:h-auto p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                        Bukti Pembayaran
                      </th>
                      <th className="p-4 md:p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none md:rounded-r-xl overflow-hidden">
                        Hapus
                      </th>
                    </tr>
                  );
                })}
              </thead>
              <tbody className="flex-1 md:flex-none">
                {pesertaTryoutList.map((item, index) => {
                  return (
                    <tr
                      className="flex flex-col md:table-row mt-5 md:mt-0"
                      key={index}
                    >
                      <td className="h-14 md:h-auto p-2 font-content-1 text-center">
                        {item.nama}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {item.email}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {item.pembayaran_partnership}
                      </td>
                      <td className="h-14 md:h-auto p-2 font-content-1 text-center">
                        <a
                          href={`${window.location.origin}/uploads/bukti/${currentPartnership.id_tryout}_${item.id_user}_bukti1.png`}
                          target="_blank"
                          rel="noreferrer"
                          className="font-content-1 text-center hover:underline text-oren font-bold"
                        >
                          Lihat Bukti
                        </a>
                      </td>
                      <td className="p-2 font-content-1 justify-center flex md:table-cell">
                        <button
                          onClick={() => {
                            setModalContent(
                              <DeletePartnerComponent
                                setOpen={setOpen}
                                currentPartnership={currentPartnership}
                                user={item}
                                query={currentQuery}
                                sort={currentSort.sort}
                                order={currentSort.order}
                                page={paginationPesertaTryout.currentPage}
                              />
                            );

                            setOpen(true);
                          }}
                          className="bg-merah font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl m-auto"
                          disabled={parseInt(currentPartnership.lunas)}
                        >
                          Hapus
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      )}

      <div className="flex w-full justify-center">
        <div className="flex gap-3">
          {paginationPesertaTryout.totalPage ? (
            <PageComponent
              currentPage={paginationPesertaTryout.currentPage}
              totalPage={paginationPesertaTryout.totalPage}
              getData={(page) => {
                getPendaftarSudahVerifikasi({
                  page: page,
                  id_tryout: currentPartnership.id_tryout,
                  query: currentQuery,
                  sort: currentSort.sort,
                  order: currentSort.order,
                });
              }}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default PendaftarComponent;
