/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select, { createFilter } from "react-select";
import HeaderJD from "../../components/Layout/HeaderJD";
import Modal from "../../components/Home/Modal";
import AdminContext from "../../context/AdminContext/AdminContext";

const AddIsiEventComponent = ({
  eventData,
  setOpen,
  isiEventList,
  addIsiEvent,
}) => {
  const [isiEventForm, setIsiEventForm] = useState({
    id_event: 0,
    id_tryout: 0,
  });
  const [optionList, setOptionList] = useState([]);
  useEffect(() => {
    if (isiEventList.length) {
      const newOptionList = isiEventList
        .filter((item) => !item.aktif)
        .map((item) => ({
          ...item,
          value: item.id_tryout,
          label: item.nama_tryout,
        }));
      setOptionList(newOptionList);
    }
  }, [isiEventList]);
  useEffect(() => {
    if (optionList.length)
      setIsiEventForm({
        id_event: eventData.id,
        id_tryout: optionList[0].id_tryout,
      });
  }, [optionList, eventData]);

  const [alertText, setAlertText] = useState({
    show: false,
    text: "",
    success: true,
  });

  const setErrorAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: false,
    });
  };
  const hideAlert = () => {
    setAlertText({
      show: false,
      text: alertText.message,
      success: alertText.success,
    });
  };

  const onSubmitAdd = async (e) => {
    e.preventDefault();
    const button = e.target;
    button.disabled = true;
    hideAlert();
    const res = await addIsiEvent(isiEventForm);
    button.disabled = false;
    if (res.success) {
      setOpen(false);
    } else {
      setErrorAlert("Gagal menambah tryout. " + res.display_message);
    }
  };
  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <form
        onSubmit={onSubmitAdd}
        className="flex flex-col gap-6 w-full px-10 pb-10"
      >
        <h6 className="font-bold text-center">
          Tambah Isi {eventData.nama_event}
        </h6>
        <div className="flex flex-col gap-3 w-full mt-5">
          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Try Out</p>
            </div>

            <div className="w-2/3 flex border-2 border-black rounded-xl items-center select-container">
              <Select
                placeholder="Pilih tryout"
                styles={{
                  control: () => ({
                    alignItems: "center",
                    border: "none",
                    display: "flex",
                    flexWrap: "wrap",
                    WebkitBoxPack: "justify",
                    justifyContent: "space-between",
                    minHeight: "38px",
                    position: "relative",
                  }),
                }}
                className="w-full border-none outline-none font-content-1"
                onChange={(val) => {
                  if (val.value !== isiEventForm.id_tryout) {
                    setIsiEventForm({
                      ...isiEventForm,
                      id_tryout: val.value,
                    });
                  }
                }}
                options={optionList}
                value={optionList.find(
                  (item) => item.id_tryout === isiEventForm.id_tryout
                )}
                filterOption={createFilter({ ignoreAccents: false })}
              />
            </div>
          </div>

          <div
            className={`${
              alertText.show ? "flex" : "hidden"
            } p-4 mb-4 text-sm ${
              alertText.success
                ? "text-green-700 bg-green-100"
                : "text-red-700 bg-red-100"
            } rounded-lg`}
            role="alert"
          >
            <svg
              className="inline flex-shrink-0 mr-3 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <div>{alertText.text}</div>
          </div>
        </div>
        <button
          type="submit"
          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-full text-white rounded-xl m-auto"
        >
          Tambah
        </button>
      </form>
    </>
  );
};

const DeleteIsiEventComponent = ({
  eventData,
  setOpen,
  tryout,
  deleteIsiEvent,
}) => {
  const [isiEventForm, setIsiEventForm] = useState({
    id_event: 0,
    id_tryout: "",
  });
  useEffect(() => {
    if (eventData && tryout)
      setIsiEventForm({
        id_event: eventData.id,
        id_tryout: tryout.id_tryout,
      });
  }, [eventData, tryout]);

  const [alertText, setAlertText] = useState({
    show: false,
    text: "",
    success: true,
  });

  const setErrorAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: false,
    });
  };
  const hideAlert = () => {
    setAlertText({
      show: false,
      text: alertText.message,
      success: alertText.success,
    });
  };

  const onSubmitDelete = async (e) => {
    e.preventDefault();
    const button = e.target;
    button.disabled = true;
    hideAlert();
    const res = await deleteIsiEvent(isiEventForm);
    button.disabled = false;
    if (res.success) {
      setOpen(false);
    } else {
      setErrorAlert("Gagal menghapus tryout. " + res.display_message);
    }
  };
  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <form
        onSubmit={onSubmitDelete}
        className="flex flex-col gap-6 w-full px-10 pb-10"
      >
        <h6 className="font-bold text-center">Konfirmasi</h6>
        <div className="flex flex-col gap-3 w-full mt-5">
          <div className="flex flex-col gap-2 w-full">
            <p className="font-content-0 w-full text-center">
              Apakah kamu yakin ingin menghapus {tryout.nama_tryout} dari daftar
              isi?
            </p>
          </div>

          <div
            className={`${
              alertText.show ? "flex" : "hidden"
            } p-4 mb-4 text-sm ${
              alertText.success
                ? "text-green-700 bg-green-100"
                : "text-red-700 bg-red-100"
            } rounded-lg`}
            role="alert"
          >
            <svg
              className="inline flex-shrink-0 mr-3 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <div>{alertText.text}</div>
          </div>
        </div>
        <div className="flex justify-center gap-4 w-100">
          <button
            type="submit"
            className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-white rounded-xl text-center"
          >
            Hapus
          </button>
          <button
            onClick={() => setOpen(false)}
            type="button"
            className="border-2 border-oren font-content-0 flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-black rounded-xl text-center"
          >
            Batal
          </button>
        </div>
      </form>
    </>
  );
};

const DetailEvent = () => {
  const { idEvent } = useParams();
  const {
    currentPartnershipEvent,
    isiEventListAdmin,
    addIsiEvent,
    deleteIsiEvent,
    getSinglePartnershipEvent,
    getIsiEventAdmin,
  } = useContext(AdminContext);
  useEffect(() => {
    getSinglePartnershipEvent({ id_event: idEvent });
    getIsiEventAdmin({ id_event: idEvent });
  }, []);

  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState(<></>);
  return (
    <>
      <div className="flex flex-col bg-white p-10 justify-start m-0 w-full">
        <HeaderJD title={`Edit Isi ${currentPartnershipEvent.nama_event}`} />

        <div className="flex flex-col w-full md:w-full-10 mt-10 gap-3">
          <p className="font-subtitle font-bold">Daftar Isi Event</p>

          <div className="w-full overflow-auto flex flex-col items-start gap-2">
            <button
              onClick={() => {
                setModalContent(
                  <AddIsiEventComponent
                    eventData={currentPartnershipEvent}
                    isiEventList={isiEventListAdmin}
                    setOpen={setOpen}
                    addIsiEvent={addIsiEvent}
                  />
                );
                setOpen(true);
              }}
              disabled={!isiEventListAdmin.filter((item) => !item.aktif).length}
              className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl"
            >
              Tambah Try Out
            </button>
            <table className="tabel-jd flex flex-row flex-nowrap w-full md:inline-table">
              <thead>
                {isiEventListAdmin
                  .filter((item) => item.aktif)
                  .map((item, index) => {
                    return (
                      <tr
                        key={index}
                        className={`${
                          index === 0 ? "md:table-row" : "md:hidden"
                        } flex flex-col mt-5 md:mt-0`}
                      >
                        <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                          Id
                        </th>
                        <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                          Nama Try Out
                        </th>
                        <th className="p-2 py-4 md:py-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none md:rounded-r-xl overflow-hidden row-span-3">
                          Aksi
                        </th>
                      </tr>
                    );
                  })}
              </thead>
              <tbody className="flex-1 md:flex-none">
                {isiEventListAdmin
                  .filter((item) => item.aktif)
                  .map((item, index) => {
                    return (
                      <tr
                        className="flex flex-col md:table-row mt-5 md:mt-0"
                        key={index}
                      >
                        <td className="p-2 font-content-1 text-center">
                          {item.id_tryout}
                        </td>
                        <td className="p-2 font-content-1 text-center">
                          {item.nama_tryout}
                        </td>
                        <td className="p-2 font-content-1 justify-center flex md:table-cell">
                          <button
                            onClick={() => {
                              setModalContent(
                                <DeleteIsiEventComponent
                                  eventData={currentPartnershipEvent}
                                  tryout={item}
                                  setOpen={setOpen}
                                  deleteIsiEvent={deleteIsiEvent}
                                />
                              );
                              setOpen(true);
                            }}
                            className="bg-merah font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl m-auto"
                          >
                            Hapus
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal {...{ open, setOpen }}>
        <div className="relative text-left w-11/12 md:w-2/3 lg:w-1/2 xl:w-2/5 z-50 flex flex-col text-xl font-bold bg-white rounded-2xl">
          {modalContent}
        </div>
      </Modal>
    </>
  );
};

export default DetailEvent;
