import dayjs from "dayjs";

const monthList = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];

const dateToString = (dateString, withHour = false) => {
  const dateObject = dayjs(dateString);
  const date = dateObject.format("DD");
  const month = monthList[dateObject.month()];
  const year = dateObject.year();

  if (withHour) {
    const hour = dateObject.hour().toString().padStart(2, "0");
    const minute = dateObject.minute().toString().padStart(2, "0");
    const second = dateObject.second().toString().padStart(2, "0");
    return `${date} ${month} ${year}, ${hour}:${minute}:${second}`;
  }

  return `${date} ${month} ${year}`;
};

export default dateToString;
export { monthList };
