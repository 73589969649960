import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";

const EditPartnershipComponent = ({
  currentPartnership,
  setOpen,
  editPartnership,
  single,
}) => {
  const [partnershipForm, setPartnershipForm] = useState({
    id_tryout: 0,
    kode_promo: "",
    profit: 0,
    lama_promo: 1,
  });

  const promoRef = useRef(<></>);

  useEffect(() => {
    setPartnershipForm({
      id_tryout: currentPartnership.id_tryout,
      kode_promo: currentPartnership.kode_promo,
      profit: currentPartnership.profit_partner,
      lama_promo: currentPartnership.lama_promo,
    });
  }, [currentPartnership]);

  const [alertText, setAlertText] = useState({
    show: false,
    text: "",
    success: true,
  });

  const setErrorAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: false,
    });
  };
  const hideAlert = () => {
    setAlertText({
      show: false,
      text: alertText.message,
      success: alertText.success,
    });
  };

  const onSubmitEdit = async (e) => {
    e.preventDefault();
    if (partnershipForm.kode_promo.length < 6) {
      promoRef.current.setCustomValidity(
        "Panjang teks kurang dari 6 karakter."
      );
      promoRef.current.reportValidity();
    } else {
      promoRef.current.setCustomValidity("");
      const button = e.target;
      button.disabled = true;
      hideAlert();
      const res = await editPartnership({ ...partnershipForm, single });
      button.disabled = false;
      if (res.success) {
        setOpen(false);
      } else {
        setErrorAlert("Gagal menambah partnership. " + res.display_message);
      }
    }
  };
  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <form
        onSubmit={onSubmitEdit}
        className="flex flex-col gap-6 w-full px-10 pb-10"
      >
        <h6 className="font-bold text-center">Edit Promo</h6>
        <div className="flex flex-col gap-3 w-full mt-5">
          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Kode Voucher</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="text"
                minLength="6"
                maxLength="20"
                value={partnershipForm.kode_promo}
                ref={promoRef}
                onChange={(e) => {
                  if (String(e.target.value).match(/^[a-zA-Z0-9]*$/)) {
                    e.target.setCustomValidity("");
                    setPartnershipForm({
                      ...partnershipForm,
                      kode_promo: e.target.value,
                    });
                  } else {
                    e.target.setCustomValidity(
                      "Kode voucher hanya boleh berisi angka dan huruf"
                    );
                    e.target.reportValidity();
                  }
                }}
                required
              />
            </div>
          </div>

          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Profit Partner</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="number"
                value={partnershipForm.profit}
                onChange={(e) => {
                  setPartnershipForm({
                    ...partnershipForm,
                    profit: e.target.value,
                  });
                }}
                required
              />
            </div>
          </div>

          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Lama Promo</p>
            </div>

            <div className="flex w-2/3 gap-2 md:gap-3">
              <div className="flex w-1/2 border-2 border-black rounded-xl items-center select-container">
                <input
                  className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                  type="number"
                  value={partnershipForm.lama_promo}
                  onChange={(e) => {
                    setPartnershipForm({
                      ...partnershipForm,
                      lama_promo: e.target.value,
                    });
                  }}
                  required
                />
              </div>
              <div className="flex items-center justify-start">
                <p className="font-content-1">hari</p>
              </div>
            </div>
          </div>

          <div
            className={`${
              alertText.show ? "flex" : "hidden"
            } p-4 mb-4 text-sm ${
              alertText.success
                ? "text-green-700 bg-green-100"
                : "text-red-700 bg-red-100"
            } rounded-lg`}
            role="alert"
          >
            <svg
              className="inline flex-shrink-0 mr-3 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <div>{alertText.text}</div>
          </div>
        </div>
        <button
          type="submit"
          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-full text-white rounded-xl m-auto"
        >
          Simpan Perubahan
        </button>
      </form>
    </>
  );
};

export default EditPartnershipComponent;
