/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from "react";
import { useState } from "react";
import Select, { createFilter } from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import $ from "jquery";
import Editor from "ckeditor5-build-classic-extended";
import Modal from "../../components/Home/Modal";

const AddSubtesComponent = ({ tryoutData, setOpen, subtesList, addSubtes }) => {
  const [subtesForm, setSubtesForm] = useState({
    id_tryout: 0,
    subtes: "",
    waktu: 25,
  });
  const [optionList, setOptionList] = useState([]);
  useEffect(() => {
    if (subtesList.length) {
      const newOptionList = subtesList
        .filter((item) => !item.aktif)
        .map((item) => ({ ...item, value: item.singkatan, label: item.nama }));
      setOptionList(newOptionList);
    }
  }, [subtesList]);
  useEffect(() => {
    if (optionList.length)
      setSubtesForm({
        id_tryout: tryoutData.id,
        subtes: optionList[0].singkatan,
        waktu: 25,
      });
  }, [optionList, tryoutData]);

  const [alertText, setAlertText] = useState({
    show: false,
    text: "",
    success: true,
  });

  const setErrorAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: false,
    });
  };
  const hideAlert = () => {
    setAlertText({
      show: false,
      text: alertText.message,
      success: alertText.success,
    });
  };

  const onSubmitAdd = async (e) => {
    e.preventDefault();
    const button = e.target;
    button.disabled = true;
    hideAlert();
    const res = await addSubtes(subtesForm);
    button.disabled = false;
    if (res.success) {
      setOpen(false);
    } else {
      setErrorAlert("Gagal menambah subtes. " + res.display_message);
    }
  };
  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <form
        onSubmit={onSubmitAdd}
        className="flex flex-col gap-6 w-full px-10 pb-10"
      >
        <h6 className="font-bold text-center">
          Tambah Subtes {tryoutData.nama_tryout}
        </h6>
        <div className="flex flex-col gap-3 w-full mt-5">
          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Subtes</p>
            </div>

            <div className="w-2/3 flex border-2 border-black rounded-xl items-center select-container">
              <Select
                placeholder="Pilih subtes"
                styles={{
                  control: () => ({
                    alignItems: "center",
                    border: "none",
                    display: "flex",
                    flexWrap: "wrap",
                    WebkitBoxPack: "justify",
                    justifyContent: "space-between",
                    minHeight: "38px",
                    position: "relative",
                  }),
                }}
                className="w-full border-none outline-none font-content-1"
                onChange={(val) => {
                  if (val.value !== subtesForm.subtes) {
                    setSubtesForm({ ...subtesForm, subtes: val.value });
                  }
                }}
                options={optionList}
                value={optionList.find(
                  (item) => item.singkatan === subtesForm.subtes
                )}
                filterOption={createFilter({ ignoreAccents: false })}
              />
            </div>
          </div>

          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Durasi</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="text"
                value={subtesForm.waktu}
                onChange={(e) => {
                  setSubtesForm({ ...subtesForm, waktu: e.target.value });
                }}
                required
              />
            </div>
          </div>

          <div
            className={`${
              alertText.show ? "flex" : "hidden"
            } p-4 mb-4 text-sm ${
              alertText.success
                ? "text-green-700 bg-green-100"
                : "text-red-700 bg-red-100"
            } rounded-lg`}
            role="alert"
          >
            <svg
              className="inline flex-shrink-0 mr-3 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <div>{alertText.text}</div>
          </div>
        </div>
        <button
          type="submit"
          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-full text-white rounded-xl m-auto"
        >
          Tambah
        </button>
      </form>
    </>
  );
};

const DeleteSubtesComponent = ({
  tryoutData,
  setOpen,
  subtes,
  deleteSubtes,
}) => {
  const [subtesForm, setSubtesForm] = useState({
    id_tryout: 0,
    subtes: "",
  });
  useEffect(() => {
    if (tryoutData && subtes)
      setSubtesForm({
        id_tryout: tryoutData.id,
        subtes: subtes.singkatan,
      });
  }, [tryoutData, subtes]);

  const [alertText, setAlertText] = useState({
    show: false,
    text: "",
    success: true,
  });

  const setErrorAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: false,
    });
  };
  const hideAlert = () => {
    setAlertText({
      show: false,
      text: alertText.message,
      success: alertText.success,
    });
  };

  const onSubmitDelete = async (e) => {
    e.preventDefault();
    const button = e.target;
    button.disabled = true;
    hideAlert();
    const res = await deleteSubtes(subtesForm);
    button.disabled = false;
    if (res.success) {
      setOpen(false);
    } else {
      setErrorAlert("Gagal menghapus subtes. " + res.display_message);
    }
  };
  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <form
        onSubmit={onSubmitDelete}
        className="flex flex-col gap-6 w-full px-10 pb-10"
      >
        <h6 className="font-bold text-center">Konfirmasi</h6>
        <div className="flex flex-col gap-3 w-full mt-5">
          <div className="flex flex-col gap-2 w-full">
            <p className="font-content-0 w-full text-center">
              Apakah kamu yakin ingin menghapus subtes {subtes.nama}?
            </p>
          </div>

          <div
            className={`${
              alertText.show ? "flex" : "hidden"
            } p-4 mb-4 text-sm ${
              alertText.success
                ? "text-green-700 bg-green-100"
                : "text-red-700 bg-red-100"
            } rounded-lg`}
            role="alert"
          >
            <svg
              className="inline flex-shrink-0 mr-3 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <div>{alertText.text}</div>
          </div>
        </div>
        <div className="flex justify-center gap-4 w-100">
          <button
            type="submit"
            className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-white rounded-xl text-center"
          >
            Hapus
          </button>
          <button
            onClick={() => setOpen(false)}
            type="button"
            className="border-2 border-oren font-content-0 flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-black rounded-xl text-center"
          >
            Batal
          </button>
        </div>
      </form>
    </>
  );
};

const DeleteSoalComponent = ({
  tryoutData,
  setOpen,
  soal,
  soalOptionList,
  setCurrentSoal,
  deleteSoal,
}) => {
  const [soalForm, setSoalForm] = useState({
    id_tryout: 0,
    id_soal: 0,
    subtes: "",
  });
  useEffect(() => {
    if (tryoutData && soal)
      setSoalForm({
        id_tryout: tryoutData.id,
        id_soal: soal.id_soal,
        subtes: soal.subtes,
      });
  }, [tryoutData, soal]);

  const [alertText, setAlertText] = useState({
    show: false,
    text: "",
    success: true,
  });

  const setErrorAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: false,
    });
  };
  const hideAlert = () => {
    setAlertText({
      show: false,
      text: alertText.message,
      success: alertText.success,
    });
  };

  const onSubmitDelete = async (e) => {
    e.preventDefault();
    const button = e.target;
    button.disabled = true;
    hideAlert();
    const res = await deleteSoal(soalForm);
    button.disabled = false;
    if (res.success) {
      setCurrentSoal({});
      setOpen(false);
    } else {
      setErrorAlert("Gagal menghapus soal. " + res.display_message);
    }
  };
  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <form
        onSubmit={onSubmitDelete}
        className="flex flex-col gap-6 w-full px-10 pb-10"
      >
        <h6 className="font-bold text-center">Konfirmasi</h6>
        <div className="flex flex-col gap-3 w-full mt-5">
          <div className="flex flex-col gap-2 w-full">
            <p className="font-content-0 w-full text-center">
              Apakah kamu yakin ingin menghapus soal ini?
            </p>
          </div>

          <div
            className={`${
              alertText.show ? "flex" : "hidden"
            } p-4 mb-4 text-sm ${
              alertText.success
                ? "text-green-700 bg-green-100"
                : "text-red-700 bg-red-100"
            } rounded-lg`}
            role="alert"
          >
            <svg
              className="inline flex-shrink-0 mr-3 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <div>{alertText.text}</div>
          </div>
        </div>
        <div className="flex justify-center gap-4 w-100">
          <button
            type="submit"
            className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-white rounded-xl text-center"
          >
            Hapus
          </button>
          <button
            onClick={() => setOpen(false)}
            type="button"
            className="border-2 border-oren font-content-0 flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-black rounded-xl text-center"
          >
            Batal
          </button>
        </div>
      </form>
    </>
  );
};

const AddSoalComponent = ({
  tryoutData,
  setOpen,
  subtes,
  soalPembahasanList,
  addSoal,
}) => {
  const [soalForm, setSoalForm] = useState({
    id_tryout: 0,
    subtes: "",
    nomor: 1,
  });

  useEffect(() => {
    if (
      Object.keys(subtes).length &&
      Object.keys(tryoutData).length &&
      soalPembahasanList
    )
      setSoalForm({
        id_tryout: tryoutData.id,
        subtes: subtes.singkatan,
        nomor: soalPembahasanList.length + 1,
      });
  }, [subtes, tryoutData, soalPembahasanList]);

  const [alertText, setAlertText] = useState({
    show: false,
    text: "",
    success: true,
  });

  const setErrorAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: false,
    });
  };
  const hideAlert = () => {
    setAlertText({
      show: false,
      text: alertText.message,
      success: alertText.success,
    });
  };

  const onSubmitAdd = async (e) => {
    e.preventDefault();
    const button = e.target[1];
    button.disabled = true;
    hideAlert();
    const res = await addSoal(soalForm);
    button.disabled = false;
    if (res.success) {
      setOpen(false);
    } else {
      setErrorAlert("Gagal menambah soal. " + res.display_message);
    }
  };
  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <form
        onSubmit={onSubmitAdd}
        className="flex flex-col gap-6 w-full px-10 pb-10"
      >
        <h6 className="font-bold text-center">Tambah Soal {subtes.nama}</h6>
        <div className="flex flex-col gap-3 w-full mt-5">
          <div className="flex w-full items-center justify-between gap-5">
            <div className="flex items-center w-1/3 gap-2 md:gap-3">
              <p className="font-content-1">Nomor</p>
            </div>

            <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
              <input
                className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                type="text"
                value={soalForm.nomor}
                onChange={(e) => {
                  setSoalForm({ ...soalForm, nomor: e.target.value });
                }}
                required
              />
            </div>
          </div>

          <div
            className={`${
              alertText.show ? "flex" : "hidden"
            } p-4 mb-4 text-sm ${
              alertText.success
                ? "text-green-700 bg-green-100"
                : "text-red-700 bg-red-100"
            } rounded-lg`}
            role="alert"
          >
            <svg
              className="inline flex-shrink-0 mr-3 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <div>{alertText.text}</div>
          </div>
        </div>
        <button
          type="submit"
          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-full text-white rounded-xl m-auto"
        >
          Tambah
        </button>
      </form>
    </>
  );
};

const EditSoalLobby = ({
  currentTryout,
  subtesListTryout,
  soalPembahasanList,
  getSubtesTryout,
  addSubtes,
  deleteSubtes,
  getSoalPembahasan,
  editSoal,
  deleteSoal,
  addSoal,
}) => {
  useEffect(() => {
    if (currentTryout?.id) {
      getSubtesTryout({ id_tryout: currentTryout.id });
    }
  }, [currentTryout]);

  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState(<></>);

  const [currentSubtes, setCurrentSubtes] = useState({});
  const [oldSubtes, setOldSubtes] = useState({});
  const [subtesOptionList, setSubtesOptionList] = useState([]);
  const [soalOptionList, setSoalOptionList] = useState([]);
  const [loadingSoal, setLoadingSoal] = useState(false);
  const [currentSoal, setCurrentSoal] = useState({});

  useEffect(() => {
    if (subtesListTryout.length) {
      setSubtesOptionList(
        subtesListTryout
          .filter((item) => item.aktif)
          .map((item) => ({ ...item, value: item.singkatan, label: item.nama }))
      );
    }
  }, [subtesListTryout]);

  useEffect(() => {
    if (Object.keys(currentSubtes).length)
      (async () => {
        setLoadingSoal(true);
        await getSoalPembahasan({
          id_tryout: currentTryout.id,
          subtes: currentSubtes.singkatan,
        });
        setLoadingSoal(false);
      })();
  }, [currentSubtes]);

  useEffect(() => {
    if (Array.isArray(soalPembahasanList)) {
      const newSoalOptionList = soalPembahasanList.map((item) => ({
        ...item,
        value: item.id_soal,
        label: item.nomor,
      }));
      setSoalOptionList(newSoalOptionList);
      if (currentSubtes.singkatan !== oldSubtes.singkatan) {
        setCurrentSoal(newSoalOptionList[0] || {});
        setOldSubtes(currentSubtes);
      }
    }
  }, [soalPembahasanList]);

  const [editorSoal, setEditorSoal] = useState(null);
  const [editorPembahasan, setEditorPembahasan] = useState(null);

  // Jawaban
  const optionJawabanList = "A,B,C,D,E"
    .split(",")
    .map((item) => ({ label: item, value: item }));
  const [jawabanSoal, setJawabanSoal] = useState({});

  useEffect(() => {
    if (Object.keys(currentSoal).length && editorSoal) {
      const decoded = $("<div/>").html(currentSoal.soal).text();
      editorSoal.setData(decoded);
    } else {
      const decoded = $("<div/>").html("").text();
      editorSoal?.setData(decoded);
    }

    if (Object.keys(currentSoal).length && editorPembahasan) {
      const decoded = $("<div/>").html(currentSoal.pembahasan).text();
      editorPembahasan.setData(decoded);
    } else {
      const decoded = $("<div/>").html("").text();
      editorPembahasan?.setData(decoded);
    }

    if (Object.keys(currentSoal).length && optionJawabanList.length) {
      setJawabanSoal(
        optionJawabanList.find((item) => item.value === currentSoal.kunci)
      );
    } else {
      setJawabanSoal({});
    }
  }, [currentSoal]);

  const [alertText, setAlertText] = useState({
    show: false,
    text: "",
    success: true,
  });

  const setSuccessAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: true,
    });
  };
  const setErrorAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: false,
    });
  };
  const hideAlert = () => {
    setAlertText({
      show: false,
      text: alertText.message,
      success: alertText.success,
    });
  };

  return (
    <>
      <div className="flex flex-col bg-white justify-start m-0 w-full">
        {/* Subtes tryout */}
        <div className="flex flex-col w-full md:w-full-10 mt-10 gap-3">
          <p className="font-subtitle font-bold">Daftar Subtes</p>

          <div className="w-full overflow-auto flex flex-col items-start gap-2">
            <button
              onClick={() => {
                setModalContent(
                  <AddSubtesComponent
                    tryoutData={currentTryout}
                    subtesList={subtesListTryout}
                    setOpen={setOpen}
                    addSubtes={addSubtes}
                  />
                );
                setOpen(true);
              }}
              disabled={!subtesListTryout.filter((item) => !item.aktif).length}
              className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl"
            >
              Tambah Subtes
            </button>
            <table className="tabel-jd flex flex-row flex-nowrap w-full md:inline-table">
              <thead>
                {subtesListTryout
                  .filter((item) => item.aktif)
                  .map((item, index) => {
                    return (
                      <tr
                        key={index}
                        className={`${
                          index === 0 ? "md:table-row" : "md:hidden"
                        } flex flex-col mt-5 md:mt-0`}
                      >
                        <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                          Nama Subtes
                        </th>
                        <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                          Jenis Subtes
                        </th>
                        <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                          Jumlah Soal
                        </th>
                        <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none overflow-hidden">
                          Durasi
                        </th>
                        <th className="p-2 py-4 md:py-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none md:rounded-r-xl overflow-hidden row-span-3">
                          Aksi
                        </th>
                      </tr>
                    );
                  })}
              </thead>
              <tbody className="flex-1 md:flex-none">
                {subtesListTryout
                  .filter((item) => item.aktif)
                  .map((item, index) => {
                    return (
                      <tr
                        className="flex flex-col md:table-row mt-5 md:mt-0"
                        key={index}
                      >
                        <td className="p-2 font-content-1 text-center">
                          {item.nama}
                        </td>
                        <td className="p-2 font-content-1 text-center">
                          {item.tipe_string}
                        </td>
                        <td className="p-2 font-content-1 text-center">
                          {item.jumlah_soal}
                        </td>
                        <td className="p-2 font-content-1 text-center">
                          {item.waktu}
                        </td>
                        <td className="p-2 font-content-1 justify-center flex md:table-cell">
                          <button
                            onClick={() => {
                              setModalContent(
                                <DeleteSubtesComponent
                                  tryoutData={currentTryout}
                                  subtes={item}
                                  setOpen={setOpen}
                                  deleteSubtes={deleteSubtes}
                                />
                              );
                              setOpen(true);
                            }}
                            className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl m-auto"
                          >
                            Hapus
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>

        <div className="flex flex-col w-full md:w-full-10 mt-10 gap-3">
          <p className="font-subtitle font-bold">Edit Soal</p>
          <div className="flex w-full gap-2">
            <div className="w-2/3 md:w-80 flex border-2 border-none rounded-xl items-center select-container">
              <Select
                placeholder="Subtes"
                styles={{
                  control: () => ({
                    alignItems: "center",
                    border: "none",
                    display: "flex",
                    flexWrap: "wrap",
                    WebkitBoxPack: "justify",
                    justifyContent: "space-between",
                    minHeight: "38px",
                    position: "relative",
                  }),
                  placeholder: (style) => ({
                    ...style,
                    color: "black",
                  }),
                }}
                className="w-full border-none rounded-xl outline-none font-content-1 bg-krem z-10"
                onChange={async (val) => {
                  if (val.singkatan !== currentSubtes.singkatan) {
                    setCurrentSubtes(val);
                  }
                }}
                value={subtesOptionList.find(
                  (item) => item.singkatan === currentSubtes.singkatan
                )}
                options={subtesOptionList}
                filterOption={createFilter({ ignoreAccents: false })}
              />
            </div>
            <div className="w-1/3 md:w-52 flex border-2 border-none rounded-xl items-center select-container">
              <Select
                placeholder="Nomor"
                styles={{
                  control: () => ({
                    alignItems: "center",
                    border: "none",
                    display: "flex",
                    flexWrap: "wrap",
                    WebkitBoxPack: "justify",
                    justifyContent: "space-between",
                    minHeight: "38px",
                    position: "relative",
                  }),
                  placeholder: (style) => ({
                    ...style,
                    color: "black",
                  }),
                }}
                className="w-full border-none rounded-xl outline-none font-content-1 bg-krem z-10"
                onChange={async (val) => {
                  if (val.id_soal !== currentSubtes.id_soal) {
                    setCurrentSoal(val);
                  }
                }}
                value={soalOptionList.find((item) => {
                  return item.id_soal === currentSoal.id_soal;
                })}
                options={soalOptionList}
                filterOption={createFilter({ ignoreAccents: false })}
              />
            </div>
          </div>
          <div className="w-full flex">
            <button
              onClick={async (e) => {
                setModalContent(
                  <AddSoalComponent
                    tryoutData={currentTryout}
                    setOpen={setOpen}
                    subtes={currentSubtes}
                    soalPembahasanList={soalPembahasanList}
                    addSoal={addSoal}
                  />
                );
                setOpen(true);
              }}
              disabled={!Object.keys(currentSubtes).length}
              className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-full md:w-max text-white rounded-xl"
            >
              Tambah soal subtes ini
            </button>
          </div>
          <p className="font-content-0 font-bold">Soal</p>
          <div className="w-full border-2 border-abu">
            <CKEditor
              editor={Editor}
              config={{
                toolbar: {
                  items: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "link",
                    "numberedList",
                    "bulletedList",
                    "|",
                    "indent",
                    "outdent",
                    "|",
                    "subscript",
                    "superscript",
                    "imageUpload",
                    "insertTable",
                    "undo",
                    "redo",
                  ],
                },
              }}
              // data={currentSoal.soal}
              onReady={function (editor) {
                // Insert the toolbar before the editable area.
                editor.ui
                  .getEditableElement()
                  .parentElement.insertBefore(
                    editor.ui.view.toolbar.element,
                    editor.ui.getEditableElement()
                  );
                setEditorSoal(editor);
              }}
            />
          </div>
          <p className="font-content-0 font-bold">Pembahasan</p>
          <div className="w-full border-2 border-abu">
            <CKEditor
              editor={Editor}
              config={{
                toolbar: {
                  items: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "link",
                    "numberedList",
                    "bulletedList",
                    "|",
                    "indent",
                    "outdent",
                    "|",
                    "subscript",
                    "superscript",
                    "imageUpload",
                    "insertTable",
                    "undo",
                    "redo",
                  ],
                },
              }}
              onReady={function (editor) {
                // Insert the toolbar before the editable area.
                editor.ui
                  .getEditableElement()
                  .parentElement.insertBefore(
                    editor.ui.view.toolbar.element,
                    editor.ui.getEditableElement()
                  );
                setEditorPembahasan(editor);

                // this.editor = editor;
              }}
            />
          </div>
          <div className="flex w-full items-center justify-start gap-5">
            <div className="flex items-center w-1/3 md:w-36 gap-2 md:gap-3">
              <p className="font-content-1">Kunci Jawaban</p>
            </div>

            <div className="w-2/3 md:w-36 flex border-2 border-black rounded-xl items-center select-container">
              <Select
                placeholder="Kunci"
                styles={{
                  control: () => ({
                    alignItems: "center",
                    border: "none",
                    display: "flex",
                    flexWrap: "wrap",
                    WebkitBoxPack: "justify",
                    justifyContent: "space-between",
                    minHeight: "38px",
                    position: "relative",
                  }),
                }}
                className="w-full border-none outline-none font-content-1"
                onChange={(val) => {
                  if (val.value !== jawabanSoal.value) {
                    setJawabanSoal(val);
                  }
                }}
                options={optionJawabanList}
                value={optionJawabanList.find(
                  (item) => item.value === jawabanSoal.value
                )}
                filterOption={createFilter({ ignoreAccents: false })}
              />
            </div>
          </div>
          <div
            className={`${
              alertText.show ? "flex" : "hidden"
            } p-4 mb-4 text-sm ${
              alertText.success
                ? "text-green-700 bg-green-100"
                : "text-red-700 bg-red-100"
            } rounded-lg`}
            role="alert"
          >
            <svg
              className="inline flex-shrink-0 mr-3 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <div>{alertText.text}</div>
          </div>
          <div className="flex gap-3 justify-start">
            <button
              onClick={async (e) => {
                const button = e.target;
                button.disabled = true;

                let soal = editorSoal.getData();
                soal = $("<div>").text(soal).html();
                let pembahasan = editorPembahasan.getData();
                pembahasan = $("<div>").text(pembahasan).html();

                const data = {
                  id_soal: currentSoal.id_soal,
                  kunci: jawabanSoal.value,
                  soal: soal,
                  pembahasan: pembahasan,
                  id_tryout: currentTryout.id,
                  subtes: currentSubtes.singkatan,
                };
                const res = await editSoal(data);
                button.disabled = false;
                if (res.success) {
                  setSuccessAlert("Berhasil menyimpan perubahan.");
                  setTimeout(() => {
                    hideAlert();
                  }, 5000);
                } else {
                  setErrorAlert(
                    "Gagal menyimpan perubahan. " + res.display_message
                  );
                }
              }}
              className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl"
              disabled={!Object.keys(currentSoal).length}
            >
              Simpan
            </button>
            <button
              onClick={() => {
                setModalContent(
                  <DeleteSoalComponent
                    tryoutData={currentTryout}
                    setOpen={setOpen}
                    soal={currentSoal}
                    soalOptionList={soalOptionList}
                    setCurrentSoal={setCurrentSoal}
                    deleteSoal={deleteSoal}
                  />
                );
                setOpen(true);
              }}
              className="text-center bg-merah text-white rounded-xl px-4 py-1 font-content-0 font-bold w-max"
              disabled={!Object.keys(currentSoal).length}
            >
              Hapus
            </button>
          </div>
        </div>
      </div>
      <Modal {...{ open, setOpen }}>
        <div className="relative text-left w-11/12 md:w-2/3 lg:w-1/2 xl:w-2/5 z-50 flex flex-col text-xl font-bold bg-white rounded-2xl">
          {modalContent}
        </div>
      </Modal>
    </>
  );
};

export default EditSoalLobby;
