import dayjs from "dayjs";
const Footer = () => {
  return (
    <div className="bg-krem w-full flex flex-col gap-10 p-10 items-center">
      <div className="flex flex-col gap-5">
        <div className="h-10 md:h-20 w-auto">
          <img
            className="h-full"
            src={`${process.env.PUBLIC_URL}/images/logo-jd-dark.png`}
            alt="jd"
          />
        </div>
        <div className="flex gap-5 text-black justify-center">
          <a
            href="https://instagram.com/jalurdalam"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-instagram text-3xl hover:text-oren transition duration-300"></i>
          </a>
          <a
            href="https://wa.me/6285713319386"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-whatsapp text-3xl hover:text-oren transition duration-300"></i>
          </a>
        </div>
      </div>
      <p className="font-content-1 text-abu">© Jalur Dalam {dayjs().year()}</p>
    </div>
  );
};

export default Footer;
