/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ImageUploading from "react-images-uploading";
import Select, { createFilter } from "react-select";
import HeaderJD from "../../components/Layout/HeaderJD";
import TryoutContext from "../../context/TryoutContext/TryoutContext";
import Modal from "../../components/Home/Modal";
import { useRef } from "react";
import caraBayar from "../../script/caraBayar";
import { getPendaftaranInfo, getPromoInfo } from "../../script/dateUtils";
import formatPrice from "../../script/formatPrice";
import FormatPrice from "../../components/Layout/FormatPrice";
import Price2Digit from "../../components/Layout/Price2Digit";
import dayjs from "dayjs";

const DaftarTryoutComponent = ({ setOpen, tryoutData }) => {
  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <div className="flex flex-col gap-6 w-full px-10 pb-10">
        <h5 className="font-bold text-center">
          Daftar Try Out <span className="text-oren font-bold">Premium</span>
        </h5>
        <div className="flex flex-col gap-2 w-full">
          <p className="font-content-1 w-full text-center">
            Dengan mendaftar Try Out premium, kamu bisa mendapatkan berbagai
            macam fasilitas dari kami.
          </p>
        </div>
        <div className="flex flex-col w-100 text-center gap-3">
          <div className="flex flex-row w-100">
            <div className="flex w-1/4 flex-col items-center justify-center">
              <p className="font-subtitle font-bold">Premium</p>
              <h6 className="font-content-0 font-bold line-through">IDR 25K</h6>
              <h6 className="font-subtitle font-bold">
                IDR <Price2Digit price={tryoutData.harga} />
              </h6>
            </div>
            <div className="flex w-2/4"></div>
            <div className="flex w-1/4 flex-col items-center justify-center">
              <p className="font-subtitle font-bold">Gratis</p>
              <h6 className="font-subtitle font-bold">IDR 0K</h6>
            </div>
          </div>

          <div className="flex flex-row w-100">
            <div className="flex w-1/4 items-center justify-center">
              <i className="fa-solid fa-star text-oren"></i>
            </div>
            <div className="flex w-2/4 font-content-0 justify-center">
              Try Out CBT dengan penilaian IRT
            </div>
            <div className="flex w-1/4 items-center justify-center">
              <i className="fa-solid fa-star text-oren"></i>
            </div>
          </div>
          <div className="flex flex-row w-100">
            <div className="flex w-1/4 items-center justify-center">
              <i className="fa-solid fa-star text-oren"></i>
            </div>
            <div className="flex w-2/4 font-content-0 justify-center">
              Sertifikat nilai hasil Try Out
            </div>
            <div className="flex w-1/4 items-center justify-center">
              <i className="fa-solid fa-star text-oren"></i>
            </div>
          </div>
          <div className="flex flex-row w-100">
            <div className="flex w-1/4 items-center justify-center">
              <i className="fa-solid fa-star text-oren"></i>
            </div>
            <div className="flex w-2/4 font-content-0 justify-center">
              Tanpa syarat share
            </div>
            <div className="flex w-1/4 items-center justify-center">
              <i
                className="fa-solid fa-star text-oren"
                style={{
                  color: "transparent",
                  WebkitTextStrokeWidth: "2px",
                  WebkitTextStrokeColor: "black",
                }}
              ></i>
            </div>
          </div>
          <div className="flex flex-row w-100">
            <div className="flex w-1/4 items-center justify-center">
              <i className="fa-solid fa-star text-oren"></i>
            </div>
            <div className="flex w-2/4 font-content-0 justify-center">
              Akses ranking ribuan peserta Try Out
            </div>
            <div className="flex w-1/4 items-center justify-center">
              <i
                className="fa-solid fa-star text-oren"
                style={{
                  color: "transparent",
                  WebkitTextStrokeWidth: "2px",
                  WebkitTextStrokeColor: "black",
                }}
              ></i>
            </div>
          </div>
          <div className="flex flex-row w-100">
            <div className="flex w-1/4 items-center justify-center">
              <i className="fa-solid fa-star text-oren"></i>
            </div>
            <div className="flex w-2/4 font-content-0 justify-center">
              Akses pembahasan soal
            </div>
            <div className="flex w-1/4 items-center justify-center">
              <i
                className="fa-solid fa-star text-oren"
                style={{
                  color: "transparent",
                  WebkitTextStrokeWidth: "2px",
                  WebkitTextStrokeColor: "black",
                }}
              ></i>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const TryoutRegister = () => {
  const { tryoutId } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const tarif = state ? state.tarif : false;
  const { tryoutList, resetTimer, daftarTryout, checkPromo } =
    useContext(TryoutContext);
  const [currentTryout, setCurrentTryout] = useState({});
  const [timerState, setTimerState] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [setelahDaftar, setSetelahDaftar] = useState(false);
  const [tryoutForm, setTryoutForm] = useState({
    tryout: "",
    tipe: 0,
    premium: false,
    // premium: (locationData.state && locationData.state.tarif) || false,
    buktiFollow: null,
    buktiKomen: null,
    buktiShare: null,
    buktiBayar: null,
    payment: "",
  });

  useEffect(() => {
    if (tryoutList && tryoutList.length) {
      const tryout = tryoutList.find(
        (item) => String(item.id) === String(tryoutId)
      );
      if (tryout) {
        const currentTimestamp = dayjs().valueOf();
        const { openPendaftaranDateTimestamp, endPendaftaranDateTimestamp } =
          getPendaftaranInfo(tryout);
        if (parseInt(tryout.tipe) === 3) {
          navigate("/tryout");
        } else if (
          tryout.waktu_verifikasi ||
          currentTimestamp < openPendaftaranDateTimestamp ||
          currentTimestamp > endPendaftaranDateTimestamp
        ) {
          if (!setelahDaftar) navigate("/tryout");
        } else if (tryout.timer === null) {
          resetTimer({
            id_tryout: tryout.id,
            new_data: true,
          });
        } else {
          const currentTimestamp = dayjs().valueOf();
          window.timerLeft = tryout.timer - currentTimestamp;
          window.timerInterval = setInterval(() => {
            const hour = Math.floor(window.timerLeft / 1000 / 3600);
            const minute = Math.floor(
              (window.timerLeft % (1000 * 3600)) / 1000 / 60
            );
            const second = Math.floor((window.timerLeft % (1000 * 60)) / 1000);

            if (window.timerLeft > 0) {
              setTimerState({
                hours: hour < 10 ? "0" + hour : hour,
                minutes: minute < 10 ? "0" + minute : minute,
                seconds: second < 10 ? "0" + second : second,
              });
            } else {
              resetTimer({
                id_tryout: tryout.id,
                new_data: false,
              });
              clearInterval(window.timerInterval);
            }

            window.timerLeft -= 1000;
          }, 1000);
          setCurrentTryout(tryout);
          setTryoutForm({
            ...tryoutForm,
            tryout: tryout.id,
            premium: tarif || false,
            tipe: parseInt(tryout.tipe),
          });
        }
        setSetelahDaftar(true);
      } else {
        navigate("/tryout");
      }
    }
  }, [tryoutList]);

  const [alertText, setAlertText] = useState({
    show: false,
    text: "",
    success: true,
  });

  const setSuccessAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: true,
    });
  };
  const setErrorAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: false,
    });
  };
  const hideAlert = () => {
    setAlertText({
      show: false,
      text: alertText.message,
      success: alertText.success,
    });
  };

  const createHandlerFunction = (id) => {
    return function (imageList, addUpdateIndex) {
      // data for submit
      if (imageList.length) {
        setTryoutForm({
          ...tryoutForm,
          [id]: imageList[addUpdateIndex[0]],
        });
      } else {
        setTryoutForm({
          ...tryoutForm,
          [id]: null,
        });
      }
    };
  };

  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState(<></>);

  const textareaRef = useRef(<></>);
  useEffect(() => {
    if (currentTryout && currentTryout.syarat_share) {
      if (textareaRef.current)
        textareaRef.current.value = currentTryout.syarat_share.replace(
          /(\\r)|(\\n)/g,
          "\n"
        );
    }

    if (currentTryout && parseInt(currentTryout.tipe) === 1) {
      if (!tryoutForm.premium) {
        setTryoutForm({
          ...tryoutForm,
          premium: true,
          tipe: parseInt(currentTryout.tipe),
        });
      }
    } else if (currentTryout && parseInt(currentTryout.tipe) === 2) {
      if (tryoutForm.premium) {
        setTryoutForm({
          ...tryoutForm,
          premium: false,
          tipe: parseInt(currentTryout.tipe),
        });
      }
    }
  }, [currentTryout, tryoutForm.premium]);

  const [expandedMethod, setExpandedMethod] = useState("");
  const paymentOption = caraBayar.map((item) => ({
    value: item.value,
    label: item.name,
  }));
  const [paymentOptionPromo, setPaymentOptionPromo] = useState([]);

  // promo
  const [alertText2, setAlertText2] = useState({
    show: false,
    text: "",
    success: true,
  });

  const setSuccessAlert2 = (message) => {
    setAlertText2({
      show: true,
      text: message,
      success: true,
    });
  };
  const setErrorAlert2 = (message) => {
    setAlertText2({
      show: true,
      text: message,
      success: false,
    });
  };
  const hideAlert2 = () => {
    setAlertText2({
      show: false,
      text: alertText2.message,
      success: alertText2.success,
    });
  };
  const [kodePromo, setKodePromo] = useState("");
  const [currentPromo, setCurrentPromo] = useState(null);
  const submitPromoRef = useRef(<></>);

  const [caraBayarPromo, setCaraBayarPromo] = useState([]);

  useEffect(() => {
    if (Object.keys(currentTryout).length)
      if (parseInt(currentTryout.tipe) === 1)
        setTryoutForm({ ...tryoutForm, payment: null });
  }, [currentPromo]);

  return (
    <>
      <div className="flex flex-col bg-white p-10 justify-start m-0 w-full">
        <HeaderJD title={`Pendaftaran ${currentTryout.nama_tryout || ""}`} />

        {/* Tryout kamu */}
        <div className="flex flex-col w-full md:w-full-10 mt-10">
          <p className="font-subtitle font-bold">
            Cukup isi formulir di bawah ini, kamu udah bisa ikutan Try Out Jalur
            Dalam!
          </p>
          <div className="flex flex-col gap-10 w-full md:w-3/5 mt-5">
            {parseInt(currentTryout.tipe) !== 1 &&
            parseInt(currentTryout.tipe) !== 2 ? (
              <div className="flex w-full items-center justify-between gap-5">
                <div className="flex items-center w-1/3 gap-2 md:gap-3">
                  <p className="font-content-1">
                    Tarif Try Out &nbsp;&nbsp;
                    <i
                      onClick={() => {
                        setModalContent(
                          <DaftarTryoutComponent
                            tryoutData={currentTryout}
                            setOpen={setOpen}
                          />
                        );
                        setOpen(true);
                      }}
                      className="fa-solid fa-circle-question text-xl text-abu hover:cursor-pointer"
                    ></i>
                  </p>
                </div>

                <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
                  <Select
                    placeholder="Pilih Tarif Try Out"
                    styles={{
                      control: () => ({
                        alignItems: "center",
                        border: "none",
                        display: "flex",
                        flexWrap: "wrap",
                        WebkitBoxPack: "justify",
                        justifyContent: "space-between",
                        minHeight: "38px",
                        position: "relative",
                      }),
                    }}
                    className="w-full border-none outline-none font-content-1"
                    onChange={(val) => {
                      if (val.value !== tryoutForm.premium) {
                        if (val.value)
                          setTryoutForm({ ...tryoutForm, premium: val.value });
                        else
                          setTryoutForm({
                            ...tryoutForm,
                            premium: val.value,
                            payment: "Gratis",
                          });
                      }
                    }}
                    options={
                      parseInt(currentTryout.tipe) === 1
                        ? [{ value: true, label: "Premium" }]
                        : [
                            { value: false, label: "Gratis" },
                            { value: true, label: "Premium" },
                          ]
                    }
                    filterOption={createFilter({ ignoreAccents: false })}
                    value={
                      tryoutForm.premium
                        ? { value: true, label: "Premium" }
                        : { value: false, label: "Gratis" }
                    }
                  />
                </div>
              </div>
            ) : null}

            <div
              className={`flex p-4 mb-4 text-sm "text-red-700 bg-red-100 rounded-lg`}
              role="alert"
            >
              <svg
                className="inline flex-shrink-0 mr-3 w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <div>
                Pendaftaran dapat ditutup sewaktu-waktu jika kuota sudah
                terpenuhi. Segera selesaikan pendaftaran sebelum{" :"}
                <br />
                <span className="text-merah font-bold">
                  {timerState.hours} : {timerState.minutes} :{" "}
                  {timerState.seconds}
                </span>
              </div>
            </div>

            {tryoutForm.premium ? (
              <>
                <form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    const button = submitPromoRef.current;
                    button.disabled = true;
                    hideAlert2();
                    const res = await checkPromo({
                      id_tryout: currentTryout.id,
                      kode_promo: kodePromo,
                    });
                    if (res.success) {
                      const promo = res.data.promo;
                      const promoDate = getPromoInfo(promo);
                      const newCaraBayarPromo = res.data.payment_list.map(
                        (item) => ({
                          value: item.nama_metode,
                          name: item.nama_metode,
                          no: item.nomor_metode,
                          pemilik: item.nama_pemilik,
                        })
                      );
                      setPaymentOptionPromo(
                        newCaraBayarPromo.map((item) => ({
                          value: item.value,
                          label: item.value,
                        }))
                      );
                      const now = dayjs().valueOf();
                      if (now <= promoDate.endPromoDateTimestamp) {
                        setCurrentPromo(promo);
                        setCaraBayarPromo(newCaraBayarPromo);
                        const diskon =
                          parseInt(currentTryout.harga) -
                          parseInt(promo.profit_jd) -
                          parseInt(promo.profit_partner);
                        setSuccessAlert2(
                          `Kode voucher berhasil dipakai.${
                            diskon > 0
                              ? ` Kamu mendapat diskon tambahan sebesar ${formatPrice(
                                  diskon
                                )}`
                              : ``
                          } `
                        );
                      } else {
                        setCurrentPromo(null);
                        setErrorAlert2("Kode voucher sudah tidak berlaku.");
                      }
                    } else {
                      setCurrentPromo(null);
                      setErrorAlert2("Kode voucher tidak valid.");
                    }
                    button.disabled = false;
                  }}
                  className="flex w-full flex-col md:flex-row gap-4"
                >
                  <div className="flex w-full md:w-2/3 items-center justify-between gap-5">
                    <div className="flex items-center w-1/2 gap-2 md:gap-3">
                      <p className="font-content-1">Punya Kode Voucher?</p>
                    </div>

                    <div className="flex w-2/3 md:w-1/2 border-2 border-black rounded-xl items-center">
                      <input
                        className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                        type="text"
                        value={kodePromo}
                        onChange={(e) => {
                          if (String(e.target.value).match(/^[a-zA-Z0-9]*$/)) {
                            setKodePromo(e.target.value);
                          }
                        }}
                        required
                        disabled={!!currentPromo}
                      />
                    </div>
                  </div>
                  <div className="flex w-full md:w-auto items-center justify-start">
                    {currentPromo === null ? (
                      <button
                        ref={submitPromoRef}
                        type="submit"
                        className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-full text-white rounded-xl m-auto"
                      >
                        Pakai
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          hideAlert2();
                          setKodePromo("");
                          setCurrentPromo(null);
                        }}
                        type="button"
                        className="bg-merah font-content-0 font-bold flex items-center justify-center px-4 py-2 w-full text-white rounded-xl m-auto"
                      >
                        Hapus
                      </button>
                    )}
                  </div>
                </form>
                <div
                  className={`${
                    alertText2.show ? "flex" : "hidden"
                  } p-4 mb-4 text-sm ${
                    alertText2.success
                      ? "text-green-700 bg-green-100"
                      : "text-red-700 bg-red-100"
                  } rounded-lg`}
                  role="alert"
                >
                  <svg
                    className="inline flex-shrink-0 mr-3 w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <div>{alertText2.text}</div>
                </div>
                <div className="flex flex-col justify-center bg-white shadow-lg rounded-2xl p-7 gap-3 w-full">
                  <div className="flex items-center gap-5">
                    <p className="font-content-0 font-bold">Cara Daftar</p>
                  </div>
                  <ol style={{ listStyle: "auto" }} className="pl-3">
                    {
                      currentPromo === null ? (
                        <>
                          <li className="font-content-1">
                            Lakukan pembayaran dengan salah satu metode berikut
                          </li>
                          <div className="flex flex-col gap-3 pl-1 w-4/5 mb-4">
                            {caraBayar.map((item, index) => (
                              <div
                                key={index}
                                onClick={() => setExpandedMethod(item.value)}
                                className={`relative overflow-hidden cursor-pointer flex flex-col justify-center bg-white shadow-lg rounded-2xl p-3 ${
                                  expandedMethod === item.value ? "gap-2" : ""
                                }`}
                              >
                                <div className="flex justify-between items-center">
                                  <p className="font-content-1 font-bold">
                                    {item.name}
                                  </p>
                                  <i className="fa-solid fa-angle-right text-abu"></i>
                                </div>
                                <div
                                  className={`${
                                    expandedMethod === item.value
                                      ? "max-h-32 animate-[fadeend_.5s_ease]"
                                      : "max-h-0 opacity-0  animate-[fadeendclose_.5s_ease]"
                                  } flex flex-col gap-1`}
                                >
                                  <p className="font-content-1">
                                    Nomor : {item.no}
                                  </p>
                                  <p className="font-content-1">
                                    Nama pemilik : {item.pemilik}
                                  </p>
                                  <p className="font-content-1">
                                    Nominal :{" "}
                                    {
                                      <FormatPrice
                                        price={currentTryout.harga}
                                      />
                                    }
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                          <li className="font-content-1">
                            Screenshot bukti pembayaran dan unggah pada formulir
                            di bawah
                          </li>
                        </>
                      ) : (
                        <>
                          <li className="font-content-1">
                            Lakukan pembayaran dengan salah satu metode berikut
                          </li>
                          <div className="flex flex-col gap-3 pl-1 w-4/5 mb-4">
                            {caraBayarPromo.map((item, index) => (
                              <div
                                key={index}
                                onClick={() => setExpandedMethod(item.value)}
                                className={`cursor-pointer flex flex-col justify-center bg-white shadow-lg rounded-2xl p-3 ${
                                  expandedMethod === item.value ? "gap-2" : ""
                                }`}
                              >
                                <div className="flex justify-between items-center">
                                  <p className="font-content-1 font-bold">
                                    {item.name}
                                  </p>
                                  <i className="fa-solid fa-angle-right text-abu"></i>
                                </div>
                                <div
                                  className={`${
                                    expandedMethod === item.value
                                      ? "max-h-32 animate-[fadeend_.5s_ease]"
                                      : "max-h-0 opacity-0  animate-[fadeendclose_.5s_ease]"
                                  } flex flex-col gap-1`}
                                >
                                  <p className="font-content-1">
                                    Nomor : {item.no}
                                  </p>
                                  <p className="font-content-1">
                                    Nama pemilik : {item.pemilik}
                                  </p>
                                  <p className="font-content-1">
                                    Nominal :{" "}
                                    <FormatPrice
                                      price={
                                        parseInt(currentPromo.profit_jd) +
                                        parseInt(currentPromo.profit_partner)
                                      }
                                    />
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                          <li className="font-content-1">
                            Screenshot bukti pembayaran dan unggah pada formulir
                            di bawah
                          </li>
                        </>
                      )
                      // (
                      //   <>
                      //     <li className="font-content-1">
                      //       Lakukan pembayaran secara langsung ke mitra pemilik
                      //       kode voucher dengan nominal{" "}
                      //       <span className="text-oren font-bold">
                      //         <FormatPrice
                      //           price={
                      //             parseInt(currentPromo.profit_jd) +
                      //             parseInt(currentPromo.profit_partner)
                      //           }
                      //         />
                      //       </span>
                      //     </li>
                      //     <li className="font-content-1">
                      //       Foto bukti pembayaran dan unggah pada formulir di
                      //       bawah
                      //     </li>
                      //   </>
                      // )
                    }
                  </ol>
                </div>
                <div className="flex w-full items-center justify-between gap-5">
                  <div className="flex items-center w-1/3 gap-2 md:gap-3">
                    <p className="font-content-1">Metode Pembayaran</p>
                  </div>

                  <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
                    <Select
                      placeholder="Pilih metode pembayaran"
                      styles={{
                        control: () => ({
                          alignItems: "center",
                          border: "none",
                          display: "flex",
                          flexWrap: "wrap",
                          WebkitBoxPack: "justify",
                          justifyContent: "space-between",
                          minHeight: "38px",
                          position: "relative",
                        }),
                      }}
                      className="w-full border-none outline-none font-content-1"
                      onChange={(val) => {
                        if (val.value !== tryoutForm.payment) {
                          setTryoutForm({ ...tryoutForm, payment: val.value });
                        }
                      }}
                      options={
                        currentPromo ? paymentOptionPromo : paymentOption
                      }
                      filterOption={createFilter({ ignoreAccents: false })}
                      value={
                        currentPromo
                          ? paymentOptionPromo.find(
                              (item) => item.value === tryoutForm.payment
                            ) || {
                              value: null,
                              label: "Pilih metode pembayaran",
                            }
                          : paymentOption.find(
                              (item) => item.value === tryoutForm.payment
                            ) || {
                              value: null,
                              label: "Pilih metode pembayaran",
                            }
                      }
                    />
                  </div>
                </div>

                <div className="flex w-full items-center justify-between gap-5">
                  <div className="flex items-center w-1/3 gap-2 md:gap-3">
                    <p className="font-content-1">Bukti Pembayaran</p>
                  </div>

                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                    className={`flex flex-col gap-3 w-2/3 ${
                      tryoutForm.buktiBayar ? "" : "cursor-pointer"
                    }`}
                  >
                    <div className="w-full flex border-2 border-black rounded-xl items-center">
                      <ImageUploading
                        multiple
                        value={
                          tryoutForm.buktiBayar ? [tryoutForm.buktiBayar] : []
                        }
                        onChange={createHandlerFunction("buktiBayar")}
                        maxNumber={2}
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageRemoveAll,
                          isDragging,
                          dragProps,
                        }) => {
                          return (
                            // write your building UI
                            <div className="w-full p-2">
                              {!imageList.length ? (
                                <div
                                  className="rounded-xl flex flex-col text-center items-center justify-center w-full py-12 font-content-1"
                                  style={
                                    isDragging
                                      ? { border: "1px solid black" }
                                      : null
                                  }
                                  onClick={onImageUpload}
                                  {...dragProps}
                                >
                                  <i className="fa-solid fa-upload text-3xl"></i>
                                  Klik atau tarik gambar ke sini (max 2MB)
                                </div>
                              ) : (
                                imageList.map((image, index) => (
                                  <div
                                    key={index}
                                    className="flex flex-col items-center gap-2"
                                  >
                                    <img src={image.dataURL} alt="profile" />
                                    <div>
                                      <button
                                        className="text-center bg-merah text-white rounded-xl flex-grow px-4 py-1 font-content-0 font-bold"
                                        onClick={() => onImageRemoveAll()}
                                      >
                                        Hapus
                                      </button>
                                    </div>
                                  </div>
                                ))
                              )}
                            </div>
                          );
                        }}
                      </ImageUploading>
                    </div>
                  </form>
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-col justify-center bg-white shadow-lg rounded-2xl p-7 gap-3 w-full">
                  <div className="flex items-center gap-5">
                    <p className="font-content-0 font-bold">Cara Daftar</p>
                  </div>
                  <ol style={{ listStyle: "auto" }} className="pl-3">
                    <li className="font-content-1">
                      Follow akun Instagram{" "}
                      <a
                        href="https://instagram.com/jalurdalam"
                        target="_blank"
                        rel="noreferrer"
                        className="text-oren hover:text-black"
                      >
                        @jalurdalam
                      </a>
                      {parseInt(currentTryout.tipe) === 2 ? (
                        <>
                          {" "}
                          dan{" "}
                          <a
                            href={`https://instagram.com/${currentTryout.collab_ig}`}
                            target="_blank"
                            rel="noreferrer"
                            className="text-oren hover:text-black"
                          >
                            @{currentTryout.collab_ig}
                          </a>
                        </>
                      ) : null}
                    </li>
                    <li className="font-content-1">
                      {parseInt(currentTryout.tipe) === 2
                        ? `Tulis komentar "Yuk ikutan Tryout Gratis" dan tag minimal 3 teman di `
                        : "Tag 10 teman di komentar "}
                      <a
                        href={
                          currentTryout.link_post ||
                          "https://instagram.com/jalurdalam"
                        }
                        target="_blank"
                        rel="noreferrer"
                        className="text-oren hover:text-black"
                      >
                        postingan Try Out ini
                      </a>
                    </li>
                    {parseInt(currentTryout.tipe) !== 2 ? (
                      <>
                        <li className="font-content-1 mb-4">
                          Salin teks di bawah dan kirim ke 5 grup belajar kamu
                        </li>
                        <div className="flex flex-col gap-3 pl-1">
                          <textarea
                            readOnly={true}
                            rows="6"
                            className="border-2 border-abu p-1 w-full"
                            style={{ whiteSpace: "pre-line" }}
                            ref={textareaRef}
                          ></textarea>

                          {navigator.share && (
                            <p className="font-content-1 flex items-center gap-2">
                              atau klik bagikan ke Whatsapp{" "}
                              <button
                                type="submit"
                                className="rounded-xl bg-oren text-white font-content-0 font-bold px-8 py-2 mb-2"
                                onClick={() => {
                                  navigator.share({
                                    text: currentTryout.syarat_share.replace(
                                      /(\\r)|(\\n)/g,
                                      "\n"
                                    ),
                                  });
                                }}
                              >
                                Bagikan
                              </button>
                            </p>
                          )}
                        </div>
                      </>
                    ) : null}

                    <li className="font-content-1">
                      Screenshot semua persyaratan dan unggah pada formulir di
                      bawah
                    </li>
                  </ol>
                </div>
                <div className="flex w-full items-center justify-between gap-5">
                  <div className="flex items-center w-1/3 gap-2 md:gap-3">
                    <p className="font-content-1">
                      Bukti Follow Instagram{" "}
                      <a
                        href="https://instagram.com/jalurdalam"
                        target="_blank"
                        rel="noreferrer"
                        className="text-oren hover:text-black"
                      >
                        @jalurdalam
                      </a>
                      {parseInt(currentTryout.tipe) === 2 ? (
                        <>
                          {" "}
                          dan{" "}
                          <a
                            href={`https://instagram.com/${currentTryout.collab_ig}`}
                            target="_blank"
                            rel="noreferrer"
                            className="text-oren hover:text-black"
                          >
                            @{currentTryout.collab_ig}
                          </a>
                        </>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>

                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                    className="flex flex-col gap-3 w-2/3"
                  >
                    <div className="w-full flex border-2 border-black rounded-xl items-center">
                      <ImageUploading
                        multiple
                        value={
                          tryoutForm.buktiFollow ? [tryoutForm.buktiFollow] : []
                        }
                        onChange={createHandlerFunction("buktiFollow")}
                        maxNumber={2}
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageRemoveAll,
                          isDragging,
                          dragProps,
                        }) => {
                          return (
                            // write your building UI
                            <div className="w-full p-2">
                              {!imageList.length ? (
                                <div
                                  className="rounded-xl flex flex-col text-center items-center justify-center w-full py-12 font-content-1"
                                  style={
                                    isDragging
                                      ? { border: "1px solid black" }
                                      : null
                                  }
                                  onClick={onImageUpload}
                                  {...dragProps}
                                >
                                  <i className="fa-solid fa-upload text-3xl"></i>
                                  Klik atau tarik gambar ke sini (max 2MB)
                                </div>
                              ) : (
                                imageList.map((image, index) => (
                                  <div
                                    key={index}
                                    className="flex flex-col items-center gap-2"
                                  >
                                    <img src={image.dataURL} alt="profile" />
                                    <div>
                                      <button
                                        className="text-center bg-merah text-white rounded-xl flex-grow px-4 py-1 font-content-0 font-bold"
                                        onClick={() => onImageRemoveAll()}
                                      >
                                        Hapus
                                      </button>
                                    </div>
                                  </div>
                                ))
                              )}
                            </div>
                          );
                        }}
                      </ImageUploading>
                    </div>
                  </form>
                </div>

                <div className="flex w-full items-center justify-between gap-5">
                  <div className="flex items-center w-1/3 gap-2 md:gap-3">
                    <p className="font-content-1">
                      Bukti Komentar dan Tag{" "}
                      {parseInt(currentTryout.tipe) === 2 ? "" : "10"} Teman
                    </p>
                  </div>

                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                    className="flex flex-col gap-3 w-2/3"
                  >
                    <div className="w-full flex border-2 border-black rounded-xl items-center">
                      <ImageUploading
                        multiple
                        value={
                          tryoutForm.buktiKomen ? [tryoutForm.buktiKomen] : []
                        }
                        onChange={createHandlerFunction("buktiKomen")}
                        maxNumber={2}
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageRemoveAll,
                          isDragging,
                          dragProps,
                        }) => {
                          return (
                            // write your building UI
                            <div className="w-full p-2">
                              {!imageList.length ? (
                                <div
                                  className="rounded-xl flex flex-col text-center items-center justify-center w-full py-12 font-content-1"
                                  style={
                                    isDragging
                                      ? { border: "1px solid black" }
                                      : null
                                  }
                                  onClick={onImageUpload}
                                  {...dragProps}
                                >
                                  <i className="fa-solid fa-upload text-3xl"></i>
                                  Klik atau tarik gambar ke sini (max 2MB)
                                </div>
                              ) : (
                                imageList.map((image, index) => (
                                  <div
                                    key={index}
                                    className="flex flex-col items-center gap-2"
                                  >
                                    <img src={image.dataURL} alt="profile" />
                                    <div>
                                      <button
                                        className="text-center bg-merah text-white rounded-xl flex-grow px-4 py-1 font-content-0 font-bold"
                                        onClick={() => onImageRemoveAll()}
                                      >
                                        Hapus
                                      </button>
                                    </div>
                                  </div>
                                ))
                              )}
                            </div>
                          );
                        }}
                      </ImageUploading>
                    </div>
                  </form>
                </div>

                {parseInt(currentTryout.tipe) !== 2 ? (
                  <div className="flex w-full items-center justify-between gap-5">
                    <div className="flex items-center w-1/3 gap-2 md:gap-3">
                      <p className="font-content-1">
                        Bukti Share Info ke 5 Grup
                      </p>
                    </div>

                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                      className="flex flex-col gap-3 w-2/3"
                    >
                      <div className="w-full flex border-2 border-black rounded-xl items-center">
                        <ImageUploading
                          multiple
                          value={
                            tryoutForm.buktiShare ? [tryoutForm.buktiShare] : []
                          }
                          onChange={createHandlerFunction("buktiShare")}
                          maxNumber={2}
                        >
                          {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            isDragging,
                            dragProps,
                          }) => {
                            return (
                              // write your building UI
                              <div className="w-full p-2">
                                {!imageList.length ? (
                                  <div
                                    className="rounded-xl flex flex-col text-center items-center justify-center w-full py-12 font-content-1"
                                    style={
                                      isDragging
                                        ? { border: "1px solid black" }
                                        : null
                                    }
                                    onClick={onImageUpload}
                                    {...dragProps}
                                  >
                                    <i className="fa-solid fa-upload text-3xl"></i>
                                    Klik atau tarik gambar ke sini (max 2MB)
                                  </div>
                                ) : (
                                  imageList.map((image, index) => (
                                    <div
                                      key={index}
                                      className="flex flex-col items-center gap-2"
                                    >
                                      <img src={image.dataURL} alt="profile" />
                                      <div>
                                        <button
                                          className="text-center bg-merah text-white rounded-xl flex-grow px-4 py-1 font-content-0 font-bold"
                                          onClick={() => onImageRemoveAll()}
                                        >
                                          Hapus
                                        </button>
                                      </div>
                                    </div>
                                  ))
                                )}
                              </div>
                            );
                          }}
                        </ImageUploading>
                      </div>
                    </form>
                  </div>
                ) : null}
              </>
            )}

            <div
              className={`${
                alertText.show ? "flex" : "hidden"
              } p-4 mb-4 text-sm ${
                alertText.success
                  ? "text-green-700 bg-green-100"
                  : "text-red-700 bg-red-100"
              } rounded-lg`}
              role="alert"
            >
              <svg
                className="inline flex-shrink-0 mr-3 w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <div>{alertText.text}</div>
            </div>

            <button
              type="button"
              className="rounded-xl bg-oren text-white font-content-0 font-bold px-8 py-2"
              onClick={async (e) => {
                const button = e.target;
                button.disabled = true;
                hideAlert();
                if (tryoutForm.premium) {
                  if (!tryoutForm.payment) {
                    setErrorAlert("Metode pembayaran wajib diisi.");
                    button.disabled = false;
                  } else if (!tryoutForm.buktiBayar) {
                    setErrorAlert("Bukti pembayaran wajib diisi.");
                    button.disabled = false;
                  } else if (tryoutForm.buktiBayar.file.size > 2000000) {
                    setErrorAlert(
                      "Ukuran bukti pembayaran harus kurang dari 2MB!"
                    );
                    button.disabled = false;
                  } else {
                    const res = await daftarTryout({
                      ...tryoutForm,
                      buktiBayar: tryoutForm.buktiBayar.file,
                      id_partnership: currentPromo ? currentPromo.id : null,
                    });
                    if (res.success) {
                      if (window.timerInterval)
                        clearInterval(window.timerInterval);
                      setSuccessAlert(
                        "Pendaftaran Try Out berhasil. Halaman akan dialihkan dalam 5 detik..."
                      );
                      setTimeout(() => {
                        navigate("/tryout");
                      }, 5000);
                    } else {
                      setErrorAlert(
                        res.display_message ||
                          "Terjadi kesalahan, coba kembali nanti"
                      );
                      button.disabled = false;
                    }
                  }
                } else {
                  let error = false;
                  if (
                    !tryoutForm.buktiFollow ||
                    !tryoutForm.buktiKomen ||
                    (!tryoutForm.buktiShare &&
                      parseInt(currentTryout.tipe) !== 2)
                  ) {
                    setErrorAlert("Semua bukti harus diisi.");
                    error = true;
                    button.disabled = false;
                  } else if (tryoutForm.buktiFollow.file.size > 2000000) {
                    setErrorAlert("Ukuran bukti follow harus kurang dari 2MB!");
                    error = true;
                    button.disabled = false;
                  } else if (tryoutForm.buktiKomen.file.size > 2000000) {
                    setErrorAlert(
                      "Ukuran bukti komentar harus kurang dari 2MB!"
                    );
                    error = true;
                    button.disabled = false;
                  } else if (parseInt(currentTryout.tipe) !== 2) {
                    if (tryoutForm.buktiShare.file.size > 2000000) {
                      setErrorAlert(
                        "Ukuran bukti share harus kurang dari 2MB!"
                      );
                      error = true;
                      button.disabled = false;
                    }
                  }
                  if (!error) {
                    let res = {};
                    if (parseInt(currentTryout.tipe) === 2) {
                      res = await daftarTryout({
                        ...tryoutForm,
                        buktiFollow: tryoutForm.buktiFollow.file,
                        buktiKomen: tryoutForm.buktiKomen.file,
                        buktiShare: {},
                      });
                    } else {
                      res = await daftarTryout({
                        ...tryoutForm,
                        buktiFollow: tryoutForm.buktiFollow.file,
                        buktiKomen: tryoutForm.buktiKomen.file,
                        buktiShare: tryoutForm.buktiShare.file,
                      });
                    }

                    if (res.success) {
                      if (window.timerInterval)
                        clearInterval(window.timerInterval);
                      setSuccessAlert(
                        "Pendaftaran Try Out berhasil. Halaman akan dialihkan dalam 5 detik..."
                      );
                      setTimeout(() => {
                        navigate("/tryout");
                      }, 5000);
                    } else {
                      setErrorAlert(
                        res.display_message ||
                          "Terjadi kesalahan, coba kembali nanti"
                      );
                      button.disabled = false;
                    }
                  }
                }
              }}
            >
              Daftar Try Out
            </button>
          </div>
        </div>
      </div>
      <Modal {...{ open, setOpen }}>
        <div className="relative text-left w-11/12 md:w-2/3 lg:w-1/2 xl:w-2/5 z-50 flex flex-col text-xl font-bold bg-white rounded-2xl">
          {modalContent}
        </div>
      </Modal>
    </>
  );
};

export default TryoutRegister;
