const PartnerReducer = (state, { type, payload }) => {
  switch (type) {
    default:
      return {
        ...state,
        ...payload,
      };
  }
};

export default PartnerReducer;
