import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getAgreementData } from "../script/agreementData";
import Footer from "../components/Home/Footer";

const Perjanjian = () => {
  const { perjanjianId } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  useEffect(() => {
    const agreementData = getAgreementData(perjanjianId);
    if (!agreementData) {
      navigate("/");
    } else {
      setData(agreementData);
    }
  }, []);
  return (
    <>
      <div className="flex flex-col bg-white items-center p-0 m-0 agreement-container">
        {/* Navbar */}
        <div className="w-full p-7 md:p-10 flex justify-between items-center">
          <div className="h-7 md:h-12 w-auto">
            <img
              className="h-full"
              src={`${process.env.PUBLIC_URL}/images/logo-jd-dark.png`}
              alt="jd"
            />
          </div>
          <div className="flex gap-16 items-center">
            <Link
              to="/"
              className="rounded-xl bg-oren text-white font-content-0 font-bold px-8 py-2"
            >
              Home
            </Link>
          </div>
        </div>
        {!data ? null : (
          <div
            className="flex flex-col gap-6 w-full px-8 md:px-10 lg:px-20 pb-20"
            style={{
              background:
                "linear-gradient(180deg, rgba(255, 236, 201, 0.0555556) 0%, #FFECC9 53.12%, rgba(255, 236, 201, 0) 100%)",
            }}
          >
            <div className="flex flex-col gap-1 text-center w-full mb-10">
              <h6 className="font-bold">{data.title}</h6>
              <p className="font-subtitle font-bold">
                Tanggal berlaku: {data.start_date}
              </p>
              <p className="font-subtitle font-bold">
                Pihak Pertama: Jalur Dalam
              </p>
              <p className="font-subtitle font-bold">
                Pihak Kedua: {data.party_2}
              </p>
            </div>
            <p>
              Kedua belah pihak setuju untuk melakukan perjanjian kerja sama
              sebagai berikut:
            </p>

            <div className="w-full flex flex-col items-start gap-3 text-justify pl-4">
              {data.section_list?.map((item, index) => (
                <div
                  key={index}
                  className="w-full flex flex-col items-start gap-3 text-justify"
                >
                  <p className="font-bold">{item.title}</p>
                  <p>{item.content}</p>
                </div>
              ))}
            </div>
            <p>{data.footer}</p>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
};

export default Perjanjian;
