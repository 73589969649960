/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import HeaderJD from "../../components/Layout/HeaderJD";
import TryoutContext from "../../context/TryoutContext/TryoutContext";
import { getPengumumanInfo } from "../../script/dateUtils";
import dayjs from "dayjs";

const PembahasanLobby = () => {
  const { tryoutId } = useParams();
  const navigate = useNavigate();
  const { tryoutList, bundleList, subtesSkorList, getSubtesSkor } =
    useContext(TryoutContext);
  const [currentTryout, setCurrentTryout] = useState({});
  useEffect(() => {
    if (tryoutList && tryoutList.length) {
      const tryout = tryoutList.find(
        (item) => String(item.id) === String(tryoutId)
      );
      if (tryout) {
        let hasBundleAccess = tryout.pembayaran?.includes("bundle");
        bundleList.forEach((item) => {
          if (item.status_daftar === "TERDAFTAR") {
            if (
              item.isi_bundle.find((item2) => item2.id_tryout === tryout.id)
            ) {
              hasBundleAccess = true;
            }
          }
        });
        const currentTimestamp = dayjs().valueOf();

        const { openPengumumanDateTimestamp } = getPengumumanInfo(tryout);

        if (!hasBundleAccess) {
          if (currentTimestamp < openPengumumanDateTimestamp) {
            navigate("/ranking-pembahasan");
          } else {
            if (!tryout.pembayaran || tryout.pembayaran === "Gratis") {
              navigate("/ranking-pembahasan");
            } else {
              setGrupSubtes([]);
              getSubtesSkor(tryout.id);
              setCurrentTryout(tryout);
            }
          }
        } else {
          if (!tryout.hasil_tersedia) {
            navigate("/ranking-pembahasan");
          } else {
            setGrupSubtes([]);
            getSubtesSkor(tryout.id);
            setCurrentTryout(tryout);
          }
        }
      } else {
        navigate("/ranking-pembahasan");
      }
    }
  }, [tryoutList]);

  useEffect(() => {
    if (subtesSkorList.length) {
      if (subtesSkorList[0].id_tryout === currentTryout.id) {
        const uniqueSubtesList = subtesSkorList
          .map((item) => item.tipe_string)
          .filter((value, index, self) => {
            return self.indexOf(value) === index;
          });
        // filter saintek soshum
        const indexToDrop = uniqueSubtesList.findIndex((item) =>
          item
            .toLowerCase()
            .includes(currentTryout.klaster === 1 ? "soshum" : "saintek")
        );
        if (indexToDrop !== -1) {
          uniqueSubtesList.splice(indexToDrop, 1);
        }
        const newGrupSubtes = uniqueSubtesList.map((item) => {
          const filtered = subtesSkorList.filter(
            (item2) => item2.tipe_string === item
          );
          return {
            tipe_string: filtered[0].tipe_string,
            subtesSkorList: filtered,
          };
        });

        setGrupSubtes(newGrupSubtes);
      } else {
        setGrupSubtes([]);
      }
    }
  }, [subtesSkorList]);

  const [grupSubtes, setGrupSubtes] = useState([]);

  return (
    <>
      <div className="flex flex-col bg-white p-10 justify-start m-0 w-full">
        <HeaderJD title={`Pembahasan ${currentTryout.nama_tryout || ""}`} />

        {/* Tryout kamu */}
        <div className="flex flex-col w-full md:w-full-10 mt-10 gap-10">
          {grupSubtes.map((item, index) => {
            return (
              <div key={index} className="w-full flex flex-col">
                <p className="font-subtitle font-bold">{item.tipe_string}</p>
                <div className="flex gap-10 flex-wrap">
                  {item.subtesSkorList.map((item2, index2) => (
                    <div
                      key={index2}
                      className="flex flex-col justify-between bg-white shadow-lg rounded-2xl p-7 gap-4 w-96 sm:w-2/5"
                    >
                      <div className="flex items-center gap-5">
                        <p className="font-content-0 font-bold">{item2.nama}</p>
                      </div>
                      <div className="flex flex-col gap-1">
                        <p className="font-content-1">
                          Waktu : {parseFloat(item2.waktu)} menit
                        </p>
                        <p className="font-content-1">
                          Jumlah Soal : {parseInt(item2.jumlah_soal)}
                        </p>
                        <p className="font-content-1">
                          Skor : {parseInt(item2.nilai)}
                        </p>
                      </div>
                      {/* 
                      {item2.dikerjakan ? (
                        <div className="flex justify-center items-center px-5 py-3 bg-green-100 rounded-xl">
                          <p className="font-content-1 font-bold text-hijau">
                            Sudah dikerjakan
                          </p>
                        </div>
                      ) : (
                        <div className="flex justify-center items-center px-5 py-3 bg-krem rounded-xl">
                          <p className="font-content-1 font-bold text-merah">
                            Belum dikerjakan
                          </p>
                        </div>
                      )} */}

                      <Link
                        to={`/ranking-pembahasan/${currentTryout.id}/pembahasan/${item2.subtes}`}
                        className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/2 text-white rounded-xl"
                      >
                        Buka
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default PembahasanLobby;
