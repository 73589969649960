/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import { useState } from "react";
import HeaderJD from "../../components/Layout/HeaderJD";
import FormatPrice from "../../components/Layout/FormatPrice";
import Modal from "../../components/Home/Modal";
import { useNavigate, useParams } from "react-router-dom";
import { useContext } from "react";
import { useEffect } from "react";
import PartnerContext from "../../context/PartnerContext/PartnerContext";
import EditPartnershipBundleComponent from "../../components/Partner/EditPartnershipBundleComponent";
import PendaftarBundleComponent from "../../components/Partner/PendaftarBundleComponent";

const DetailPartnershipBundle = () => {
  const { idBundle } = useParams();
  const navigate = useNavigate();
  const {
    currentPartnership,
    getSinglePartnershipBundle,
    editPartnershipBundle,
    summaryPendaftarBundle,
    getSummaryPendaftarBundle,
  } = useContext(PartnerContext);

  useEffect(() => {
    (async () => {
      const res = await getSinglePartnershipBundle({ id_bundle: idBundle });
      if (res.success) {
        if (!res.data.id) navigate("/partner-area");
      }
    })();
    getSummaryPendaftarBundle({ id_bundle: idBundle });
  }, []);

  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState(<></>);

  // Sudah terdaftar
  const optionSortList = [
    { name: "Nama (A-Z)", sort: "nama", order: "asc", value: 1 },
    { name: "Nama (Z-A)", sort: "nama", order: "desc", value: 2 },
    {
      name: "Waktu Daftar (9-0)",
      sort: "waktu_verifikasi",
      order: "desc",
      value: 3,
    },
    {
      name: "Waktu Daftar (0-9)",
      sort: "waktu_verifikasi",
      order: "asc",
      value: 4,
    },
  ];
  const [currentSort, setCurrentSort] = useState(optionSortList[2]);
  const [currentQuery, setCurrentQuery] = useState("");

  return (
    <>
      <div className="flex flex-col bg-white p-10 justify-start m-0 w-full">
        <HeaderJD title="Detail Partnership" />

        <div className="flex flex-col w-full md:w-full-10 mt-10 gap-3">
          <p className="font-subtitle font-bold">Info Promo</p>

          <table className="tabel-jd flex flex-row flex-nowrap w-full">
            <thead>
              <tr className={`flex flex-col mt-5 gap-2`}>
                <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                  Bundle
                </th>
                <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                  Kode Voucher
                </th>
              </tr>
            </thead>
            <tbody className="flex-1">
              <tr className="flex flex-col mt-5 gap-2">
                <td className="p-2 pl-4 font-content-1 text-left flex justify-start items-center">
                  {currentPartnership.nama_bundle}
                </td>
                <td className="p-2 pl-4 font-content-1 text-left flex justify-start items-center">
                  {currentPartnership.kode_promo}
                </td>
              </tr>
            </tbody>
          </table>
          {/* <button
            onClick={() => {
              setModalContent(
                <EditPartnershipBundleComponent
                  setOpen={setOpen}
                  currentPartnership={currentPartnership}
                  editPartnershipBundle={editPartnershipBundle}
                  single={true}
                />
              );
              setOpen(true);
            }}
            className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl"
          >
            Edit Promo
          </button> */}
        </div>

        <div className="flex flex-col w-full md:w-full-10 mt-10 gap-3">
          <p className="font-subtitle font-bold">Info Harga</p>
          <table className="tabel-jd flex flex-row flex-nowrap w-full">
            <thead>
              <tr className={`flex flex-col mt-5 gap-2`}>
                <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                  Harga Dasar
                </th>
                <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                  Profit Partner
                </th>
                <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                  Harga Akhir
                </th>
                <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                  Total Profit Partner
                </th>
              </tr>
            </thead>
            <tbody className="flex-1">
              <tr className="flex flex-col mt-5 gap-2">
                <td className="p-2 pl-4 font-content-1 text-left flex justify-start items-center">
                  <FormatPrice price={currentPartnership.profit_jd || 0} />
                </td>
                <td className="p-2 pl-4 font-content-1 text-left flex justify-start items-center">
                  <FormatPrice price={currentPartnership.profit_partner || 0} />
                </td>
                <td className="p-2 pl-4 font-content-1 text-left flex justify-start items-center">
                  <FormatPrice
                    price={
                      parseInt(currentPartnership.profit_partner || 0) +
                      parseInt(currentPartnership.profit_jd || 0)
                    }
                  />
                </td>
                <td className="p-2 pl-4 font-content-1 text-left flex justify-start items-center">
                  <FormatPrice
                    price={
                      parseInt(currentPartnership.profit_partner) *
                        parseInt(currentPartnership.user_count) || 0
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="flex flex-col w-full md:w-full-10 mt-10 gap-3">
          <p className="font-subtitle font-bold">Info Pendaftar</p>
          <table className="tabel-jd flex flex-row flex-nowrap w-full">
            <thead>
              <tr className={`flex flex-col mt-5 gap-2`}>
                <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                  Total Pendaftar
                </th>
                <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                  Sudah Diverifikasi
                </th>
                <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                  Belum Diverifikasi
                </th>
              </tr>
            </thead>
            <tbody className="flex-1">
              <tr className="flex flex-col mt-5 gap-2">
                <td className="p-2 pl-4 font-content-1 text-left flex justify-start items-center">
                  {summaryPendaftarBundle.total_pendaftar}
                </td>
                <td className="p-2 pl-4 font-content-1 text-left flex justify-start items-center">
                  {summaryPendaftarBundle.pendaftar_sudah_verifikasi}
                </td>
                <td className="p-2 pl-4 font-content-1 text-left flex justify-start items-center">
                  {summaryPendaftarBundle.pendaftar_belum_verifikasi}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* List pendaftar */}
        <PendaftarBundleComponent
          currentPartnership={currentPartnership}
          optionSortList={optionSortList}
          currentSort={currentSort}
          setCurrentSort={setCurrentSort}
          currentQuery={currentQuery}
          setCurrentQuery={setCurrentQuery}
        />
      </div>
      <Modal {...{ open, setOpen }}>
        <div className="relative text-left w-11/12 md:w-2/3 lg:w-1/2 xl:w-2/5 z-50 flex flex-col text-xl font-bold bg-white rounded-2xl">
          {modalContent}
        </div>
      </Modal>
    </>
  );
};

export default DetailPartnershipBundle;
