/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import HeaderJD from "../../components/Layout/HeaderJD";
import Modal from "../../components/Home/Modal";
import AdminContext from "../../context/AdminContext/AdminContext";
import PageComponent from "../../components/Layout/PageComponent";

const ConfirmationComponent = ({
  tryoutData,
  checkForm,
  setCheckForm,
  setOpen,
  verifyUser,
  verify,
}) => {
  const [verifyForm, setVerifyForm] = useState({
    id_tryout: 0,
    id_user_list: [],
    verify: true,
  });
  useEffect(() => {
    if (tryoutData && checkForm.length)
      setVerifyForm({
        id_tryout: tryoutData.id,
        id_user_list: checkForm.map((item) => item.id),
        verify: verify,
      });
  }, [tryoutData, checkForm, verify]);

  const [alertText, setAlertText] = useState({
    show: false,
    text: "",
    success: true,
  });

  const setErrorAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: false,
    });
  };
  const hideAlert = () => {
    setAlertText({
      show: false,
      text: alertText.message,
      success: alertText.success,
    });
  };

  const onSubmitDelete = async (e) => {
    e.preventDefault();
    const button = e.target;
    button.disabled = true;
    hideAlert();
    const res = await verifyUser(verifyForm);
    button.disabled = false;
    if (res.success) {
      setCheckForm([]);
      setOpen(false);
    } else {
      setErrorAlert("Gagal melakukan verifikasi user. " + res.display_message);
    }
  };

  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <form
        onSubmit={onSubmitDelete}
        className="flex flex-col gap-6 w-full px-10 pb-10"
      >
        <h6 className="font-bold text-center">Konfirmasi</h6>
        <div className="flex flex-col gap-3 w-full mt-5">
          <div className="flex flex-col gap-2 w-full">
            <p className="font-content-0 w-full text-center">
              Apakah kamu yakin ingin {verify ? "menerima" : "menolak"}{" "}
              pendaftar yang dipilih?
            </p>
          </div>

          <div
            className={`${
              alertText.show ? "flex" : "hidden"
            } p-4 mb-4 text-sm ${
              alertText.success
                ? "text-green-700 bg-green-100"
                : "text-red-700 bg-red-100"
            } rounded-lg`}
            role="alert"
          >
            <svg
              className="inline flex-shrink-0 mr-3 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <div>{alertText.text}</div>
          </div>
        </div>
        <div className="flex justify-center gap-4 w-100">
          <button
            type="submit"
            className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-white rounded-xl text-center"
          >
            {verify ? "Ya, terima" : "Ya, tolak"}
          </button>
          <button
            onClick={() => setOpen(false)}
            type="button"
            className="border-2 border-oren font-content-0 flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-black rounded-xl text-center"
          >
            Batal
          </button>
        </div>
      </form>
    </>
  );
};

const PendaftarTryout = () => {
  const { tryoutId } = useParams();
  const navigate = useNavigate();
  const {
    tryoutListAdmin,
    verifikasiTryoutList,
    paginationVerifikasiTryout,
    summaryPendaftarTryout,
    getPendaftarBelumVerifikasi,
    verifyUser,
    getSummaryPendaftarTryout,
  } = useContext(AdminContext);
  const [currentTryout, setCurrentTryout] = useState({});
  useEffect(() => {
    if (tryoutListAdmin && tryoutListAdmin.length) {
      const tryout = tryoutListAdmin.find(
        (item) => String(item.id) === String(tryoutId)
      );
      if (tryout) {
        getPendaftarBelumVerifikasi({ id_tryout: tryout.id, page: 1 });
        getSummaryPendaftarTryout({ id_tryout: tryout.id });
        setCurrentTryout(tryout);
      } else {
        navigate("/admin-area");
      }
    }
  }, [tryoutListAdmin]);

  useEffect(() => {
    if (verifikasiTryoutList.length) {
      const newCheckForm = verifikasiTryoutList.map((item, index) => ({
        checked: false,
        id: item.id_user,
      }));
      setCheckForm(newCheckForm);
    }
  }, [verifikasiTryoutList]);

  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState(<></>);
  const [checkForm, setCheckForm] = useState([]);

  return (
    <>
      <div className="flex flex-col bg-white p-10 justify-start m-0 w-full">
        <HeaderJD title={`Pendaftar ${currentTryout.nama_tryout}`} />

        <div className="flex flex-col w-full md:w-full-10 mt-10 gap-3">
          <p className="font-subtitle font-bold">Info Pendaftar</p>
          <table className="tabel-jd flex flex-row flex-nowrap w-full">
            <thead>
              <tr className={`flex flex-col mt-5 gap-2`}>
                <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                  Total Pendaftar
                </th>
                <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                  Gratis
                </th>
                <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                  Premium non Bundle
                </th>
                <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                  Pendaftar Bundle
                </th>
                <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                  Sudah Diverifikasi
                </th>
                <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                  Belum Diverifikasi
                </th>
              </tr>
            </thead>
            <tbody className="flex-1">
              <tr className="flex flex-col mt-5 gap-2">
                <td className="p-2 pl-4 font-content-1 text-left flex justify-start items-center">
                  {summaryPendaftarTryout.total_pendaftar}
                </td>
                <td className="p-2 pl-4 font-content-1 text-left flex justify-start items-center">
                  {summaryPendaftarTryout.pendaftar_gratis}
                </td>
                <td className="p-2 pl-4 font-content-1 text-left flex justify-start items-center">
                  {summaryPendaftarTryout.pendaftar_premium}
                </td>{" "}
                <td className="p-2 pl-4 font-content-1 text-left flex justify-start items-center">
                  {summaryPendaftarTryout.pendaftar_bundle}
                </td>{" "}
                <td className="p-2 pl-4 font-content-1 text-left flex justify-start items-center">
                  {summaryPendaftarTryout.pendaftar_sudah_verifikasi}
                </td>
                <td className="p-2 pl-4 font-content-1 text-left flex justify-start items-center">
                  {summaryPendaftarTryout.pendaftar_belum_verifikasi}
                </td>
              </tr>
            </tbody>
          </table>
          <Link
            to={`/admin-area/tryout/${tryoutId}/pendaftar/detail`}
            className="font-content-0 mt-2 hover:underline text-oren font-bold"
          >
            Lihat rincian pendaftar
          </Link>
        </div>

        <div className="flex flex-col w-full md:w-full-10 mt-10 gap-3">
          <p className="font-subtitle font-bold">Belum Verifikasi</p>
          {(verifikasiTryoutList.length && (
            <div className="grid grid-cols-2 md:grid-cols-3 gap-3">
              {verifikasiTryoutList.length === checkForm.length &&
                verifikasiTryoutList.map((item, index) => (
                  <div
                    key={index}
                    className="flex flex-col gap-2 items-center border-2 border-abu rounded-xl py-3 px-1"
                  >
                    <input
                      type="checkbox"
                      checked={checkForm[index].checked}
                      onChange={() => {
                        const newCheckForm = [...checkForm];
                        newCheckForm[index].checked =
                          !newCheckForm[index].checked;
                        setCheckForm(newCheckForm);
                      }}
                    />
                    <p className="font-content-0">{item.nama}</p>
                    <p className="font-content-1">{item.email}</p>
                    <p className="font-content-0">{item.pembayaran}</p>
                    <div className="w-full">
                      <img
                        src={`${window.location.origin}/uploads/bukti/${currentTryout.id}_${item.id_user}_bukti1.png`}
                        alt=""
                      />
                    </div>
                    {item.pembayaran === "Gratis" && (
                      <>
                        <div className="w-full">
                          <img
                            src={`${window.location.origin}/uploads/bukti/${currentTryout.id}_${item.id_user}_bukti2.png`}
                            alt=""
                          />
                        </div>
                        <div className="w-full">
                          <img
                            src={`${window.location.origin}/uploads/bukti/${currentTryout.id}_${item.id_user}_bukti3.png`}
                            alt=""
                          />
                        </div>
                      </>
                    )}
                    {item.pembayaran === "Gratis-collab" && (
                      <>
                        <div className="w-full">
                          <img
                            src={`${window.location.origin}/uploads/bukti/${currentTryout.id}_${item.id_user}_bukti2.png`}
                            alt=""
                          />
                        </div>
                      </>
                    )}
                  </div>
                ))}
            </div>
          )) || (
            <p className="font-content-0">Semua pendaftar telah diverifikasi</p>
          )}

          <div className="flex w-full justify-center">
            <div className="flex gap-3">
              {paginationVerifikasiTryout.totalPage ? (
                <PageComponent
                  currentPage={paginationVerifikasiTryout.currentPage}
                  totalPage={paginationVerifikasiTryout.totalPage}
                  getData={(page) => {
                    getPendaftarBelumVerifikasi({
                      id_tryout: currentTryout.id,
                      page: page,
                    });
                  }}
                />
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <button
              onClick={() => {
                const initialState = checkForm[0].checked;
                const newCheckForm = checkForm.map((item) => ({
                  ...item,
                  checked: !initialState,
                }));
                setCheckForm(newCheckForm);
              }}
              disabled={!verifikasiTryoutList.length}
              className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl"
            >
              Pilih Semua
            </button>
            <button
              onClick={() => {
                setModalContent(
                  <ConfirmationComponent
                    tryoutData={currentTryout}
                    checkForm={checkForm.filter((item) => item.checked)}
                    setCheckForm={setCheckForm}
                    setOpen={setOpen}
                    verifyUser={verifyUser}
                    verify={true}
                  />
                );
                setOpen(true);
              }}
              disabled={!checkForm.filter((item) => item.checked).length}
              className="bg-hijau font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl"
            >
              Terima Pendaftar
            </button>
            <button
              onClick={() => {
                setModalContent(
                  <ConfirmationComponent
                    tryoutData={currentTryout}
                    checkForm={checkForm.filter((item) => item.checked)}
                    setCheckForm={setCheckForm}
                    setOpen={setOpen}
                    verifyUser={verifyUser}
                    verify={false}
                  />
                );
                setOpen(true);
              }}
              disabled={!checkForm.filter((item) => item.checked).length}
              className="bg-merah font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl"
            >
              Tolak Pendaftar
            </button>
          </div>
        </div>
      </div>
      <Modal {...{ open, setOpen }}>
        <div className="relative text-left w-11/12 md:w-2/3 lg:w-1/2 xl:w-2/5 z-50 flex flex-col text-xl font-bold bg-white rounded-2xl">
          {modalContent}
        </div>
      </Modal>
    </>
  );
};

export default PendaftarTryout;
