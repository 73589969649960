/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import HeaderJD from "../../components/Layout/HeaderJD";
import Modal from "../../components/Home/Modal";
import AdminContext from "../../context/AdminContext/AdminContext";
import PageComponent from "../../components/Layout/PageComponent";

const FormatPrice = ({ price }) => {
  const result = parseInt(price).toLocaleString("id", {
    style: "currency",
    currency: "IDR",
  });
  return <>{result}</>;
};

const ConfirmationComponent = ({
  bundleData,
  checkForm,
  setCheckForm,
  setOpen,
  verifyUserBundle,
  verify,
}) => {
  const [verifyForm, setVerifyForm] = useState({
    id_bundle: 0,
    id_user_list: [],
    verify: true,
  });
  useEffect(() => {
    if (bundleData && checkForm.length)
      setVerifyForm({
        id_bundle: bundleData.id,
        id_user_list: checkForm.map((item) => item.id),
        verify: verify,
      });
  }, [bundleData, checkForm, verify]);

  const [alertText, setAlertText] = useState({
    show: false,
    text: "",
    success: true,
  });

  const setErrorAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: false,
    });
  };
  const hideAlert = () => {
    setAlertText({
      show: false,
      text: alertText.message,
      success: alertText.success,
    });
  };

  const onSubmitDelete = async (e) => {
    e.preventDefault();
    const button = e.target;
    button.disabled = true;
    hideAlert();
    const res = await verifyUserBundle(verifyForm);
    button.disabled = false;
    if (res.success) {
      setCheckForm([]);
      setOpen(false);
    } else {
      setErrorAlert("Gagal melakukan verifikasi user. " + res.display_message);
    }
  };

  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <form
        onSubmit={onSubmitDelete}
        className="flex flex-col gap-6 w-full px-10 pb-10"
      >
        <h6 className="font-bold text-center">Konfirmasi</h6>
        <div className="flex flex-col gap-3 w-full mt-5">
          <div className="flex flex-col gap-2 w-full">
            <p className="font-content-0 w-full text-center">
              Apakah kamu yakin ingin {verify ? "menerima" : "menolak"}{" "}
              pendaftar yang dipilih?
            </p>
          </div>

          <div
            className={`${
              alertText.show ? "flex" : "hidden"
            } p-4 mb-4 text-sm ${
              alertText.success
                ? "text-green-700 bg-green-100"
                : "text-red-700 bg-red-100"
            } rounded-lg`}
            role="alert"
          >
            <svg
              className="inline flex-shrink-0 mr-3 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <div>{alertText.text}</div>
          </div>
        </div>
        <div className="flex justify-center gap-4 w-100">
          <button
            type="submit"
            className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-white rounded-xl text-center"
          >
            {verify ? "Ya, terima" : "Ya, tolak"}
          </button>
          <button
            onClick={() => setOpen(false)}
            type="button"
            className="border-2 border-oren font-content-0 flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-black rounded-xl text-center"
          >
            Batal
          </button>
        </div>
      </form>
    </>
  );
};

const PendaftarBundle = () => {
  const { bundleId } = useParams();
  const navigate = useNavigate();
  const {
    bundleListAdmin,
    verifikasiBundleList,
    paginationVerifikasiBundle,
    summaryPendaftarBundle,
    getPendaftarBundleBelumVerifikasi,
    verifyUserBundle,
    getSummaryPendaftarBundle,
  } = useContext(AdminContext);
  const [currentBundle, setCurrentBundle] = useState({});
  useEffect(() => {
    if (bundleListAdmin && bundleListAdmin.length) {
      const bundle = bundleListAdmin.find(
        (item) => String(item.id) === String(bundleId)
      );
      if (bundle) {
        getPendaftarBundleBelumVerifikasi({ id_bundle: bundle.id, page: 1 });
        getSummaryPendaftarBundle({ id_bundle: bundle.id });
        setCurrentBundle(bundle);
      } else {
        navigate("/admin-area");
      }
    }
  }, [bundleListAdmin]);

  useEffect(() => {
    if (verifikasiBundleList.length) {
      const newCheckForm = verifikasiBundleList.map((item, index) => ({
        checked: false,
        id: item.id_user,
      }));
      setCheckForm(newCheckForm);
    }
  }, [verifikasiBundleList]);

  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState(<></>);
  const [checkForm, setCheckForm] = useState([]);

  return (
    <>
      <div className="flex flex-col bg-white p-10 justify-start m-0 w-full">
        <HeaderJD title={`Pendaftar ${currentBundle.nama_bundle}`} />

        <div className="flex flex-col w-full md:w-full-10 mt-10 gap-3">
          <p className="font-subtitle font-bold">Info Pendaftar</p>
          <table className="tabel-jd flex flex-row flex-nowrap w-full">
            <thead>
              <tr className={`flex flex-col mt-5 gap-2`}>
                <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                  Total Pendaftar
                </th>
                <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                  Sudah Diverifikasi
                </th>
                <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                  Belum Diverifikasi
                </th>
              </tr>
            </thead>
            <tbody className="flex-1">
              <tr className="flex flex-col mt-5 gap-2">
                <td className="p-2 pl-4 font-content-1 text-left flex justify-start items-center">
                  {summaryPendaftarBundle.total_pendaftar}
                </td>
                <td className="p-2 pl-4 font-content-1 text-left flex justify-start items-center">
                  {summaryPendaftarBundle.pendaftar_sudah_verifikasi}
                </td>
                <td className="p-2 pl-4 font-content-1 text-left flex justify-start items-center">
                  {summaryPendaftarBundle.pendaftar_belum_verifikasi}
                </td>
              </tr>
            </tbody>
          </table>
          <Link
            to={`/admin-area/bundle/${bundleId}/pendaftar/detail`}
            className="font-content-0 mt-2 hover:underline text-oren font-bold"
          >
            Lihat rincian pendaftar
          </Link>
        </div>
        <div className="flex flex-col w-full md:w-full-10 mt-10 gap-3">
          <p className="font-subtitle font-bold">Belum Verifikasi</p>
          {(verifikasiBundleList.length && (
            <div className="grid grid-cols-2 md:grid-cols-3 gap-3">
              {verifikasiBundleList.length === checkForm.length &&
                verifikasiBundleList.map((item, index) => (
                  <div
                    key={index}
                    className="flex flex-col gap-2 items-center border-2 border-abu rounded-xl py-3 px-1"
                  >
                    <input
                      type="checkbox"
                      checked={checkForm[index].checked}
                      onChange={() => {
                        const newCheckForm = [...checkForm];
                        newCheckForm[index].checked =
                          !newCheckForm[index].checked;
                        setCheckForm(newCheckForm);
                      }}
                    />
                    <p className="font-content-0">{item.nama}</p>
                    <p className="font-content-1">{item.email}</p>
                    <p className="font-content-0">{item.pembayaran}</p>
                    {item.kode_promo ? (
                      <p className="font-content-0">Kode : {item.kode_promo}</p>
                    ) : null}
                    <p className="font-content-0">
                      <FormatPrice price={item.harga} />
                    </p>
                    <div className="w-full">
                      <img
                        src={`${window.location.origin}/uploads/bukti/bundle_${currentBundle.id}_${item.id_user}_bukti1.png`}
                        alt=""
                      />
                    </div>
                  </div>
                ))}
            </div>
          )) || (
            <p className="font-content-0">Semua pendaftar telah diverifikasi</p>
          )}

          <div className="flex w-full justify-center">
            <div className="flex gap-3">
              {paginationVerifikasiBundle.totalPage ? (
                <PageComponent
                  currentPage={paginationVerifikasiBundle.currentPage}
                  totalPage={paginationVerifikasiBundle.totalPage}
                  getData={(page) => {
                    getPendaftarBundleBelumVerifikasi({
                      id_bundle: currentBundle.id,
                      page: page,
                    });
                  }}
                />
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <button
              onClick={() => {
                const initialState = checkForm[0].checked;
                const newCheckForm = checkForm.map((item) => ({
                  ...item,
                  checked: !initialState,
                }));
                setCheckForm(newCheckForm);
              }}
              disabled={!verifikasiBundleList.length}
              className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl"
            >
              Pilih Semua
            </button>
            <button
              onClick={() => {
                setModalContent(
                  <ConfirmationComponent
                    bundleData={currentBundle}
                    checkForm={checkForm.filter((item) => item.checked)}
                    setCheckForm={setCheckForm}
                    setOpen={setOpen}
                    verifyUserBundle={verifyUserBundle}
                    verify={true}
                  />
                );

                setOpen(true);
              }}
              disabled={!checkForm.filter((item) => item.checked).length}
              className="bg-hijau font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl"
            >
              Terima Pendaftar
            </button>
            <button
              onClick={() => {
                setModalContent(
                  <ConfirmationComponent
                    bundleData={currentBundle}
                    checkForm={checkForm.filter((item) => item.checked)}
                    setCheckForm={setCheckForm}
                    setOpen={setOpen}
                    verifyUserBundle={verifyUserBundle}
                    verify={false}
                  />
                );

                setOpen(true);
              }}
              disabled={!checkForm.filter((item) => item.checked).length}
              className="bg-merah font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl"
            >
              Tolak Pendaftar
            </button>
          </div>
        </div>
      </div>
      <Modal {...{ open, setOpen }}>
        <div className="relative text-left w-11/12 md:w-2/3 lg:w-1/2 xl:w-2/5 z-50 flex flex-col text-xl font-bold bg-white rounded-2xl">
          {modalContent}
        </div>
      </Modal>
    </>
  );
};

export default PendaftarBundle;
