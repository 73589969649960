/* eslint-disable react-hooks/exhaustive-deps*/

import { useContext, useEffect, useRef, useState } from "react";

import Select, { createFilter } from "react-select";

import GlobalContext from "../../context/GlobalContext/GlobalContext";

const RegisterGoogleForm = ({ setOpen, data }) => {
  const {
    provinsiList,
    sekolahList,
    getProvinsiList,
    getSekolahList,
    registerGoogle,
    loginWithGoogle,
    navigate,
  } = useContext(GlobalContext);
  useEffect(() => {
    getProvinsiList();
  }, []);

  const [currentProv, setCurrentProv] = useState("");

  const DynamicSelect = ({ option, currentValue }) => {
    const options = option.map((item) => {
      return { value: item.npsn, label: item.nama };
    });
    const MenuList = function MenuList(props) {
      const children = props.children;

      if (!children.length) {
        return <>{children}</>;
      }

      return (
        <>
          {children.length &&
            children.map((key) => {
              delete key.props.innerProps.onMouseMove; //FIX LAG!!
              delete key.props.innerProps.onMouseOver; //FIX LAG!!

              return key;
            })}
        </>
      );
    };

    return (
      <Select
        placeholder="Asal sekolah"
        styles={{
          control: () => ({
            alignItems: "center",
            border: "none",
            display: "flex",
            flexWrap: "wrap",
            WebkitBoxPack: "justify",
            justifyContent: "space-between",
            minHeight: "38px",
            position: "relative",
          }),
          menu: (provided, state) => ({
            ...provided,
            height: "13rem",
            overflow: "auto",
          }),
        }}
        className="w-full border-none outline-none font-content-1"
        onChange={(val) => {
          formData.sekolah = val.value;
        }}
        options={options}
        filterOption={createFilter({ ignoreAccents: false })}
        components={{
          MenuList,
        }}
        value={options.find((item) => item.value === currentValue)}
      />
    );
  };

  // Handle Form Data
  const [formData, setFormData] = useState({
    nama: "",
    asal: "",
    sekolah: "",
  });

  const [textAlert, setTextAlert] = useState("");
  const errorAlertRef = useRef(<></>);
  const successAlertRef = useRef(<></>);

  const showErrorAlert = () => {
    errorAlertRef.current.classList.remove("hidden");
    errorAlertRef.current.classList.add("flex");
  };
  const showSuccessAlert = () => {
    successAlertRef.current.classList.remove("hidden");
    successAlertRef.current.classList.add("flex");
  };

  const hideAllAlert = () => {
    errorAlertRef.current.classList.remove("flex");
    errorAlertRef.current.classList.add("hidden");
    successAlertRef.current.classList.remove("flex");
    successAlertRef.current.classList.add("hidden");
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();
    let completed = true;
    let safe = true;
    Object.keys(formData).forEach((item) => {
      if (String(formData[item]).replace(" ", "").length <= 0) {
        completed = false;
      }
    });
    if (!completed) {
      setTextAlert("Semua kolom harus diisi!");
      hideAllAlert();
      showErrorAlert();
    } else {
      if (safe && formData["nama"].length < 6) {
        setTextAlert("Nama harus berisi minimal 6 karakter!");
        showErrorAlert();
        safe = false;
      }
    }

    if (completed && safe) {
      formData.nama = formData.nama.trim().replace(/ +(?= )/g, "");
      const result = await registerGoogle({ ...formData, email: data.email });
      if (result.success) {
        setTextAlert("Profil berhasil dilengkapi");
        hideAllAlert();
        showSuccessAlert();
        const loginRes = await loginWithGoogle(data);
        if (loginRes.success) {
          navigate("/dashboard");
        } else {
          setTextAlert(
            loginRes.display_message ||
              "Terjadi kesalahan saat login, coba beberapa saat lagi"
          );
          hideAllAlert();
          showErrorAlert();
        }
      } else {
        setTextAlert(
          result.display_message ||
            "Terjadi kesalahan saat mengirim data, coba beberapa saat lagi"
        );
        hideAllAlert();
        showErrorAlert();
      }
    }
  };

  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <div className="flex flex-col gap-6 w-full px-10 pb-10">
        <h6 className="font-bold text-center">Lengkapi Detail Akunmu</h6>
        <p className="font-content-1 w-full text-center">
          Akunmu berhasil didaftarkan! Yuk isi informasimu untuk melanjutkan!
        </p>
        <form className="flex flex-col gap-5 w-full" onSubmit={onSubmitForm}>
          <div className="w-full flex border-2 border-black rounded-xl items-center">
            <input
              className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
              placeholder="Nama lengkap"
              type="text"
              value={formData.nama}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  nama: e.target.value.replace(/ +(?= )/g, ""),
                });
              }}
              required={true}
              autoFocus={true}
            />
          </div>
          <div className="w-full flex border-2 border-black rounded-xl items-center select-container">
            <Select
              placeholder="Asal daerah"
              styles={{
                control: () => ({
                  alignItems: "center",
                  border: "none",
                  display: "flex",
                  flexWrap: "wrap",
                  WebkitBoxPack: "justify",
                  justifyContent: "space-between",
                  minHeight: "38px",
                  position: "relative",
                }),
              }}
              className="w-full border-none outline-none font-content-1"
              onChange={(val) => {
                if (val.value !== currentProv) {
                  getSekolahList(val.value);
                  setCurrentProv(val.value);
                  setFormData({ ...formData, asal: val.value });
                }
              }}
              options={provinsiList.map((item) => {
                return { value: item, label: item };
              })}
              filterOption={createFilter({ ignoreAccents: false })}
            />
          </div>
          <div className="w-full flex border-2 border-black rounded-xl items-center">
            <DynamicSelect
              option={sekolahList}
              currentValue={formData.sekolah}
            />
          </div>

          <div
            className="hidden p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg"
            role="alert"
            ref={errorAlertRef}
          >
            <svg
              className="inline flex-shrink-0 mr-3 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <div>{textAlert}</div>
          </div>
          <div
            ref={successAlertRef}
            className="hidden p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg"
            role="alert"
          >
            <svg
              className="inline flex-shrink-0 mr-3 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <div>{textAlert}</div>
          </div>

          <button
            type="submit"
            className="rounded-xl bg-oren text-white font-content-0 font-bold px-8 py-2"
          >
            Lanjutkan
          </button>
        </form>
      </div>
    </>
  );
};

export default RegisterGoogleForm;
