/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import HeaderJD from "../../components/Layout/HeaderJD";
import TryoutContext from "../../context/TryoutContext/TryoutContext";
import { getPengumumanInfo } from "../../script/dateUtils";
import PageComponent from "../../components/Layout/PageComponent";
import dayjs from "dayjs";

const Ranking = () => {
  const { tryoutId } = useParams();
  const navigate = useNavigate();
  const {
    tryoutList,
    bundleList,
    rankingList,
    paginationRanking,
    subtesList,
    getSubtes,
    getRankingList,
  } = useContext(TryoutContext);
  const [currentTryout, setCurrentTryout] = useState({});
  const [tryoutCampur, setTryoutCampur] = useState(true);
  useEffect(() => {
    if (tryoutList && tryoutList.length) {
      const tryout = tryoutList.find(
        (item) => String(item.id) === String(tryoutId)
      );
      if (tryout) {
        let hasBundleAccess = tryout.pembayaran?.includes("bundle");
        bundleList.forEach((item) => {
          if (item.status_daftar === "TERDAFTAR") {
            if (
              item.isi_bundle.find((item2) => item2.id_tryout === tryout.id)
            ) {
              hasBundleAccess = true;
            }
          }
        });
        const currentTimestamp = dayjs().valueOf();

        const { openPengumumanDateTimestamp } = getPengumumanInfo(tryout);

        if (!hasBundleAccess) {
          if (currentTimestamp < openPengumumanDateTimestamp) {
            navigate("/ranking-pembahasan");
          } else {
            if (!tryout.pembayaran || tryout.pembayaran === "Gratis")
              navigate("/ranking-pembahasan");
            else {
              getRankingList({
                id_tryout: tryout.id,
                klaster: 1,
              });
              getSubtes(tryout.id);
              setCurrentTryout(tryout);
            }
          }
        } else {
          if (!tryout.hasil_tersedia) {
            navigate("/ranking-pembahasan");
          } else {
            getRankingList({
              id_tryout: tryout.id,
              klaster: 1,
            });
            getSubtes(tryout.id);
            setCurrentTryout(tryout);
          }
        }
      } else {
        navigate("/ranking-pembahasan");
      }
    }
  }, [tryoutList]);

  useEffect(() => {
    if (subtesList.length) {
      if (subtesList[0].id_tryout === currentTryout.id) {
        const uniqueSubtesList = subtesList
          .map((item) => item.tipe_string)
          .filter((value, index, self) => {
            return self.indexOf(value) === index;
          });
        if (uniqueSubtesList.length > 1) {
          setTryoutCampur(true);
        } else {
          setTryoutCampur(false);
        }
      }
    }
  }, [subtesList]);

  const optionSortList = [
    { name: "Nama (A-Z)", sort: "nama", order: "asc", value: 1 },
    { name: "Nama (Z-A)", sort: "nama", order: "desc", value: 2 },
    { name: "Asal (A-Z)", sort: "asal", order: "asc", value: 3 },
    { name: "Asal (Z-Z)", sort: "asal", order: "desc", value: 4 },
    { name: "Rerata (9-0)", sort: "rerata", order: "desc", value: 5 },
    { name: "Rerata (0-9)", sort: "rerata", order: "asc", value: 6 },
  ];

  const optionKlaster = [
    { name: "Saintek", value: 1 },
    { name: "Soshum", value: 2 },
  ];

  const [currentSort, setCurrentSort] = useState(optionSortList[4]);
  const [currentKlaster, setCurrentKlaster] = useState(optionKlaster[0]);
  const [currentQuery, setCurrentQuery] = useState("");

  const onSubmitSearch = async (e) => {
    e.preventDefault();
    const button = e.target.querySelector("button");
    button.disabled = true;
    await getRankingList({
      id_tryout: currentTryout.id,
      klaster: currentKlaster.value,
      page: 1,
      query: currentQuery,
      sort: currentSort.sort,
      order: currentSort.order,
    });

    button.disabled = false;
  };

  const getData = (page) => {
    getRankingList({
      id_tryout: currentTryout.id,
      klaster: currentKlaster.value,
      page: page,
      query: currentQuery,
      sort: currentSort.sort,
      order: currentSort.order,
    });
  };

  return (
    <>
      <div className="flex flex-col bg-white p-10 justify-start m-0 w-full">
        <HeaderJD title={`Peringkat ${currentTryout.nama_tryout || ""}`} />

        {/* Tryout kamu */}
        <div className="flex flex-col w-full md:w-full-10 mt-10 gap-10">
          <div className="w-full flex flex-col-reverse sm:flex-row items-center justify-end gap-3">
            {tryoutCampur && (
              <Select
                placeholder="Saintek"
                styles={{
                  control: () => ({
                    alignItems: "center",
                    border: "none",
                    display: "flex",
                    flexWrap: "wrap",
                    WebkitBoxPack: "justify",
                    justifyContent: "space-between",
                    minHeight: "38px",
                    position: "relative",
                  }),
                  placeholder: (style) => ({
                    ...style,
                    color: "black",
                  }),
                }}
                className="w-full sm:w-32 h-fit text-center border-none rounded-xl outline-none font-content-1 bg-krem"
                onChange={(val) => {
                  if (val.value !== currentSort.value) {
                    const option = optionKlaster.find(
                      (item) => item.value === val.value
                    );
                    setCurrentKlaster(option);

                    getRankingList({
                      id_tryout: currentTryout.id,
                      klaster: option.value,
                      page: 1,
                      query: currentQuery,
                      sort: option.sort,
                      order: option.order,
                    });
                  }
                }}
                options={optionKlaster.map((item) => {
                  return { value: item.value, label: item.name };
                })}
              />
            )}

            <Select
              placeholder="Urutan"
              styles={{
                control: () => ({
                  alignItems: "center",
                  border: "none",
                  display: "flex",
                  flexWrap: "wrap",
                  WebkitBoxPack: "justify",
                  justifyContent: "space-between",
                  minHeight: "38px",
                  position: "relative",
                }),
                placeholder: (style) => ({
                  ...style,
                  color: "black",
                }),
              }}
              className="w-full sm:w-32 h-fit text-center border-none rounded-xl outline-none font-content-1 bg-krem"
              onChange={(val) => {
                if (val.value !== currentSort.value) {
                  const option = optionSortList.find(
                    (item) => item.value === val.value
                  );
                  setCurrentSort(option);

                  getRankingList({
                    id_tryout: currentTryout.id,
                    klaster: currentTryout.klaster,
                    page: 1,
                    query: currentQuery,
                    sort: option.sort,
                    order: option.order,
                  });
                }
              }}
              options={optionSortList.map((item) => {
                return { value: item.value, label: item.name };
              })}
            />
            <div className="w-full sm:w-96 flex">
              <form onSubmit={onSubmitSearch} className="flex flex-1 gap-2">
                <div className="relative w-auto flex-1 flex border-2 border-abu focus-within:border-black transition-colors rounded-xl items-center">
                  <i
                    className={`${
                      currentQuery && "hidden"
                    } fa-solid fa-magnifying-glass text-base sm:text-lg absolute text-gray-400 ml-3`}
                  ></i>
                  <input
                    className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                    placeholder="        Cari nama, univ, jurusan, atau sekolah"
                    type="text"
                    onChange={(e) => setCurrentQuery(e.target.value)}
                  />
                </div>
                <button className="bg-oren font-content-0 text-white flex gap-2 items-center justify-center px-4 py-2 rounded-xl">
                  <i className="fa-solid fa-magnifying-glass text-xl"></i>
                </button>
              </form>
            </div>
          </div>
          <div className="w-full overflow-auto">
            <table id="tabel-ranking" className="md:w-full">
              <thead>
                <tr>
                  <th
                    className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden"
                    rowSpan={2}
                  >
                    No
                  </th>
                  <th
                    className="p-2 font-content-1 font-bold bg-krem"
                    rowSpan={2}
                  >
                    Nama
                  </th>
                  <th
                    className="p-2 font-content-1 font-bold bg-krem"
                    rowSpan={2}
                  >
                    Asal
                  </th>
                  <th
                    className="p-2 font-content-1 font-bold bg-krem"
                    rowSpan={2}
                  >
                    Sekolah
                  </th>
                  <th
                    className="p-2 font-content-1 font-bold bg-krem"
                    rowSpan={2}
                  >
                    Rerata
                  </th>
                  <th
                    className="p-2 font-content-1 font-bold bg-krem"
                    rowSpan={2}
                  >
                    Universitas
                  </th>
                  <th
                    className="p-2 font-content-1 font-bold bg-krem"
                    rowSpan={2}
                  >
                    Jurusan
                  </th>
                  <th
                    className="p-2 font-content-1 font-bold bg-krem rounded-tr-xl overflow-hidden"
                    colSpan={4}
                  >
                    Ranking
                  </th>
                </tr>
                <tr>
                  <th className="p-2 font-content-1 font-bold bg-krem">Nas</th>
                  <th className="p-2 font-content-1 font-bold bg-krem">Reg</th>
                  <th className="p-2 font-content-1 font-bold bg-krem">Univ</th>
                  <th className="p-2 font-content-1 font-bold bg-krem rounded-br-xl overflow-hidden">
                    Prodi
                  </th>
                </tr>
              </thead>
              <tbody>
                {rankingList.map((item, index) => {
                  const no =
                    index + 1 + (paginationRanking.currentPage - 1) * 25;
                  return (
                    <tr key={index}>
                      <td className="p-2 font-content-1">{no}</td>
                      <td className="p-2 font-content-1">{item.nama}</td>
                      <td className="p-2 font-content-1">{item.asal}</td>
                      <td className="p-2 font-content-1">{item.sekolah}</td>
                      <td className="p-2 font-content-1">
                        {Number(item.rerata).toFixed(0)}
                      </td>
                      <td className="p-2 font-content-1">{item.univ}</td>
                      <td className="p-2 font-content-1">{item.jurusan}</td>
                      <td className="p-2 font-content-1">
                        {item.rank_nasional}
                      </td>
                      <td className="p-2 font-content-1">
                        {item.rank_regional}
                      </td>
                      <td className="p-2 font-content-1">{item.rank_univ}</td>
                      <td className="p-2 font-content-1">
                        {item.rank_jurusan}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="flex w-full justify-center">
            <div className="flex gap-3">
              {paginationRanking.totalPage ? (
                <PageComponent
                  currentPage={paginationRanking.currentPage}
                  totalPage={paginationRanking.totalPage}
                  getData={getData}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ranking;
