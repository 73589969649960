import { useState } from "react";
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext/GlobalContext";
import TryoutContext from "../../context/TryoutContext/TryoutContext";
import UserContext from "../../context/UserContext/UserContext";
import Sidebar from "../Layout/Sidebar";

const PrivateRoute = ({ children }) => {
  const { navigate } = useContext(GlobalContext);
  const { userData, getUserData } = useContext(UserContext);
  const { sedangDikerjakan, setSedangDikerjakan, getAllTryout, getAllBundle } =
    useContext(TryoutContext);
  const { pathname } = useLocation();

  useEffect(() => {
    if (!localStorage.token) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (Object.keys(userData).length) {
      (async () => {
        await getAllBundle();
        await getAllTryout();
      })();

      if (userData.sedang_dikerjakan) {
        const tryoutPathname = `/tryout/${userData.sedang_dikerjakan.id_tryout}/kerjakan/${userData.sedang_dikerjakan.subtes}`;
        setSedangDikerjakan(tryoutPathname);
      }
    }
  }, [userData]);

  useEffect(() => {
    if (
      sedangDikerjakan !== "" &&
      pathname !== sedangDikerjakan &&
      pathname !== "/profil"
    ) {
      // window.location = process.env.PUBLIC_URL + sedangDikerjakan;
      navigate(sedangDikerjakan);
    }
  }, [pathname, sedangDikerjakan]);
  return (
    <>
      <div className="flex bg-white items-center p-0 m-0 h-screen">
        {/* Sidebar */}
        <div className="absolute lg:static w-full lg:w-72 lg:h-screen top-0 left-0">
          <Sidebar />
        </div>
        <div className="flex-1 self-start h-screen overflow-auto">
          {children}
        </div>
      </div>
    </>
  );
};

export default PrivateRoute;
