import axios from "axios";
import { useReducer } from "react";
import { useNavigate } from "react-router-dom";
import PartnerContext from "./PartnerContext";
import PartnerReducer from "./PartnerReducer";
import { host } from "../config";
import fileDownload from "js-file-download";

const PartnerState = ({ children }) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.token}`;
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(PartnerReducer, {
    partnershipList: [],
    paginationPartnership: {
      currentPage: 0,
      totalPage: 0,
    },
    currentPartnership: {},
    verifikasiTryoutList: [],
    paginationVerifikasiTryout: {
      currentPage: 0,
      totalPage: 0,
    },
    summaryPendaftarTryout: {
      total_pendaftar: 0,
      pendaftar_premium: 0,
      pendaftar_sudah_verifikasi: 0,
      pendaftar_belum_verifikasi: 0,
    },
    pesertaTryoutList: [],
    paginationPesertaTryout: {
      currentPage: 0,
      totalPage: 0,
    },
    paymentMethodList: [],
    partnershipBundleList: [],
    paginationPartnershipBundle: {
      currentPage: 0,
      totalPage: 0,
    },
    summaryPendaftarBundle: {
      total_pendaftar: 0,
      pendaftar_sudah_verifikasi: 0,
      pendaftar_belum_verifikasi: 0,
    },
    pesertaBundleList: [],
    paginationPesertaBundle: {
      currentPage: 0,
      totalPage: 0,
    },
    partnershipEventList: [],
    paginationPartnershipEvent: {
      currentPage: 0,
      totalPage: 0,
    },
    currentPartnershipEvent: {},
    isiEventList: [],
    currentTryoutPartnerEvent: {},
    subtesListTryoutPartner: [],
    soalPembahasanListPartner: [],
    pesertaEventList: [],
  });

  /* 
   data = {
    page: 1
   }
  */
  const getAllPartnership = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/partner/get_partnership.php",
        params: data,
      });
      if (res.data.success) {
        dispatch({
          type: "getAllPartnership",
          payload: {
            partnershipList: res.data.data.items,
            paginationPartnership: res.data.data.paginationInfo,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    page: 1
   }
  */
  const getAllPartnershipBundle = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/partner/get_partnership_bundle.php",
        params: data,
      });
      if (res.data.success) {
        dispatch({
          type: "getAllPartnershipBundle",
          payload: {
            partnershipBundleList: res.data.data.items,
            paginationPartnershipBundle: res.data.data.paginationInfo,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    page: 1
   }
  */
  const getAllPartnershipEvent = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/partner/get_partnership_event.php",
        params: data,
      });
      if (res.data.success) {
        dispatch({
          type: "getAllPartnershipEvent",
          payload: {
            partnershipEventList: res.data.data.items,
            paginationPartnershipEvent: res.data.data.paginationInfo,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_tryout: 1,
   }
  */
  const getSinglePartnership = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/partner/get_single_partnership.php",
        params: data,
      });
      if (res.data.success) {
        dispatch({
          type: "getSinglePartnership",
          payload: {
            currentPartnership: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_bundle: 1,
   }
  */
  const getSinglePartnershipBundle = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/partner/get_single_partnership_bundle.php",
        params: data,
      });
      if (res.data.success) {
        dispatch({
          type: "getSinglePartnershipBundle",
          payload: {
            currentPartnership: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_event: 1,
   }
  */
  const getSinglePartnershipEvent = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/partner/get_single_partnership_event.php",
        params: data,
      });
      if (res.data.success) {
        dispatch({
          type: "getSinglePartnershipEvent",
          payload: {
            currentPartnershipEvent: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_event: 1
  }
  */
  const getIsiEvent = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/partner/get_isi_event.php",
        params: data,
      });
      if (res.data.success) {
        dispatch({
          type: "getIsiEvent",
          payload: {
            isiEventList: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_event: 1
  }
  */
  const getPesertaEvent = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/partner/get_peserta_event.php",
        params: data,
      });
      if (res.data.success) {
        dispatch({
          type: "getPesertaEvent",
          payload: {
            pesertaEventList: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_tryout: 0,
    kode_promo: "haksaksja",
    profit: 4000,
    lama_promo: 3,
    single: false (opsional)
   }
  */
  const editPartnership = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/partner/edit_partnership.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        if (data.single) {
          dispatch({
            type: "editPartnership",
            payload: {
              currentPartnership: res.data.data,
            },
          });
        } else {
          dispatch({
            type: "editPartnership",
            payload: {
              partnershipList: res.data.data.items,
              paginationPartnership: res.data.data.paginationInfo,
            },
          });
        }
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_tryout: 0,
    kode_promo: "haksaksja",
    single: false (opsional)
   }
  */
  const editPartnershipBundle = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/partner/edit_partnership_bundle.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        if (data.single) {
          dispatch({
            type: "editPartnershipBundle",
            payload: {
              currentPartnership: res.data.data,
            },
          });
        } else {
          dispatch({
            type: "editPartnershipBundle",
            payload: {
              partnershipBundleList: res.data.data.items,
              paginationPartnershipBundle: res.data.data.paginationInfo,
            },
          });
        }
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_tryout: 20227,
    page: 1
  }
  */
  const getPendaftarBelumVerifikasi = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/partner/get_pendaftar_verifikasi_tryout.php",
        params: data,
      });
      if (res.data.success) {
        dispatch({
          type: "getPendaftarBelumVerifikasi",
          payload: {
            verifikasiTryoutList: res.data.data.items,
            paginationVerifikasiTryout: res.data.data.paginationInfo,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_tryout: 20227,
    id_user_list: [2, 3, 18, 20],
    verify: true,
  }
  */
  const verifyUser = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/partner/verif_tryout_single.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        dispatch({
          type: "verifyUser",
          payload: {
            verifikasiTryoutList: res.data.data.items,
            paginationVerifikasiTryout: res.data.data.paginationInfo,
          },
        });
        getSummaryPendaftarTryout({ id_tryout: data.id_tryout });
        getSinglePartnership({ id_tryout: data.id_tryout });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_tryout: 20227
  }
  */
  const getSummaryPendaftarTryout = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/partner/get_summary_pendaftar_tryout.php",
        params: data,
      });
      if (res.data.success) {
        dispatch({
          type: "getSummaryPendaftarTryout",
          payload: {
            summaryPendaftarTryout: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_bundle: 20227
  }
  */
  const getSummaryPendaftarBundle = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/partner/get_summary_pendaftar_bundle.php",
        params: data,
      });
      if (res.data.success) {
        dispatch({
          type: "getSummaryPendaftarBundle",
          payload: {
            summaryPendaftarBundle: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_tryout: 20227,
    page: 1,
    query : '' (opsional),
    sort : 'nama' / 'waktu_verifikasi' (opsional),
    order : 'asc' atau 'desc' (opsional)
  }
  */
  const getPendaftarSudahVerifikasi = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/partner/get_peserta_tryout.php",
        params: data,
      });
      if (res.data.success) {
        dispatch({
          type: "getPendaftarSudahVerifikasi",
          payload: {
            pesertaTryoutList: res.data.data.items,
            paginationPesertaTryout: res.data.data.paginationInfo,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_bundle: 20227,
    page: 1,
    query : '' (opsional),
    sort : 'nama' / 'waktu_verifikasi' (opsional),
    order : 'asc' atau 'desc' (opsional)
  }
  */
  const getPendaftarBundleSudahVerifikasi = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/partner/get_peserta_bundle.php",
        params: data,
      });
      if (res.data.success) {
        dispatch({
          type: "getPendaftarBundleSudahVerifikasi",
          payload: {
            pesertaBundleList: res.data.data.items,
            paginationPesertaBundle: res.data.data.paginationInfo,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_tryout: 20227,
    id_user: 12,
    page: 1,
    query : '' (opsional),
    sort : 'nama' / 'waktu_verifikasi' (opsional),
    order : 'asc' atau 'desc' (opsional)
  }
  */
  const deleteUser = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/partner/delete_user.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        dispatch({
          type: "deleteUser",
          payload: {
            pesertaTryoutList: res.data.data.items,
            paginationPesertaTryout: res.data.data.paginationInfo,
          },
        });
        getSummaryPendaftarTryout({
          id_tryout: data.id_tryout,
        });
        getSinglePartnership({ id_tryout: data.id_tryout });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_tryout: 20227
  }
  */
  const downloadPeserta = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/partner/download_peserta.php",
        params: data,
      });
      fileDownload(res.data, "peserta_tryout.csv");
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_bundle: 20227
  }
  */
  const downloadPesertaBundle = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/partner/download_peserta_bundle.php",
        params: data,
      });
      fileDownload(res.data, "peserta_bundle.csv");
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  const getAllPaymentMethod = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/partner/get_payment_method.php",
      });
      if (res.data.success) {
        dispatch({
          type: "getAllPaymentMethod",
          payload: {
            paymentMethodList: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    nama_metode: "",
    nomor_metode: "",
    nama_pemilik: "",
  }
  */
  const addPaymentMethod = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/partner/add_payment_method.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        dispatch({
          type: "addPaymentMethod",
          payload: {
            paymentMethodList: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_metode: 12,
    nama_metode: "",
    nomor_metode: "",
    nama_pemilik: "",
  }
  */
  const editPaymentMethod = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/partner/edit_payment_method.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        dispatch({
          type: "editPaymentMethod",
          payload: {
            paymentMethodList: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_metode: 12
  }
  */
  const deletePaymentMethod = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/partner/delete_payment_method.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        dispatch({
          type: "deletePaymentMethod",
          payload: {
            paymentMethodList: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_tryout: 1,
   }
  */
  const getSingleTryoutPartnerEvent = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/partner/get_single_tryout_partner.php",
        params: data,
      });
      if (res.data.success) {
        dispatch({
          type: "getSingleTryoutPartnerEvent",
          payload: {
            currentTryoutPartnerEvent: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_tryout: 1,
   }
  */
  const getSubtesTryoutPartner = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/partner/get_subtes_tryout_partner.php",
        params: data,
      });
      if (res.data.success) {
        dispatch({
          type: "getSubtesTryoutPartner",
          payload: {
            subtesListTryoutPartner: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_tryout: 20227,
    subtes: 'tps',
    waktu: 25
  }
  */
  const addSubtes = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/partner/add_subtes.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        dispatch({
          type: "addSubtes",
          payload: {
            subtesListTryoutPartner: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_tryout: 20227,
    subtes: 'tps',
  }
  */
  const deleteSubtes = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/partner/delete_subtes.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        dispatch({
          type: "deleteSubtes",
          payload: {
            subtesListTryoutPartner: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_tryout: 20227,
    subtes: 'tpk'
  }
  */
  const getSoalPembahasanPartner = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/partner/get_soal_pembahasan.php",
        params: data,
      });
      if (res.data.success) {
        dispatch({
          type: "getSoalPembahasanPartner",
          payload: {
            soalPembahasanListPartner: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
  data = {
    id_soal: 43,
    kunci: 'A',
    soal: '',
    pembahasan: '',
    id_tryout: 20227,
    subtes: 'tpk'
  }
  */
  const editSoal = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/partner/edit_soal.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        dispatch({
          type: "editSoal",
          payload: {
            soalPembahasanListPartner: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
  data = {
    id_tryout: 20227,
    subtes: 'tpk',
    nomor: 20    
  }
  */
  const addSoal = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/partner/add_soal.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        dispatch({
          type: "addSoal",
          payload: {
            soalPembahasanListPartner: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_tryout: 20227,
    id_soal: 3,
    subtes: 'tpk'
  }
  */
  const deleteSoal = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/partner/delete_soal.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        dispatch({
          type: "deleteSoal",
          payload: {
            soalPembahasanListPartner: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_user: 0,
    password: "123456"
   }
  */
  const editPasswordUser = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/partner/edit_password_user.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        // do nothing
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  return (
    <PartnerContext.Provider
      value={{
        getAllPartnership,
        editPartnership,
        getSinglePartnership,
        getPendaftarBelumVerifikasi,
        verifyUser,
        getSummaryPendaftarTryout,
        getPendaftarSudahVerifikasi,
        deleteUser,
        downloadPeserta,
        getAllPaymentMethod,
        addPaymentMethod,
        editPaymentMethod,
        deletePaymentMethod,
        getAllPartnershipBundle,
        editPartnershipBundle,
        getSinglePartnershipBundle,
        getSummaryPendaftarBundle,
        getPendaftarBundleSudahVerifikasi,
        downloadPesertaBundle,
        getAllPartnershipEvent,
        getSinglePartnershipEvent,
        getIsiEvent,
        getSingleTryoutPartnerEvent,
        getSubtesTryoutPartner,
        addSubtes,
        deleteSubtes,
        getSoalPembahasanPartner,
        editSoal,
        addSoal,
        deleteSoal,
        getPesertaEvent,
        editPasswordUser,
        currentPartnership: state.currentPartnership,
        partnershipList: state.partnershipList,
        paginationPartnership: state.paginationPartnership,
        verifikasiTryoutList: state.verifikasiTryoutList,
        paginationVerifikasiTryout: state.paginationVerifikasiTryout,
        summaryPendaftarTryout: state.summaryPendaftarTryout,
        pesertaTryoutList: state.pesertaTryoutList,
        paginationPesertaTryout: state.paginationPesertaTryout,
        paymentMethodList: state.paymentMethodList,
        partnershipBundleList: state.partnershipBundleList,
        paginationPartnershipBundle: state.paginationPartnershipBundle,
        summaryPendaftarBundle: state.summaryPendaftarBundle,
        pesertaBundleList: state.pesertaBundleList,
        paginationPesertaBundle: state.paginationPesertaBundle,
        partnershipEventList: state.partnershipEventList,
        paginationPartnershipEvent: state.paginationPartnershipEvent,
        currentPartnershipEvent: state.currentPartnershipEvent,
        isiEventList: state.isiEventList,
        currentTryoutPartnerEvent: state.currentTryoutPartnerEvent,
        subtesListTryoutPartner: state.subtesListTryoutPartner,
        soalPembahasanListPartner: state.soalPembahasanListPartner,
        pesertaEventList: state.pesertaEventList,
      }}
    >
      {children}
    </PartnerContext.Provider>
  );
};

export default PartnerState;
