import axios from "axios";
import { useReducer } from "react";
import { useNavigate } from "react-router-dom";
import AdminContext from "./AdminContext";
import AdminReducer from "./AdminReducer";
import { host } from "../config";

const AdminState = ({ children }) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.token}`;
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(AdminReducer, {
    tryoutListAdmin: [],
    subtesListAdmin: [],
    bundleListAdmin: [],
    soalPembahasanListAdmin: [],
    isiBundleListAdmin: [],
    kontakListAdmin: [],
    paginationKontak: {
      currentPage: 0,
      totalPage: 0,
    },
    verifikasiTryoutList: [],
    paginationVerifikasiTryout: {
      currentPage: 0,
      totalPage: 0,
    },
    summaryPendaftarTryout: {
      total_pendaftar: 0,
      pendaftar_gratis: 0,
      pendaftar_premium: 0,
      pendaftar_bundle: 0,
      pendaftar_sudah_verifikasi: 0,
      pendaftar_belum_verifikasi: 0,
    },
    verifikasiBundleList: [],
    paginationVerifikasiBundle: {
      currentPage: 0,
      totalPage: 0,
    },
    summaryPendaftarBundle: {
      total_pendaftar: 0,
      pendaftar_sudah_verifikasi: 0,
      pendaftar_belum_verifikasi: 0,
    },
    partnerList: [],
    paginationPartner: {
      currentPage: 0,
      totalPage: 0,
    },
    partnershipList: [],
    paginationPartnership: {
      currentPage: 0,
      totalPage: 0,
    },
    currentPartner: {},
    pendaftarPromoList: [],
    pendaftarNonPromoList: [],
    tipeTryoutList: [],
    partnershipBundleList: [],
    paginationPartnershipBundle: {
      currentPage: 0,
      totalPage: 0,
    },
    pendaftarPromoBundleList: [],
    pendaftarNonPromoBundleList: [],
    partnershipEventList: [],
    paginationPartnershipEvent: {
      currentPage: 0,
      totalPage: 0,
    },
    currentPartnershipEvent: {},
    isiEventListAdmin: [],
  });

  const getAllTryoutAdmin = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/admin/get_tryout_admin.php",
      });
      if (res.data.success) {
        dispatch({
          type: "getAllTryoutAdmin",
          payload: {
            tryoutListAdmin: res.data.data,
          },
        });
      } else {
        console.log(res.data.message);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }
      return res.data;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const getAllBundleAdmin = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/admin/get_bundle_admin.php",
      });
      if (res.data.success) {
        dispatch({
          type: "getAllBundleAdmin",
          payload: {
            bundleListAdmin: res.data.data,
          },
        });
      } else {
        console.log(res.data.message);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }
      return res.data;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  /* 
   data = {
    id: parseInt(tryoutData.id),
    nama_tryout: tryoutData.nama_tryout,
    waktu_dibuka: tryoutData.waktu_dibuka,
    waktu_pendaftaran: tryoutData.waktu_pendaftaran,
    pengumuman: tryoutData.pengumuman,
    tampilkan: parseInt(tryoutData.tampilkan),
    link_post: tryoutData.link_post,
    syarat_share: tryoutData.syarat_share,
    tipe: tryoutData.tipe,
    harga: tryoutData.harga,
    profit_partnership: tryoutData.profit_partnership,
    collab_ig: tryoutData.collab_ig
  }
  */
  const editTryout = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/admin/edit_tryout.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        dispatch({
          type: "editTryout",
          payload: {
            tryoutListAdmin: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id: 1,
    nama_tryout: "",
    waktu_dibuka: `2022-12-12`,
    waktu_pendaftaran: 6,
    pengumuman: `2022-12-12`,
    tampilkan: 0,
    link_post: "",
    syarat_share: "",
    tipe: 1,
    harga: 20000,
    profit_partnership: 6000,
    collab_ig: "jalurdalam"
  }
  */
  const addTryout = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/admin/add_tryout.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        dispatch({
          type: "addTryout",
          payload: {
            tryoutListAdmin: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_tryout: 20227
  }
  */
  const getSubtesAdmin = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/admin/get_subtes_admin.php",
        params: data,
      });
      if (res.data.success) {
        dispatch({
          type: "getSubtesAdmin",
          payload: {
            subtesListAdmin: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_tryout: 20227,
    subtes: 'tps',
    waktu: 25
  }
  */
  const addSubtes = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/admin/add_subtes.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        dispatch({
          type: "addSubtes",
          payload: {
            subtesListAdmin: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_tryout: 20227,
    subtes: 'tps',
  }
  */
  const deleteSubtes = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/admin/delete_subtes.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        dispatch({
          type: "deleteSubtes",
          payload: {
            subtesListAdmin: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_tryout: 20227,
    subtes: 'tpk'
  }
  */
  const getSoalPembahasanAdmin = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/admin/get_soal_pembahasan.php",
        params: data,
      });
      if (res.data.success) {
        dispatch({
          type: "getSoalPembahasanAdmin",
          payload: {
            soalPembahasanListAdmin: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
  data = {
    id_soal: 43,
    kunci: 'A',
    soal: '',
    pembahasan: '',
    id_tryout: 20227,
    subtes: 'tpk'
  }
  */
  const editSoal = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/admin/edit_soal.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        dispatch({
          type: "editSoal",
          payload: {
            soalPembahasanListAdmin: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
  data = {
    id_tryout: 20227,
    subtes: 'tpk',
    nomor: 20    
  }
  */
  const addSoal = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/admin/add_soal.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        dispatch({
          type: "addSoal",
          payload: {
            soalPembahasanListAdmin: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_tryout: 20227,
    id_soal: 3,
    subtes: 'tpk'
  }
  */
  const deleteSoal = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/admin/delete_soal.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        dispatch({
          type: "deleteSoal",
          payload: {
            soalPembahasanListAdmin: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_tryout: 20227,
    page: 1
  }
  */
  const getPendaftarBelumVerifikasi = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/admin/get_pendaftar_verifikasi_tryout.php",
        params: data,
      });
      if (res.data.success) {
        dispatch({
          type: "getPendaftarBelumVerifikasi",
          payload: {
            verifikasiTryoutList: res.data.data.items,
            paginationVerifikasiTryout: res.data.data.paginationInfo,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_tryout: 20227,
    id_user_list: [2, 3, 18, 20],
    verify: true,
  }
  */
  const verifyUser = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/admin/verif_tryout_single.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        dispatch({
          type: "verifyUser",
          payload: {
            verifikasiTryoutList: res.data.data.items,
            paginationVerifikasiTryout: res.data.data.paginationInfo,
          },
        });
        getSummaryPendaftarTryout({ id_tryout: data.id_tryout });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_tryout: 20227
  }
  */
  const getSummaryPendaftarTryout = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/admin/get_summary_pendaftar_tryout.php",
        params: data,
      });
      if (res.data.success) {
        dispatch({
          type: "getSummaryPendaftarTryout",
          payload: {
            summaryPendaftarTryout: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id: 1,
    nama_bundle: "",
    harga: 50000,
    tampilkan: 0,
  }
  */
  const addBundle = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/admin/add_bundle.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        dispatch({
          type: "addBundle",
          payload: {
            bundleListAdmin: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id: 1,
    nama_bundle: "",
    harga: 50000,
    tampilkan: 0,
  }
  */
  const editBundle = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/admin/edit_bundle.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        dispatch({
          type: "editBundle",
          payload: {
            bundleListAdmin: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_bundle: 1
  }
  */
  const getIsiBundleAdmin = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/admin/get_isi_bundle_admin.php",
        params: data,
      });
      if (res.data.success) {
        dispatch({
          type: "getIsiBundleAdmin",
          payload: {
            isiBundleListAdmin: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_event: 1
  }
  */
  const getIsiEventAdmin = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/admin/get_isi_event_admin.php",
        params: data,
      });
      if (res.data.success) {
        dispatch({
          type: "getIsiEventAdmin",
          payload: {
            isiEventListAdmin: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_event: 1,
    id_tryout: 20227,
  }
  */
  const addIsiEvent = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/admin/add_isi_event.php",
        data: data,
      });
      if (res.data.success) {
        dispatch({
          type: "addIsiEvent",
          payload: {
            isiEventListAdmin: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_event: 1,
    id_tryout: 20227,
  }
  */
  const deleteIsiEvent = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/admin/delete_isi_event.php",
        data: data,
      });
      if (res.data.success) {
        dispatch({
          type: "deleteIsiEvent",
          payload: {
            isiEventListAdmin: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_bundle: 1,
    id_tryout: 20227,
  }
  */
  const addIsiBundle = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/admin/add_isi_bundle.php",
        data: data,
      });
      if (res.data.success) {
        dispatch({
          type: "addIsiBundle",
          payload: {
            isiBundleListAdmin: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_bundle: 1,
    id_tryout: 20227,
  }
  */
  const deleteIsiBundle = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/admin/delete_isi_bundle.php",
        data: data,
      });
      if (res.data.success) {
        dispatch({
          type: "deleteIsiBundle",
          payload: {
            isiBundleListAdmin: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_bundle: 1,
    page: 1
  }
  */
  const getPendaftarBundleBelumVerifikasi = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/admin/get_pendaftar_verifikasi_bundle.php",
        params: data,
      });
      if (res.data.success) {
        dispatch({
          type: "getPendaftarBundleBelumVerifikasi",
          payload: {
            verifikasiBundleList: res.data.data.items,
            paginationVerifikasiBundle: res.data.data.paginationInfo,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_bundle: 1
  }
  */
  const getSummaryPendaftarBundle = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/admin/get_summary_pendaftar_bundle.php",
        params: data,
      });
      if (res.data.success) {
        dispatch({
          type: "getSummaryPendaftarBundle",
          payload: {
            summaryPendaftarBundle: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_bundle : 0,
    id_user_list : [20, 23, 32, 38],
    verify : true
   }
  */
  const verifyUserBundle = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/admin/verif_tryout_bundle.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        dispatch({
          type: "verifyUserBundle",
          payload: {
            verifikasiBundleList: res.data.data.items,
            paginationVerifikasiBundle: res.data.data.paginationInfo,
          },
        });
        getSummaryPendaftarBundle({ id_bundle: data.id_bundle });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  const generateRanking = async () => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/admin/generate_ranking.php",
      });
      if (res.data.success) {
        console.log(res.data);
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    page: 1
   }
  */
  const getAllKontakAdmin = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/admin/get_kontak_admin.php",
        params: data,
      });
      if (res.data.success) {
        dispatch({
          type: "getAllKontakAdmin",
          payload: {
            kontakListAdmin: res.data.data.items,
            paginationKontak: res.data.data.paginationInfo,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  const generateKontak = async () => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/admin/generate_kontak.php",
      });
      if (res.data.success) {
        dispatch({
          type: "generateKontak",
          payload: {
            kontakListAdmin: res.data.data.items,
            paginationKontak: res.data.data.paginationInfo,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_kontak: 1
   }
  */
  const deleteKontak = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/admin/delete_kontak.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        dispatch({
          type: "deleteKontak",
          payload: {
            kontakListAdmin: res.data.data.items,
            paginationKontak: res.data.data.paginationInfo,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    page: 1
    query : '' (opsional),
    sort : 'nama' / 'total_revenue_jd' / 'pending_revenue_jd' (opsional),
    order : 'asc' atau 'desc' (opsional)
   }
  */
  const getAllPartner = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/admin/get_partner.php",
        params: data,
      });
      if (res.data.success) {
        dispatch({
          type: "getAllPartner",
          payload: {
            partnerList: res.data.data.items,
            paginationPartner: res.data.data.paginationInfo,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_user: 18
   }
  */
  const getSinglePartner = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/admin/get_single_partner.php",
        params: data,
      });
      if (res.data.success) {
        dispatch({
          type: "getSinglePartner",
          payload: {
            currentPartner: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    email: "coba@gmail.com",
    page: 18 (optional),
    query : '' (opsional),
    sort : 'nama' / 'total_revenue_jd' / 'pending_revenue_jd' (opsional),
    order : 'asc' atau 'desc' (opsional)
   }
  */
  const addPartner = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/admin/add_partner.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        dispatch({
          type: "addPartner",
          payload: {
            partnerList: res.data.data.items,
            paginationPartner: res.data.data.paginationInfo,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_user: "12",
    page: 18 (optional),
    query : '' (opsional),
    sort : 'nama' / 'total_revenue_jd' / 'pending_revenue_jd' (opsional),
    order : 'asc' atau 'desc' (opsional)
   }
  */
  const deletePartner = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/admin/delete_partner.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        dispatch({
          type: "deletePartner",
          payload: {
            partnerList: res.data.data.items,
            paginationPartner: res.data.data.paginationInfo,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    page: 1,
    id_user: 18
   }
  */
  const getAllPartnership = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/admin/get_partnership.php",
        params: data,
      });
      if (res.data.success) {
        dispatch({
          type: "getAllPartnership",
          payload: {
            partnershipList: res.data.data.items,
            paginationPartnership: res.data.data.paginationInfo,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    page: 1,
    id_user: 18
   }
  */
  const getAllPartnershipBundle = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/admin/get_partnership_bundle.php",
        params: data,
      });
      if (res.data.success) {
        dispatch({
          type: "getAllPartnershipBundle",
          payload: {
            partnershipBundleList: res.data.data.items,
            paginationPartnershipBundle: res.data.data.paginationInfo,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_user: 18,
    id_tryout: 3,
    page: 1
   }
  */
  const toggleLunasPartnership = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/admin/toggle_lunas_partnership.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        dispatch({
          type: "toggleLunasPartnership",
          payload: {
            partnershipList: res.data.data.items,
            paginationPartnership: res.data.data.paginationInfo,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_tryout: 0,
    id_user: 18,
    kode_promo: "haksaksja",
    profit: 4000,
   }
  */
  const addPartnership = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/admin/add_partnership.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        if (data.id_tryout) {
          dispatch({
            type: "addPartnership",
            payload: {
              partnershipList: res.data.data.items,
              paginationPartnership: res.data.data.paginationInfo,
            },
          });
        } else if (data.id_bundle) {
          dispatch({
            type: "addPartnership",
            payload: {
              partnershipBundleList: res.data.data.items,
              paginationPartnershipBundle: res.data.data.paginationInfo,
            },
          });
        }
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_user: 18,
    nama_event: "Persiapan UTBK 2023",
    logo_url : "https://blablabla.com/gambar.png",
    origin : "namapartner.jalurdalam.com"
   }
  */
  const addPartnershipEvent = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/admin/add_partnership_event.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        dispatch({
          type: "addPartnershipEvent",
          payload: {
            partnershipEventList: res.data.data.items,
            paginationPartnershipEvent: res.data.data.paginationInfo,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    page: 1,
    id_user: 18
   }
  */
  const getAllPartnershipEvent = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/admin/get_partnership_event.php",
        params: data,
      });
      if (res.data.success) {
        dispatch({
          type: "getAllPartnershipEvent",
          payload: {
            partnershipEventList: res.data.data.items,
            paginationPartnershipEvent: res.data.data.paginationInfo,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_event: 1
   }
  */
  const getSinglePartnershipEvent = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/admin/get_single_partnership_event.php",
        params: data,
      });
      if (res.data.success) {
        dispatch({
          type: "getSinglePartnershipEvent",
          payload: {
            currentPartnershipEvent: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_tryout: 0,
    id_user: 18
   }
  */
  const deletePartnership = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/admin/delete_partnership.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        dispatch({
          type: "deletePartnership",
          payload: {
            partnershipList: res.data.data.items,
            paginationPartnership: res.data.data.paginationInfo,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_bundle: 0,
    id_user: 18
   }
  */
  const deletePartnershipBundle = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/admin/delete_partnership_bundle.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        dispatch({
          type: "deletePartnershipBundle",
          payload: {
            partnershipBundleList: res.data.data.items,
            paginationPartnershipBundle: res.data.data.paginationInfo,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_event: 0,
    id_user: 18
   }
  */
  const deletePartnershipEvent = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/admin/delete_partnership_event.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        dispatch({
          type: "deletePartnershipEvent",
          payload: {
            partnershipEventList: res.data.data.items,
            paginationPartnershipEvent: res.data.data.paginationInfo,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
      id_tryout: 20227
    }
  */
  const getDetailPendaftar = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/admin/get_detail_pendaftar.php",
        params: data,
      });
      if (res.data.success) {
        dispatch({
          type: "getDetailPendaftar",
          payload: {
            pendaftarPromoList: res.data.data.pendaftar_promo,
            pendaftarNonPromoList: res.data.data.pendaftar_non_promo,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
      id_bundle: 20227
    }
  */
  const getDetailPendaftarBundle = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/admin/get_detail_pendaftar_bundle.php",
        params: data,
      });
      if (res.data.success) {
        dispatch({
          type: "getDetailPendaftarBundle",
          payload: {
            pendaftarPromoBundleList: res.data.data.pendaftar_promo,
            pendaftarNonPromoBundleList: res.data.data.pendaftar_non_promo,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  const getTipeTryoutList = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/admin/get_tipe_tryout_list.php",
      });
      if (res.data.success) {
        dispatch({
          type: "getTipeTryoutList",
          payload: {
            tipeTryoutList: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  return (
    <AdminContext.Provider
      value={{
        getAllTryoutAdmin,
        getSubtesAdmin,
        getAllBundleAdmin,
        addTryout,
        editTryout,
        addSubtes,
        deleteSubtes,
        getSoalPembahasanAdmin,
        editSoal,
        addSoal,
        deleteSoal,
        getPendaftarBelumVerifikasi,
        verifyUser,
        getSummaryPendaftarTryout,
        addBundle,
        editBundle,
        getIsiBundleAdmin,
        deleteIsiBundle,
        addIsiBundle,
        getPendaftarBundleBelumVerifikasi,
        getSummaryPendaftarBundle,
        verifyUserBundle,
        generateRanking,
        getAllKontakAdmin,
        generateKontak,
        deleteKontak,
        getAllPartner,
        getSinglePartner,
        addPartner,
        deletePartner,
        getAllPartnership,
        toggleLunasPartnership,
        addPartnership,
        deletePartnership,
        getDetailPendaftar,
        getTipeTryoutList,
        getAllPartnershipBundle,
        deletePartnershipBundle,
        getDetailPendaftarBundle,
        addPartnershipEvent,
        getAllPartnershipEvent,
        deletePartnershipEvent,
        getSinglePartnershipEvent,
        getIsiEventAdmin,
        addIsiEvent,
        deleteIsiEvent,
        tryoutListAdmin: state.tryoutListAdmin,
        subtesListAdmin: state.subtesListAdmin,
        soalPembahasanListAdmin: state.soalPembahasanListAdmin,
        verifikasiTryoutList: state.verifikasiTryoutList,
        paginationVerifikasiTryout: state.paginationVerifikasiTryout,
        summaryPendaftarTryout: state.summaryPendaftarTryout,
        bundleListAdmin: state.bundleListAdmin,
        isiBundleListAdmin: state.isiBundleListAdmin,
        verifikasiBundleList: state.verifikasiBundleList,
        paginationVerifikasiBundle: state.paginationVerifikasiBundle,
        summaryPendaftarBundle: state.summaryPendaftarBundle,
        kontakListAdmin: state.kontakListAdmin,
        paginationKontak: state.paginationKontak,
        partnerList: state.partnerList,
        paginationPartner: state.paginationPartner,
        currentPartner: state.currentPartner,
        partnershipList: state.partnershipList,
        paginationPartnership: state.paginationPartnership,
        pendaftarPromoList: state.pendaftarPromoList,
        pendaftarNonPromoList: state.pendaftarNonPromoList,
        tipeTryoutList: state.tipeTryoutList,
        partnershipBundleList: state.partnershipBundleList,
        paginationPartnershipBundle: state.paginationPartnershipBundle,
        pendaftarPromoBundleList: state.pendaftarPromoBundleList,
        pendaftarNonPromoBundleList: state.pendaftarNonPromoBundleList,
        partnershipEventList: state.partnershipEventList,
        paginationPartnershipEvent: state.paginationPartnershipEvent,
        currentPartnershipEvent: state.currentPartnershipEvent,
        isiEventListAdmin: state.isiEventListAdmin,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export default AdminState;
