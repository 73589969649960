/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useRef } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ImageUploading from "react-images-uploading";
import Select, { createFilter } from "react-select";
import HeaderJD from "../../components/Layout/HeaderJD";
import TryoutContext from "../../context/TryoutContext/TryoutContext";
import caraBayar from "../../script/caraBayar";
import formatPrice from "../../script/formatPrice";
import FormatPrice from "../../components/Layout/FormatPrice";

const BundleRegister = () => {
  const { bundleId } = useParams();
  const navigate = useNavigate();
  const { bundleList, daftarTryoutBundle, checkPromo } =
    useContext(TryoutContext);
  const [currentBundle, setCurrentBundle] = useState({});
  // const [timerState, setTimerState] = useState({
  //   hours: 0,
  //   minutes: 0,
  //   seconds: 0,
  // });
  const [setelahDaftar, setSetelahDaftar] = useState(false);
  useEffect(() => {
    if (bundleList && bundleList.length) {
      const bundle = bundleList.find(
        (item) => String(item.id) === String(bundleId)
      );
      if (bundle) {
        if (bundle.waktu_verifikasi) {
          if (!setelahDaftar) navigate("/tryout");
          // } else if (bundle.timer === null) {
          //   resetTimer({
          //     id_tryout: bundle.id,
          //     new_data: true,
          //   });
        } else {
          setCurrentBundle(bundle);
          setBundleForm({
            ...bundleForm,
            bundle: bundle.id,
          });
        }
        setSetelahDaftar(true);
      } else {
        navigate("/tryout");
      }
    }
  }, [bundleList]);

  const [bundleForm, setBundleForm] = useState({
    bundle: "",
    buktiBayar: null,
    payment: "",
  });

  const [alertText, setAlertText] = useState({
    show: false,
    text: "",
    success: true,
  });

  const setSuccessAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: true,
    });
  };
  const setErrorAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: false,
    });
  };
  const hideAlert = () => {
    setAlertText({
      show: false,
      text: alertText.message,
      success: alertText.success,
    });
  };

  const createHandlerFunction = (id) => {
    return function (imageList, addUpdateIndex) {
      // data for submit
      if (imageList.length) {
        setBundleForm({
          ...bundleForm,
          [id]: imageList[addUpdateIndex[0]],
        });
      } else {
        setBundleForm({
          ...bundleForm,
          [id]: null,
        });
      }
    };
  };

  const [expandedMethod, setExpandedMethod] = useState("");
  const paymentOption = caraBayar.map((item) => ({
    value: item.value,
    label: item.name,
  }));

  // promo
  const [alertText2, setAlertText2] = useState({
    show: false,
    text: "",
    success: true,
  });

  const setSuccessAlert2 = (message) => {
    setAlertText2({
      show: true,
      text: message,
      success: true,
    });
  };
  const setErrorAlert2 = (message) => {
    setAlertText2({
      show: true,
      text: message,
      success: false,
    });
  };
  const hideAlert2 = () => {
    setAlertText2({
      show: false,
      text: alertText2.message,
      success: alertText2.success,
    });
  };
  const [kodePromo, setKodePromo] = useState("");
  const [currentPromo, setCurrentPromo] = useState(null);
  const submitPromoRef = useRef(<></>);

  return (
    <>
      <div className="flex flex-col bg-white p-10 justify-start m-0 w-full">
        <HeaderJD title={`Pendaftaran ${currentBundle.nama_bundle || ""}`} />

        {/* Tryout kamu */}
        <div className="flex flex-col w-full md:w-full-10 mt-10">
          <p className="font-subtitle font-bold">
            Cukup isi formulir di bawah ini, kamu bisa dapet{" "}
            {currentBundle.nama_bundle} Jalur Dalam!
          </p>
          <div className="flex flex-col gap-10 w-full md:w-3/5 mt-5">
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                const button = submitPromoRef.current;
                button.disabled = true;
                hideAlert2();
                const res = await checkPromo({
                  id_bundle: currentBundle.id,
                  kode_promo: kodePromo,
                });
                if (res.success) {
                  const promo = res.data.promo;
                  const diskon =
                    parseInt(currentBundle.harga) -
                    parseInt(promo.profit_jd) -
                    parseInt(promo.profit_partner);
                  setCurrentPromo({ ...promo, diskon });
                  setSuccessAlert2(
                    `Kode voucher berhasil dipakai.${
                      diskon > 0
                        ? ` Kamu mendapat diskon tambahan sebesar ${formatPrice(
                            diskon
                          )}`
                        : ` Kamu mendapat diskon tambahan sebesar ${formatPrice(
                            diskon
                          )}`
                    } `
                  );
                } else {
                  setCurrentPromo(null);
                  setErrorAlert2("Kode voucher tidak valid.");
                }
                button.disabled = false;
              }}
              className="flex w-full flex-col md:flex-row gap-4"
            >
              <div className="flex w-full md:w-2/3 items-center justify-between gap-5">
                <div className="flex items-center w-1/2 gap-2 md:gap-3">
                  <p className="font-content-1">Punya Kode Voucher?</p>
                </div>

                <div className="flex w-2/3 md:w-1/2 border-2 border-black rounded-xl items-center">
                  <input
                    className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
                    type="text"
                    value={kodePromo}
                    onChange={(e) => {
                      if (String(e.target.value).match(/^[a-zA-Z0-9]*$/)) {
                        setKodePromo(e.target.value);
                      }
                    }}
                    required
                    disabled={!!currentPromo}
                  />
                </div>
              </div>
              <div className="flex w-full md:w-auto items-center justify-start">
                {currentPromo === null ? (
                  <button
                    ref={submitPromoRef}
                    type="submit"
                    className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-full text-white rounded-xl m-auto"
                  >
                    Pakai
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      hideAlert2();
                      setKodePromo("");
                      setCurrentPromo(null);
                    }}
                    type="button"
                    className="bg-merah font-content-0 font-bold flex items-center justify-center px-4 py-2 w-full text-white rounded-xl m-auto"
                  >
                    Hapus
                  </button>
                )}
              </div>
            </form>
            <div
              className={`${
                alertText2.show ? "flex" : "hidden"
              } p-4 mb-4 text-sm ${
                alertText2.success
                  ? "text-green-700 bg-green-100"
                  : "text-red-700 bg-red-100"
              } rounded-lg`}
              role="alert"
            >
              <svg
                className="inline flex-shrink-0 mr-3 w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <div>{alertText2.text}</div>
            </div>

            <div className="flex flex-col justify-center bg-white shadow-lg rounded-2xl p-7 gap-3 w-full">
              <div className="flex items-center gap-5">
                <p className="font-content-0 font-bold">Cara Daftar</p>
              </div>
              <ol style={{ listStyle: "auto" }} className="pl-3">
                <li className="font-content-1">
                  Lakukan pembayaran dengan salah satu metode berikut
                </li>
                <div className="flex flex-col gap-3 pl-1 w-4/5 mb-4">
                  {caraBayar.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => setExpandedMethod(item.value)}
                      className={`relative overflow-hidden cursor-pointer flex flex-col justify-center bg-white shadow-lg rounded-2xl p-3 ${
                        expandedMethod === item.value ? "gap-2" : ""
                      }`}
                    >
                      <div className="flex justify-between items-center">
                        <p className="font-content-1 font-bold">{item.name}</p>
                        <i className="fa-solid fa-angle-right text-abu"></i>
                      </div>
                      <div
                        className={`${
                          expandedMethod === item.value
                            ? "max-h-32 animate-[fadeend_.5s_ease]"
                            : "max-h-0 opacity-0  animate-[fadeendclose_.5s_ease]"
                        } flex flex-col gap-1`}
                      >
                        <p className="font-content-1">Nomor : {item.no}</p>
                        <p className="font-content-1">
                          Nama pemilik : {item.pemilik}
                        </p>
                        <p className="font-content-1">
                          Nominal :{" "}
                          <FormatPrice
                            price={
                              currentBundle.harga - (currentPromo?.diskon || 0)
                            }
                          />
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                <li className="font-content-1">
                  Screenshot bukti pembayaran dan unggah pada formulir di bawah
                </li>
              </ol>
            </div>
            <div className="flex w-full items-center justify-between gap-5">
              <div className="flex items-center w-1/3 gap-2 md:gap-3">
                <p className="font-content-1">Metode Pembayaran</p>
              </div>

              <div className="flex w-2/3 border-2 border-black rounded-xl items-center select-container">
                <Select
                  placeholder="Pilih metode pembayaran"
                  styles={{
                    control: () => ({
                      alignItems: "center",
                      border: "none",
                      display: "flex",
                      flexWrap: "wrap",
                      WebkitBoxPack: "justify",
                      justifyContent: "space-between",
                      minHeight: "38px",
                      position: "relative",
                    }),
                  }}
                  className="w-full border-none outline-none font-content-1"
                  onChange={(val) => {
                    if (val.value !== bundleForm.payment) {
                      setBundleForm({ ...bundleForm, payment: val.value });
                    }
                  }}
                  options={paymentOption}
                  filterOption={createFilter({ ignoreAccents: false })}
                  value={
                    paymentOption.find(
                      (item) => item.value === bundleForm.payment
                    ) || { value: null, label: "Pilih metode pembayaran" }
                  }
                />
              </div>
            </div>

            <div className="flex w-full items-center justify-between gap-5">
              <div className="flex items-center w-1/3 gap-2 md:gap-3">
                <p className="font-content-1">Bukti Transfer</p>
              </div>

              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                className={`flex flex-col gap-3 w-2/3 ${
                  bundleForm.buktiBayar ? "" : "cursor-pointer"
                }`}
              >
                <div className="w-full flex border-2 border-black rounded-xl items-center">
                  <ImageUploading
                    multiple
                    value={bundleForm.buktiBayar ? [bundleForm.buktiBayar] : []}
                    onChange={createHandlerFunction("buktiBayar")}
                    maxNumber={2}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      isDragging,
                      dragProps,
                    }) => {
                      return (
                        // write your building UI
                        <div className="w-full p-2">
                          {!imageList.length ? (
                            <div
                              className="rounded-xl flex flex-col text-center items-center justify-center w-full py-12 font-content-1"
                              style={
                                isDragging
                                  ? { border: "1px solid black" }
                                  : null
                              }
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              <i className="fa-solid fa-upload text-3xl"></i>
                              Klik atau tarik gambar ke sini (max 2MB)
                            </div>
                          ) : (
                            imageList.map((image, index) => (
                              <div
                                key={index}
                                className="flex flex-col items-center gap-2"
                              >
                                <img src={image.dataURL} alt="profile" />
                                <div>
                                  <button
                                    className="text-center bg-merah text-white rounded-xl flex-grow px-4 py-1 font-content-0 font-bold"
                                    onClick={() => onImageRemoveAll()}
                                  >
                                    Hapus
                                  </button>
                                </div>
                              </div>
                            ))
                          )}
                        </div>
                      );
                    }}
                  </ImageUploading>
                </div>
              </form>
            </div>

            <div
              className={`${
                alertText.show ? "flex" : "hidden"
              } p-4 mb-4 text-sm ${
                alertText.success
                  ? "text-green-700 bg-green-100"
                  : "text-red-700 bg-red-100"
              } rounded-lg`}
              role="alert"
            >
              <svg
                className="inline flex-shrink-0 mr-3 w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <div>{alertText.text}</div>
            </div>

            <button
              type="button"
              className="rounded-xl bg-oren text-white font-content-0 font-bold px-8 py-2"
              onClick={async (e) => {
                const button = e.target;
                button.disabled = true;
                hideAlert();
                if (!bundleForm.payment) {
                  setErrorAlert("Metode pembayaran wajib diisi.");
                  button.disabled = false;
                } else if (!bundleForm.buktiBayar) {
                  setErrorAlert("Bukti pembayaran wajib diisi.");
                  button.disabled = false;
                } else if (bundleForm.buktiBayar.file.size > 2000000) {
                  setErrorAlert(
                    "Ukuran bukti pembayaran harus kurang dari 2MB!"
                  );
                  button.disabled = false;
                } else {
                  const res = await daftarTryoutBundle({
                    ...bundleForm,
                    buktiBayar: bundleForm.buktiBayar.file,
                    id_partnership_bundle: currentPromo?.id || null,
                  });
                  if (res.success) {
                    if (window.timerInterval)
                      clearInterval(window.timerInterval);
                    setSuccessAlert(
                      "Bukti transfer berhasil dikirim. Halaman akan dialihkan dalam 5 detik..."
                    );
                    setTimeout(() => {
                      navigate("/tryout");
                    }, 5000);
                  } else {
                    setErrorAlert(
                      res.display_message ||
                        "Terjadi kesalahan, coba kembali nanti"
                    );
                    button.disabled = false;
                  }
                }
              }}
            >
              Beli Bundle
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BundleRegister;
