/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import HeaderJD from "../../components/Layout/HeaderJD";
import Modal from "../../components/Home/Modal";
import PartnerContext from "../../context/PartnerContext/PartnerContext";
import EditPasswordUserComponent from "../../components/Partner/EditPasswordUserComponent";

const DetailPartnershipEvent = () => {
  const { idEvent } = useParams();
  const navigate = useNavigate();
  const {
    currentPartnershipEvent,
    isiEventList,
    getSinglePartnershipEvent,
    getIsiEvent,
    pesertaEventList,
    getPesertaEvent,
    editPasswordUser,
  } = useContext(PartnerContext);
  useEffect(() => {
    (async () => {
      const res = await getSinglePartnershipEvent({ id_event: idEvent });
      if (!res?.data) {
        navigate("/partner-area");
      }
      getIsiEvent({ id_event: idEvent });
      getPesertaEvent({ id_event: idEvent });
    })();
  }, []);

  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState(<></>);
  return (
    <>
      <div className="flex flex-col bg-white p-10 justify-start m-0 w-full">
        <HeaderJD
          title={`Detail Event ${currentPartnershipEvent.nama_event}`}
        />

        <div className="flex flex-col w-full md:w-full-10 mt-10 gap-3">
          <p className="font-subtitle font-bold">Daftar Isi Event</p>

          <div className="w-full overflow-auto flex flex-col items-start gap-2">
            {isiEventList.filter((item) => item.aktif).length === 0 ? (
              <p className="font-content-0">
                Belum ada try out yang ditambahkan oleh admin.
              </p>
            ) : (
              ""
            )}
            <table className="tabel-jd flex flex-row flex-nowrap w-full md:inline-table">
              <thead>
                {isiEventList
                  .filter((item) => item.aktif)
                  .map((item, index) => {
                    return (
                      <tr
                        key={index}
                        className={`${
                          index === 0 ? "md:table-row" : "md:hidden"
                        } flex flex-col mt-5 md:mt-0`}
                      >
                        <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                          No
                        </th>
                        <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                          Nama Try Out
                        </th>
                        <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                          Tanggal Dibuka
                        </th>
                        <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                          Tanggal Pengumuman
                        </th>
                        <th className="p-2 py-4 md:py-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none md:rounded-r-xl overflow-hidden row-span-3">
                          Edit Soal
                        </th>
                      </tr>
                    );
                  })}
              </thead>
              <tbody className="flex-1 md:flex-none">
                {isiEventList
                  .filter((item) => item.aktif)
                  .map((item, index) => {
                    return (
                      <tr
                        className="flex flex-col md:table-row mt-5 md:mt-0"
                        key={index}
                      >
                        <td className="p-2 font-content-1 text-center">
                          {index + 1}
                        </td>
                        <td className="p-2 font-content-1 text-center">
                          {item.nama_tryout}
                        </td>
                        <td className="p-2 font-content-1 text-center">
                          {item.waktu_dibuka}
                        </td>
                        <td className="p-2 font-content-1 text-center">
                          {item.pengumuman}
                        </td>
                        <td className="p-2 font-content-1 justify-center flex md:table-cell">
                          <Link
                            to={`/partner-area/event/edit-tryout/${item.id_tryout}`}
                            className="text-oren hover:underline font-bold"
                          >
                            Edit Soal
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>

        <div className="flex flex-col w-full md:w-full-10 mt-10 gap-3">
          <p className="font-subtitle font-bold">Daftar Peserta Event</p>

          <div className="w-full overflow-auto flex flex-col items-start gap-2">
            {pesertaEventList.length === 0 ? (
              <p className="font-content-0">
                Belum ada peserta yang mendaftar.
              </p>
            ) : (
              ""
            )}
            <table className="tabel-jd flex flex-row flex-nowrap w-full md:inline-table">
              <thead>
                {pesertaEventList.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={`${
                        index === 0 ? "md:table-row" : "md:hidden"
                      } flex flex-col mt-5 md:mt-0`}
                    >
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                        No
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                        Nama
                      </th>
                      <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                        Email
                      </th>
                      <th className="p-2 py-4 md:py-2 font-content-1 font-bold bg-krem rounded-l-xl md:rounded-l-none md:rounded-r-xl overflow-hidden row-span-3">
                        Ubah Password
                      </th>
                    </tr>
                  );
                })}
              </thead>
              <tbody className="flex-1 md:flex-none">
                {pesertaEventList.map((item, index) => {
                  return (
                    <tr
                      className="flex flex-col md:table-row mt-5 md:mt-0"
                      key={index}
                    >
                      <td className="p-2 font-content-1 text-center">
                        {index + 1}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {item.nama}
                      </td>
                      <td className="p-2 font-content-1 text-center">
                        {item.email}
                      </td>
                      <td className="p-2 font-content-1 justify-center flex md:table-cell">
                        <div className="flex justify-center items-center">
                          <button
                            onClick={() => {
                              setModalContent(
                                <EditPasswordUserComponent
                                  setOpen={setOpen}
                                  currentUser={item}
                                  editPasswordUser={editPasswordUser}
                                />
                              );
                              setOpen(true);
                            }}
                            className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl"
                          >
                            Ubah Password
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal {...{ open, setOpen }}>
        <div className="relative text-left w-11/12 md:w-2/3 lg:w-1/2 xl:w-2/5 z-50 flex flex-col text-xl font-bold bg-white rounded-2xl">
          {modalContent}
        </div>
      </Modal>
    </>
  );
};

export default DetailPartnershipEvent;
