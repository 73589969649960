const PageComponent = ({ currentPage, totalPage, getData }) => {
  const start = currentPage > 2 ? currentPage - 2 : 1;
  const pageCount = window.innerWidth < 520 ? 2 : 4;
  const end = start + pageCount > totalPage ? totalPage : start + pageCount;
  const pageList = [];
  for (let i = start; i <= end; i++) {
    pageList.push(i);
  }

  return (
    <>
      {currentPage !== 1 && (
        <div
          onClick={() => getData(1)}
          className="rounded-full w-10 h-10 bg-krem flex justify-center items-center font-content-1 cursor-pointer"
        >
          &lt;&lt;
        </div>
      )}

      {start !== 1 && (
        <div className="rounded-full w-10 h-10 bg-krem flex justify-center items-center font-content-1 cursor-default">
          ...
        </div>
      )}

      {pageList.map((item, index) => (
        <div
          key={index}
          onClick={() => {
            if (item !== currentPage) getData(item);
          }}
          className={`rounded-full w-10 h-10 flex justify-center items-center font-content-1 ${
            currentPage === item
              ? "text-white bg-oren cursor-default"
              : "bg-krem cursor-pointer"
          }`}
        >
          {item}
        </div>
      ))}

      {end !== totalPage && (
        <div className="rounded-full w-10 h-10 bg-krem flex justify-center items-center font-content-1 cursor-default">
          ...
        </div>
      )}

      {currentPage !== totalPage && (
        <div
          onClick={() => getData(totalPage)}
          className="rounded-full w-10 h-10 bg-krem flex justify-center items-center font-content-1 cursor-pointer"
        >
          &gt;&gt;
        </div>
      )}
    </>
  );
};

export default PageComponent;
