import axios from "axios";
import { useReducer } from "react";
import { useNavigate } from "react-router-dom";
import TryoutContext from "./TryoutContext";
import TryoutReducer from "./TryoutReducer";
import { host } from "../config";

const TryoutState = ({ children }) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.token}`;
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(TryoutReducer, {
    tryoutList: [],
    sedangDikerjakan: "",
    subtesList: [],
    soalList: [],
    jawabanList: [],
    subtesSkorList: [],
    soalPembahasanList: [],
    rankingList: [],
    paginationRanking: {
      currentPage: 0,
      totalPage: 0,
    },
    hasilTryoutList: [],
    bundleList: [],
  });

  const getAllTryout = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/tryout/get_tryout.php",
        headers: {
          "Content-type": "application/json",
        },
      });
      if (res.data.success) {
        dispatch({
          type: "getAllTryout",
          payload: {
            tryoutList: res.data.data,
          },
        });
        return true;
      } else {
        console.log(res.data.message);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const setSedangDikerjakan = (path) => {
    dispatch({
      type: "setSedangDikerjakan",
      payload: {
        sedangDikerjakan: path,
      },
    });
  };

  const getSubtes = async (idTryout) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/tryout/get_subtes.php",
        params: { idTryout },
      });
      if (res.data.success) {
        dispatch({
          type: "getSubtes",
          payload: {
            subtesList: res.data.data,
          },
        });
        return true;
      } else {
        console.log(res.data.message);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  /* 
   data = {
    id_tryout,
    id_user,
    new_data
   }
  */
  const resetTimer = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/tryout/reset_timer.php",
        data: data,
        headers: { "content-type": "application/json" },
      });
      if (res.data.success) {
        dispatch({
          type: "resetTimer",
          payload: {
            tryoutList: res.data.data,
          },
        });
        return true;
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  /* 
   data = {
    tryout: 20227, (id tryout)
    premium: false,
    buktiFollow: null,
    buktiKomen: null,
    buktiShare: null,
    buktiBayar: null,
    payment: "",
   }
  */
  const daftarTryout = async (data) => {
    const newData = { ...data };
    if (newData.premium) {
      delete newData.buktiFollow;
      delete newData.buktiKomen;
      delete newData.buktiShare;
    } else {
      delete newData.buktiBayar;
    }

    const formData = new FormData();
    Object.keys(newData).forEach((prop) => {
      if (newData[prop] !== null) formData.append(prop, newData[prop]);
    });

    try {
      const res = await axios({
        method: "POST",
        url: host + "/tryout/daftar_tryout.php",
        data: formData,
        headers: { "content-type": "multipart/form-data" },
      });
      if (res.data.success) {
        dispatch({
          type: "daftarTryout",
          payload: {
            tryoutList: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_tryout: 20227
   }
  */
  const daftarTryoutEvent = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/tryout/daftar_tryout_event.php",
        data: data,
        headers: { "content-type": "application/json" },
      });
      if (res.data.success) {
        dispatch({
          type: "daftarTryoutEvent",
          payload: {
            tryoutList: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_jurusan : 0,
    id_tryout : 0
   }
  */
  const kerjakanTryout = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/tryout/kerjakan_tryout.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        dispatch({
          type: "kerjakanTryout",
          payload: {
            tryoutList: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    subtes : 'tpk',
    id_tryout : 0
   }
  */
  const kerjakanSubtes = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/tryout/kerjakan_subtes.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        dispatch({
          type: "kerjakanSubtes",
          payload: {
            subtesList: res.data.data,
            sedangDikerjakan: `/tryout/${data.id_tryout}/kerjakan/${data.subtes}`,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    subtes : 'tpk',
    id_tryout : 0
   }
  */
  const getSoal = async (data) => {
    dispatch({
      type: "pregetSoal",
      payload: {
        soalList: [],
      },
    });
    try {
      const res = await axios({
        method: "GET",
        url: host + "/tryout/get_soal.php",
        params: { ...data },
      });
      if (res.data.success) {
        dispatch({
          type: "getSoal",
          payload: {
            soalList: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    subtes : 'tpk',
    id_tryout : 0
   }
  */
  const getJawaban = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/tryout/get_jawaban.php",
        params: { ...data },
      });
      if (res.data.success) {
        const jawaban = res.data.data.jawaban;

        dispatch({
          type: "getJawaban",
          payload: {
            jawabanList: String(jawaban).split(","),
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
      id_tryout: 20227,
      subtes: 'tpk',
      jawaban: 'A,B,C,A,A,B,D,A'
    }
  */
  const jawabSoal = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/tryout/jawab_soal.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        const jawaban = res.data.data.jawaban;
        dispatch({
          type: "jawabSoal",
          payload: {
            jawabanList: String(jawaban).split(","),
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
      id_tryout: 20227,
      subtes: 'tpk',
    }
  */
  const finishSubtes = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/tryout/finish_subtes.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        dispatch({
          type: "finishSubtes",
          payload: {
            subtesList: res.data.data,
            jawabanList: [],
            soalList: [],
            sedangDikerjakan: "",
          },
        });
        localStorage.removeItem("flagList");
        localStorage.removeItem("flagUrl");
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
      id_tryout: 20227
    }
  */
  const finishTryout = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/tryout/finish_tryout.php",
        data: data,
        headers: { "Content-type": "application/json" },
      });
      if (res.data.success) {
        dispatch({
          type: "finishTryout",
          payload: {
            tryoutList: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  const getSubtesSkor = async (idTryout) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/tryout/get_subtes_skor.php",
        params: { idTryout },
      });
      if (res.data.success) {
        dispatch({
          type: "getSubtesSkor",
          payload: {
            subtesSkorList: res.data.data,
          },
        });
        return true;
      } else {
        console.log(res.data.message);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  /* 
   data = {
    subtes : 'tpk',
    id_tryout : 0
   }
  */
  const getSoalPembahasan = async (data) => {
    dispatch({
      type: "pregetSoalPembahasan",
      payload: {
        soalPembahasanList: [],
      },
    });
    try {
      const res = await axios({
        method: "GET",
        url: host + "/tryout/get_soal_pembahasan.php",
        params: { ...data },
      });
      if (res.data.success) {
        dispatch({
          type: "getSoalPembahasan",
          payload: {
            soalPembahasanList: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_tryout : 20227,
    klaster : 1 atau 2,
    page : 1 (opsional),
    query : '' (opsional),
    sort : 'nama' / 'asal' / 'rerata' (opsional),
    order : 'asc' atau 'desc' (opsional)    
   }
  */
  const getRankingList = async (data) => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/tryout/get_ranking_list.php",
        params: data,
      });
      if (res.data.success) {
        dispatch({
          type: "getRankingList",
          payload: {
            rankingList: res.data.data.items,
            paginationRanking: res.data.data.paginationInfo,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  const getHasilTryout = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/tryout/get_hasil_tryout.php",
      });
      if (res.data.success) {
        dispatch({
          type: "getHasilTryout",
          payload: {
            hasilTryoutList: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  const getAllBundle = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: host + "/tryout/get_bundle.php",
        headers: {
          "Content-type": "application/json",
        },
      });
      if (res.data.success) {
        dispatch({
          type: "getAllBundle",
          payload: {
            bundleList: res.data.data.filter((item) =>
              parseInt(item.tampilkan)
            ),
          },
        });
        return true;
      } else {
        console.log(res.data.message);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  /* 
   data = {
    bundle: 1, (id bundle)
    buktiBayar: null,
    payment: "",
   }
  */
  const daftarTryoutBundle = async (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((prop) => {
      if (data[prop] !== null) formData.append(prop, data[prop]);
    });

    try {
      const res = await axios({
        method: "POST",
        url: host + "/tryout/daftar_tryout_bundle.php",
        data: formData,
        headers: { "content-type": "multipart/form-data" },
      });
      if (res.data.success) {
        dispatch({
          type: "daftarTryoutBundle",
          payload: {
            bundleList: res.data.data,
          },
        });
      } else {
        console.log(res.data);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
      }

      return res.data;
    } catch (err) {
      console.log(err);
      return {
        success: false,
        display_message: "Terjadi kesalahan, silakan coba kembali",
      };
    }
  };

  /* 
   data = {
    id_tryout: 3,
    kode_promo: "ASJDSAHDA"
   }
  */
  const checkPromo = async (data) => {
    try {
      const res = await axios({
        method: "POST",
        url: host + "/tryout/check_promo.php",
        data: data,
        headers: {
          "Content-type": "application/json",
        },
      });
      if (res.data.success) {
      } else {
        console.log(res.data.message);
        if (res.data.status === 403) {
          delete localStorage.token;
          navigate("/");
        }
        return false;
      }
      return res.data;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const clearVariable = (data) => {
    dispatch({
      type: "clearVariable",
      payload: {
        ...data,
      },
    });
  };

  return (
    <TryoutContext.Provider
      value={{
        tryoutList: state.tryoutList,
        sedangDikerjakan: state.sedangDikerjakan,
        subtesList: state.subtesList,
        soalList: state.soalList,
        jawabanList: state.jawabanList,
        subtesSkorList: state.subtesSkorList,
        soalPembahasanList: state.soalPembahasanList,
        rankingList: state.rankingList,
        paginationRanking: state.paginationRanking,
        hasilTryoutList: state.hasilTryoutList,
        bundleList: state.bundleList,
        getAllTryout,
        setSedangDikerjakan,
        resetTimer,
        daftarTryout,
        getSubtes,
        kerjakanTryout,
        kerjakanSubtes,
        getSoal,
        getJawaban,
        jawabSoal,
        finishSubtes,
        finishTryout,
        getSubtesSkor,
        getSoalPembahasan,
        getRankingList,
        getHasilTryout,
        getAllBundle,
        daftarTryoutBundle,
        checkPromo,
        clearVariable,
        daftarTryoutEvent,
      }}
    >
      {children}
    </TryoutContext.Provider>
  );
};

export default TryoutState;
