const FormatPrice = ({ price }) => {
  const localeString = parseInt(price).toLocaleString("id", {
    style: "currency",
    currency: "IDR",
  });
  const result = localeString.substring(0, localeString.length - 3);
  return <>{result}</>;
};

export default FormatPrice;
